@import "../../../../assets/css/variables.less";

.myWork-studentMileStoneSec{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    &__noDataSec{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-inline: auto;
        border-radius: 10px;
        padding: 60px 40px;
        min-height: 100px;
        min-width: 260px;
        max-width: 500px;
        margin-top: 200px;
        transition: all 0.2s ease-out;
        box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.1);
        border: 1px solid #cccccc;
        background-color: #fff;
        &__textBlack{
            text-align: center;
            font-family:  @font--poppins-regular;
            margin-bottom: 0;
            color: #000;
        }
    }
}