@import "../../../../../assets/css/variables.less";

.myWork-planCard-unApproved {
    border: 1px solid #E64A19;
}
.myWork-planCard-suggested {
    border: 1.5px solid #29a016;
}
.myWork-planCard {
    border: 1px solid #8A8A8A;
}

.myWork-planCard,
.myWork-planCard-suggested,
.myWork-planCard-unApproved {
    border-radius: 10px;
    width: 280px;
    background-color: #fff;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;

    &__topSec {
        padding: 5px 10px;
        background-color: @backcolor--primary;
        height: 40px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        width: 100%;
        display: flex;
        // justify-content: space-between;
        align-items: center;

        &__title {
            margin-inline: auto;
            color: @backcolor--white;
            font-size: 16px;
            font-family: @font--poppins-500;
            margin-bottom: 0px;
            display: -webkit-box;
            max-width: 240px;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

    }
    .cardgrid__dot{
        background-color: transparent;
        border: none;
        .dropdown-toggle{
            background-color: transparent;
            border: none;
        }
        .dropdown-toggle::after{
            display: none;
        }
    }
    &__midtop{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 30px;
        padding-right: 4px;
        &__pill{
            background-color: #E5FFE1;
            border: 2px solid #29A016;
            padding: 0 10px;
            border-radius: 20px;
            color: #29A016;
            font-size: 12px;
            font-family: @font--poppins-500;
            margin-bottom: 0px;
        }
    }
    &__midSec {
        padding: 5px 10px;
        .ChipSections{
            &__header{
                font-size: 14px;
                font-family: @font--poppins-500;
            }
        }
    }

    &__suggestionSec {
        margin-top: auto;
        padding: 15px 10px;

        &__dangerText, &__successText{
            font-family: @font--poppins-regular;
            font-size: 13px;
            text-align: center;
            margin: 0;
            line-height: 24px;
            text-wrap: balance;
        }
        &__dangerText{
            color: #E64A19;
        }
        &__successText{
            color: #29a016;
        }
        &__chatIcon {
            width: 12px !important;
            height: 12px !important;
            color: #515050 !important;
            border-radius: 50%;
            margin-left: 5px;
            margin-bottom: 5px;
            cursor: pointer;
        }

    }

    &__ctaSection {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: auto;
        gap: 10px;
        .MuiAvatar-root {
            border: 1px solid #8A8A8A !important;
            width: 35px !important;
            height: 35px !important;
        }

        &__primary-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #1C84EE;
            margin-top: auto;
            color: #fff;
            border: none;
            width: 85%;
            height: 42px;
            border-radius: 10px;
            font-family: @font--poppins-500;
            cursor: pointer;
        }

        &__outline-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            margin-top: auto;
            color: #1C84EE;
            border: 1px solid #1C84EE;
            width: 85%;
            height: 42px;
            border-radius: 10px;
            font-family: @font--poppins-500;
            padding-inline: 1em;
            cursor: pointer;
        }

        &__flat-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            margin-top: auto;
            color: #1C84EE;
            border: none;
            width: 85%;
            height: 42px;
            border-radius: 10px;
            font-family: @font--poppins-500;
            cursor: pointer;
        }
    }

    &__btnSection {
        margin-top: auto;

        &__Cnt {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            padding: 10px;

            &__primary-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #1C84EE;
                color: #fff;
                border: none;
                width: 100%;
                height: 42px;
                border-radius: 10px;
                font-family: @font--poppins-500;
                cursor: pointer;
            }

            &__secondary-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: @backcolor--white;
                color: @color--red;
                border: 2px solid @backcolor--red;
                width: 100%;
                height: 42px;
                border-radius: 10px;
                font-family: @font--poppins-500;
                cursor: pointer;
            }
        }
    }
}
@media screen and (max-width: 320px) {
    .myWork-planCard,
    .myWork-planCard-suggested,
    .myWork-planCard-unApproved {
        width: 260px;
    }
}

// .myWork-planCard__ctaSection__btn-container {
//     position: relative;
//     display: inline-block;
//   }
  
//   .myWork-planCard__ctaSection__primary-btn {
//     padding: 10px;
//     background-color: #007bff;
//     color: white;
//     border-radius: 5px;
//     cursor: pointer;
//     position: relative;
//   }
  
//   .myWork-planCard__badge-container {
//     position: absolute;
//     top: -10px; /* Adjust this value to move the badge higher or lower */
//     right: -10px; /* Adjust this value to move the badge more or less to the right */
//   }
  