@import "../../assets/css/variables.less";

.profileUser {
    padding: 2rem
}

.profileUser {

    &__viewProfile-sec {
        padding: 20px 140px;
    }

    .advisorProfileCard {
        padding: 30px 25px;
        border: 2px solid #E3E3E3;
        border-radius: 15px;



        &__container {
            display: flex;
            justify-content: space-between;
        }

        &__info-sec {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            &__sub-img {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 140px !important;
                height: 140px !important;
                border-radius: 50% !important;
                overflow: hidden !important;
            }

            &__profileCont {
                margin-left: 30px;

                .profileName {
                    font-family: @font--poppins-600;
                    font-size: 32px;
                    margin-bottom: 10px;
                }

                &__about-sec {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    span {
                        display: flex;
                        align-items: center;
                        gap: 20px;

                        .label {
                            font-size: 14px;
                            font-family: @font--poppins-500;
                            margin: 0;
                            color: #515050;
                        }
                    }
                }
            }
        }

        &__connect-btn {
            &__primary-btn {
                width: 160px;
                height: 40px;
                background-color: #1C84EE;
                color: #fff;
                border-radius: 10px;
                border: none;
            }

            &__secondary-btn {
                width: 160px;
                height: 40px;
                border: 2px solid #E64A19;
                color: #E64A19;
                border-radius: 10px;
                background-color: none;
            }
        }
    }

    .advisorCoursesCard,
    .advisorPackageCard {
        &__heading {
            font-family: @font--poppins-500;
            font-size: 22px;
            margin-bottom: 10px;
            color: #1B1C1E;
        }

        &__courseCards-sec {
            position: relative;

            .owl-carousel {
                .owl-stage-outer {
                    .owl-stage {
                        display: flex;

                        .owl-item {
                            margin-inline: auto;

                            .owl__item {
                                height: 100%;
                            }

                            .advProfile--CourseSec {
                                &__CourseSec {
                                    &__courseCard {
                                        border: 2px solid #E3E3E3;
                                        margin: 0;
                                        height: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .owl-prev,
            .owl-next {
                position: absolute;
            }

            .owl-prev {
                left: -35px;
                top: 60px;
            }

            .owl-nav {
                .owl-next {
                    right: -35px !important;
                    top: 60px;
                }
            }

            .owl-theme .owl-nav [class*='owl-'] {
                background: transparent !important;
                color: black !important;
                font-size: 40px;

            }
        }

        &__packCard-sec {
            position: relative;

            .owl-carousel {
                .owl-stage-outer {
                    .owl-stage {
                        display: flex;

                        .owl-item {
                            margin-inline: auto;
                        }
                    }
                }
            }

            .owl-prev,
            .owl-next {
                position: absolute;
            }

            .owl-prev {
                left: -35px;
                top: 160px;
            }

            .owl-nav {
                .owl-next {
                    right: -35px !important;
                    top: 160px;
                }
            }

            .owl-theme .owl-nav [class*='owl-'] {
                background: transparent !important;
                color: black !important;
                font-size: 40px;

            }
        }
    }

}

.packageTitle {
    color: #000;
    font-family: @font--poppins-600;
    font-size: 22px;
    font-style: normal;

}

.packageSession {
    color: #000;
    font-family: @font--poppins-500;
    font-size: 15px;
}

.packageAdvisor {
    display: flex;
    flex-wrap: wrap;
    gap: 15px
}

.subPackagePrice {
    color: #000;
    font-family: @font--poppins-regular;
    font-size: 15px;
    font-style: normal;
}

.courseCardTitle {
    color: #3C3C3C;
    font-family: @font--poppins-600;
    font-size: 17px;

}

.advPackageGrid {
    height: fit-content;
    width: 24%;
}


.flex-grow-1 {
    flex-grow: 1;
}

.courseSubCardTitle {
    color: #3C3C3C;
    font-family: @font--poppins-500;
    font-size: 14px;
    margin-right: 10px;

}

.courseSubParah {
    color: #515050;
    font-family: @font--poppins-500;
    font-size: 14px;
}

.coursePara {
    color: #515050;
    font-size: 14px;
    font-family: @font--poppins-300;
    font-weight: 400px;
}

.reviewerName {
    color: #3C3C3C;
    font-family: @font--poppins-500;
    font-size: 18px;
}

.reviewedDate {
    color: #5C5C5C;
    font-family: @font--poppins-300;
    font-size: 14px;
}

.reviewMsg {
    color: #1B1C1E;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media only screen and (max-width: 1204px) {
    .advPackageGrid {
        width: 30%;
    }

    .packageAdvisor {
        display: flex;
        flex-wrap: wrap;
        gap: 20px
    }
}

@media only screen and (max-width: 760px) {
    .advPackageGrid {
        width: 48%;
    }
}

@media only screen and (max-width: 732px) {
    .advPackageGrid {
        width: 48%;
    }

    .goalsModal {
        .advisorProfileCard {
            &__container {
                justify-content: space-around;
            }

            &__info-sec {
                justify-content: space-around;
            }
        }
    }
}

@media only screen and (max-width: 625px) {
    .advPackageGrid {
        width: 100%;
    }
}

@media only screen and (max-width: 520px) {
    .goalsModal {
        .modal-body {
            padding: 20px 10px !important;

            .advisorProfileCard {
                padding: 30px 15px;

                &__info-sec {
                    &__sub-img {
                        margin-bottom: 20px;
                    }

                    &__profileCont {
                        margin: 0 0 20px 0;
                    }
                }
            }

            .advisorCoursesCard {
                &__courseCards-sec {
                    margin: 0 30px;

                    .owl-carousel {

                        .owl-prev,
                        .owl-next {
                            position: absolute;
                        }

                        .owl-nav {
                            .owl-prev {
                                left: -45px !important;
                                top: 60px;
                            }
                        }

                        .owl-nav {
                            .owl-next {
                                right: -35px !important;
                                top: 60px;
                            }
                        }

                        .owl-theme .owl-nav [class*='owl-'] {
                            background: transparent !important;
                            color: black !important;
                            font-size: 40px;

                        }
                    }
                }
            }

            .advisorPackageCard {
                &__packCard-sec {
                    margin: 0 30px;

                    .owl-carousel {
                        .owl-nav {
                            .owl-prev {
                                left: -45px !important;
                                top: 160px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 400px) {
    .goalsModal {
        .modal-body {
            .advisorCoursesCard {
                &__courseCards-sec {
                    margin: 0;

                    .owl-carousel {

                        .owl-prev,
                        .owl-next {
                            position: unset;
                        }
                    }
                }
            }

            .advisorPackageCard {
                &__packCard-sec {
                    margin: 0;

                    .owl-carousel {

                        .owl-prev,
                        .owl-next {
                            position: unset;
                        }
                    }
                }
            }
        }
    }

    .profileUser .advisorProfileCard__info-sec__profileCont {
        margin-left: 0px;
    }

    .profileUser .advisorProfileCard__info-sec {
        display: flex;
        justify-content: center;
    }

    .aspirngtext {
        margin-top: 0;
        margin-bottom: 1rem;
        word-break: break-all;
    }
}

.pkgCard-btnSec {
    width: 100% !important;

    .pkgCard-btnSec__primary-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100% !important;
    }

    .pkgCard-btnSec__primary-btn__text {
        margin-bottom: 0px;
    }
}

.courseFullViewCard__bottomSec {
    width: 100%;
    min-height: 80px;
    max-height: fit-content;

    .courseFullViewCard__bottomSec__description:hover,
    .courseFullViewCard__bottomSec__detailedDescription:hover {
        background-color: #f4f5f8;
    }
}
.advisorProfileCard__Services{
    padding: 30px 25px;
    border: 2px solid #E3E3E3;
    border-radius: 15px;
    background-color: @backcolor--white;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 30px;
    .servicelist{
        display: flex;
        gap: 20px;
        align-items: center;
        .label{
            font-size: 14px;
            font-family: @font--poppins-500;
            margin: 0;
            color: #515050;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: wrap;
        }
        .label-pills{
            font-size: 14px;
            font-family: @font--poppins-500;
            margin: 0;
            color: #515050;
            overflow: hidden;
            display: flex;
            flex-wrap: wrap;
        }
    }
}