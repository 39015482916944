@import "variables.less";
@import "fonts.less";

.User_Image_Card {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: @backcolor--white;
    border: 1px solid #e3e3e3 !important;
    border-radius: 15px;
    padding: 20px 30px;
    align-items: center;
    height: 100%;
    .User_Image {
        border-radius: 10px;
        width: 90px;
        height: 90px;
        position: relative;
        .profileimage {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .deletePic{
            right: -10px;
            top: -10px;
            position: absolute;
            cursor: pointer;
            
            :hover{
                color: #E64A19;
            }
        }
    }
    .ImageErrorList {
        margin-top: 10px;
        width: 550px;
        font-size: 12px;
        color: #E64A19;
        font-family: 'poppins-regular', sans-serif;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .Upload_Btn_Cnt {
        padding-top: 15px;
        padding-right: 20px;

        .Upload_Btn_Cnt_Top {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .Upload_Btn {
                background-color: @backcolor--white;
                border: 2px solid @backcolor--primary;
                border-radius: 10px;
                width: 167px;
                text-transform: capitalize;
                font-size: 16px;
                font-family: @font--poppins-regular;

                svg {
                    fill: @color--primary;
                    width: 28px;
                    height: 28px;
                }
            }
        }

        .Upload_Btn_Cnt_Btm {
            display: flex;
            justify-content: center;

            p {
                font-size: 14px;
                font-family: @font--poppins-regular;
                color: @color--secondary;
                margin-top: 5px;
            }
        }
    }
}

@media only screen and (max-width: 389px) {
    .User_Image_Card {
        display: flex;
        flex-direction: row;
        padding: 10px 10px;
        // justify-content: bottom;
        align-items: center;

        .User_Image {
            border-radius: 10px;
            width: 80px;
            height: 80px;
            .profileimage {
                width: 100%;
                height: 100%;
            }
        }
        .Upload_Btn_Cnt {
            padding-right: 0px;

            .Upload_Btn_Cnt_Top {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .Upload_Btn {
                    background-color: @backcolor--white;
                    border: 2px solid @backcolor--primary;
                    border-radius: 10px;
                    width: 125px;
                    text-transform: capitalize;
                    font-size: 14px;
                    font-family: @font--poppins-regular;
                    svg {
                        fill: @color--primary;
                        width: 18px;
                        height: 18px;
                    }
                }
            }

            .Upload_Btn_Cnt_Btm {
                display: flex;
                justify-content: center;
                p {
                    font-size: 12px;
                    font-family: @font--poppins-regular;
                    color: @color--secondary;
                    margin-top: 5px;
                }
            }
        }
    }
}

