@import "../../../../../assets/css/variables.less";

.sentRequestCard{
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 13px;
    padding: 22px 16px;
    width: 330px;
    background-color: #fff;

    &__topSec{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        &__userDetailSec{
            display: flex;
            gap: 20px;

            &__placeholder{
                border: 1px solid grey !important;
                border-radius: 6px !important;
                font-size: 22px !important;
                width: 65px !important;
                height: 70px !important
            }

            span{
                border: 1px solid grey !important;
                border-radius: 6px !important;
                width: 65px !important;
                height: 70px !important;
                padding: 2px;

                .avatarImage{
                    width: 100%;
                    height: 100%;
                    border-radius: 6px !important;
                    object-fit: cover;
                }
            }
            &__userInfo{
                &__primaryText{
                    color: #1B1C1E;
                    font-size: 15px;
                    margin-bottom: 0;
                    font-family: @font--poppins-500;
                }
                &__secondaryText{
                    color: #1B1C1E;
                    font-size: 12px;
                    margin-bottom: 1px;
                    font-family: @font--poppins-regular;
                }
                &__rejectedStatus{
                    display: flex;
                    justify-content: center;
                    padding: 3px 9px;
                    border-radius: 100px;
                    border: 1px solid #E64A19;
                    background: #FCE5DD;

                    p{
                        color: #E64A19;
                        font-size: 10px;
                        margin-bottom: 0;
                        font-family: @font--poppins-500;
                    }
                }
                &__pendingStatus{
                    display: flex;
                    justify-content: center;
                    padding: 3px 9px;
                    border-radius: 100px;
                    border: 1.5px solid #1C84EE;
                    background: #DEEEFF;

                    p{
                        color: #1C84EE;
                        font-size: 10px;
                        margin-bottom: 0;
                        font-family: @font--poppins-500;
                    }
                }
                &__acceptedStatus{
                    display: flex;
                    justify-content: center;
                    padding: 3px 9px;
                    border-radius: 100px;
                    border: 1.5px solid #29A016;
                    background: #E5FFE1;

                    p{
                        color: #29A016;
                        font-size: 10px;
                        margin-bottom: 0;
                        font-family: @font--poppins-500;
                    }
                }
            }
        }
        &__cta{
            &__cancelIcon{
                position: relative;
                top: -10px;
                width: 18px !important;
                height: 18px !important;
                color: #1e1b1ba3 !important;
                cursor: pointer;

                &:hover{
                    color: #E64A19 !important;
                    transition: ease-in-out .2s;
                }
            }
        }
    }
    &__schoolDetail{
        display: flex;
        min-height: 45px;
        gap: 10px;

        &__schoolIcon{
            font-size: 10px !important;
            width: 19px !important;
            height: 19px !important;
            color: #404144;
        }
        &__schoolName{
            color: #1B1C1E;
            font-size: 15px;
            margin-bottom: 0;
            font-family: @font--poppins-regular;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    &__servicesOfferedSec{
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 10px;

        &__title{
            color: #1B1C1E;
            font-size: 15px;
            margin-bottom: 0;
            font-family: @font--poppins-500;
        }

        &__services{
            padding: 15px 10px;
            border-radius: 10px;
            background-color: #F4F5F8;
            display: flex;
            flex-direction: column;
            gap: 5px;

            &__serviceSec{
                display: flex;
                align-items: flex-start;
                gap: 10px;

                &__starIcon{
                    color: #1C84EE;
                    margin-top: 3px !important;
                    width: 16px !important;
                    height: 16px !important;
                }
                &__label{
                    font-family: @font--poppins-regular;
                    font-size: 15px;
                    color: #1B1C1E;
                    margin-bottom: 0;
                }

            }
        }

        &__otherServices{
            padding: 15px 10px;
            border-radius: 10px;
            background-color: #F4F5F8;
            gap: 10px;

            &__description{
                font-family: @font--poppins-regular;
                font-size: 15px;
                color: #1B1C1E;
                margin-bottom: 0;
                word-wrap: break-word;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                cursor: default;
            }
        }
    }
    &__btnSec{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: auto;

        &__danger-btn{
            width: 85%;
            height: 42px;
            border-radius: 10px;
            color: #E64A19;
            border: 2px solid #E64A19;
            background-color: #fff;
            font-family: @font--poppins-500;
        }
        &__primary-btn{
            background-color: #1C84EE;
            color: #fff;
            border: none;
            width: 85%;
            height: 42px;
            border-radius: 10px;
            font-family: @font--poppins-500;
        }
    }
    &:hover {
        transition: all 0.3s ease-out;
        box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    }
}

.pending{
    border: 1px solid #1C84EE;

    &:hover {
        border: 1.5px solid #1C84EE;
    }
}
.rejected{
    border: 1px solid #E64A19;

    &:hover {
        border: 1.5px solid #E64A19;
    }
}
.accepted{
    border: 2px solid rgba(41, 160, 22, 0.50);
    
    &:hover {
        border: 2.5px solid rgba(41, 160, 22, 0.50);
    }
}

@media only screen and (max-width: 425px) {
    .sentRequestCard{
        max-width: 270px !important;
        
        &__btnSec{
            &__primary-btn{
                width: 100% !important;
            }
            &__danger-btn{
                width: 100% !important;
            }
        }
    }
}