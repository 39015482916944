@import "../../../../assets/css/variables.less";

.connectedStudentCard{
    display: flex;
    flex-direction: column;
    border: 1px solid #E3E3E3;
    border-radius: 13px;
    padding: 22px 16px;
    max-width: 330px;
    background-color: #fff;

    &__topSec{
        display: flex;
        margin-bottom: 10px;
        gap: 20px;

        &__userIcon{
            border: 1px solid grey !important;
            border-radius: 6px !important;
            font-size: 22px !important;
            width: 65px !important;
            height: 70px !important
        }

        &__userInfo{
            &__nameSec{
                display: flex;

                &__primaryText{
                    color: #1B1C1E;
                    font-size: 16px;
                    margin-bottom: 1px;
                    font-family: @font--poppins-500;
                    // min-height: 50px;
                }
                &__iconBg{
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    position: relative;
                    bottom: 7px;
                    left: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #f4f5f8d3;
                    
                    &:hover{
                        cursor: pointer;
                        background-color: #f1f2f3d8;
                    }

                    &__chatIcon{
                        width: 11px !important;
                        height: 11px !important;
                        color: #515050 !important;
                        border-radius: 50%;
                    }
                }
            }
            &__secondaryText{
                color: #1B1C1E;
                font-size: 12px;
                margin-bottom: 1px;
                font-family: @font--poppins-regular;
            }
            &__helperText{
                color: #515050;
                font-size: 10px;
                margin-bottom: 2px;
                font-family: @font--poppins-regular;
            }
        }
    }
    &__midSec{
        display: flex;
        flex-direction: column;
        margin-bottom: 20px !important;

        &__educationSec{
            display: flex;
            gap: 10px;
            min-height: 50px;
            align-items: center;
            &__schoolIcon{
                font-size: 12px !important;
                width: 20px !important;
                height: 20px !important;
                color: #404144;

            }
            &__schoolName{
                color: #1B1C1E;
                font-size: 15px;
                margin-bottom: 5px;
                font-family: @font--poppins-regular;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        
        &__goalsSec{
            display: flex;
            flex-direction: column;
            background-color: #F4F5F8;
            border-radius: 14px;
            padding: 15px 10px;
            gap: 10px;
            min-height: 510px;
            max-height: fit-content;
            &__targetUniversitySec{
                display: flex;
                // align-items: center;
                gap: 10px;

                &__starIcon{
                    color: #1C84EE;
                    width: 16px !important;
                    height: 16px !important;
                }
                &__label{
                    font-family: @font--poppins-regular;
                    font-size: 15px;
                    color: #1B1C1E;
                    margin-bottom: 0;
                }
            }
        }
    }
    &__sample{
        flex-grow: 1;
    }
    &__buttonSec{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: auto;

        &__primary-btn{
            background-color: #1C84EE;
            color: #fff;
            border: none;
            width: 85%;
            height: 42px;
            border-radius: 10px;
            font-family: @font--poppins-500;
        }
    }
    .advisor-card-description{
        background-color: #f4f5f8;
        padding: 10px;
        border-radius: 10px;
        font-family: @font--poppins-regular;
        p{
            margin-bottom: 0px;
            text-overflow: ellipsis;
            font-family: @font--poppins-500;
            overflow: hidden;
            -webkit-box-orient: vertical;
            height: 74px;
            margin: 0;
            -webkit-line-clamp: 3;
            word-break: break-all;
            display: -webkit-box;
        }
    }
}
.studentmsgcnt {
    height: 100px;
}