@import "../../../../assets/css/variables.less";

.AccesibleAdvisorList {
    max-height: 260px;
    overflow-y: auto;

    &__card {
        background-color: @backcolor--white;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 20px;

        &:hover {
            transition: all 0.2s ease-out;
            box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
            top: -4px;
            border: 1px solid #cccccc;
            background-color: @backcolor--white;
        }

        &__Usersection {
            display: flex;
            gap: 10px;

            .userprofile {
                width: 80px;
                height: 90px;
                border-radius: 10px;
                object-fit: cover;
            }

            .username {
                display: flex;
                max-width: 190px;
                overflow: hidden;
                white-space: nowrap;
                margin-bottom: 0px;
                gap: 6px;
            }

            .username-text {
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 16px;
                font-family: @font--poppins-500;
                margin-top: 6px;
            }
        }

        &__Btnsection {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .primary_btn {
                background-color: @backcolor--primary;
                font-family: @font--poppins-regular;
                border-radius: 10px;
                color: @color--white;
                height: 40px;
                border: none;
            }

            .secondary_btn {
                background-color: @backcolor--white;
                font-family: @font--poppins-regular;
                border-radius: 10px;
                color: @backcolor--red;
                border: 2px solid @backcolor--red;
                height: 40px;
            }
        }

    }
}

.confirmationtxt {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    font-family: @font--poppins-500;
}

.primary_btn {
    background-color: @backcolor--primary;
    font-family: @font--poppins-regular;
    border-radius: 10px;
    color: @color--white;
    height: 40px;
    border: none;
}

.addConnectionText {
    padding: 10px 60px
}

@media only screen and (max-width: 628px) {
    .addConnectionText {   
        padding: 0px 0px
    }

    .AccesibleAdvisorList__card {
        display: block;
    }
}

@media only screen and (max-width: 300px) {
    .addConnectionText {
        .btn-primary {
            height: 51px;
        }
    }
}