.feedBackPopup {
    padding-left: 20rem;
    padding-right: 20rem;

}

@media (max-width: 768px) {
    .feedBackPost {
       width: fit-content !important;
    }
}