@import "variables.less";
@import "fonts.less";

html,
body {
    height: 100%;
    margin: 0;

}

a {
    text-decoration: none !important;
}

.grandParentContaniner {
    display: table;
    height: 100vh;
    margin: 0 auto;

}

.parentContainer {
    display: table-cell;
    vertical-align: middle;
}

#default-layout,
#dashboard-layout {
    background-color: #FBFBFB !important;
}

#root {
    background-color: #FBFBFB !important;
}

#layoutSidenav {
    height: 100vh;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #5C5C5C;
    opacity: 0.6;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #5C5C5C;
    opacity: 0.6;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #5C5C5C;
    opacity: 0.6;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #5C5C5C;
    opacity: 0.6;
}

a {
    color: @color--black;
    text-decoration: none;

    &:hover {
        color: @color--black;
    }
}

ul {
    padding: 0px;

    li {
        display: inline-block;
        list-style: none;
    }
}

.or {
    text-align: center;
    border-bottom: 1px solid #727272;
    line-height: 0.1em;
    margin: 25px 0;

    span {
        background: #fff;
        padding: 0 10px;
        font-family: @font--poppins-regular;
        font-size: 14px;
    }
}

.cta--center {
    text-align: center;
}

.cta--underline {
    font-size: 16px;
    text-decoration: underline !important;
    color: @color--cta--underline;

    &:hover {
        color: @color--cta--underline--hover;
    }
}

.cta--text {
    font-size: 12px;
    color: @color--gray;
}

.cta--light {
    font-size: 16px;
    color: @color--light;
    text-decoration: none;
}

.cta-primary {
    background-color: @btn--primary !important;
    font-size: 16px !important;
    font-family: @font--poppins-500 !important;
    color: @color--white !important;
    font-weight: 500 !important;
}

.cta-default {
    width: 300px;
}

.text-black {
    color: #000 !important;
}

.textbox--rounded,
.cta--rounded {
    border-radius: 10px;
}

.textbox--primary {
    height: 48px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0px 16px;
    font-size: 15px;
    font-family: @font--poppins-500;
    color: @color--primary;
}

.textarea--primary {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 16px;
    font-size: 15px;
    font-family: @font--poppins-500;
    color: @color--primary;
}

.textarea--primary-bg {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 16px;
    font-size: 15px;
    font-family: @font--poppins-500;
    color: @color--primary;
    background-color: #fff !important;
}

.textbox--mb-36 {
    margin-bottom: 36px !important;
}

.styled-checkbox {
    position: absolute;
    opacity: 0;
}

.styled-checkbox+label {
    position: relative;
    cursor: pointer;
    padding: 0;
    font-family: @font--poppins-regular;
}

.styled-checkbox+label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 24px;
    height: 24px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.styled-checkbox:focus+label:before {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12);
}

.styled-checkbox:checked+label:before {
    background: @btn--primary;
}

.styled-checkbox:disabled+label {
    color: #b8b8b8;
    cursor: auto;
}

.styled-checkbox:disabled+label:before {
    box-shadow: none;
    background: #ddd;
}

.styled-checkbox:checked+label:after {
    content: "";
    position: absolute;
    left: 7px;
    top: 11px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
}

.styled-radio {
    // margin: 0.5rem;
    margin-right: 0.7rem;
}

.styled-radio input[type=radio] {
    position: absolute;
    opacity: 0;
}

.styled-radio input[type=radio]+.radio-label:before {
    content: "";
    background: #f4f4f4;
    border-radius: 100%;
    border: 1px solid #b4b4b4;
    display: inline-block;
    width: 1.1em;
    height: 1.1em;
    position: relative;
    top: 0;
    margin-right: 0.5em !important;
    vertical-align: middle;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease;
}

.styled-radio input[type=radio]:checked+.radio-label:before {
    background-color: #3197EE;
    box-shadow: inset 0 0 0 4px #f4f4f4;
}

.styled-radio input[type=radio]:focus+.radio-label:before {
    outline: none;
    border-color: #3197EE;
}

.styled-radio input[type=radio]:disabled+.radio-label:before {
    box-shadow: inset 0 0 0 4px #f4f4f4;
    border-color: #b4b4b4;
    background: #b4b4b4;
}

.styled-radio input[type=radio]+.radio-label:empty:before {
    margin-right: 0;
}

.styled-radio-flex {
    display: flex;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
}

.w-100 {
    width: 100% !important;
}

.footer {

    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    // margin-bottom: 25px;
    // margin-top: 25px;
    padding-bottom: 25px;
    padding-top: 25px;
    background-color: #fff;
    // z-index: 1;

    &__left {
        &__cta {
            color: @color--black !important;
            font-size: 16px;
            cursor: pointer;

            &:hover {
                color: @color--black;
            }

            span {
                font-size: 16px;
                padding-left: 16px;
            }
        }

        display: table;

        p {
            margin: 0;
            display: table-cell;
            vertical-align: middle;
        }
    }

    &__center {
        display: table;

        p {
            margin: 0;
            display: table-cell;
            vertical-align: middle;
        }
    }

    &__cta {
        height: 40px;
        -webkit-user-select: none;
        user-select: none;
        cursor: default;
        background: @btn--primary;
        border-radius: 10px;
        color: #fff;
        // width: 180px;
        display: flex;
        text-align: center;
        line-height: 36px;
        margin: 0 auto;
        text-align: center;
        border: 2px solid @btn--primary;
        align-items: center;
        justify-content: center;

        svg {
            margin-left: 5px;

            .rolestrokes {
                stroke: #ffffff;
            }

            .rolefills {
                fill: #fff;
            }
        }

        &:hover {
            color: #000;
            cursor: pointer;
            background: #fff;
            border: 2px solid @btn--primary;

            svg {
                .rolestrokes {
                    stroke: #000;
                }

                .rolefills {
                    fill: #000;
                }
            }
        }

        &:focus {
            border: 2px solid transparent;
        }
    }
}

.bluetext {
    color: #1C84EE;
}

.text-white {
    color: #fff !important;
}

.error-text {
    font-size: 12px;
    color: @color--red;
    font-family: @font--poppins-regular;
    padding-left: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    // background-color: #FBFBFB;
}

.error-text-select {
    font-size: 12px;
    color: @color--red;
    font-family: @font--poppins-regular;
    padding-left: 0px;
}

.error-input {
    border: 1px solid @color--red !important;
}

.modal-footer {
    .btnCookieClose {
        color: #6C6C6C;
        font-size: 16px;
        font-weight: 500;
        padding: 6px 25px;
        border: 1px solid #000000;
        border-radius: 8px;
        cursor: pointer;
        user-select: none;
    }
}

/* textbox with floating label code start */
.input-floating-label {
    position: relative;
    margin-bottom: 32px;
    background: transparent;
    width: 100%;

    .input {
        width: 100%;
        display: block;
        outline: 0;
        background: white;
        transition: border-color 0.2s;

        &:invalid,
        &:required {
            box-shadow: none;
        }

        &::placeholder {
            color: transparent;
        }

        &:placeholder-shown {
            border-color: rgba(0, 0, 0, 0.1);
        }

        &:placeholder-shown~label {
            pointer-events: none;
            cursor: text;
            transform: translatey(14px);
            padding: 0px 0px;
            font-size: 13px;
            font-family: @font--poppins-500;
            color: rgba(27, 28, 30, 0.5);
            margin-left: 5px;
        }

        &:focus {
            border-color: rgba(0, 0, 0, 0.1);
        }

        &:focus~label {
            position: absolute;
            transform: translatey(-10px);
            font-family: @font--poppins-500;
            color: #000 !important;
            padding: 0px 8px;
            font-size: 13px;
            background-color: #fff;
            margin-left: 0px;
        }

        &:not(:placeholder-shown)~label {
            color: #000 !important;
            border-radius: 5px;
        }
    }

    label {
        position: absolute;
        left: 12px;
        display: block;
        transform: translatey(-10px);
        transition: 0.3s;
        padding: 0px 8px;
        font-size: 13px;
        font-family: @font--poppins-500;
        // color: rgba(27, 28, 30, 0.5) !important;
        top: 0;
        background-color: white;
    }

    &.w-right-icon {
        position: relative;

        .textbox-icon {
            position: absolute;
            right: 10px;
            top: 10px;
        }

        .input {
            padding-right: 30px;
        }
    }

    &.w-left-icon {
        position: relative;

        .textbox-icon {
            position: absolute;
            left: 10px;
            top: 10px;
        }

        .input {
            padding-left: 30px;

            &:placeholder-shown~label {
                padding-left: 20px;
            }
        }
    }
}

.describer {
    position: relative;
    margin-bottom: 32px;
    background: transparent;
    width: 100%;

    .input {
        width: 100%;
        display: block;
        outline: 0;
        background: white;
        transition: border-color 0.2s;

        &:invalid,
        &:required {
            box-shadow: none;
        }

        &::placeholder {
            color: transparent;
        }

        &:placeholder-shown {
            border-color: rgba(0, 0, 0, 0.1);
        }

        &:placeholder-shown~label {
            pointer-events: none;
            cursor: text;
            transform: translatey(14px);
            padding: 0px 0px;
            font-size: 11px;
            font-family: @font--poppins-500;
            color: rgba(27, 28, 30, 0.5);
            margin-left: 5px;
        }

        &:focus {
            border-color: rgba(0, 0, 0, 0.1);
        }

        &:focus~label {
            position: absolute;
            transform: translatey(-10px);
            font-family: @font--poppins-500;
            color: #000 !important;
            padding: 0px 8px;
            font-size: 11px;
            background-color: #fff;
            margin-left: 2px;
        }

        &:not(:placeholder-shown)~label {
            color: #000 !important;
            border-radius: 5px;
        }
    }

    label {
        position: absolute;
        left: 12px;
        display: block;
        transform: translatey(-10px);
        transition: 0.3s;
        padding: 0px 8px;
        font-size: 11px;
        font-family: @font--poppins-500;
        // color: rgba(27, 28, 30, 0.5) !important;
        top: 0;
        background-color: white;
    }
}

.plandescription {
    position: relative;
    margin-bottom: 32px;
    background: transparent;
    width: 100%;

    .input {
        width: 100%;
        display: block;
        outline: 0;
        background: white;
        transition: border-color 0.2s;

        &:invalid,
        &:required {
            box-shadow: none;
        }

        &::placeholder {
            color: transparent;
        }

        &:placeholder-shown {
            border-color: rgba(0, 0, 0, 0.1);
        }

        &:placeholder-shown~label {
            pointer-events: none;
            cursor: text;
            transform: translatey(14px);
            padding: 0px 0px;
            font-size: 10px;
            font-family: @font--poppins-500;
            color: rgba(27, 28, 30, 0.5);
            margin-left: 0px;
        }

        &:focus {
            border-color: rgba(0, 0, 0, 0.1);
        }

        &:focus~label {
            position: absolute;
            transform: translatey(-10px);
            font-family: @font--poppins-500;
            color: #000 !important;
            padding: 0px 8px;
            font-size: 10px;
            background-color: #fff;
            margin-left: 0px;
        }

        &:not(:placeholder-shown)~label {
            color: #000 !important;
            border-radius: 5px;
        }
    }

    label {
        position: absolute;
        left: 12px;
        display: block;
        transform: translatey(-10px);
        transition: 0.3s;
        padding: 0px 8px;
        font-size: 10px;
        font-family: @font--poppins-500;
        // color: rgba(27, 28, 30, 0.5) !important;
        top: 0;
        background-color: white;
    }
}

/* textbox with floating label code end */

/*react select custom styles*/
.mySelect__single-value {
    margin-left: 12px !important;
}

.mySelect__multi-value {
    margin: 0px 2px 2px !important;
    padding: 2px 10px 2px 7px !important;
    border-radius: 15px !important;
}

.mySelect__control {
    border-radius: 10px !important;
    height: unset;
}

.mySelect__value-container {
    height: 100%;
    font-family: @font--poppins-500 !important;
}

.mySelect__placeholder {
    font-size: 13px;
    font-family: @font--poppins-500 !important;
    color: rgba(27, 28, 30, 0.5) !important;
}

.mySelect__multi-value__remove {
    background: rgba(0, 0, 0, 0.6);
    width: 20px;
    height: 20px;
    border-radius: 100% !important;
    color: #fff;
    position: relative;
    top: 3px;
}

.mySelect__multi-value__remove:hover {
    background-color: rgba(0, 0, 0, 0.4) !important;
    color: #fff !important;
}

.mySelect__control--is-focused {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    box-shadow: unset !important;

    .mySelect__placeholder {
        top: -13px;
        color: #000 !important;
    }
}

.mySelect__value-container--has-value {
    .mySelect__placeholder {
        color: #000 !important;
    }
}

// .mySelect__value-container,
.mySelect__value-container--is-multi,
.mySelect__value-container--has-value,
.css-as3f3x-ValueContainer {
    padding-top: 20px;
}

.radio__select {
    .mySelect__option--is-selected {
        background-color: transparent;
        color: #000;
    }

    .mySelect__option span {
        padding-left: 10px;
        position: relative;
        top: -1px;
    }
}

.d-inline-block {
    display: inline-block !important;
}

.center-wrap {
    margin-left: 100px;
    margin-right: 380px;
    padding: 50px;
    background-color: #f4f5f8;

    &-default {
        margin-left: 100px;
        padding: 50px;
        background-color: #F4F5F8;
        height: 100vh;
    }
}

.center-wrap-switch {
    margin-left: 100px;
    margin-right: 381px !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;

    &-default {
        margin-left: 100px;
        margin-right: 380px !important;
        // padding: 50px;
        background-color: #F4F5F8;
        height: 100vh;
    }
}

// Tags Chip
.tag {
    &__tags-chip {
        background-color: #E3E3E3;
        padding: 3px 10px;
        margin: 0 10px 10px 0;
        border-radius: 10px;

        &__tag-text-light {
            margin-bottom: 0;
            font-size: 13px;
            font-weight: 400;
            line-height: 22px;
            color: #000000;
            font-family: @font--poppins-300;
        }

        &__tag-text-bold {
            margin-bottom: 0;
            font-size: 13px;
            line-height: 22px;
            color: #1B1C1E;
            font-family: @font--poppins-500;
        }

        &:not(:only-child) {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        &:only-child {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

    }

    &__tags-chip {
        &:has(&__tag-text-bold) {
            display: flex;
            align-items: center;
            padding: 5px 10px;
            min-height: 40px;
        }
    }
}

/*Chatbox scrollbar*/

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    border-radius: 0;
}

::-webkit-scrollbar-thumb {
    margin: 2px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
}

.Two-Avatar-icon {
    height: 100%;
    padding: 5px 0px;

    img:last-child {
        position: relative;
        left: -10px;
    }
}



.CircleUserAddCta,
.CircleAddCta {
    width: 32px;
    height: 32px;
    background-color: @backcolor--primary;
    border-radius: 100%;
    text-align: center;
    padding: 2px 0px;
    float: right;
}

.DocumentsList,
.RecommendedFriends,
.GetAdvisors {
    background: @backcolor--white;
    border-radius: 10px;
    padding: 16px;

    &__title {
        font-weight: 700;
        font-size: 15px;
        line-height: 22px;
        color: @color--light-black;
    }

    &__sub-title {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: @color--light-gray;
    }

    &__List {
        &__items {
            padding: 16px 8px;

            .DocumentsList & {
                padding: 10px;
            }

            border: 1px solid rgba(48, 63, 159, 0.5);
            border-radius: 10px;
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0px;
            }

            &__Extension {
                width: 28px;
                height: 36px;
                font-weight: 500;
                font-size: 10px;
                line-height: 20px;
                border-radius: 2px;
                text-align: center;
                color: #fff;
                padding-top: 15px;

                &.doc {
                    background-color: @backcolor--primary;
                }

                &.zip {
                    background-color: @backcolor--red;
                }
            }

            .Avatar-icon {
                padding: 5px 0px;
            }

            &__title {
                font-weight: 600;
                font-size: 15px;
                line-height: 22px;
                color: @color--light-black;
                margin-bottom: 0px;
            }

            &__sub-title {
                font-weight: 400;
                font-size: 10px;
                line-height: 15px;
                color: rgba(0, 0, 0, 0.7);
                margin-bottom: 0px;
            }

            &__sub-title_date {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: @color--text-gray;
                margin-bottom: 0px;

                &.black {
                    color: @color--primary
                }
            }

            &__rating {
                margin-bottom: 0px;
            }

        }
    }
}

.fileSize {
    height: 24px;
    width: 56px;
    border-radius: 2px;
    background-color: @backcolor--light--black;
    line-height: 24px;

    span {
        font-weight: 400;
        font-size: 11px;
        line-height: 24px;
        color: @color--text-blue;
        text-align: center;
        display: block;
    }
}

.fileExtension {
    width: 28px;
    height: 36px;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    border-radius: 2px;
    text-align: center;
    color: #fff;
    padding-top: 15px;

    &.doc {
        background-color: @backcolor--primary;
    }

    &.zip {
        background-color: @backcolor--red;
    }
}

.fileExtension-desc p {
    margin-bottom: 0px;
}

.search {
    width: 100%;
    position: relative;

    .searchTerm {
        width: 100%;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #E6E6E6;
        border-radius: 8px;
        padding-left: 50px;
        font-size: 16px;
    }

    .searchIcon {
        position: absolute;
        top: 0px;
        width: 60px;
        height: 40px;
        padding: 5px;
        text-align: center;
        z-index: 0;
    }
}

.mobile-search {
    display: none;
}

.topHeader {
    margin-left: 100px;
    background: #fff;
    padding: 5px 10px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    border-bottom: 1px solid #ddd;

    &-text {
        h3 {
            font-size: 40px;
            margin-bottom: 4px;
        }

        p {
            font-size: 12px;
        }
    }
}

@media only screen and (max-width: 1000px) {
    .center-wrap {
        margin-left: 100px;
        margin-right: 0px;
        padding: 50px;
        background-color: #F4F5F8;
    }
}

@media screen and (max-width: 768px) {
    .role {
        margin-bottom: 60px;
        margin-top: 50px;
    }

    .footer {
        position: fixed;
        background-color: #fff;
        margin-bottom: 0px;
        padding: 10px 0px;
        border-top: 1px solid #ddd;
        z-index: 2;

        &__cta {
            height: auto !important;
            -webkit-user-select: none;
            user-select: none;
            cursor: default;
            background: @btn--primary;
            border-radius: 10px;
            color: #fff;
            width: 85px;
            display: block;
            line-height: 30px;
            margin: 0 auto;
            text-align: center;

        }
    }

    .w-mob-100 {
        width: 100% !important;
    }

    .Search-content {
        .search {
            display: none;
        }

        .mobile-search {
            display: block;
            position: relative;
            padding-bottom: 0;
            bottom: -5px;
            right: 30px;
            text-align: right;
        }

        .mobile-search-input::placeholder {
            color: @color--primary;
            opacity: 0.8;
        }

        .mobile-search-input {
            position: absolute;
            right: -30px;
            visibility: hidden;
            opacity: 0;
            top: 35px;
            background: #fff;
            color: @color--primary;
            padding: 20px 6px;
            font-size: 16px;
            outline: none;
            width: 220px;
            transition: 80ms all ease-in;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            box-shadow: none;
            height: 40px;
            border: 1px solid rgba(48, 63, 159, 0.5);
        }

        .mobile-search-button {
            background: transparent;
            color: white;
            cursor: pointer;
            font-size: 14px;
            padding-top: 4px;
        }

        .mobile-search-button:hover+.mobile-search-input,
        .mobile-search-input:hover,
        .mobile-search:hover .mobile-search-input {
            visibility: visible !important;
            opacity: 1 !important;
            z-index: 9 !important;
            box-shadow: 1px 3px 4px #E6E6E6;
            height: 25px !important;
        }

    }

    .center-wrap {
        margin-left: 0px;
        margin-right: 0px;
        padding: 0px;
        background-color: #F4F5F8;

        &-default {
            margin-left: 0px;
            padding: 0px;
        }
    }

    .dashboardtop {
        padding: 5px 10px 5px 70px;
        background-color: @backcolor--white;
        position: fixed;
        left: 0;
        right: 0px;
        z-index: 99;
        border-bottom: 1px solid #ddd;
    }

    .topHeader {
        &-text {
            h3 {
                font-size: 16px;
            }

            // p {
            //     font-size: 12px;
            // }
        }
    }
}

@media screen and (max-width: 576px) {
    .p-mob-0 {
        padding: 0px !important;
    }

    .footer {
        &__cta {
            height: auto !important;
        }
    }
}

@media screen and (max-width: 480px) {
    .cta-default {
        width: 75%;
    }
}

.center-wrap-default {
    height: max-content;
    min-height: 100vh;
    background-color: #f4f5f8;
}

.childSwitch {
    margin-left: 116px;
}

.childSwitchDefault {
    margin-left: 116px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
}

.switchMargin {
    margin-top: 1rem;
}

.onlyDashboardSwitch {
    margin-top: 1rem;
}

.onlyMywork {
    margin-top: 1rem;
}

@media screen and (max-width: 1000px) {
    .center-wrap-switch {
        margin-left: 100px;
        margin-right: 0px !important;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 99;
    }
}


@media screen and (max-width: 768px) {
    .onlyDashboardSwitch {
        top: 0px;
        margin-top: 0rem !important;
    }

    .center-wrap-switch {
        margin-left: 0px;
        margin-right: 0px !important;
        position: fixed;
        top: 55px;
        left: 0;
        right: 0;
        z-index: 99;
    }

    .childSwitchDefault {
        margin-top: 63px;
        margin-left: 0px;

    }

    .switchMargin {
        margin-top: 4rem;
    }

    .librarySwitch {
        margin-top: 6rem;
    }

    .onlyMywork {
        margin-top: 0rem;
    }


    .onlyNotify {
        margin-top: 142px !important;
    }

}

.center-wrap {
    height: max-content;
    min-height: 100vh;
}

.select-filter {
    max-width: 150px;
    min-width: 95px;
    position: relative;
    margin: 0 5px;
}

.sortby {
    width: 100%;
    position: relative;

    .sortTerm {
        width: 100%;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #ECECEC;
        border-radius: 8px;
        padding-left: 53px;
        font-size: 13px;
        color: #000000;
    }

    .sortIcon {
        position: absolute;
        top: 0px;
        width: 60px;
        height: 40px;
        padding: 5px;
        text-align: center;
        z-index: 9;
    }
}


.filter {
    width: 100%;
    position: relative;

    .filterTerm {
        width: 100%;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #ECECEC;
        border-radius: 8px;
        padding-left: 40px;
        font-size: 13px;
        color: #000000;
    }

    .filterIcon {
        position: absolute;
        top: 0px;
        width: 60px;
        height: 40px;
        padding: 5px;
        text-align: center;
        z-index: 9;
    }
}

.accordion-top {
    max-width: 410px;
    margin-left: auto;
    margin-top: 10px;
    background-color: #ffffff !important;
    border-radius: 10px;
    padding: 15px;
    border: 1px solid #ececec !important;

    .accordion {
        .accordion-header {
            border-bottom: 1px solid #ececec;
        }
    }

    .filter-name {
        font-size: 14px;
        font-family: @font--poppins-500;
        color: @color--primary ;
    }

    .filter-underline {
        font-size: 13px;
        font-family: @font--poppins-regular ;
        color: @color--primary ;
    }

    .accordion-item {
        border: 1px solid #ffffff !important;
        background-color: #ffffff !important;

        .collapse {
            border: 0;
        }
    }

    .accordion-button:not(.collapsed) {
        color: @color--primary !important;
        background-color: #ffffff !important;
        box-shadow: none !important;
    }

    .accordion-button:focus {
        z-index: 3;
        border-color: #ffffff !important;
        outline: 0;
        box-shadow: none !important;
    }

    .accordion-button {
        padding: 1rem 0rem !important;
        font-size: 14px !important;
        color: @color--primary !important;
        font-family: @font--poppins-500;
        background-color: #ffffff !important;
    }

    .accordion-body {
        padding: 1rem 0rem;
    }

    .accordion-list {
        ul li {
            span {
                font-size: 14px;
                font-family: @font--poppins-regular ;
                color: #4F555C;
            }
        }
    }

    .accordion-button:not(.collapsed)::after {
        flex-shrink: 0;
        width: 1.25rem;
        height: 1.25rem;
        margin-left: auto;
        content: "";
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fillRule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
        background-repeat: no-repeat;
        background-size: 1.25rem;
        transition: transform 0.2s ease-in-out;
    }
}

@media screen and (max-width: 768px) {
    .accordion-top {
        background-color: #ffffff !important;

        .accordion-item {
            border: 1px solid #f4f4f4 !important;
            background-color: #ffffff !important;
        }

        .accordion-button:not(.collapsed) {
            background-color: #ffffff !important;
        }

        .accordion-button:focus {
            border-color: #f4f4f4 !important;
        }

        .accordion-button {
            background-color: #ffffff !important;
        }
    }
}

.invitation {
    &__invite-profileimage {
        height: 140px;
        width: 140px;
        border-radius: 10px;
    }

    &__invite-name {
        font-size: 18px;
        font-family: @font--poppins-600;
        color: @color--primary;
    }
}

@media screen and(min-width:575px)and (max-width: 768px) {

    .invitation {
        .sendInvite__form {
            .invitation-button {
                width: 30% !important;
                text-align: center;
            }
        }

        &__invite-name {
            font-size: 16px;
        }

        &__invite-profileimage {
            height: 116px;
            width: 110px;
            border-radius: 10px;
        }
    }
}

.btnWrapper {
    margin: auto;
}

@media (max-width: 300px) {
    .cookieContent {
        max-width: 240px;
    }
}

.pointer {
    cursor: pointer;
    height: 50px;
    border-radius: 10px;

    :hover {
        border: 1px solid #cfddec;
        border-radius: 10px;
    }
}

.alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    padding: 1.45rem 0.5rem !important;
    font-size: 10px !important;
    padding-right: 0.5rem !important;
}

.alert-dismissible {
    padding-right: 2rem !important;
}

//advisor
.advisor-connection-card {
    border: 1px solid @backcolor--white;
    border-radius: 10px;
    height: 100%;
    padding: 10px 10px;
    background-color: @backcolor--white;

    &__next {
        display: none;
    }

    &:hover {
        transition: all 0.2s ease-out;
        box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        border: 1px solid #cccccc;
        background-color: white;

        .advisor-connection-card {
            &__next {
                display: inline;
            }
        }
    }

    &__title {
        font-size: 14px;
        font-family: @font--poppins-500;
        color: #8d8d8e;
        margin-top: 15px;
        margin-bottom: 5px;
    }

    &__counter {
        font-size: 28px;
        font-family: @font--poppins-700;
        color: @color--primary;
    }
}

//milesCard
.advisor-mile-card {
    .milescard {
        border: 1px solid #ececec;
        width: 205px;
        height: 250px;
        background-color: white;
        border-radius: 15px;
        margin: 10px 6px 10px 5px;
        padding: 10px;

        &:hover {
            transition: all 0.2s ease-out;
            box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
            top: -4px;
            border: 1px solid #cccccc;
            background-color: white;
        }

        .endcols {
            display: flex;
            justify-content: end;
        }

        .milemid {
            font-size: 15px;
            font-weight: 600;
            color: #1b1c1e;
        }

        .midbtm {
            margin-top: 15px;
            line-height: 10px;

            .midfrm {
                font-size: 14px;
                font-weight: 400;
                color: #919293;
                margin-bottom: 5px;

                span {
                    margin-left: 10px;
                    color: #1b1c1e;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                }
            }
        }

        .progress {
            margin-top: 30px;
            height: 3px;
        }

        .progress-bar {
            background-color: @backcolor--red;
        }
    }
}

.light-bg {
    background: @backcolor--light--black;
}

@media (min-width:280px) and (max-width:768px) {
    .profile {
        margin-top: 50px;
    }

    .set_initial_plan {
        margin-top: 50px;
    }
}

.profile-wrap {
    margin-left: 50px;
    margin-right: 50px;
    padding: 0px 10px 10px 10px;
    background-color: #ffffff;

    &-default {
        margin-left: 50px;
        padding: 0px;
        background-color: #ffffff;
        height: 100vh;
        padding: 0px 0px 0px 50px;
    }
}

.accordion-filter {
    position: absolute;
    z-index: 98;
    right: 38px !important;
    width: 320px !important;
    margin-top: 21px !important;
}

@media screen and (max-width: 768px) {
    .accordion-filter {
        right: 0px !important;
    }
}

//hollow
.tab-content {
    .sub-title {
        font-size: 15px;
        font-family: @font--poppins-500;
        color: @color--text-gray;
        text-align: center;
        margin-top: 25px;
    }

    .primary-title {
        text-decoration: underline;
        color: @backcolor--primary;
        padding-left: 6px;
    }

    .box-network {
        display: flex;
        justify-content: center;
        margin-top: 80px;

        .boxnetwork {
            width: 450px;
            height: 250px;
            background: #FFFFFF;
            border: 4px solid #ECECEC;
            border-radius: 20px;
        }
    }
}

.borderbtn {
    height: 40px;
    border: 2px solid @backcolor--primary;
    border-radius: 10px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    padding: 5px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}

@media (min-width: 320px) and (max-width: 374px) {
    .advisor-mile-card {
        .milescard {
            margin: 10px 6px 10px 35px;
        }
    }
}

@media (min-width: 500px) and (max-width: 575px) {
    .advisor-mile-card {
        .milescard {
            margin: 10px 6px 10px 18px;
        }
    }
}

@media (min-width: 850px) and (max-width: 880px) {
    .advisor-mile-card {
        .milescard {
            width: 195px;
        }
    }
}

@media (min-width: 1500px) and (max-width: 1700px) {
    .advisor-mile-card {
        .milescard {
            width: 243px;
            padding: 27px;
            height: 280px;
        }

        .milemid {
            font-size: 16px;
            margin-top: 5px;
        }

        .midbtm {
            .midfrm {
                font-size: 15px;

                span {
                    font-size: 15px;
                }
            }
        }
    }
}

@media (min-width: 430px) and (max-width: 440px) {
    .advisor-mile-card {
        .milescard {
            width: 184px;

            .milemid {
                font-size: 13px;
            }

            .midbtm {
                .midfrm {
                    font-size: 12px;

                    span {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

@media (min-width: 1300px) and (max-width: 1358px) {
    .advisor-mile-card {
        .milescard {
            width: 189px;

            .milemid {
                font-size: 13px;
            }

            .midbtm {
                .midfrm {
                    font-size: 12px;

                    span {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

@media (min-width: 1701px) and (max-width: 1920px) {
    .advisor-mile-card {
        .milescard {
            width: 280px;
            padding: 35px;
            height: 300px;
            margin: 10px 6px 10px 40px;
        }
    }
}

@media (min-width: 441px) and (max-width: 473px) {
    .advisor-mile-card {
        .milescard {
            margin: 10px 6px 10px 0px;
            width: 194px;
        }
    }
}

@media (min-width:320px) and (max-width:374px) {
    .advisor-connection-card {
        &__image {
            svg {
                width: 28px;
                height: 28px;
            }
        }

        &__title {
            font-size: 9px;
        }

        &__counter {
            font-size: 20px;
        }
    }
}

@media (min-width:375px) and (max-width:424px) {
    .advisor-connection-card {
        &__image {
            svg {
                width: 38px;
                height: 38px;
            }
        }

        &__title {
            font-size: 12px;
        }

        &__counter {
            font-size: 20px;
        }
    }
}

@media (min-width:498px) and (max-width:576px) {
    .advisor-connection-card {
        &__image {
            svg {
                width: 28px;
                height: 28px;
            }
        }

        &__title {
            font-size: 10px;
        }

        &__counter {
            font-size: 20px;

        }
    }
}

@media (min-width:577px) and (max-width:619px) {
    .advisor-connection-card {
        &__image {
            svg {
                width: 28px;
                height: 28px;
            }
        }

        &__title {
            font-size: 13px;
        }

        &__counter {
            font-size: 20px;
        }
    }
}

@media (min-width:769px) and (max-width:781px) {
    .advisor-connection-card {
        &__image {
            svg {
                width: 28px;
                height: 28px;
            }
        }

        &__title {
            font-size: 13px;
        }

        &__counter {
            font-size: 20px;
        }
    }
}

@media (min-width:950px) and (max-width:986px) {
    .advisor-connection-card {
        &__image {
            svg {
                width: 28px;
                height: 28px;
            }
        }

        &__title {
            font-size: 13px;
        }

        &__counter {
            font-size: 20px;
        }
    }
}

@media (min-width:1100px) and (max-width:1187px) {
    .advisor-connection-card {
        &__image {
            svg {
                width: 38px;
                height: 38px;
            }
        }

        &__title {
            font-size: 13px;
        }

        &__counter {
            font-size: 20px;
        }
    }
}

@media (min-width:1290px) and (max-width:1374px) {
    .advisor-connection-card {
        &__image {
            svg {
                width: 38px;
                height: 38px;
            }
        }

        &__title {
            font-size: 12px;
        }

        &__counter {
            font-size: 20px;
        }
    }
}

@media (min-width:1375x) and (max-width:1392px) {
    .adv-card-carousel {
        .advisor-connection-card {
            &__image {
                svg {
                    width: 38px;
                    height: 38px;
                }
            }

            &__title {
                font-size: 13px;
            }

            &__counter {
                font-size: 23px;
            }
        }
    }
}

@media (min-width:1500px) and (max-width:1546px) {
    .advisor-connection-card {
        &__image {
            svg {
                width: 48px;
                height: 48px;
            }
        }

        &__title {
            font-size: 15px;
        }

        &__counter {
            font-size: 20px;
        }
    }
}

@media (min-width:1560px) and (max-width:1571px) {
    .advisor-connection-card {
        &__image {
            svg {
                width: 38px;
                height: 38px;
            }
        }

        &__title {
            font-size: 13px;
        }

        &__counter {
            font-size: 20px;
        }
    }
}

@media (min-width:1800px) and (max-width:1920px) {
    .advisor-connection-card {
        &__image {
            svg {
                width: 48px;
                height: 48px;
            }
        }

        &__title {
            font-size: 16px;
            margin-bottom: 5px;
        }

        &__counter {
            font-size: 25px;
        }
    }
}

@media (max-width:768px) {
    .center-wrap-default {
        background-color: #f4f5f8;
        padding-bottom: 50px;
    }
}

@media only screen and (max-width: 418px) {
    .styled-radio-flex {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        .radio-label {
            font-size: 15px;
        }
    }

    .sendInviteParent {
        .alert-dismissible {
            padding-right: 2rem !important;
            width: 15rem;
        }
    }
}

.asyncmultiselect {
    .css-1s2u09g-control {
        height: fit-content !important;
        border-color: #E6E6E6 !important;
    }

    .css-7h4uon {
        height: 46px !important;
    }

    .css-13fpqzo-placeholder,
    .css-1dsj8q8-placeholder,
    .css-10hkxo4-placeholder {
        width: auto !important;
    }

    .css-ygwmvc-container {
        min-height: 48px !important;
        height: fit-content !important;

        .css-1s2u09g-control {
            border-radius: 12px !important;
        }

        .css-as3f3x-ValueContainer {
            padding: 5px !important;
            width: 409px !important;
        }

        .css-1hb7zxy-IndicatorsContainer {
            display: none !important;
        }
    }

    .mySelect__multi-value__remove {
        background-color: transparent !important;
    }

    .mySelect__multi-value__remove:hover {
        background-color: transparent !important;
    }

    .mySelect__multi-value {
        margin-top: 5px !important;
    }
}

@media only screen and (max-width: 780px) {
    .asyncmultiselect {
        .css-ygwmvc-container {
            min-height: 48px !important;
            height: fit-content !important;

            .css-1s2u09g-control {
                border-radius: 12px !important;
            }

            .css-as3f3x-ValueContainer {
                padding: 5px !important;
                width: 277px !important;
            }

            .css-1hb7zxy-IndicatorsContainer {
                display: none !important;
            }
        }

        .css-pq7ndz {
            padding: 5px !important;
            width: 277px !important;
        }
    }
}

.asyncmultiselect {
    .css-pq7ndz {
        padding: 5px !important;
        width: 409px !important;
    }

    .mySelect__indicators,
    .css-1wy0on6 {
        display: none !important;
    }

    .mySelect__value-container,
    .mySelect__value-container--is-multi,
    .mySelect__value-container--has-value,
    .css-pq7ndz {
        padding: 5px !important;
        width: 409px !important;
    }

    .css-pq7ndz {
        padding: 5px !important;
        width: 100% !important;
    }

    .mySelect__indicators,
    .css-1wy0on6 {
        display: none !important;
    }

    .mySelect__value-container,
    .mySelect__value-container--is-multi,
    .mySelect__value-container--has-value,
    .css-pq7ndz {
        padding: 5px !important;
        // width: 100% !important;
        min-height: 45.9px !important;
        font-family: @font--poppins-regular;
    }
}

.mySelect__placeholder,
.css-oqbfyi-placeholder {
    width: auto !important;
}

.css-1s2u09g-control {
    height: fit-content !important;
    border-color: #E6E6E6 !important;
}

.modal-fullscreen {
    width: 100% !important;
}

.mySelect__input-container {
    max-width: 397px !important;
    // border: 1px solid red;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.asyncmultiselect {

    .MuiOutlinedInput-root {
        border-radius: 10px !important;
        //   height: 48px !important;
        padding: 1rem;
    }

    .MuiInputLabel-root {
        font-size: 13px;
        font-family: @font--poppins-500 !important;
        padding-top: 4px;
        color: rgba(27, 28, 30, 0.5) !important;
        z-index: 0;
    }

    .MuiInputLabel-shrink {
        color: black !important;
        font-size: 17px !important;
        font-family: @font--poppins-500 !important;
        padding-top: 1px !important;
        z-index: 0;
    }

    .Mui-focused {
        color: black !important;
        font-size: 17px !important;
        font-family: @font--poppins-500 !important;
    }
}

.Mui-focused {
    border: none !important;
}

.multinew {
    .MuiInputBase-sizeSmall {
        min-height: 48px !important;
        max-height: fit-content !important;
    }

    .MuiOutlinedInput-notchedOutline {
        border-color: #000000 !important;
        opacity: 0.1 !important;
    }

    .MuiAutocomplete-tag {
        margin-top: 5px !important;
        background-color: #E6E6E6 !important;

        .MuiChip-label {
            color: hsl(0, 0%, 20%) !important;
            font-size: 13px !important;
            font-family: @font--poppins-500 !important;
        }
    }

    .MuiChip-deleteIcon {
        color: rgba(34, 34, 34, 0.67) !important;
    }
}
@media only screen and (min-width: 458px) and (max-width: 570px) {
    .role {
        .role_card {
            .login_card_form {
                .goals__form {
                    .multinew {
                        .goals__form__select {
                            min-width: 380px !important;
                            max-width: 100%;
                        }
                    }

                    .multinew .MuiAutocomplete-tag .MuiChip-label {
                        max-width: 420px !important;
                    }

                    .multinew,
                    .MuiAutocomplete-tag {
                        min-width: 162px !important;
                        // Developers Don't add anything here after entire project
                    }

                    .asyncmultiselect {
                        width: 100%;

                        .mySelect__value-container {
                            min-width: 100px !important;
                            max-width: 420px !important;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 381px) and (max-width: 459px) {
    .role {
        .role_card {
            .login_card_form {
                .goals__form {
                    .multinew {
                        .goals__form__select {
                            min-width: 350px !important;
                            max-width: 100%;
                        }
                    }

                    .multinew .MuiAutocomplete-tag .MuiChip-label {
                        max-width: 280px !important;
                    }

                    .multinew,
                    .MuiAutocomplete-tag {
                        min-width: fit-content !important;
                    }

                    .asyncmultiselect {
                        width: 100%;

                        .mySelect__value-container {
                            min-width: 100px !important;
                            max-width: 280px !important;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 380px) {
    .role {
        .role_card {
            .login_card_form {
                .goals__form {
                    .multinew {
                        .goals__form__select {
                            min-width: 184px !important;
                            max-width: 100%;
                        }
                    }

                    .multinew .MuiAutocomplete-tag .MuiChip-label {
                        max-width: 150px !important;
                    }

                    .multinew,
                    .MuiAutocomplete-tag {
                        min-width: 162px !important;
                        // Developers Don't add anything here after entire project
                    }

                    .asyncmultiselect {
                        width: 100%;

                        .mySelect__value-container {
                            min-width: 100px !important;
                            max-width: 200px !important;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 380px) {
    .error-text {
        padding-left: 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

@media only screen and (max-width: 1920px) {
    .role {
        .error-text {
            padding-left: 0px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
}

.MuiSvgIcon-root {
    height: 100% !important;
}

.ModalTitle {
    font-size: 18px !important;
    font-family: @font--poppins-500 !important;
}

@media only screen and(min-width: 421px) and (max-width: 768px) {
    .godashboard {
        width: max-content;
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media only screen and (max-width: 420px) {
    .godashboard {
        width: max-content;
        padding-left: 2px;
        padding-right: 2px;
    }
}

// recomendation empty state 

.Recommend-Advisor {
    margin-top: 100px;
    margin-bottom: 100px;
    display: flex;
    justify-content: center;

    .Recommend-Advisor-card {
        border: 1px solid #ececec;
        width: 380px;
        background-color: @backcolor--white;
        border-radius: 15px;
        margin: 10px;
        padding: 20px;
        cursor: pointer;
        display: flex;
        justify-content: center;

        .Recommend-title {
            display: flex;
            justify-content: center;

            p {
                font-size: 18px;
                font-family: @font--poppins-500;
            }
        }

        .Recommend-subtitle {
            display: flex;
            justify-content: center;

            p {
                font-size: 16px;
            }

        }
    }
}

.sendConnect {
    display: flex;
    justify-content: center;
    align-items: center;
}

//style to be apply only in firefox browser
@-moz-document url-prefix() {
    .courseRecommended {
        height: 85px;
    }
}

.notes-popup {
    .ql-editor {
        height: 73%;
    }
}

.MuiTextField-root {
    background-color: white;
}
.paperStyle-popup {
    width: 36% !important;
    .popup-btn{
        width: 50%;
    }
}
@media only screen and (max-width: 1000px) {
    .paperStyle-popup {
        width: 90% !important;
        .popup-btn{
            width: 80% !important;
        }
    }
}
@media screen and (max-width: 320px) {
    .planCourseAccess__commentSection__inputArea label {
        width: 17rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.pR{
    font-family: @font--poppins-regular;
}
.pB{
    font-family: @font--poppins-500;
}
