@import "../../../../../assets/css/variables.less";

.planDetailCard {
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    max-width: 100%;
    width: 280px;
    max-height: 2700px;
    background-color: #fff;

    &__Header {
        background-color: @backcolor--primary;
        height: 40px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        width: 100%;
        padding: 5px;
        display: flex;
        align-items: center;

        &__Heading {
            text-align: center;
            margin-inline: auto;
            margin-block: 10px;

            p {
                color: @backcolor--white;
                font-size: 16px;
                font-family: @font--poppins-500;
                margin-bottom: 0px;
                display: -webkit-box;
                max-width: 200px;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &__Menu {
            margin-inline-start: auto;
            display: flex;
            align-items: center;
            .MuiFormControlLabel-root{
                margin-right: 0px !important;
                margin-left: 0px !important;
            }
        }
    }

    &__Body {
        padding: 10px;
        .ChipSections{
            .ChipSection__header{
                font-size: 13px;
                font-family: @font--poppins-600;
            }
            .pkgCardTag-Sec{
                .tag__tags-chip{
                    height: 30px !important;
                    display: flex;
                    align-items: center;
                    &__tag-text-bold{
                        font-size: 12px !important;
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 250px) and (max-width: 350px)  {
    .planDetailCard {
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        width: 250px;
        max-width: 100%;
        max-height: 2700px;
        margin-bottom: 50px;
        background-color: #fff;
    }
   
}