@import "../../../../assets/css/variables.less";

.modalContent{
    padding: 10px 30px;

    &__section{
        &__textCont{
            display: flex;
            margin-bottom: 8px;

            &__label{
                min-width: 120px;
                font-family: @font--poppins-regular;
                font-size: 16px;
                color: #4A4A4A;
            }

            &__value{
                color: #1B1C1E;
                font-family: @font--poppins-500;
                font-size: 18PX;
                margin-bottom: 5px;
            }

            &__block-value{
                span{
                    font-family: @font--poppins-regular;
                    font-size: 16PX;
                }
            }

            .blue-text{
                font-family: @font--poppins-500;
                color: #1C84EE;
                font-size: 16px;
                height: max-content;
                white-space: pre-wrap;
                word-wrap: break-word;
                word-break: break-all;
                white-space: normal;
                display:block;
            }
        }
        &__value{
            color: #1B1C1E;
            font-family: @font--poppins-regular;
            font-size: 16PX;
        }
        &__bottom{
            &__reSchedule-sec{
                display: flex;
                justify-content: space-between;
                align-items: center;

                .reSchedule-btn{
                    color: #1C84EE !important ;
                    background-color: #fff !important;
                    border: 1px solid #1C84EE !important;

                }

                .btn{
                    font-weight: 500;
                    margin-inline: 10px !important;
                }

                &__label{
                    color: #1B1C1E;
                    font-family: @font--poppins-500;
                    font-size: 16PX;
                    margin-bottom: 0;
                }
            }
            &__confirmation-sec{
                display: flex;
                justify-content: flex-end;
                flex-wrap: wrap;

                & div{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                }


                .reject-btn{
                    border-radius: 10px;
                    color: @btn--white;
                    background-color: #E64A19 !important;
                    border: 1px solid #E64A19 !important;
                    padding-inline: 30px;
                }
                
                .accept-btn, .reSchedule-btn{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 40px;
                    user-select: none;
                    cursor: pointer;
                    background: @btn--primary;
                    border-radius: 10px;
                    color: @btn--white;
                    width: 140px;
                    line-height: 36px;
                    text-align: center;
                    border: 2px solid @btn--primary;
                    font-family: @font--poppins-regular;
                    &:hover{
                        cursor: pointer;
                        color: #1C84EE;
                        background: @btn--white;
                        border: 2px solid @btn--primary;
                        font-family: @font--poppins-regular;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .modalContent{
        padding: 10px 10px;

        &__section{
            &__textCont{
                display: flex;
    
                &__label{
                    min-width: 60px;
                }
            }
        }
    }
}
