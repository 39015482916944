@import "common.less";
.react-calendar {
  width: 350px;
  max-width: 100%;
  background-color: #fff;
  color: #222;
  height: 320px;
  border-radius: 8px;
  border: none;
  line-height: 1.125em;
  padding: 10px;
}
.react-calendar__navigation button {
  color: #000000;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
  border-radius: 20px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
abbr[title] {
  text-decoration: none;
}
/* .react-calendar__month-view__days__day--weekend {
 color: #d10000;
} */
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #6f48eb;
  border-radius: 20px;
}
.react-calendar__tile--now {
  background: #6f48eb33;
  font-weight: bold;
  color: #6f48eb;
  border-radius: 20px;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #6f48eb33;
  font-weight: bold;
  color: #6f48eb;
  border-radius: 20px;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
  border-radius: 20px;
}
.react-calendar__tile--active {
  background: #6f48eb;
  font-weight: bold;
  color: white;
  border-radius: 20px;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #6f48eb;
  color: white;
  border-radius: 20px;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
  border-radius: 20px;
}
.react-calendar__tile--range {
  background: #f8f8fa;
  color: #6f48eb;
  border-radius: 0;
  border-radius: 20px;
}
.react-calendar__tile--rangeStart {
  background: #6f48eb;
  color: white;
  border-radius: 20px;
}
.react-calendar__tile--rangeEnd {
  background: #6f48eb;
  color: white;
  border-radius: 20px;
}

.right-wrap {
  width: 380px;
  height: 100%;
  background-color: white;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  overflow-x: hidden;
  padding: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.dashboard-top {
  margin-left: 100px;
  background: #fff;
  padding: 5px 10px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  border-bottom: 1px solid #ddd;
  &-text {
    h3 {
      font-size: 16px;
      margin-bottom: 4px;
    }
    // p{
    //     font-size: 12px;
    // }
  }
}
.upcomecnt {
 
  h5 {
    margin-bottom: 15px;
  }
  &_list {
    height: calc(100vh - 400px);
    overflow: auto;
    &_item {
      background: #f4f5f8;
      // border: 1px solid #DBDBDB;
      border-radius: 10px;
      margin-bottom: 24px;
      padding: 15px;

      .upcomecnt_list_item_count {
        width: 50px;
        height: 50px;
        background: #1c84ee;
        line-height: 50px;
        text-align: center;
        font-weight: 700;
        font-size: 25px;
        color: #ffffff;
        border-radius: 100%;
        position: relative;
        top: 30%;
      }

      .upcomecnt_list_item-card {
        width: 100%;
        border-radius: 6px;
        position: relative;
        color: #5a5a5a;
        font-size: 15px !important;
        font-family: "poppins-regular", sans-serif !important;
        text-wrap: pretty;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        word-break: break-word;
      }

      .upcomecnt_list_item-card p {
        font-weight: 500;
        margin-bottom: 0px;
      }

      .upcomecnt_list_item-card h1 {
        text-align: center;
      }

      .upcomecnt_list_item-card .additional {
        height: 100%;
        overflow: hidden;
        z-index: 2;
      }

      .upcomecnt_list_item-card .additional .user-card {
        width: 50px;
        padding: 0px 10px;
        position: relative;
        float: left;
        height: 100%;
      }

      .upcomecnt_list_item-card .general {
        height: 100%;
        position: absolute;
        top: 0;
        left: 70px;
        z-index: 1;
        box-sizing: border-box;
        // padding: 1rem;
        // padding-top: 0;
        h6 {
          font-weight: 700;
          font-size: 15px;
        }
        p {
          font-weight: 700;
          font-size: 10px;
          line-height: 14px;
          color: #8a8a8a;
          margin-bottom: 5px;
        }
      }
    }
  }
}
.main-content {
  &__center {
    background: @backcolor--white;
    border-radius: 10px;
    padding: 16px;
    h5 {
      padding: 16px;
    }
    .contents {
      border: 1px solid rgba(48, 63, 159, 0.5);
      margin: 0px;
      border-radius: 10px;
      padding: 16px;

      &__text {
        &__title {
          font-size: 15px;
          line-height: 22px;
          color: @color--light-black;
          margin-bottom: 4px;
        }
        &__sub-title {
          font-size: 12px;
          line-height: 14px;
          color: @color--light-gray;
          margin-bottom: 0px;
        }
      }
    }
  }
  &__cta {
    color: @color--black;
    float: right;
  }
}

@media only screen and (max-width: 1000px) {
  .right-wrap {
    display: none;
    &.active {
      display: block;
      z-index: 9;
      top: 0px;
    }
  }
}
@media (max-width: 768px) {
  .main-wrap {
    padding: 10px;
    padding-top: 50px;
  }
  .right-wrap {
    &.active {
      top: 50px;
    }
  }
}

@media (max-width: 500px) {
  .right-wrap {
    width: 100%;
    border-top-left-radius: 0px;
    &.active {
      top: 50px;
    }
    .react-calendar {
      width: 100%;
    }
  }
}
