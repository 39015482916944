@import "variables.less";
@import "fonts.less";

@media only screen and (min-width: 280px) and (max-width:420px) {
    .SelectAdvisors {
        min-width: 280px;
        max-width: 320px;

        &__form {
            &__title {
                margin-bottom: 20px;
                width: 250px;
            }
        }
    }
}

.SelectAdvisors .SelectAdvisors__list {
    margin-bottom: 100px;
    min-height: 470px;
}

@media only screen and (max-width: 575px) {
    .SelectAdvisors {
        width: 575px;
    }
}

@media only screen and (max-width: 550px) {
    .SelectAdvisors {
        width: 550px;
    }
}

.SelectAdvisors {
    width: 1200px;
}

@media only screen and (min-width: 375px) {
    .SelectAdvisors {
        width: 350px;
    }
}

@media only screen and (min-width: 425px) {
    .SelectAdvisors {
        width: 400px;
    }
}

@media only screen and (min-width: 768px) {
    .SelectAdvisors {
        width: 700px;
    }
}

@media only screen and (min-width: 1024px) {
    .SelectAdvisors {
        width: 1000px;
    }
}

@media only screen and (min-width: 1280px) {
    .SelectAdvisors {
        width: 1100px;
    }
}

@media only screen and (min-width: 1290px) and (max-width: 1600px) {
    .SelectAdvisors {
        width: 1100px;
    }
}

@media only screen and (min-width: 200px) and (max-width: 490px) {
    .SelectAdvisors {
        margin-top: 50px;
    }
}
@media only screen and (min-width: 200px) and (max-width: 490px) {
    .footer__right {
        .footer__cta_plan {
            font-size: 12px;
        }
    }
}