@import "../../../assets/css/variables.less";

.Parent-MyNetwork {
    display: flex;

    &__mainCnt {

        .Advisor_Network_Sec {
            .Network_Title {
                font-family: @font--poppins-500;
                font-size: 32px;
            }
        }

        .Advisor_Network_Sec_Mobile {
            display: none;
            position: fixed;
            z-index: 99;
            top: 0px;
        }

        .card-sec {
            display: flex;
            flex-wrap: wrap;


            .netWork-card {
                max-width: 260px;
                margin: 0 20px 20px 0;
                padding: 20px 15px;
                border: 1px solid #ECECEC;
                background-color: #fff;
                border-radius: 15px;

                .profile-heading {
                    font-family: @font--poppins-600;
                    font-size: 16px;
                    color: #1B1C1E;
                    align-self: center;

                }

                .requestPending {
                    color: #1C84EE;
                    font-family: @font--poppins-500;
                    font-size: 14px;
                    font-style: normal;
                    line-height: 24px;
                    margin-bottom: 10px;
                    margin-top: 5px;
                }

                .requestRejected {
                    color: #E64A19;
                    font-family: @font--poppins-500;
                    font-size: 14px;
                    font-style: normal;
                    line-height: 24px;
                    margin-bottom: 10px;
                    margin-top: 5px;
                }

                .requestAccept {
                    color: #29A016;
                    font-family: @font--poppins-500;
                    font-size: 14px;
                    font-style: normal;
                    line-height: 24px;
                    margin-bottom: 10px;
                    margin-top: 5px;

                }

                .rating-heading {
                    font-family: @font--poppins-600;
                    font-size: 12px;
                    color: #1B1C1E;
                    align-self: center;

                }

                .profile-img {
                    width: 100% !important;
                }

                .session-heading {
                    font-family: @font--poppins-600;
                    font-size: 14px;
                    color: #1B1C1E;
                    align-self: center;

                }



                &__address-sec {
                    margin-top: 20px;

                    &__addr-line {
                        display: flex;

                        .location {
                            font-family: @font--poppins-300;
                            font-size: 12px;
                            font-weight: 400;
                            color: #525252;
                            margin-left: 10px;
                            margin-bottom: 10px;
                        }
                    }
                }

                &__tags-sec {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 10px;
                }

                &__button-sec {
                    margin-top: 10px;
                    display: flex;
                    flex-direction: column;



                    &__primary-btn {
                        background-color: #1C84EE;
                        color: #fff;
                        border: none;
                        width: 100%;
                        height: 35px;
                        border-radius: 10px;
                    }

                    &__cancel-btn {
                        background-color: #E64A19;
                        color: #fff;
                        border: none;
                        width: 100%;
                        height: 35px;
                        border-radius: 10px;
                    }

                    &__secondary-btn {
                        margin-top: 10px;
                        border: 2px solid #E64A19;
                        background: none;
                        color: #E64A19;
                        font-size: 16px;
                        width: 100%;
                        height: 35px;
                        border-radius: 10px;
                    }

                }

                &:hover {
                    transition: all 0.2s ease-out;
                    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
                    top: -4px;
                    border: 1px solid #cccccc;
                }
            }



        }
    }
}



.parent_Profile_Sec_Mobile{
    display: none;
}
@media screen and (max-width: 768px) {
.parent_Profile_Sec_Mobile{
    display: block;
}

}
.parent-profile-card {


    .spacingBtwCard {
        margin-top: 3px;
        margin-bottom: 5px;
    }

    .ListTitle {
        font-family: 'poppins-500', sans-serif;
        font-size: 14px;
        color: #3c3c3c;
    }

    .subListTitle {
        font-family: 'poppins-regular', sans-serif;
        font-size: 12px;
        line-height: 24px;
        color: #515050;
    }

}

.cardTitle {
    font-family: 'poppins-600', sans-serif;
    font-size: 17px;
    color: #3c3c3c;
}

.course-activity-card {

    // margin: 0 20px 20px 0;
    padding: 20px 15px;
    border: 1px solid #ECECEC;
    background-color: #fff;
    border-radius: 15px;

    .statusTab {
        background: #1C84EE;
        border: 0.5px solid rgba(189, 189, 189, 0.2);
        border-radius: 46px;
        color: #FFFFFF;
        width: 60%;
        padding: 5px;
        text-align: center;
    }
}




@media screen and (max-width: 768px) {
    .Parent-MyNetwork {
        padding: 20px;

        &__mainCnt {
            padding: 15px 20px;

            .Advisor_Network_Sec {
                display: none;
            }

            .Advisor_Network_Sec_Mobile {
                background-color: #fff;
                border-bottom: 1px solid #e3e3e3;
                height: 50px;
                display: flex;
                justify-content: center;
                width: 100%;
                align-items: center;

                .Network_Title {

                    font-size: 28px;
                    margin: 0;
                }
            }
        }
    }
}

.reasonRejectedPara {
    color: #1B1C1E;
    font-family: @font--poppins-500;
    font-size: 15px;
    font-style: normal;
    line-height: 36px;
    justify-content: center;
}


.RecievedRequest {
    margin-top: 30px;

    &__Title {
        p {
            font-size: 20px;
            font-family: @font--poppins-500;
        }
    }

    .Studentcard {
        .connectedStudentCard {
            &__buttonSec {
                display: block;
            }
        }

        &__footer {
            &__bottom {
                margin-top: 20px;
                display: flex;
                justify-content: space-evenly;
                gap: 20px;
            }
        }
    }
}

.PillStatus {
    &__Pending {
        border-radius: 100px;
        border: 2px solid #1C84EE;
        background-color: #DEEEFF;
        width: fit-content;
        padding-left: 10px;
        padding-right: 10px;

        p {
            margin-bottom: 0px;
            font-size: 10px;
            font-family: @font--poppins-500;
            color: #1C84EE;
        }
    }

    &__Accepted {
        border-radius: 100px;
        border: 2px solid #29A016;
        background-color: #E5FFE1;
        width: fit-content;
        padding-left: 10px;
        padding-right: 10px;

        p {
            margin-bottom: 0px;
            font-size: 10px;
            font-family: @font--poppins-500;
            color: #29A016;
        }
    }

    &__Rejected {
        border-radius: 100px;
        border: 2px solid #E64A19;
        background-color: #FCE5DD;
        width: fit-content;
        padding-left: 10px;
        padding-right: 10px;

        p {
            margin-bottom: 0px;
            font-size: 10px;
            font-family: @font--poppins-500;
            color: #E64A19;
        }
    }
}

.advisor-card-content {
    border-radius: 10px;
    background: #F4F5F8;
    padding: 10px;
    margin-top: 10px;

    .advisor-subText {
        color: #1B1C1E;
        font-family: @font--poppins-regular;
        font-size: 14px;
        font-style: normal;
    }
}



.Studentcard {
    .connectedStudentCard {
        &__buttonSec {
            display: block;
        }
    }

    &__footer {
        &__bottom {
            margin-top: 20px;
            display: flex;
            justify-content: space-evenly;
            gap: 20px;
        }

        .ExploreButton {
            background-color: @backcolor--primary;
            border: 1px solid @backcolor--primary;
            text-transform: none;
            color: @color--white;
            font-size: 16px;
            font-family: @font--poppins-regular;
            border-radius: 10px;
            padding: 10px;
            height: 40px;

            &:hover {
                border: 2px solid @backcolor--primary;
                color: @backcolor--primary;
            }
        }

        .RejectButton {
            background-color: @backcolor--white;
            border: 1px solid @backcolor--red;
            text-transform: none;
            color: @backcolor--red;
            font-size: 14px;
            font-family: @font--poppins-regular;
            border-radius: 10px;
            padding: 10px;
            height: 40px;
        }
    }
}

.AdvisorCard {
    background-color: @backcolor--white;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    padding: 10px;
    width: 300px;

    .ExploreButton {
        background-color: @backcolor--primary;
        border: 1px solid @backcolor--primary;
        text-transform: none;
        color: @color--white;
        font-size: 16px;
        font-family: @font--poppins-regular;
        border-radius: 10px;
        padding: 10px;
        height: 40px;

        &:hover {
            border: 2px solid @backcolor--primary;
            color: @backcolor--primary;
        }
    }

    .RejectButton {
        background-color: @backcolor--white;
        border: 1px solid @backcolor--red;
        text-transform: none;
        color: @backcolor--red;
        font-size: 14px;
        font-family: @font--poppins-regular;
        border-radius: 10px;
        padding: 10px;
        height: 40px;
    }

    .advisor-card-content {
        background-color: #f4f5f8;
        padding: 10px;
        border-radius: 10px;
        font-family: @font--poppins-regular;
    }

    .advisor-card-description {
        background-color: #f4f5f8;
        padding: 10px;
        border-radius: 10px;
        font-family: @font--poppins-regular;

        p {
            margin-bottom: 0px;
        }
    }

    .advisord-card-footer {
        margin-top: 10px;
        padding: 10px 30px;

        &__View {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__accept {
            display: flex;
            justify-content: space-evenly;
            gap: 10px;
            align-items: center;
        }
    }
}

.Empty__Container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px;
    width: 100%;


    &__Card {
        background-color: #fff;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        padding: 20px;
        cursor: pointer;
        width: 600px;
        text-align: center;

        &:hover {
            transition: all 0.2s ease-out;
            box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
            top: -4px;
            border: 1px solid #cccccc;
            background-color: @backcolor--white;
        }

        p {
            font-family: @font--poppins-regular;
            font-size: 18px;
            margin-bottom: 0px;
        }
    }
}

.Student_Profile_Sec_Mobile {
    display: none;
}

.Network__Content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-left: 10px;
}

.Plan_Card_Empty_Cnt {
    margin-top: 0px;
    width: 100%;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;

    .Empty_Card {
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        background-color: @backcolor--white;
        padding: 20px;
        text-align: center;
        transition: all 0.2s ease-out;
        box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        top: -4px;
        border: 1px solid #cccccc;
        background-color: @backcolor--white;
        p {
            font-family: @font--poppins-500;
        }
    }
}


@media only screen and (max-width: 768px) {
    .Parent-MyNetwork__mainCnt{
        .Network__Content{
            margin-top: 6rem;
        }
    }

    .Student_Profile_Sec_Mobile {
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @backcolor--white;
        border-bottom: 1px solid #e3e3e3;
        height: 50px;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;

        .Profile_Title {
            font-family: @font--poppins-500;
            font-size: 16px;
            margin-bottom: 0px;
            margin-left: 12px;
        }
    }
}


@media only screen and (max-width: 400px) {
    .Empty__Container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
    }
}

.zeroStateReceived {
    margin-inline: auto;
}

.netWork-card {
    width: 260px;
    margin: 0 20px 20px 0;
    padding: 20px 15px;
    border: 1px solid #ECECEC;
    background-color: #fff;
    border-radius: 15px;

    &__title {
        font-family: @font--poppins-500;
        font-size: 16px;
    }

    &__top-sec {
        display: flex;

        &__profile-img {
            width: 70px;
            height: 70px;
        }

        &__profile-heading {
            font-family: @font--poppins-600;
            font-size: 16px;
            color: #1B1C1E;
            align-self: center;
            margin-left: 20px;
        }
    }

    &__address-sec {
        margin-top: 5px;

        &__addr-line {
            display: flex;

            &__location {
                font-family: @font--poppins-300;
                font-size: 13px;
                font-weight: 500 !important;
                color: #000 !important;
                margin-left: 10px;
                margin-bottom: 5px;
            }
        }

        &__school-line {
            width: 100%;
            display: flex;

            &__location {
                font-family: @font--poppins-300;
                font-size: 13px;
                font-weight: 500 !important;
                color: #000 !important;
                margin-left: 10px;
                margin-bottom: 5px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                height: 40px;
            }
        }
    }

    &__tags-sec {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;

        .tag__tags-chip:only-child {
            // display: -webkit-box;
            // -webkit-line-clamp: 1;
            // -webkit-box-orient: vertical;
            // overflow: hidden;
            // text-overflow: ellipsis;
        }

        &__msg-tag {
            background-color: #E3E3E3 !important;
            padding: 3px 10px;
            border-radius: 10px;
            width: 100%;
            height: 75px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;

            &__message {
                margin-bottom: 0;
                font-size: 13px;
                line-height: 22px;
                color: #000000;
                font-family: @font--poppins-500;

            }
        }

        &__noMsg-tag {
            background-color: #E3E3E3 !important;
            padding: 3px 10px;
            border-radius: 10px;
            width: 100%;
            height: 75px;
            display: flex;
            justify-content: center;

            &__message {
                font-family: @font--poppins-500;
                font-size: 13px;
                margin-bottom: 0;
                color: #000000;
                padding: 10px;
            }
        }
    }

    &__button-sec {
        align-items: center;
        margin-top: 10px;
        display: flex;
        flex-direction: column;

        &__primary-btn {
            background-color: #1C84EE;
            color: #fff;
            border: none;
            width: 100%;
            height: 35px;
            border-radius: 10px;
        }

        &__secondary-btn {
            // margin-top: 10px;
            border: 2px solid #E64A19;
            background: none;
            color: #E64A19;
            font-size: 16px;
            width: 100%;
            height: 35px;
            border-radius: 10px;
        }

        &__primes-btn {
            color: #1C84EE;
            margin-top: 10px;
            border: 2px solid #1C84EE;
            background-color: #fff;
            height: 42px;
            width: 85%;
            border-radius: 10px;
            font-family: @font--poppins-500;
        }

        &__grey-btn {
            margin-top: 10px;
            border: 2px solid #cdcdcd;
            background: none;
            color: #1C84EE;
            font-size: 16px;
            width: 100%;
            height: 35px;
            border-radius: 10px;
        }
    }

    &:hover {
        transition: all 0.2s ease-out;
        box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        top: -4px;
        border: 1px solid #cccccc;
    }
}

.cta-primary {
    background-color: @btn--primary !important;
    font-size: 16px !important;
    font-family: @font--poppins-500 !important;
    color: @color--white !important;
    font-weight: 500 !important;
}

@media screen and (max-width: 430px) {
    .advisor-card-content{
        height: fit-content !important;
        margin-bottom: 5px;
    }  
    .pkgCardTag-Sec{
        flex-wrap: wrap;
        margin-bottom: 9px;
    }
    }

