@import "../../../../../assets/css/variables.less";

.advisorPricingCard{
    background-color: #fff;
    border: 1px solid #8A8A8A;
    min-height: 270px;
    width: 270px;
    border-radius: 10px;

    &__topSec{
        padding: 7px 10px;
        background-color: #1C84EE;
        border-top-right-radius: 10px;
        border-top-left-radius: 9.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        &__title{
            margin: 0;
            color: #fff;
            font-size: 14px;
            max-width: 210px;
            font-family: @font--poppins-500;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            cursor: default;
        }

        &__cardgrid__dot{
            position: absolute;
            right: 5px;
           
            .btn-primary {
                background-color: transparent !important;
                border-color: transparent !important;
                padding: 0 4px;
                display: flex;

                svg{
                    fill: #fff !important;
                }
            }
            .btn-primary:hover {
                background-color: #ffffff00 !important;
                border-color: #ffffff00 !important;
            }
            .primary.dropdown-toggle {
                background-color: #dfdfdf00 !important;
                border-color: #dfdfdf00 !important;
            }
            .btn-primary.dropdown-toggle:focus {
                border: none;
                box-shadow: transparent !important;
            }
            .btn-primary::after{
                display: none;
            }
        }
    }

    &__midSec{
        padding: 15px 12px;
        display: flex;
        flex-direction: column;
        gap: 15px;

        &__content, &__statusContent{
            display: flex;
            gap: 10px;
            min-height: 43px;

            &__label{
                margin: 0;
                flex: 1;
                color: #1B1C1E;
                font-size: 15px;
                font-family: @font--poppins-600;
                min-width: 75px;
            }
            
            &__value{
                flex: 2;
                margin: 0;
                font-size: 14px;
                font-family: @font--poppins-500;
                color: #6C6C6C;
                white-space: normal;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                cursor: pointer;
            }
            .servicesIncluded{
                -webkit-line-clamp: 4;
                min-height: 85px;
            }
        }
        &__statusContent{
            min-height: 20px;
            display: flex;
            align-items: center;

            .ACTIVE{
                color: #237e15;
            }
            .DISABLED{
                color: #f32c25;
            }
        }
    }
}

@media screen and (max-width: 340px) {
    .advisorPricingCard{
        &__topSec{
            &__title{
                max-width: 175px;
            }
        }
    }
}