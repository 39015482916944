@import "../../../../assets/css/variables.less";

.modalContent {
    padding: 10px 30px;

    &__section {
        &__textCont {
            display: flex;

            &__label {
                min-width: 120px;
                font-family: @font--poppins-regular;
                font-size: 16px;
                color: #4A4A4A;
            }

            &__value {
                color: #1B1C1E;
                font-family: @font--poppins-500;
                font-size: 18PX;
            }

            &__block-value {
                span {
                    font-family: @font--poppins-regular;
                    font-size: 16PX;
                }
            }

            .blue-text {
                font-family: @font--poppins-500;
                color: #1C84EE;
                font-size: 16px;
                max-width: 400px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .reg-text {
                font-family: @font--poppins-500;
                color: #1B1C1E;
                font-size: 16px;
                max-width: 400px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        &__value {
            color: #1B1C1E;
            font-family: @font--poppins-regular;
            font-size: 16PX;
        }

        &__bottom {
            &__reSchedule-sec {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .reschedulesave-btn  {
                    background-color: #1C84EE !important;
                    color: #fff !important;
                    border: 1px solid #1C84EE !important;
                    border-radius: 10px;
                }
                .cancel-btn {
                    color: @color--white !important;
                    background-color: @backcolor--red !important;
                    border: @backcolor--red !important;
                    border-radius: 10px;
                }
                .btn {
                    font-weight: 500;
                    margin-inline: 10px !important;
                }

                &__label {
                    color: #1B1C1E;
                    font-family: @font--poppins-500;
                    font-size: 16PX;
                    margin-bottom: 0;
                }
            }

            &__confirmation-sec {
                display: flex;
                justify-content: flex-end;

                .reject-btn {
                    color: #fff !important;
                    background-color: #E64A19 !important;
                    border: 1px solid #E64A19 !important;
                    padding-inline: 30px;
                }

                .accept-btn {
                    color: #fff !important;
                    background-color: #1C84EE !important;
                    border: 1px solid #1C84EE !important;
                    padding-inline: 30px;
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .modalContent {
        padding: 10px 10px;

        &__section {
            &__textCont {
                display: flex;

                &__label {
                    min-width: 60px;
                }
            }
        }
    }
}