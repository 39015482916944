@import "../../../assets/css/variables.less";

.textCenter {
    text-align: center;
}

.textRight {
    text-align: right;
}

.justifyContent {
    justify-content: end;
}

.blueBtn {
    background: #1C84EE !important;
    text-transform: capitalize !important;
    font-family: @font--poppins-regular !important;
}

.greyBtn {
    background: #6c757d !important;
    text-transform: capitalize !important;
    font-family: @font--poppins-regular !important;
}

.floatRight {
    float: right;
}

.outLineBtn {
    text-transform: capitalize !important;
    color: black !important;
    border-color: black !important;
    font-family: @font--poppins-regular !important;
}

.outLineBtnRed {
    text-transform: capitalize !important;
    color: #E64A19 !important;
    border-color: #E64A19 !important;
    font-family: @font--poppins-regular !important;
}

.profile_pill_btns {
    padding: 5px 10px;
    background-color: @backcolor--primary;
    color: @backcolor--white;
    font-size: 12px;
    font-family: @font--poppins-regular;
    border-radius: 30px;
    margin-left: 60px;
    position: absolute;
    margin-top: 5px;
    cursor: pointer;
}

.profile_pill_btns:hover {
    color: white !important;
}

.ImageErrorList {
    font-size: 12px;
    color: #E64A19;
    font-family: 'poppins-regular', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.footerGoals {
    display: flex !important;
    justify-content: center;
    align-items: center;
    background: #1c84ee;
    border: 2px solid #1c84ee;
    border-radius: 10px;
    padding: 10px 25px;
    user-select: none;
    color: #fff;
    cursor: pointer;
    gap: 8px;

    svg {
        .rolestrokes {
            stroke: #ffffff;
        }

        .rolefills {
            fill: #fff;
        }
    }


}

@media only screen and (max-width: 530px) {
    .profile_pill_btns {
        width: fit-content !important;
    }
    .spouseConnection{
        width: 85% !important;
    }
}


@media screen and (max-width: 379px) {

    .mySelect__value-container {
        width: 80px !important;
        min-width: 80px !important;
    }

    .connectedModal {
        .css-oqbfyi-placeholder {
            padding-left: 3px;
        }
    }


}

.card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ddd;
    padding: 10px;
    margin: 10px;
}

.card-image {
    flex: 1;
    margin-right: 10px;
}

.card-details {
    flex: 2;
    margin-right: 10px;
}

.card-button {
    flex: 1;
}



.Parentprofilecontainer {
    .profileTitle {
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: 0px;
        text-align: left;
    }

    .commanCardCnt {
        background-color: white;
        border: 1px solid #CDCDCD;
        padding: 1rem;
        border-radius: 15px;
        margin-bottom: 1rem;
    }

    .profileLayout {
        padding: 20px 15px
    }

    .error-text-billing {
        padding-left: 45px;
    }

    .childCard {
        width: 10rem;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 1rem;
    }

    .deleteSubText {
        color: #000;
        text-align: center;
        font-family: @font--poppins-500 !important;
        font-size: 12px;
    }



    .billingMonth {
        .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-readOnly {
            border-radius: 11px !important;
            height: 51px !important;
        }

    }

    .actionBtns {
        margin-left: 7rem;
    }


    .Billing_Card {

        .Billing_Card_Title {
            font-size: 20px;
            font-family: @font--poppins-500;
        }

        .Debit_Card_Container {
            display: flex;
            flex-wrap: wrap;
            padding-top: 20px;

            .Debit_Card {
                border-radius: 10px;
                width: 350px;
                // min-height: 200px;
                background: #5A94ED;
                background: -webkit-linear-gradient(top left, #5A94ED, #2EC2E8);
                background: -moz-linear-gradient(top left, #5A94ED, #2EC2E8);
                background: linear-gradient(to bottom right, #5A94ED, #2EC2E8);
                border: 1px solid #5A94ED;
                margin-right: 30px;
                margin-bottom: 40px;

                &:hover {
                    transition: all 0.1s ease-out;
                    box-shadow: 0px 4px 8px #5A94ED;
                    top: -4px;
                    border: 1px solid #2EC2E8;
                    background-color: #2EC2E8;
                }

                .Debit_Card_Number_Cnt {
                    padding-left: 20px;
                    padding-top: 40px;

                    .cardnumber {
                        font-size: 36px;
                        font-family: @font--poppins-500;
                        color: @color--white;
                    }
                }

                .Debit_Card_Expiry_Cnt {
                    padding-left: 20px;
                    padding-top: 15px;

                    .Expire {
                        font-size: 22px;
                        font-family: @font--poppins-regular;
                        color: @color--white;
                        line-height: 0px;
                    }

                    .carddate {
                        font-size: 22px;
                        font-family: @font--poppins-regular;
                        color: @color--white;
                    }

                    .edit_row {
                        display: flex;
                        justify-content: space-evenly;

                        .Edit_btn {
                            font-size: 22px;
                            font-family: @font--poppins-regular;
                            color: @color--white;
                            cursor: pointer;

                            &:hover {
                                color: @backcolor--black;
                            }
                        }

                        .Delete_btn {
                            font-size: 22px;
                            font-family: @font--poppins-regular;
                            color: @color--white;
                            cursor: pointer;

                            &:hover {
                                color: @backcolor--black;
                            }
                        }
                    }
                }
            }

        }
    }

    .billcardsection {
        .billcard {
            .billbox {
                cursor: pointer;
                border: 1px solid #1B1C1E;
                ;
                border-radius: 10px;
                width: 200px;
                height: 150px;
                display: flex;
                justify-content: center;

                .cnt {
                    padding: 50px;
                }
            }

        }
    }

    .passwordContainer {
        margin-top: 0px !important;
        padding-top: 0px !important;

    }
    .connectedGoalsPage{
        .role_card {
            margin-bottom: 5rem;
        }
    }

    @media only screen and (max-width: 400px) {
        .changePswForm {
            .input-floating-label {
                margin-bottom: 50px !important;
            }
        }
    }

    @media only screen and(min-width: 311px) and (max-width: 470px) {

        .Billing_Card {
            max-width: 428px !important;
            min-width: 238px !important;

            .Debit_Card_Container {
                padding-top: 0px !important;

                .Debit_Card {
                    margin-right: 0px !important;
                    margin-bottom: 0px !important;

                    .Debit_Card_Number_Cnt {
                        padding-left: 10px !important;
                        padding-top: 20px !important;

                        .cardnumber {
                            font-size: 24px !important;
                        }
                    }

                    .Debit_Card_Expiry_Cnt {
                        padding-left: 5px !important;
                        padding-top: 5px !important;

                        .Expire {
                            font-size: 18px !important;
                        }

                        .carddate {
                            font-size: 18px !important;
                        }

                        .edit_row {
                            .Edit_btn {
                                font-size: 18px !important;
                            }

                            .Delete_btn {
                                font-size: 18px !important;
                            }
                        }
                    }
                }
            }

        }

    }


    @media only screen and (max-width: 310px) {


        .Billing_Card {
            max-width: 428px !important;
            min-width: 238px !important;

            .Debit_Card_Container {
                padding-top: 0px !important;

                .Debit_Card {
                    margin-right: 0px !important;
                    margin-bottom: 0px !important;

                    .Debit_Card_Number_Cnt {
                        padding-left: 10px !important;
                        padding-top: 20px !important;

                        .cardnumber {
                            font-size: 18px !important;
                        }
                    }

                    .Debit_Card_Expiry_Cnt {
                        padding-left: 5px !important;
                        padding-top: 5px !important;

                        .Expire {
                            font-size: 14px !important;
                        }

                        .carddate {
                            font-size: 14px !important;
                        }

                        .edit_row {
                            .Edit_btn {
                                font-size: 14px !important;
                            }

                            .Delete_btn {
                                font-size: 14px !important;
                            }
                        }
                    }
                }
            }

        }

    }

    @media only screen and (max-width: 300px) {
        .Billing_Card {
            .Debit_Card_Container {
                width: 13rem !important;
            }
        }

    }

    .MuiFormControlLabel-label {
        font-size: 14px !important;
    }

    .Goals_Container_Button {
        margin: 0 auto;
        width: 100%;
        display: flex;
        width: 678px;
        display: flex;
        justify-content: center;

        .cta--role--btn {
            border-radius: 300px;
            padding: 6px 20px;
            border: 2px solid #4B4B4B;
            font-size: 15px;

            &.checked {
                background: @btn--primary;
                color: @color--white;
            }
        }
    }

    .input-non-floating-label {

        margin-bottom: 20px;
        width: 100%;

        .input {
            width: 100%;
            display: block;
            outline: 0;
            background: transparent;
            transition: border-color 0.2s;

            &:invalid,
            &:required {
                box-shadow: none;
            }

            &:focus {
                border-color: rgba(0, 0, 0, 0.1);
            }


            &:not(:placeholder-shown)~label {
                color: #000 !important;
            }
        }

    }

    .formGoalsContainer {
        justify-content: center;
    }

    .cardContent {
        border: 1px solid #CDCDCD !important;
        border-radius: 15px !important;
        box-shadow: none !important;
        padding-top: 15px;
        padding-left: 15px;
    }

    .cardParentContent {
        border: 1px solid #CDCDCD !important;
        border-radius: 15px !important;
        box-shadow: none !important;

    }

    .__Top_Mobile {
        display: none;
    }

    .tab_card {
        display: block;
    }

    @media screen and (max-width: 425px) {


        .User_Image_Card {
            display: block;

            .User_Image {
                margin-inline: auto;
            }

            .Upload_Btn_Cnt {
                margin-inline: auto;
                padding-right: 0;
                display: flex;
                justify-content: center;
                flex-direction: column;

                .Upload_Btn_Cnt_Top {
                    display: flex;
                    justify-content: center;
                }
            }
        }


    }



    .Profile_Image_Card {
        display: flex;
        justify-content: space-around;

        .User_Image {
            border-radius: 10px;
            width: 90px;
            height: 90px;
            position: relative;

            .profileimage {
                width: 100%;
                height: 100%;
            }

            .deletePic {
                right: -10px;
                top: -10px;
                position: absolute;
                cursor: pointer;

                :hover {
                    color: #E64A19;
                }
            }
        }

        .Upload_Btn_Cnt {
            padding-top: 15px;
            padding-right: 20px;

            .Upload_Btn_Cnt_Top {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .Upload_Btn {
                    background-color: @backcolor--white;
                    border: 2px solid @backcolor--primary;
                    border-radius: 10px;
                    width: 162px;
                    text-transform: capitalize;
                    font-size: 16px;
                    font-family: @font--poppins-regular;

                    svg {
                        fill: @color--primary;
                        width: 28px;
                        height: 28px;
                    }
                }
            }

            .Upload_Btn_Cnt_Btm {
                display: flex;
                justify-content: center;

                p {
                    font-size: 14px;
                    font-family: @font--poppins-regular;
                    color: @color--secondary;
                    margin-top: 5px;
                }
            }
        }
    }




    @media only screen and (max-width: 2560px) {
        .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-readOnly {
            border-radius: 11px !important;
            height: 51px !important;
        }
    }

    @media only screen and (max-width: 800px) {
        .tab-container {
            margin-top: 9rem !important;
        }

    }

    @media only screen and (max-width: 768px) {
        .tab-container {
            margin-top: 9rem !important;
        }

        .__Top_Mobile {
            display: block;
        }

        .tab_card {
            display: none;
        }

    }

}


@media screen and (max-width: 435px) {
    .actionBtns {
        width: 100% !important;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-left: 0px !important;
    }

    .textRight {
        text-align: center !important;
    }
}

.goalsModal {



    .profilesavebtn {
        background: @btn--primary;
        border: 2px solid #1c84ee;
        border-radius: 10px;
        color: @btn--white;
        cursor: pointer;
        // font-size: 16px;
        height: 40px;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        width: 165px;
        text-transform: capitalize;

    }

    .profilesavebtn:hover {
        background: @backcolor--white;
        border: 2px solid #1c84ee;
        color: rgb(0, 0, 0);
        cursor: pointer;
        text-transform: capitalize;
    }

    .profilesavebtn:focus {
        background: @btn--primary;
        border: 2px solid #1c84ee;
        color: @btn--white;
        cursor: pointer;
        text-transform: capitalize;
    }

}

@media screen and (max-width: 600px) {
    .goalsModal {
        .profilesavebtn {
            background: @btn--primary;
            border: 2px solid #1c84ee;
            border-radius: 10px;
            color: @btn--white;
            cursor: pointer;
            // font-size: 16px;
            height: 40px;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
            width: 100% !important;
            text-transform: capitalize;
        }
    }


}


.backArrow {
    margin-right: 5px;
    margin-top: -6px;
}

.Tab {
    padding: 0px;

    height: 0px;
    min-height: 49px;
    font-family: @font--poppins-regular;
    font-size: 18px;
    text-transform: none;

    &:hover {
        color: @backcolor--primary;
    }
}

.MuiTabs-root {
    align-items: center;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-labelIcon.MuiTab-textColorPrimary {
    text-transform: capitalize !important;
    font-family: @font--poppins-regular;
    font-size: 18px;
}

.MuiBox-root {
    padding: 0px;
}

.disconnectUser {
    padding: 1rem !important;
    text-align: center;
}

.Already_Connect_Account {
    border: 1px solid #e3e3e3;
    background-color: #ffff;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: center;

    .Already_Connect_Account_Title {
        font-size: 18px;
        font-family: @font--poppins-500;
    }
}