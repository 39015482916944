@import "../../../../assets/css/variables.less";

.myWorkCard {
    background-color: @backcolor--white;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    height: 40rem;

    .timeLineTitle {
        font-family: @font--poppins-regular !important;
    }

    .Timelinecontainer {
        max-height: 35rem;
        overflow: auto;
    }
}

.myWorkCardemptystate {
    background-color: @backcolor--white;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    padding: 10px;
    min-height: 100px;
}

.cardgrid__dot {
    .btn-primary {
        background-color: #d7111100 !important;
        border-color: #ffffff00 !important;
        color: #0d0d0dc1 !important;
        padding: 0;
    }

    .dropdown-toggle::after {
        display: none;
    }

    .btn-primary:hover {
        background-color: #ffffff00 !important;
        border-color: #ffffff00 !important;
    }

    .primary.dropdown-toggle {
        background-color: #dfdfdf00 !important;
        border-color: #dfdfdf00 !important;
    }

    .btn-primary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0) !important;
    }
}

.planMilestone {
    .milestoneTitle {
        font-size: 18px;
        font-family: 'poppins-500', sans-serif;
        width: 210px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .myWorkCard {
        background-color: @backcolor--white;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        height: 40rem;

        .timeLineTitle {
            font-family: @font--poppins-regular !important;
        }

        .Timelinecontainer {
            max-height: 35rem;
            overflow: auto;
        }
    }

    .myWorkCardemptystate {
        background-color: @backcolor--white;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        padding: 10px;
        min-height: 100px;
    }

    .subMileTitle {
        font-size: 15px;
        font-family: 'poppins-500', sans-serif;
    }

    .milestoneType {
        font-size: 14px;
        font-family: @font--poppins-regular;
        color: #5C5C5C;
    }

    .otherDetail {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

}

@media only screen and (max-width: 625px) {
    .Milestone_Page{
        padding: 15px;
    }
    .planMilestone{
        margin-top: 0px !important;
    }

    .milestoneTab{
        padding: 15px;
    }
    .tab_card__addMileStonebtn{
        margin-right: 1rem;
    }
}

.phase_page_Mid_Desktop_TabCnt{
    .MuiTab-root{
        text-transform: none !important;
    }
}

@media only screen and (max-width: 768px) {
    .MuiTimelineItem-root::before {
        -webkit-flex: 0 !important;
        padding: 0px !important;
    }
}

.activityMiles{
    .MuiTab-root{
        text-transform: none !important;
    }  
}
