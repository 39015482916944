@import "../../../../assets/css/variables.less";



.advisor-officeHours {
    padding: 25px 30px;
    border: 1px solid #CDCDCD;
    border-radius: 10px;
    background-color: #fff;

    &__title {
        margin-bottom: 30px;
        p {
            font-size: 18px;
            letter-spacing: 0.15px;
            font-family: 'poppins-500', sans-serif;
            line-height: 24px;
            color: #000;
        }
    }

    &__formSec{
        .timeinputcnt{
            .startTimeGrid, .endTimeGrid{
                .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-readOnly 
                {
                border-radius: 11px !important;
                height: 51px !important;
                } 

                
            }
        }
    }

  
  
}
