@import "common.less";

.owl {
    &__item {
        a{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &__desc {
            font-size: 13px;
            display: flex;
            align-items: flex-end;
            text-align: center;
            letter-spacing: 0.15px;
            color: rgba(0, 0, 0, 0.7);
            width: 260px;
            margin: 0;
            margin-inline: auto;
            min-height: 38px;
            justify-content: flex-start;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        &__lists {
            background: #FFFFFF;
            border: 2px solid #959595;
            border-radius: 24px;
            padding: 24px;
            margin-top: 8px;
            min-height: 360px;

            &__icon {
                width: 70px;
                height: 72px;
            }

            &__session {
                font-weight: 600;
                font-size: 15px;
                line-height: 24px;
                text-align: right;
                color: #1B1C1E;
                margin-bottom: 8px;

                p {
                    margin-bottom: 0px;
                }
            }

            &__rating {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                text-align: right;
                color: #000000;

                p {
                    margin-bottom: 0px;
                }
            }

            &__titel {
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
                color: #1B1C1E;
                margin-top: 17px;
            }

            &__location {
                margin-top: 8px;

                p {
                    margin-bottom: 0px;

                    span {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 16px;
                        color: #525252;
                    }
                }
            }

            &__tags {
                margin-top: 26px;

                span {
                    background: #d6d7dd;
                    border-radius: 8px;
                    height: 26px;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 26px;
                    color: #1B1C1E;
                    padding: 0px 15px;
                    display: inline-block;
                    margin: 6px;
                }
            }

            &__cta {
                color: #3E3E3E;
                font-weight: 600;
                font-size: 16px;
                line-height: 26px;
                text-align: center;
                display: none;
                margin-top: 26px;
            }
        }

        &:hover {
            .owl__item__lists {
                border: 2px solid #3C3F51;
            }

            .owl__item__lists__cta {
                display: block;
            }
        }
    }
}



@media screen and (max-width: 426px) {
    .owl__item {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
    }
}

.owl__item__lists__tags {
    height: 7rem;
    overflow: hidden;
    margin-top: 0px !important;
}


.owl__item__lists__location p {
    margin-bottom: 0px;
    width: 11rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
    font-size: 24px;
    font-weight: 500;

    &:hover {
        background-color: transparent;
        color: #1C84EE;
    }
}

.owl__item {
    .exploreAdvisor {
        .advisorCard {
            .netWork-card__button-sec__primary-btn {
                width: 70%;
            }
        }
    }
    .netWork-card {
        width: 260px;
        margin: 0 20px 20px 0;
        padding: 20px 15px;
        border: 1px solid #dfdddd;
        background-color: #fff;
        border-radius: 15px;

        &__top-sec {
            display: flex;

            &__profile-img {
                width: 70px;
                height: 70px;
            }



            &__profile-heading {
                font-family: @font--poppins-600;
                font-size: 16px;
                color: #1B1C1E;
                align-self: center;
                margin-left: 20px;

            }
        }

        &__address-sec {
            margin-top: 10px;

            &__addr-line {
                display: flex;

                .location {
                    font-family: @font--poppins-300;
                    font-size: 12px;
                    font-weight: 400;
                    color: #525252;
                    margin-left: 10px;
                    // margin-bottom: 10px;
                    // height: 36px;
                    // white-space: nowrap;
                    // overflow: hidden;
                    // text-overflow: ellipsis;
                }

                .schoolName {
                    height: 36px;
                }
            }
        }


        .empty_title {
            min-height: 25px;
        }

        &__tags-sec {
            display: flex;
            flex-wrap: wrap;
            margin-top: 10px;
        }



        &__buttonSec {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: auto;
            align-items: center;

            &__primary-btn {
                background-color: #1C84EE;
                color: #fff;
                border: none;
                width: 85%;
                height: 42px;
                border-radius: 10px;
                font-family: @font--poppins-500;
            }

            &__secondary-btn {
                margin-top: 10px;
                border: 2px solid #E64A19;
                background: none;
                color: #E64A19;
                font-size: 16px;
                width: 100%;
                height: 35px;
                border-radius: 10px;
            }
        }

        &:hover {
            transition: all 0.2s ease-out;
            box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
            top: -4px;
            border: 1px solid #cccccc;
        }
    }
}

.SelectAdvisors__list {
    position: relative;

    .course_title {
        font-size: 16px;
        font-family: @font--poppins-500;
        line-height: 10px;
    }

    .owl-prev,
    .owl-next {
        position: absolute;
    }

    .owl-prev {
        left: -35px;
        top: 240px;
    }

    .owl-next {
        right: -35px;
        top: 240px;
    }

    .owl-theme .owl-nav [class*='owl-'] {
        background: transparent !important;
        color: black !important;
        font-size: 40px;

    }

}

.studentRecomment {
    .SelectAdvisors__list {
        position: relative;

        .course_title {
            font-size: 16px;
            font-family: @font--poppins-500;
            line-height: 10px;
        }

        .owl-prev,
        .owl-next {
            position: absolute;
        }

        .owl-prev {
            left: -35px;
            top: 260px !important;
        }

        .owl-next {
            right: -35px;
            top: 260px !important;
        }

        .owl-theme .owl-nav [class*='owl-'] {
            background: transparent !important;
            color: black !important;
            font-size: 40px;

        }

    }
}

// @media screen and (max-width: 767px) {
//     .owl-next {
//         right: 75px !important;
//         top: 157px;
//     }
// }

@media screen and (max-width: 426px) {
    .SelectAdvisors__list {

        .owl-prev {
            left: -20px !important;
            top: 292px;
        }

        .owl-next {
            right: -20px !important;
            top: 292px;
        }
    }
}

@media screen and (max-width: 424px) {
    .owl-next {
        right: 0px !important;
        top: 157px;
    }

    .owl-prev {
        left: 0px !important;
        top: 157px;
    }
}

.owl-carousel-tags {
   

    p.tag__tags-chip__tag-text-bold {
        // overflow: hidden;
        // white-space: nowrap;
        // text-overflow: ellipsis;
    }
}


// Tags Chip

.tags-chip-number {
    background-color: #E3E3E3;
    padding: 3px 10px;
    margin: 0 10px 10px 0;
    border-radius: 10px;

    &__tag-text-bold {
        margin-bottom: 0;
        font-size: 13px;
        line-height: 22px;
        color: #000000;
        font-family: @font--poppins-600;
    }
}

.tags-chip-number {
    &:has(&__tag-text-bold) {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        min-height: 40px;
    }
}

.schoolName {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.owlTitle {
    .profile-heading {
        width: 7rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: @font--poppins-600;
    }
}

// .tag__tags-chip__tag-text-bold {
//     line-height: 30px !important;
// }