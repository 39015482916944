@import "../../../assets/css/variables.less";

.mywork_Page {
    &_Top {
        &_Desktop {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &_Title {
                p {
                    font-size: 20px;
                    font-family: @font--poppins-500;
                    margin-bottom: 0px;
                }
            }

            &_Btncnt {
                &_PlanBtn {
                    background-color: @backcolor--primary;
                    font-family: @font--poppins-regular;
                    border-radius: 10px;
                }
            }
        }

        &_Mobile {
            display: none;
        }
        @media screen and (max-width: 768px) {
            &_Desktop {
                display: block;
                justify-content: center;
            }
    
            &_Mobile {
                display: flex;
                justify-content: center;
                &__Section {
                    display: flex;
                    justify-content: center;
                    height: 50px;
                    position: fixed;
                    top: 0;
                    width: 100%;
                    z-index: 9;
                    align-items: center;
                    border-bottom: 1px solid #e3e3e3;
                    background-color: @backcolor--white;
    
                    &_Title {
                        p {
                            font-size: 18px;
                            font-family: @font--poppins-500;
                            margin-bottom: 0px;
                        }
                    }
                }
    
                &_Btncnt {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 60px;
                    width: 100%;
                    margin-right: 10px;
    
                    &_PlanBtn {
                        background-color: @backcolor--primary;
                        font-family: @font--poppins-regular;
                        border-radius: 10px;
                        margin-top: 20px;
                    }
                }
            }
        }
    }
    &_Mid{
        margin-top: 20px;
        &_Mobile{
            display: none;
        }
        &_Desktop{
            display: flex;
            &_TabCnt{
                // background-color: #FFFFFF;
                // border: 1px solid #e3e3e3;
                border-radius: 10px;
                width: 100%;
                padding-left: 20px;
                padding-right: 20px;
                .Tab {
                    padding: 0px;
                    padding-left: 30px;
                    padding-right: 30px;
                    height: 0px;
                    min-height: 49px;
                    font-family: @font--poppins-regular;
                    font-size: 18px;
                    text-transform: none;
        
                    &:hover {
                        color: @backcolor--primary;
                    }
                }
            }
        }
        @media screen and (max-width: 900px) {
            &_Desktop {
                display: none;
            }
    
            &_Mobile {
                display: flex;
                margin-top: 2rem !important;
            }

        }
    }
    @media screen and (max-width: 768px) {
        &_Mid{
            margin-top: 20px;
            padding: 0px 10px;
        }
        .mywork_Page_Mid_Mobile {
            display: flex;
            margin-top: 8rem !important;
        }
    }
}
.PlanSaveBtn{
    background-color: @backcolor--primary;
    font-family: @font--poppins-regular;
    font-size: 20px;
    border-radius: 10px;
    width: 150px;
    height: 50px;
}

.planviewcnttopsec {
    padding: 15px;
    .plancnttitle {
        p {
            font-size: 14px;
            font-family: @font--poppins-300;
        }
    }

    .planMaintitle {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        p {
            font-size: 24px;
            font-family: @font--poppins-500;
            margin-left: 20px;
        }
    }

    &__emptyState{
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 70vh;

        &__content{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            width: 90%;
            margin-inline: auto;
            min-height: 240px;
            border-radius: 13px;

            &__empty-text{
                font-family: @font--poppins-500;
                font-size: 16px;
                color: #919293;
            }
            &__empty-cta{
                font-family: @font--poppins-500;
                font-size: 18px;
                color: #1C84EE;
                text-align: center;
                cursor: pointer;
            }
        }
    }

}

.semesterCourseSec{
    margin-bottom: 20px;
    &__topSec{
        display: flex;
        gap: 10px;
        margin-bottom: 20px;

        &__title{
            margin-bottom: 0;
            font-family: @font--poppins-500;
            font-size: 18px;
        }
        &__primaryBtn{
            display: flex;
            justify-content: center;
            align-items: center;
            user-select: none;
            cursor: pointer;
            padding-inline: 3px;
            background: @btn--primary;
            border-radius: 5px;
            color: @btn--white;
            line-height: 36px;
            text-align: center;
            border: 2px solid @btn--primary;
            font-family: @font--poppins-regular;

            &__icon{
                width: 18px !important;
                height: 18px !important;
            }
        }
    }
    &__content{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 20px;
        max-height: 600px;
        overflow-y: scroll;
    }
    &__noDataSec{
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 250px;

        &__noDataCard{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            border: 1px solid #d6d5d5;
            border-radius: 15px;
            cursor: pointer;
            margin: 10px;
            padding: 20px;
            width: 380px;
            min-height: 100px;

            p{
                font-size: 16px;
                margin-bottom: 0;
                font-family: @font--poppins-500;
            }
        }
    }
}