@import "../../../assets/css/variables.less";


.modal-body {
    &__viewProfile-sec {
        padding: 20px 140px;
    }

    .advisorProfileCard {
        padding: 30px 25px;
        border: 2px solid #E3E3E3;
        border-radius: 15px;

        &__container {
            display: flex;
            justify-content: space-between;
        }

        &__info-sec {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            // &__sub-img {
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            //     width: 100px !important;
            //     height: 120px !important;
            // }

            &__profileCont {
                margin-left: 30px;

                .profileName {
                    font-family: @font--poppins-600;
                    font-size: 32px;
                    margin-bottom: 10px;
                }

                &__about-sec {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    span {
                        display: flex;
                        align-items: center;
                        gap: 20px;

                        .label {
                            font-size: 14px;
                            font-family: @font--poppins-500;
                            margin: 0;
                            color: #515050;
                        }
                    }
                }
            }
        }

        &__connect-btn {
            &__primary-btn {
                width: 160px;
                height: 40px;
                background-color: #1C84EE;
                color: #fff;
                border-radius: 10px;
            }
        }
    }

    .advisorCoursesCard,
    .advisorPackageCard {
        &__heading {
            font-family: @font--poppins-500;
            font-size: 22px;
            margin-bottom: 10px;
            color: #1B1C1E;
        }

        &__courseCards-sec {
            position: relative;

            .owl-carousel {
                .owl-stage-outer {
                    .owl-stage {
                        display: flex;

                        .owl-item {
                            margin-inline: auto;

                            .owl__item {
                                height: 100%;
                            }

                            .advProfile--CourseSec {
                                &__CourseSec {
                                    &__courseCard {
                                        border: 2px solid #E3E3E3;
                                        margin: 0;
                                        height: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .owl-prev,
            .owl-next {
                position: absolute;
            }

            .owl-prev {
                left: -35px;
                top: 60px;
            }

            .owl-nav {
                .owl-next {
                    right: -35px !important;
                    top: 60px;
                }
            }

            .owl-theme .owl-nav [class*='owl-'] {
                background: transparent !important;
                color: black !important;
                font-size: 40px;

            }
        }

        &__packCard-sec {
            position: relative;

            .owl-carousel {
                .owl-stage-outer {
                    .owl-stage {
                        display: flex;

                        .owl-item {
                            margin-inline: auto;
                        }
                    }
                }
            }

            .owl-prev,
            .owl-next {
                position: absolute;
            }

            .owl-prev {
                left: -35px;
                top: 160px;
            }

            .owl-nav {
                .owl-next {
                    right: -35px !important;
                    top: 160px;
                }
            }

            .owl-theme .owl-nav [class*='owl-'] {
                background: transparent !important;
                color: black !important;
                font-size: 40px;

            }
        }
    }

}

.descriptionDetails {
    color: #515050;
    font-family: @font--poppins-300;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    line-height: 35px;
}

@media only screen and (max-width: 1060px) {
    .modal-body {
        .advisorProfileCard {
            &__container {
                justify-content: space-around;
            }

            &__info-sec {
                justify-content: space-around;
            }

            &__connect-btn {
                margin-top: 20px;
                margin-inline: auto;
            }
        }
    }
}

@media only screen and (max-width: 520px) {
    .modal-body {
        .modal-body {
            padding: 20px 10px !important;

            .advisorProfileCard {
                padding: 30px 15px;

                &__info-sec {
                    &__sub-img {
                        margin-bottom: 20px;
                    }

                    &__profileCont {
                        margin: 0 0 20px 0;
                    }
                }
            }

            .advisorCoursesCard {
                &__courseCards-sec {
                    margin: 0 30px;

                    .owl-carousel {

                        .owl-prev,
                        .owl-next {
                            position: absolute;
                        }

                        .owl-nav {
                            .owl-prev {
                                left: -45px !important;
                                top: 60px;
                            }
                        }

                        .owl-nav {
                            .owl-next {
                                right: -35px !important;
                                top: 60px;
                            }
                        }

                        .owl-theme .owl-nav [class*='owl-'] {
                            background: transparent !important;
                            color: black !important;
                            font-size: 40px;

                        }
                    }
                }
            }

            .advisorPackageCard {
                &__packCard-sec {
                    margin: 0 30px;

                    .owl-carousel {
                        .owl-nav {
                            .owl-prev {
                                left: -45px !important;
                                top: 160px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 400px) {
    .goalsModal {
        .modal-body {
            .advisorCoursesCard {
                &__courseCards-sec {
                    margin: 0;

                    .owl-carousel {

                        .owl-prev,
                        .owl-next {
                            position: unset;
                        }
                    }
                }
            }

            .advisorPackageCard {
                &__packCard-sec {
                    margin: 0;

                    .owl-carousel {

                        .owl-prev,
                        .owl-next {
                            position: unset;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1060px) {
    .modal-body {
        .advisorProfileCard {
            &__connect-btn {
                margin-top: 20px;
            }
        }
    }
}