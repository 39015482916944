@import "../../../../assets/css/variables.less";

// My Profile Add Course Sec 
.advProfile--CourseSec {
    padding: 25px 30px;
    border: 1px solid #cdcdcd;
    border-radius: 10px;
    background-color: #fff;
    width: 100%;

    &__topSec {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &__formTitle {
            margin-bottom: 0;
            font-size: 18px;
            letter-spacing: 0.15px;
            font-family: 'poppins-500', sans-serif;
            line-height: 24px;
            color: #000;
        }

        &__profilesavebtn, 
            &__profilesavebtn:focus{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 40px;
                user-select: none;
                cursor: pointer;
                background: @btn--primary;
                border-radius: 10px;
                color: @btn--white;
                width: 140px;
                line-height: 36px;
                margin: 0 8px 0 auto;
                text-align: center;
                border: 2px solid @btn--primary;
        }
        &__profilesavebtn:hover {
            cursor: pointer;
            color: @color--black;
            background: @btn--white;
            border: 1.5px solid @btn--primary;
           
        }
    }
    &__CourseSec {
        margin-top: 40px;

        &__courseCard {
            width: 260px;
            border: 1px solid #e6e6e6;
            margin: 0 25px 30px 0;
            border-radius: 10px;
            padding: 15px 5px;
            &:hover {
                transition: all 0.2s ease-out;
                box-shadow: 0px 4px 8px rgba(92, 91, 91, 0.1);
                top: -4px;
                border: 1px solid #cccccc;
                background-color: white;
            }
            .toplayer {
                display: flex;
                margin-bottom: 5px;

                .coursetitlecnt {
                    padding-left: 10px;
                    padding-top: 10px;
                    width: 220px;
                    text-overflow: ellipsis;
    
                    .coursetitle {
                        text-overflow: ellipsis;
                        font-size: 18px;
                        font-family: @font--poppins-500;
                        overflow: hidden;
                        -webkit-box-orient: vertical;
                        width: 190px;
                        height: 30px;
                        margin: 0;
                        -webkit-line-clamp: 1;
                        // word-break: break-word;
                        display: -webkit-box;
                    }
                }
            }
            .cardgrid__dot {
                .btn-primary {
                    background-color: #ffffff00 !important;
                    border-color: #ffffff00 !important;
                }
                .btn-primary:hover {
                    background-color: #ffffff00 !important;
                    border-color: #ffffff00 !important;
                }
                .primary.dropdown-toggle {
                    background-color: #dfdfdf00 !important;
                    border-color: #dfdfdf00 !important;
                }
            }
            .dropdown-item:hover {
                background-color: #dfdfdf;
            }
            .dropdown-item {
                color: @color--primary !important;
                font-size: 12px;
                font-family: @font--poppins-regular;
                padding-top: 4px !important;
            }
            .dropdown-menu {
                border: 0.5px solid #dfdfdf;
                border-radius: 8px;
            }
            
            .cardmid {
                padding: 5px;

                .coursetitlecnt {
                    margin-bottom: 20px;

                    .coursetitle {
                        font-size: 18px;
                        font-family: @font--poppins-500;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        min-height: 60px;
                    }
                }

                .coursesubtitlecnt {
                    margin-bottom: 5px;

                    .label {
                        min-width: 75px;

                        .coursesubtitleLabel {
                            font-size: 15px;
                            font-family: @font--poppins-600;
                            color: @color--black;
                        }
                    }
                    .value {
                        .coursesubtitle {
                            font-size: 14px;
                            font-family: @font--poppins-600;
                            color: @color--secondary;
                            white-space: normal;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            word-break: break-word;
                            -webkit-box-orient: vertical;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                        }
                    }
                }
                .coursedescriptioncnt {
                    margin-bottom: 5px;

                    .coursedesc {
                        font-size: 14px;
                        font-family: @font--poppins-500;
                        color: @color--secondary;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        word-break: break-word;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 428px) {
    .advProfile--CourseSec, .advProfile--ActivitySec {
        &__topSec{
            flex-wrap: wrap;
            gap: 20px;

            &__profilesavebtn{
                margin-left: auto;
            }
        }
    }
}
@media only screen and (max-width: 320px) {
    .advProfile--CourseSec {
        padding: 25px 15px;

        &__CourseSec {
            &__courseCard {
                width: 225px;
                margin: 0 0 20px 0;

                .toplayer {
                    .coursetitlecnt {
                        width: 175px;
                        padding-left: 5px;
                    }
                }
            }
        }
    }
}

//// My Profile Add Activity Sec 
.advProfile--ActivitySec {
    padding: 25px 30px;
    border: 1px solid #cdcdcd;
    border-radius: 10px;
    background-color: @backcolor--white;
    width: 100%;

    &__topSec {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        &__formTitle {
            margin-bottom: 0;
            font-size: 18px;
            letter-spacing: 0.15px;
            font-family: 'poppins-500', sans-serif;
            line-height: 24px;
            color: @color--primary;
        }
        &__profilesavebtn, 
            &__profilesavebtn:focus{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 40px;
                user-select: none;
                cursor: pointer;
                background: @btn--primary;
                border-radius: 10px;
                color: @btn--white;
                width: 140px;
                line-height: 36px;
                margin: 0 8px 0 auto;
                text-align: center;
                border: 2px solid @btn--primary;
        }
        &__profilesavebtn:hover {
            cursor: pointer;
            color: @color--black;
            background: @btn--white;
            border: 1.5px solid @btn--primary;
           
        }
    }

    &__ActivitySec {
        margin-top: 40px;

        &__activityCard {
            width: 260px;
            border: 1px solid #e6e6e6;
            margin: 0 25px 30px 0;
            border-radius: 10px;
            padding: 15px 5px;
            &:hover {
                transition: all 0.2s ease-out;
                box-shadow: 0px 4px 8px rgba(92, 91, 91, 0.1);
                top: -4px;
                border: 1px solid #cccccc;
                background-color: white;
            }
            .toplayer {
                display: flex;
                margin-bottom: 5px;

                .coursetitlecnt {
                    padding-left: 5px;
                    padding-top: 10px;
                    width: 220px;
                    text-overflow: ellipsis;
    
                    .coursetitle {
                        text-overflow: ellipsis;
                        font-size: 18px;
                        font-family: @font--poppins-500;
                        overflow: hidden;
                        -webkit-box-orient: vertical;
                        width: 190px;
                        height: 30px;
                        margin: 0;
                        -webkit-line-clamp: 1;
                        // word-break: break-word;
                        display: -webkit-box;
                    }
                }
            }
            .cardgrid__dot {
                .btn-primary {
                    background-color: #ffffff00 !important;
                    border-color: #ffffff00 !important;
                }
                .btn-primary:hover {
                    background-color: #ffffff00 !important;
                    border-color: #ffffff00 !important;
                }
                .primary.dropdown-toggle {
                    background-color: #dfdfdf00 !important;
                    border-color: #dfdfdf00 !important;
                }
                .btn-primary.dropdown-toggle:focus {
                    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0) !important;
                }
            }
            .dropdown-item:hover {
                background-color: #dfdfdf;
            }
            .dropdown-item {
                color: @color--primary !important;
                font-size: 12px;
                font-family: @font--poppins-regular;
                padding-top: 4px !important;
            }
            .dropdown-menu {
                border: 0.5px solid #dfdfdf;
                border-radius: 8px;
            }
            
            .cardmid {
                padding: 5px;

                .coursetitlecnt {
                    margin-bottom: 20px;

                    .coursetitle {
                        font-size: 18px;
                        font-family: @font--poppins-500;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        min-height: 60px;
                    }
                }

                .coursesubtitlecnt {
                    margin-bottom: 5px;

                    .label {
                        min-width: 75px;

                        .coursesubtitleLabel {
                            font-size: 15px;
                            font-family: @font--poppins-600;
                            color: @color--black;
                        }
                    }
                    .value {
                        .coursesubtitle {
                            font-size: 14px;
                            font-family: @font--poppins-600;
                            color: @color--secondary;
                            white-space: normal;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            word-break: break-word;
                            -webkit-box-orient: vertical;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                        }
                    }
                }
                .coursedescriptioncnt {
                    margin-bottom: 5px;

                    .coursedesc {
                        font-size: 14px;
                        font-family: @font--poppins-500;
                        color: @color--secondary;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        word-break: break-word;
                    }
                }
            }
        }

        &__noActivityBox{
            max-width: 240px;
            min-width: 340px;
            border: 1px solid #E4E4E4;
            margin-inline: auto;
            padding: 25px 35px;
            border-radius: 15px;
        
            &__addcoursecardscnt {
                .title {
                    font-family: @font--poppins-500;
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 24px;
                }
    
                .descp {
                    font-family: @font--poppins-regular;
                    font-size: 13px;
                    font-weight: 400;
                    color: #495057;
                }
            }
        }
    }
}

@media only screen and (max-width: 320px) {
    .advProfile--ActivitySec {
        padding: 25px 15px;

        &__ActivitySec {
            &__activityCard {
                width: 225px;

                .toplayer {
                    .coursetitlecnt {
                        width: 175px;
                        padding-left: 5px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 425px) {
    .advProfile--ActivitySec {
        &__ActivitySec {
            &__noActivityBox{
                min-width: 220px;
            }
        }
    }
}
