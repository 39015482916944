@import "../../../../assets/css/variables.less";

.phase_page{
    &_Top {
        &_Desktop {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &_Title {
                p {
                    font-size: 20px;
                    font-family: @font--poppins-500;
                    margin-bottom: 0px;
                }
            }

            &_Btncnt {
                &_PlanBtn {
                    background-color: @backcolor--primary;
                    font-family: @font--poppins-regular;
                    border-radius: 10px;
                }
            }
        }

        &_Mobile {
            display: none;
        }
        @media screen and (max-width: 768px) {
            &_Desktop {
                display: none;
            }
    
            &_Mobile {
                display: flex;
    
                &__Section {
                    display: flex;
                    justify-content: center;
                    height: 50px;
                    position: fixed;
                    top: 0;
                    width: 100%;
                    z-index: 9;
                    align-items: center;
                    border-bottom: 1px solid #e3e3e3;
                    background-color: @backcolor--white;
    
                    &_Title {
                        p {
                            font-size: 18px;
                            font-family: @font--poppins-500;
                            margin-bottom: 0px;
                        }
                    }
                }
    
                &_Btncnt {
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    margin-top: 60px;
                    width: 100%;
                    margin-left: 15px;
                    p{
                        font-size: 18px;
                        font-family: @font--poppins-500;
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
    &_Mid{
        margin-top: 20px;
        &_Mobile{
            display: none;
        }
        &_Desktop{
            display: flex;
            &_TabCnt{
                border-radius: 10px;
                width: 100%;
                .Tab {
                    padding: 0px;
                    padding-left: 0px;
                    padding-right: 15px;
                    height: 0px;
                    min-height: 49px;
                    font-family: @font--poppins-regular;
                    font-size: 18px;
                    text-transform: none;
        
                    &:hover {
                        color: @backcolor--primary;
                    }
                }
            }
        }
        @media screen and (max-width: 768px) {
            &_Desktop {
                display: none;
            }
    
            &_Mobile {
                display: flex;
            }
        }
    }
    &_Btm{
        &_section{
            &_course_cnt{
                display: flex;
                flex-wrap: wrap;
            }
        }
    }
    @media screen and (max-width: 768px) {
        &_Mid{
            margin-top: 20px;
            padding: 0px 10px;
        }
    }
}

.myWork-courseCard,
.myWork-courseCard-unApproved,
.myWork-courseCard-suggested {
    background-color: #fff;
    min-height: 270px;
    width: 290px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    &__topSec{
        padding: 7px 8px;
        background-color: #1C84EE;
        border-top-right-radius: 9px;
        border-top-left-radius: 9px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        &__titleSec{
            display: flex;
            align-items: center;
            gap: 10px;
            color: #fff;
            &__iconLeft{
                width: 16px !important;
                height: 18px !important;
            }
            &__iconRight{
                font-size: 15px !important;
                margin-bottom: 10px;
                cursor: pointer !important;
            }
            &__title{
                margin: 0;
                color: #fff;
                font-size: 14px;
                max-width: 170px;
                font-family: @font--poppins-500;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                cursor: default;
            }
        }
        &__menuSec{
            position: absolute;
            right: 10px;
        }
    }
    &__statusSec{
        display: flex;
        justify-content: flex-end;
        margin-top: 5px;
        margin-right: 10px;
        &__status{
            border: 1.5px solid #29a016;
            padding: .1em .8em;
            border-radius: 40px;
            background-color: #E5FFE1;

            &__successText{
                margin: 0;
                font-size: 12px;
                font-family: @font--poppins-regular;
                color: #29a016;
            }
        }
    }
    &__midSec{
        padding: 15px 10px 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        
        &__content{
            display: flex;
            gap: 10px;
            &__label{
                margin: 0;
                min-width: 100px;
                color: #1B1C1E;
                font-size: 14px;
                font-family: @font--poppins-500;
            }
            &__value{
                margin: 0;
                font-size: 14px;
                font-family: @font--poppins-regular;
                color: #6C6C6C;
                white-space: normal;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                cursor: pointer;
            }
        }
        &__value{
            margin: 0;
            font-size: 14px;
            font-family: @font--poppins-regular;
            color: #6C6C6C;
            white-space: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            cursor: pointer;
        }
    }
    &__suggestionSec{
        margin-bottom: 10px;

        &__dangerText, &__successText{
            font-family: @font--poppins-regular;
            font-size: 13px;
            text-align: center;
            margin: 0;
            line-height: 24px;
            text-wrap: balance;
        }
        &__dangerText{
            color: #E64A19;
        }
        &__successText{
            color: #29a016;
        }
        &__chatIcon{
            width: 12px !important;
            height: 12px !important;
            color: #515050 !important;
            border-radius: 50%;
            margin-left: 5px;
            margin-bottom: 5px;
            cursor: pointer;
        }
    }
    &__ctaSec{
        margin-top: auto;
        margin-bottom: 15px;

        &__primaryBtnSec{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &__outline-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;
                margin-top: auto;
                color: #1C84EE;
                border: 1px solid #1C84EE;
                width: 70%;
                height: 35px;
                border-radius: 10px;
                font-family: @font--poppins-500;
                padding-inline: 1em;
                cursor: pointer;
                margin-bottom: 1rem;
            }
            &__helperText{
                font-size: 10px;
                margin-bottom: 5px;
                max-width: 180px;
                font-family: @font--poppins-500;
                color: #6C6C6C;
                white-space: normal;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                cursor: pointer;
            }
            &__primaryBtn{
                display: flex;
                justify-content: center;
                align-items: center;
                padding-inline: 3em;
                background-color: #1C84EE;
                color: #fff;
                border: none;
                height: 35px;
                border-radius: 7px;
                &:hover{
                    cursor: pointer;
                }
            }
        }
        &__ManageAdvisorBtnSec{
            display: flex;
            justify-content: space-evenly;
            gap: 10px;
            &__outlineBtn{
                display: flex;
                justify-content: center;
                align-items: center;
                padding-inline: 1em;
                background-color: #fff;
                color: #1C84EE;
                border: 2px solid #1C84EE;
                height: 35px;
                border-radius: 7px;
                &:hover{
                    cursor: pointer;
                }
            }
        }
        &__ManageStudentBtnSec{
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-top: 10px;
            p{
                font-size: 16px;
                margin-bottom: 0px;
                color: #1C84EE;
                font-family: @font--poppins-regular;
                cursor: pointer;
            }
        }
    }
}