@import "../../../../../assets/css/variables.less";

.exploreAdvisor {
    .advisorEyebrow {
        font-size: 13px;
        text-align: center;
        color: rgba(0, 0, 0, 0.7);
        max-width: 320px;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // display: -webkit-box;
        // -webkit-line-clamp: 2;
        // -webkit-box-orient: vertical;
    }

    .advisorCard {
        max-width: 320px;
        height: 430px;
        margin: 0 20px 20px 0;
        padding: 20px 15px;
        border: 1px solid #ECECEC;
        background-color: #fff;
        border-radius: 15px;
        position: relative;
        .profile-img{
            width: 60px !important;
            height: 60px !important;
            border-radius: 6px !important;
            object-fit: cover !important;
        }
        .netWork-card__button-sec__primary-btn {
            position: absolute;
            bottom: 20px;
            left: 50%;
            width: 90%;
            transform: translateX(-50%);
        }

        .profile-heading {
            font-family: @font--poppins-500;

        }

        .advisor-country {
            color: #1B1C1E;
            font-family: @font--poppins-500;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 17px;
        }

        .pill-status {
            border-radius: 100px;
            background: #1C84EE;
            display: flex;
            width: fit-content;
            height: 17px;
            padding: 7px 7px;
            align-items: center;
            flex-shrink: 0;
            color: #fff;
            font-size: 10px;
            font-family: @font--poppins-500;
        }

        .advisor-subText {
            color: #1B1C1E;
            font-family: @font--poppins-regular;
            font-size: 14px;
            font-style: normal;
            font-weight: 400 !important;
        }

        .advisor-card-content {
            border-radius: 10px;
            background: #F4F5F8;
            padding: 10px;
        }

    }
    @media screen and (max-width: 320px) {
        .advisorCard {
            .imagesection{
                width: 50px;
                height: 50px !important;
            }
            .profile-heading{
                width: 110px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: wrap;
            }
        }
    }
}