@import "../../../assets/css/variables.less";

.scheduledSessions{
    font-family: @font--poppins-500 ;
    .userInfo{
        display: flex;
        flex-direction: column;
        border-left: "3px solid #dadada";
        padding-left: 10;
        gap: 10;
    }
    h6{
        font-weight: 500 !important;
        
    }
}