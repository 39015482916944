@import "common.less";
ul li{
    list-style: none;
  }
  .calendar {
    bottom: 0;
    position: fixed;
    margin: 1em;
    right: 0;
    z-index: 998;
    display: none;
  }
  
  .fabcalendar {
    display: block;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    text-align: center;
    color: #f0f0f0;
    margin: 25px auto 0;
    box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28);
    cursor: pointer;
    -webkit-transition: all .1s ease-out;
    transition: all .1s ease-out;
    position: relative;
    z-index: 998;
    overflow: hidden;
    background: @backcolor--primary;
    bottom: 60px;
    span {
        display: block;
        line-height: 48px;
        &.chat-icons{
            display: none;
            &.show{
                display: block;
            }
        }
    }
  }
  
  .fabcalendar > i {
    font-size: 2em;
    line-height: 55px;
    -webkit-transition: all .2s ease-out;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }
  
  .fabcalendar:not(:last-child) {
    width: 0;
    height: 0;
    margin: 20px auto 0;
    opacity: 0;
    visibility: hidden;
    line-height: 40px;
  }
  
  .fabcalendar:not(:last-child) > i {
    font-size: 1.4em;
    line-height: 40px;
  }
  
  .fabcalendar:not(:last-child).is-visible {
    width: 40px;
    height: 40px;
    margin: 15px auto 10;
    opacity: 1;
    visibility: visible;
  }
  
  .fabcalendar:nth-last-child(1) {
    -webkit-transition-delay: 25ms;
    transition-delay: 25ms;
  }
  
  .fabcalendar:not(:last-child):nth-last-child(2) {
    -webkit-transition-delay: 20ms;
    transition-delay: 20ms;
  }
  
  .fabcalendar:not(:last-child):nth-last-child(3) {
    -webkit-transition-delay: 40ms;
    transition-delay: 40ms;
  }
  
  .fabcalendar:not(:last-child):nth-last-child(4) {
    -webkit-transition-delay: 60ms;
    transition-delay: 60ms;
  }
  
  .fabcalendar:not(:last-child):nth-last-child(5) {
    -webkit-transition-delay: 80ms;
    transition-delay: 80ms;
  }
  
  .fabcalendar:last-child:active,
  .fabcalendar:last-child:focus,
  .fabcalendar:last-child:hover {
    box-shadow: 0 0 6px rgba(0, 0, 0, .16), 0 6px 12px rgba(0, 0, 0, .32);
  }
  


  @media screen and (max-width: 1000px) {
    .calendar {
      display: block;
    }
  }