@import "../../../../../assets/css/variables.less";

.rating-sec{
    
    &__progressBar{
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        &__label{
            font-family: @font--poppins-regular;
            font-size: 18px;
            margin: 0;
            min-width: 4em;
            text-align: center;
            color: #3C3C3C;
        }
        &__percentage {
            color: #3C3C3C;
            font-family: @font--poppins-500;
            font-size: 15px; /* Adjust the font size as needed */
            padding-left: 10px;
        }
        &__linearRating{
            width: 100%;
            background-color: #EBEFF2 !important;
            border-radius: 20px;
            height: 6px !important;
            max-width: 320px;
            margin-left: 20px;

            span{
                background-color: #E64A19 !important;
                border-radius: 20px;
            }
        }
    }
}