@import "variables.less";
@import "fonts.less";

:root {
  --fc-small-font-size: .85em;
  --fc-page-bg-color: rgb(235, 223, 223);
  --fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
  --fc-neutral-text-color: #a5a4a4;
  --fc-border-color: none;

  --fc-button-text-color: rgb(59, 56, 56);
  --fc-button-bg-color: none;
  --fc-button-border-color: none;
  --fc-button-hover-bg-color: none;

  --fc-button-hover-border-color: none;
  --fc-button-active-bg-color: none;
  --fc-button-active-border-color: none;
  --fc-button-active-border: none;

  --fc-event-bg-color: none;
  --fc-event-border-color: none;
  --fc-event-text-color: #7367F0;
  --fc-event-selected-overlay-color: none;

  --fc-more-link-bg-color: #d0d0d0;
  --fc-more-link-text-color: inherit;

  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;

  --fc-non-business-color: rgba(220, 0, 0, 0.3);
  --fc-bg-event-color: rgb(143, 223, 130);
  --fc-bg-event-opacity: 0.2;
  --fc-highlight-color: none;
  --fc-today-bg-color: none;
  ;

  --fc-now-indicator-color: red;
}

.schedule {
  background: #fff;
  position: relative;
  width: 100%;
  padding: 20px;
  border: 1px solid #CDCDCD;
  border-radius: 10px;
  .fc .fc-popover{
    z-index: 99 !important;
  }
  // events css
  .monthview-event-class {
    .eventTimeGridview {
      display: none;
    }
  }

  td.fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink.day-slot-class {
    height: 110px;
  }

  //week and day view event
  .week-view-event-css,
  .day-view-event-css {
    min-height: 100%;
    max-height: max-content;

    .eventMonthview {
      display: none;
    }

    .eventTimeGridview {
      padding: 2px 3px;
      display: flex;
      gap: 5px;
    }

    img {
      width: 35px;
      height: 35px;
      border-radius: 32px;
    }

    .eventName {
      font-weight: 600;
      font-family: @font--poppins-regular;
      font-size: 12px;
      line-height: 26px;
      color: rgba(27, 28, 30, 1);
    }

    .eventTitle {
      font-family: @font--poppins-500;
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 3px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
    }

    .eventTime {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 3px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
    }

    .eventGoal {
      max-width: 145px;
      background-color: rgba(223, 223, 223, 1);
      font-family: @font--poppins-500;
      font-size: 10px;
      text-align: center;
      line-height: 22px;
      border-radius: 4px;
      margin-bottom: 15px;
      color: rgba(27, 28, 30, 1);
    }
  }

  // for day view
  .day-view-event-css {
    background-color: rgba(240, 240, 240, 1);
    border: 0.5px solid rgba(213, 213, 213, 1);

    .eventTimeGridview {
      padding: 15px 20px;
      align-items: center;
    }
  }

  //time grid for week and day
  .fc .fc-timegrid-col-events {
    width: 100%;
  }

  .fc-direction-ltr .fc-timegrid-col-events {
    margin: 0px;
  }

  //for week view
  .week-view-event-css {
    background-color: white;
  }

  .week-view-event-css:hover {
    box-shadow: 0px 10px 40px 0px rgba(27, 28, 30, 0.1);
  }



  .fc-timegrid.fc-timeGridWeek-view.fc-view {
    col {
      width: 120px !important; //week view time column 
    }

    td.fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink.time-hours {
      height: 110px; //height of the cell in weekview
    }

    //weekview header day and date
    .weekview-day-header {
      a.fc-col-header-cell-cushion {
        color: rgba(149, 150, 153, 1);
        font-size: 20px;
        height: 90px;
        text-align: center;
        text-indent: 0px;
        line-height: 32px;

        .week-event-Day {
          font-size: 15px;
        }

        .week-event-Date {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .fc-scrollgrid-sync-inner {
        display: flex;
        justify-content: center;
      }
    }

    th.fc-col-header-cell.fc-day.fc-day-today.weekview-day-header {
      .week-event-Date {
        background-color: rgba(28, 132, 238, 1);
        color: rgba(255, 255, 255, 1);
        padding: 6px 12px;
        text-indent: 0px;
        display: flex;
        justify-content: center;
        border-radius: 10px;
        width: 45px;
        height: 45px;
      }
    }
  }

  th.fc-col-header-cell.fc-day.dayview-header {
    display: none; //to hide the header in the dayview
  }

  // left side time column in dayview
  .fc-direction-ltr .fc-timegrid-slot-label-frame {
    text-align: left;
    font-family: @font--poppins-500;
    color: rgba(149, 150, 153, 1);
    font-size: 16px;
    line-height: 32px;
  }

  .fc .fc-timegrid-slot-label {
    vertical-align: initial;
  }

  .fc .fc-timegrid-axis-cushion,
  .fc .fc-timegrid-slot-label-cushion {
    padding: 10px 10px;

  }

  //for day view time column width
  col {
    width: 110px !important;
  }

  .fc-daygrid.fc-dayGridMonth-view.fc-view {
    .fc-day-today {
      background-color: #d6d4ef;
    }
  }

  //monthview each day header
  .daycell-head {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: baseline;
    position: relative;
  }

  button.daycell-event-add-btn {
    vertical-align: middle;
    border: 1px solid black;
    width: 14px;
    height: 14px;
    font-size: 14px;
    background-color: transparent;
    line-height: 0.1;
    display: none;
  }

  .p-day {
    padding: 0px 0px 2px 0px;
  }

  .p-month {
    padding: 2px 0px 0px 0px;
  }

  .fc-daygrid-day-frame:hover button.daycell-event-add-btn {
    display: inline-block;
  }

  .event-add-btn {
    display: none;
  }

  .fc-timegrid-slot-lane.dayview-slot-class:hover button.daycell-event-add-btn {
    display: inline-block;

  }

  .fc-timegrid-slot-lane.dayview-slot-class:hover {
    background-color: rgba(240, 240, 240, 1);
    border: 1px solid #D5D5D5;
  }

  .day-slot-add {
    padding: 20px;
  }

  tbody,
  td {
    border-width: 1px !important;
    border-color: rgb(233, 230, 230) !important;
    border-top-color: rgb(233, 230, 230) !important;
    border-bottom-color: rgb(233, 230, 230) !important;
    border-left-color: rgb(233, 230, 230) !important;
    border-right-color: rgb(233, 230, 230) !important;

  }

  tr {
    border-color: rgb(233, 230, 230) !important;
    border-width: 1px;
  }

  .fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid {
    border-width: 1px;
  }

  tr.fc-scrollgrid-section.fc-scrollgrid-section-header {
    border-width: 0px;
  }

  .fc-theme-standard td {
    border: 1px solid rgb(233, 230, 230);
  }

  th.fc-col-header-cell {
    height: 40px;
  }

  .fc .fc-daygrid-day-number {
    font-weight: 500;
    font-family: @font--poppins-500 ;
    color: rgba(27, 28, 30, 1);
    width: 100%;
    padding-block: 1px;
  }

  .fc-toolbar h2 {
    margin: 0;
    color: rgba(27, 28, 30, 1);
    font-weight: 500;
    font-size: 40px;
    font-family: @font--poppins-500 !important;
  }

  .fc-daygrid-dot-event {
    padding: 2px 0;
  }

  .event {
    width: 100%;
    border-radius: 3px;
    padding: 1px 0;
  }

  .event:hover {
    cursor: pointer;
  }

  .eventMonthview {
    display: flex;
    justify-content: center;
    align-items: baseline;
  }

  .fc-event-time {
    color: #fff;
    // text-transform: lowercase;
    font-size: 11px;
    text-align: left;
    margin-left: 0;
    left: 0;
    // min-width: 37px;
    font-weight: 400;
    text-align: left;
  }

  .fc-event-title {
    color: #fff;
    text-transform: none;
    overflow: hidden;
    font-weight: 500;
    font-family: @font--poppins-500;
    text-overflow: ellipsis;
    text-align: justify;
    width: 100%;
    line-height: 1.2;
    text-indent: 0px;
    text-align: left;
    font-weight: 400;
    font-size: 11px;
  }

  //schedule title css
  .fc .fc-button-group {
    gap: 4px;
    color: grey;
    border: none;
  }

  .fc .fc-button-primary {
    border: none;
  }

  .fc .fc-toolbar {
    justify-content: flex-start;
    align-items: center;
    height: 50px;
  }

  .fc-title::before {
    font-family: "Font Awesome 5 Free";
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    padding: 0 8px;
    text-decoration: inherit;
    content: "\f017";
  }

  .fc-icon-calendar:before {
    content: url(../images/icons/small-schedule-icon.png);
    font-size: 9px;
  }

  .fc-toolbar-chunk {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  .fc-toolbar-chunk:nth-child(3) {
    justify-content: flex-end;
  }

  .fc-toolbar-chunk:nth-child(1) {
    width: 220px;
    .fc-toolbar-title{
      font-size: 24px;
    }
  }

  .fc-daygrid-day-frame.fc-scrollgrid-sync-inner:hover {
    background-color: #edecf6;

  }

  .fc-daygrid-dot-event:hover,
  .fc-daygrid-dot-event.fc-event-mirror {
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .fc-theme-standard .fc-scrollgrid {

    border-top: 0px solid #696666 !important;
    border-bottom: 0px solid #696666 !important;
    border-left: 0px solid #696666 !important;
    border-right: 0px solid #696666 !important;

  }

  .fc .fc-scrollgrid {
    border-collapse: collapse;
  }

  button.fc-prev-button.fc-button.fc-button-primary,
  button.fc-next-button.fc-button.fc-button-primary {
    padding: 0px;
  }

  .fc-col-header-cell {
    border-left: none !important;
    border-right: none !important;
  }



  .fc-icon-calendar {
    pointer-events: none !important;
  }



  .cal-para {
    font-weight: 400;
    font-size: small;
    text-align: left;
    color: rgba(27, 28, 30, 1);
    font-family: @font--poppins-regular;
    position: absolute;
    line-height: 24px;
    padding-left: 0px;
    top: 65px;
    left: 0px;
    max-width: 330px;
  }

  .fc .fc-col-header-cell-cushion {
    color: rgba(27, 28, 30, 1);
    font-size: 16px;
    font-weight: 500;
    font-family: @font--poppins-500;
  }

  .fc .fc-highlight {
    border: none;
  }


  .fc-daygrid-day-number {
    color: inherit;
  }

  .fc-button {
    box-shadow: none !important;
  }

  .calendar-box {
    align-items: center;
    position: relative;
    margin: auto;
  }

  .fc-scrollgrid-sync-inner {
    text-align: left;
    text-indent: 6px;
    text-transform: uppercase;
  }


  .fc-daygrid-day-top {
    flex-direction: row !important;
  }

  .top-cal {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    margin-left: 10px;
    p{
      margin-bottom: 0px;
      font-family: @font--poppins-regular;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 1;
    }
  }
}


// media-query for monthview
@media only screen and (max-width:1001px) {
  body {
    .calendar-outer {
      padding-right: 8px;
      margin: 20px 15px;
    }
  }
}

@media only screen and (min-width: 769px) {
  .schedule-mobile-top {
    display: none;
  }



  .schedule {
    .calendar-outer {
      margin: 1px;
    }
  }

}

@media only screen and (max-width: 768px) {
  .schedule {
    // padding-top: 60px;
    padding: 60px 3px 3px;
  }

  .onlySchedule {
    padding-top: 140px !important;
  }

}




@media only screen and (max-width: 325px) {
  .form-select-xs {
    width: 90%;
  }

  .date-time-xxsmall {
    width: 285px;
  }
  .calendar-outer {
    margin-top: 2rem !important;
  }

}

.schedule-head-box {
  position: absolute;
  height: 50px;
  left: 305px;
  right: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 90px;
}

.fc-toolbar-title {
  padding-top: 25px;
}

.small-cal {
  position: absolute;
  top: 70px;
  align-items: center;
  // display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 30px;
  .right {
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 768px) {
  body {
    .schedule {
      width: 100%;

      .fc-view-harness{
        margin-top: 3rem;
      }

      .schedule-head-box {
        display: none;
      }

      .fc-toolbar h2 {
        font-size: 30px;
      }

      th.fc-col-header-cell {
        height: 30px;
      }

      .cal-para {
        font-size: 12px;
        line-height: 19px;
      }

      .fc .fc-col-header-cell-cushion {
        font-size: 12px;
        display: flex;
      }

      .fc .fc-daygrid-day-number {
        font-size: 12px;
      }

      .fc-event-title {
        font-size: 7px;
        font-weight: 300;
      }

      .fc-event-time {
        font-size: 7px;
        font-weight: 400;

      }

      .fc-daygrid-day-events {
        height: 2.1rem;
      }


      .btn {
        line-height: 1.5;
      }

      .calendar-outer {
        padding-right: 0px;
        margin: 10px;
      }

      select#cal-month {
        width: 90px;
        padding: 1px;
      }
    }
  }
}

@media only screen and (max-width: 330px) {
  .schedule {
    .fc .fc-toolbar.fc-header-toolbar {
      margin-bottom: 130px;
    }

    .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
      height: 583px !important;
    }

    table.fc-scrollgrid-sync-table {
      height: 200px !important;
    }

    .small-cal {
      top: 56px;
      gap: 50px;
      left: 0px;

      .right {
        gap: 50px;
      }
    }

    .fc-daygrid-day-number {
      padding: 4px 4px 0;

      .daycell-head {
        div {
          font-size: 10px;
        }
      }
    }

    .eventMonthview {
      flex-direction: column;

      .fc-event-time {
        display: none;
      }
    }

    .fc-daygrid-day-events {
      height: 3rem;
    }

    .fc-event-title {
      text-indent: unset;
      font-size: 9px;
    }

    .fc-event-time {
      font-size: 9px;
      min-width: auto;
    }

    .cal-para {
      top: 110px;
      max-width: 100%;
      font-size: small;

    }
  }
}

@media only screen and (max-width: 534px)and (min-width:330px) {
  .schedule {
    .fc .fc-toolbar.fc-header-toolbar {
      margin-bottom: 115px;
    }

    .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
      height: 566px !important;
      margin-top: 40px;
    }

    table.fc-scrollgrid-sync-table {
      height: 200px !important;
    }

    .small-cal {
      top: 56px;
      gap: 50px;
      left: 0px;

      .right {
        gap: 50px;
      }
    }

    .eventMonthview {
      flex-direction: column;

      .fc-event-time {
        display: none;
      }
    }

    .fc-event-title {
      text-indent: unset;
      font-size: 9px;
    }

    .fc-event-time {
      font-size: 9px;
      min-width: auto;
    }

    .cal-para {
      top: 110px;
      max-width: 100%;
      font-size: small;
    }
  }
}



@media only screen and (max-width: 768px) and (min-width:534px) {
  body {
    .schedule {
      width: 100%;

      .calendar-outer {
        padding-top: 12px;
      }

      .fc-toolbar-chunk:nth-child(1) {
        width: 200px;
      }

      .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
        height: 608px !important;
        margin-top: 30px;
      }

      table.fc-scrollgrid-sync-table {
        height: 300px !important;
      }

      .fc-daygrid-day-events {
        height: 2.9rem;
        // margin-left: 3px;
      }

      .fc-event-title {
        font-size: 9px;
        text-indent: 0px;
        
      }

      .fc-event-time {
        min-width: 33px;
        font-size: 9px;
        margin-right: 6px !important;
        text-overflow: ellipsis;
        text-align: center;
        width: 100%;
        line-height: 1.2;
        text-indent: 0px;

      }

      .schedule-head-box {
        display: none;
      }

      .fc .fc-toolbar.fc-header-toolbar {
        margin-bottom: 0px;
      }

      .fc .fc-col-header-cell-cushion {
        font-size: 13px;
      }

      .fc .fc-daygrid-day-number {
        font-size: 13px;
      }

      .small-cal {
        gap: 30px;
        height: 50px;
        top: 0;
        right: 0;
        left: 340px;

        .right {
          gap: 20px;
        }
      }

      .cal-para {
        top: 65px;
        max-width: 360px;
        font-size: small;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  body {
    .schedule {
      .small-cal {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 912px)and (min-width:769px) {
  body {
    .schedule {
      width: 100%;

      .fc-toolbar h2 {
        font-size: 32px;
      }

      .fc-toolbar-chunk:nth-child(1) {
        width: 220px;
      }

      .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
        height: 666px !important;
      }

      table.fc-scrollgrid-sync-table {
        height: 350px !important;
      }

      .fc .fc-toolbar.fc-header-toolbar {
        margin-bottom: 80px;
      }

      th.fc-col-header-cell {
        height: 30px;
      }

      .fc-event-title {
        font-size: 9px;
        
      }

      .fc-event-time {
        min-width: 33px;
        font-size: 9px;
        margin-right: 10px !important;
        text-overflow: ellipsis;
        text-align: center;
        width: 100%;
        line-height: 1.2;
        text-indent: 0px;

      }

      .fc-daygrid-day-events {
        height: 3.4rem;
      }

      .fc .fc-col-header-cell-cushion {
        font-size: 13px;
      }

      .fc .fc-daygrid-day-number {
        font-size: 13px;
      }

      .small-cal {
        display: none;
      }

      .schedule-head-box {
        gap: 20px;
        left: 340px;
      }

      .calendar-outer {
        margin: 1px;
      }

      .cal-para {
        font-size: small;
        max-width: 360px;
      }

      .top-cal {
        gap: 5px;
      }

      .cal-select-month {
        height: 40px;
      }

      select#cal-month {
        width: 85px;
        padding: 2px;
      }
    }
  }
}

@media only screen and (max-width: 1000px)and (min-width:913px) {
  body {
    .schedule {
      width: 100%;

      .fc-toolbar h2 {
        font-size: 37px;
      }

      .top-cal {
        gap: 5px;
      }

      select#cal-month {
        width: 90px;
        padding: 4px;
      }

      .schedule-head-box {
        gap: 20px;
      }

      .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
        height: 724px !important;
      }

      table.fc-scrollgrid-sync-table {
        height: 553px !important;

      }

      .fc .fc-toolbar.fc-header-toolbar {
        margin-bottom: 78px;
      }

      .fc-daygrid-day-events {
        height: 3.7rem;
      }

      .cal-para {
        font-size: small;
        max-width: 360px;
      }

      .fc .fc-col-header-cell-cushion {
        font-size: 14px;
      }

      .fc .fc-daygrid-day-number {
        font-size: 14px;
      }

      .fc-event-title {
        font-size: 10px;
      }

      .fc-event-time {
        // min-width: 35px;
        font-size: 10px;
      }
    }
  }
}

@media only screen and (max-width: 1064px)and (min-width:1000px) {
  body {
    .schedule {
      .top-cal {
        gap: 10px;
      }

      .fc-toolbar-chunk:nth-child(1) {
        width: 240px;
      }

      .fc .fc-toolbar.fc-header-toolbar {
        margin-bottom: 75px;
      }

      .schedule-head-box {
        left: 350px;
      }

      .fc-toolbar h2 {
        font-size: 37px;
      }

      .cal-para {
        font-size: small;
        max-width: 360px;
      }

      .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
        height: 718px !important;
      }

      .fc-daygrid-day-events {
        height: 6rem;
      }

      table.fc-scrollgrid-sync-table {
        height: 550px !important;
      }

      select#cal-month {
        width: 90px;
        padding: 4px;
      }

      .fc .fc-col-header-cell-cushion {
        font-size: 14px;
      }

      .fc .fc-daygrid-day-number {
        font-size: 14px;
      }

      .fc-event-time {
        font-size: 11px;
        // min-width: 36px;
      }

      .fc-event-title {
        font-size: 11px;
      }
    }
  }
}

@media only screen and (max-width: 1444px)and (min-width:1064px) {
  body {
    .schedule {
      .top-cal {
        gap: 10px;
        // REFER
      }

      .fc-toolbar h2 {
        font-size: 37px;
      }

      .cal-para {
        max-width: 360px;
      }

      .fc-toolbar-chunk:nth-child(1) {
        width: 240px;
      }

      .fc .fc-toolbar.fc-header-toolbar {
        margin-bottom: 35px;
      }

      .fc .fc-col-header-cell-cushion {
        font-size: 14px;
      }

      .fc .fc-daygrid-day-number {
        font-size: 14px;
      }

      .schedule-head-box {
        left: 368px;
      }

      .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
        height: 708px !important;
      }

      .fc-daygrid-day-events {
        height: 6em;
      }

      table.fc-scrollgrid-sync-table {
        height: 320px !important;

      }
    }
  }
}

@media only screen and (min-width:1444px)and(max-width:1900px) {
  body {
    .schedule {
      .fc-toolbar h2 {
        font-size: 37px;
      }
      .fc .fc-toolbar.fc-header-toolbar {
        margin-bottom: 35px;
      }
      .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
        height: 748px !important;
      }
      .fc-daygrid-day-events {
        height: 6rem;
      }
      table.fc-scrollgrid-sync-table {
        height: 315px !important;
      }
      .cal-para {
        top: 75px;
        left: 0px;
        max-width: 410px;
      }

      .schedule-head-box {
        height: 60px;
        left: 348px;
        right: 0px;
      }

      .top-cal {
        gap: 20px;
      }

      .cal-btn {
        height: 40px;
        width: 40px;
      }

      .fc .fc-toolbar {
        height: 60px;
      }

      .fc-toolbar h2 {
        font-size: 37px !important;
      }

      .fc-event-time {
        // min-width: 42px;
        font-size: 12px;
      }

      .fc-event-title {
        font-size: 12px;
      }

      .fc-toolbar-chunk:nth-child(1) {
        width: 240px;

      }
    }
  }
}

@media only screen and (min-width:1900px) {
  body {
    .schedule {
      .fc .fc-toolbar.fc-header-toolbar {
        margin-bottom: 35px;
      }

      .cal-para {
        top: 80px;
        left: 0px;
        max-width: 500px;
        font-size: medium;
      }

      .fc-event-time {
        // min-width: 45px;
        font-size: 14px;
      }

      .fc-event-title {
        font-size: 14px;
      }

      .schedule-head-box {
        left: 380px;
      }

      .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
        height: 995px !important;
      }

      .fc-daygrid-day-events {
        height: 6rem;
      }

      table.fc-scrollgrid-sync-table {
        height: 670px !important;
      }

      .schedule-head-box {
        height: 60px;
        left: 340px;
        right: 0px;
      }

      .cal-select-month {
        height: 40px;
      }

      .top-cal {
        gap: 50px;
      }

      .fc .fc-toolbar {
        height: 60px;
      }

      .fc-toolbar h2 {
        font-size: 37px !important;
      }

      .fc-toolbar-chunk:nth-child(1) {
        width: 240px;
      }

      .fc .fc-col-header-cell-cushion {
        font-size: 18px;
      }

      .fc .fc-daygrid-day-number {
        font-size: 18px;
      }
    }
  }
}

//media query for day and week view



@media only screen and (max-width:501px) {
  .schedule {
    .fc .fc-timegrid-slot-label-cushion {
      padding: 3px;
    }

    .fc-direction-ltr .fc-timegrid-slot-label-frame {
      font-size: 10px;
    }

    .fc-timegrid.fc-timeGridWeek-view.fc-view {
      col {
        width: 40px !important;
      }

      td.fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink.time-hours {
        height: 60px;
      }

      .weekview-day-header {
        a.fc-col-header-cell-cushion {
          height: 60px;
          font-size: 13px;
          line-height: 24px;

          .week-event-Day {
            font-size: 12px;
          }
        }
      }

      th.fc-col-header-cell.fc-day.fc-day-today.weekview-day-header {
        .week-event-Date {
          width: 25px;
          height: 25px;
          border-radius: 6px;
        }
      }
    }

    .week-view-event-css {
      .eventTimeGridview {
        padding: 1px 3px;
      }

      img {
        width: 10px;
        height: 10px;
      }

      .eventName,
      .eventTitle {
        font-size: 5px;
        line-height: 6px;
        margin-bottom: 2px;
      }

      .eventTime {
        margin-bottom: 2px;
        font-size: 4px;
        line-height: 4px;
      }

      .eventGoal {
        font-size: 4px;
        line-height: 4px;
        padding: 2px;
      }
    }

    //for day view
    col {
      width: 60px !important;
    }

    td.fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink.day-slot-class {
      height: 80px;
    }

    .day-view-event-css {

      img {
        width: 25px;
        height: 25px;
        margin-bottom: 4px;
      }

      .eventName,
      .eventTitle,
      .eventTime,
      .eventGoal {
        font-size: 10px;
        line-height: normal;
        margin-bottom: 3px;
      }

      .eventGoal {
        padding: 4px;
        margin: 6px 0px 0px 0px;
      }

      .eventTimeGridview {
        max-width: 100%;
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .eventTime{
          &::after{
            content: '|';
            height: 100%;
            width: 1px;
            position: relative;
            margin-left: 3px;
          }
        }

      }
    }
  }
}

@media only screen and (min-width:500px) and (max-width:649px) {
  .schedule {
    .fc .fc-timegrid-slot-label-cushion {
      padding: 5px;
    }

    .fc-direction-ltr .fc-timegrid-slot-label-frame {
      font-size: 12px;
    }

    .fc-timegrid.fc-timeGridWeek-view.fc-view {
      col {
        width: 53px !important;
      }

      td.fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink.time-hours {
        height: 60px;
      }

      .weekview-day-header {
        a.fc-col-header-cell-cushion {
          height: 60px;
          font-size: 13px;
          line-height: 24px;

          .week-event-Day {
            font-size: 12px;
          }
        }
      }

      th.fc-col-header-cell.fc-day.fc-day-today.weekview-day-header {
        .week-event-Date {
          width: 25px;
          height: 25px;
          border-radius: 6px;
        }
      }
    }

    .week-view-event-css {
      .eventTimeGridview {
        padding: 1px 3px;
      }

      img {
        width: 13px;
        height: 13px;
      }

      .eventName,
      .eventTitle {
        font-size: 6px;
        line-height: 8px;
        margin-bottom: 3px;
      }

      .eventTime {
        margin-bottom: 3px;
        font-size: 5px;
        line-height: 5px;
      }

      .eventGoal {
        font-size: 5px;
        line-height: 5px;
        padding: 2px;
      }
    }

    //for day view
    col {
      width: 70px !important;
    }

    td.fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink.day-slot-class {
      height: 90px;
    }

    .day-view-event-css {

      img {
        width: 25px;
        height: 25px;
      }

      .eventName,
      .eventTitle,
      .eventTime,
      .eventGoal {
        font-size: 11px;
        line-height: normal;
        margin-bottom: 4px;
      }

      .eventGoal {
        padding: 4px;
        margin: 6px 0px 0px 0px;
      }

      .eventTimeGridview {
        max-width: 100%;
        padding: 5px;
        margin-bottom: 4px;
      }
    }
  }
}

@media only screen and (min-width:649px) and (max-width:769px) {
  .schedule {
    .fc .fc-timegrid-slot-label-cushion {
      padding: 6px;
    }

    .fc-direction-ltr .fc-timegrid-slot-label-frame {
      font-size: 12px;
    }

    .fc-timegrid.fc-timeGridWeek-view.fc-view {
      col {
        width: 56px !important;
      }

      td.fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink.time-hours {
        height: 60px;
      }

      .weekview-day-header {
        a.fc-col-header-cell-cushion {
          height: 64px;
          font-size: 13px;
          line-height: 24px;

          .week-event-Day {
            font-size: 12px;
          }
        }
      }

      th.fc-col-header-cell.fc-day.fc-day-today.weekview-day-header {
        .week-event-Date {
          width: 25px;
          height: 25px;
          border-radius: 6px;
        }
      }
    }

    .week-view-event-css {
      .eventTimeGridview {
        padding: 1px 3px;
      }

      img {
        width: 15px;
        height: 15px;
        margin-bottom: 4px;
      }

      .eventName,
      .eventTitle {
        font-size: 7px;
        line-height: 9px;
        margin-bottom: 4px;
      }

      .eventTime {
        margin-bottom: 4px;
        font-size: 7px;
        line-height: 6px;
      }

      .eventGoal {
        font-size: 6px;
        line-height: 5px;
        padding: 4px 3px 3px 3px;
      }
    }

    //for day view
    col {
      width: 80px !important;
    }

    td.fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink.day-slot-class {
      height: 90px;
    }

    .day-view-event-css {

      img {
        width: 25px;
        height: 25px;
        margin: 5px 0px;
      }

      .eventName,
      .eventTitle,
      .eventTime,
      .eventGoal {
        font-size: 11px;
        line-height: normal;
        margin-bottom: 4px;
      }

      .eventGoal {
        padding: 4px;
        margin: 6px 0px 0px 0px;
      }

      .eventTimeGridview {
        max-width: 100%;
        padding: 5px;
        margin-bottom: 4px;
      }
    }
  }
}

@media only screen and (min-width:769px) and (max-width:850px) {
  .schedule {
    .fc .fc-timegrid-slot-label-cushion {
      padding: 6px;
    }

    .fc-direction-ltr .fc-timegrid-slot-label-frame {
      font-size: 12px;
    }

    .fc-timegrid.fc-timeGridWeek-view.fc-view {
      col {
        width: 56px !important;
      }

      td.fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink.time-hours {
        height: 60px;
      }

      .weekview-day-header {
        a.fc-col-header-cell-cushion {
          height: 64px;
          font-size: 13px;
          line-height: 24px;

          .week-event-Day {
            font-size: 12px;
          }
        }
      }

      th.fc-col-header-cell.fc-day.fc-day-today.weekview-day-header {
        .week-event-Date {
          width: 25px;
          height: 25px;
          border-radius: 6px;
        }
      }
    }

    .week-view-event-css {
      .eventTimeGridview {
        padding: 1px 3px;
      }

      img {
        width: 15px;
        height: 15px;
        margin-bottom: 4px;
      }

      .eventName,
      .eventTitle {
        font-size: 7px;
        line-height: 9px;
        margin-bottom: 4px;
      }

      .eventTime {
        margin-bottom: 6px;
        font-size: 7px;
        line-height: 6px;
      }

      .eventGoal {
        font-size: 6px;
        line-height: 5px;
        padding: 4px 3px 3px 3px;
      }
    }

    //for day view
    col {
      width: 80px !important;
    }

    td.fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink.day-slot-class {
      height: 90px;
    }

    .day-view-event-css {

      img {
        width: 25px;
        height: 25px;
      }

      .eventName,
      .eventTitle,
      .eventTime,
      .eventGoal {
        font-size: 11px;
        line-height: normal;
        margin-bottom: 4px;
      }

      .eventGoal {
        padding: 4px;
        margin: 6px 0px 0px 0px;
      }

      .eventTimeGridview {
        max-width: 100%;
        padding: 5px;
        margin-bottom: 4px;
      }
    }
  }
}

@media only screen and (min-width:850px) and (max-width:1050px) {
  .schedule {
    .fc .fc-timegrid-slot-label-cushion {
      padding: 8px;
    }

    .fc-direction-ltr .fc-timegrid-slot-label-frame {
      font-size: 12px;
    }

    .fc-timegrid.fc-timeGridWeek-view.fc-view {
      col {
        width: 63px !important;
      }

      td.fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink.time-hours {
        height: 66px;
      }

      .weekview-day-header {
        a.fc-col-header-cell-cushion {
          height: 66px;
          font-size: 13px;
          line-height: 24px;

          .week-event-Day {
            font-size: 12px;
          }
        }
      }

      th.fc-col-header-cell.fc-day.fc-day-today.weekview-day-header {
        .week-event-Date {
          width: 25px;
          height: 25px;
          border-radius: 6px;
        }
      }
    }

    .week-view-event-css {
      .eventTimeGridview {
        padding: 1px 3px;
      }

      img {
        width: 18px;
        height: 18px;
        margin: 5px 0px;
      }

      .eventName,
      .eventTitle {
        font-size: 8px;
        line-height: 10px;
        margin-bottom: 5px;
      }

      .eventTime {
        margin-bottom: 8px;
        font-size: 8px;
        line-height: 8px;
      }

      .eventGoal {
        font-size: 7px;
        line-height: 6px;
        padding: 5px 4px 4px 4px;
      }
    }

    //for day view
    col {
      width: 100px !important;
    }

    td.fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink.day-slot-class {
      height: 90px;
    }

    .day-view-event-css {

      img {
        width: 25px;
        height: 25px;
        margin: 5px 0px;
      }

      .eventName,
      .eventTitle,
      .eventTime,
      .eventGoal {
        font-size: 11px;
        line-height: normal;
        margin-bottom: 4px;
      }

      .eventGoal {
        padding: 4px;
        margin: 6px 0px 0px 0px;
      }

      .eventTimeGridview {
        max-width: 100%;
        padding: 5px;
        margin-bottom: 4px;
      }
    }
  }
}

@media only screen and (min-width:1050px) and (max-width:1350px) {
  .schedule {
    .fc .fc-timegrid-slot-label-cushion {
      padding: 8px;
    }

    .fc-direction-ltr .fc-timegrid-slot-label-frame {
      font-size: 12px;
    }

    .fc-timegrid.fc-timeGridWeek-view.fc-view {
      col {
        width: 75px !important;
      }

      td.fc-timegrid-slot.fc-timegrid-slot-label.fc-scrollgrid-shrink.time-hours {
        height: 80px;
      }

      .weekview-day-header {
        a.fc-col-header-cell-cushion {
          height: 72px;
          font-size: 16px;
          line-height: 28px;

          .week-event-Day {
            font-size: 13px;
          }
        }
      }

      th.fc-col-header-cell.fc-day.fc-day-today.weekview-day-header {
        .week-event-Date {
          width: 29px;
          height: 29px;
          border-radius: 6px;
        }
      }


    }

    .week-view-event-css {
      .eventTimeGridview {
        padding: 2px 3px;
      }

      img {
        width: 22px;
        height: 22px;
        margin: 6px 0px;
      }

      .eventName,
      .eventTitle {
        font-size: 9px;
        line-height: 11px;
        margin-bottom: 6px;
      }

      .eventTime {
        margin-bottom: 7px;
        font-size: 9px;
        line-height: 8px;
      }

      .eventGoal {
        font-size: 8px;
        line-height: 6px;
        padding: 6px 5px 5px 5px;
      }
    }
  }
}

.schedule-switch {
  .dashboardtop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0px;
  }

  .sidebarcnt {
    z-index: 99999;
    position: fixed;
  }
}

@media only screen and (max-width:768px) {
  .cal-add-btn {
    margin-top: 10px;
  }
  .studentSwitch {
    margin-top: 4rem !important;
  }
  .schedule {
    .fc-daygrid-day-frame{
      .fc-daygrid-day-top{
        .fc-daygrid-day-number{
          padding-block: 1px;
        }
      }

      .fc-daygrid-day-bottom{
        overflow: hidden;
        text-overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        padding-inline: 1px;
        word-break: break-all;

        .fc-more-link{
          font-size: 10px;
        }
      }
    }
  }
}
.cal-add-btn {
  flex-shrink: 0;
  .btn-primary {
    background-color: @backcolor--primary;
    border-radius: 10px;
    font-size: 16px;
    font-family: @font--poppins-regular;
    padding: 10px;
    border-color: transparent;
    box-shadow: transparent;
    border-color: transparent;
  }
  .dropdown-toggle::after{
    margin-left: 1em;
  }
  .btn-primary:focus {
    border-color: transparent;
    box-shadow: transparent;
    border-color: transparent;
  }
  .Booksession-btn{
    border-radius: 10px;
    font-size: 16px;
    background-color: @backcolor--primary !important;
    color: @backcolor--white !important;
    font-family: @font--poppins-regular !important;
    padding: 10px;
    border-color: transparent;
    box-shadow: transparent;
    border-color: transparent;
    text-transform: none;
  }
}
.Backdrop__container{
  display: flex;
  align-self: center;
  justify-content: center;
  &__card{
    background-color: @backcolor--white;
    padding: 10px;
    border-radius: 15px;
    width: 525px;
    .title_cnt{
      padding: 30px 30px 10px 30px;
      text-align: center;
      p{
        font-size: 18px;
        color: @backcolor--black;
        font-family: @font--poppins-regular;
        margin-bottom: 0px;
      }
    }
    .btn_cnt{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 10px 30px 10px;;
      p{
        font-size: 18px;
        color: @backcolor--primary;
        font-family: @font--poppins-regular;
        margin-bottom: 0px;
        cursor: pointer;
        span{
          margin-right: 5px;
        }
      }
    }
  }
}
.cal-select-month-mobile{
  margin-bottom: 10px;
}

@media only screen and (max-width:400px) {
  .Backdrop__container{
    display: flex;
    align-self: center;
    justify-content: center;
    &__card{
    
      width: fit-content;
   
    }
  }
}
.Backdropsschedule{
  margin-left: 100px;
  @media screen and (max-width: 768px) {
    margin-left: 0px;
    padding: 15px;
  }
}