@import "../../../../assets/css/variables.less";

.Advisor-mySubscriptions{
    display: flex;

    &__mainCnt{
        .advisor-mySubscriptions_Sec{
            .mySubscriptionsTitle{
                font-family: @font--poppins-500;
                font-size: 32px;
            }
        }
        .advisor-mySubscriptions_Mobile{
            display: none;
            position: fixed;
            z-index: 99;
        }
        &__section{
            &__subscriptionSec{
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                max-height: 700px;
                overflow-y: scroll;

                div:hover{
                    cursor: pointer;
                }
            }
            &--mobileTabSec {
                display: none;
            }   
            &--desktopTabSec{
                // background-color: #FFFFFF;
                // border: 1px solid #e3e3e3;
                border-radius: 10px;
                width: 100%;

                .Tab{
                    font-family: @font--poppins-regular;
                    font-size: 16px;
                    text-transform: capitalize;
                }
            } 
            &--tabPannelSec{
                &__pannel{
                    &__subscriptionSec{
                        display: flex;
                        flex-wrap: wrap;
                        gap: 20px;
                    }
                    .myWorkContentSec {
                        padding-inline: 10px;
                    }
                }
            }        
        }
        .mySubscriptionsNoDataSec{
            width: 100%;
            min-height: 500px;
            display: flex;
            justify-content: center;
            align-items: center;

            &__noDataCard{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                gap: 20px;
                border-radius: 10px;
                padding: 60px 40px;
                min-height: 100px;
                min-width: 260px;
                max-width: 500px;
                transition: all 0.2s ease-out;
                box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.1);
                border: 1px solid #cccccc;
                background-color: #fff;

                &__textBlack{
                    text-align: center;
                    font-family:  @font--poppins-regular;
                    margin-bottom: 0;
                    color: #000;
                }
                &__cta{
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    cursor: pointer;

                    &__searchIcon{
                        width: 20px !important;
                        height: 20px !important;
                        color: #222222 !important;
                    }
                    &__blueText{
                        font-family:  @font--poppins-regular;
                        font-size: 16px;
                        margin-bottom: 0;
                        color: #1C84EE;
                        user-select: none;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .Advisor-mySubscriptions{
        padding: 20px;

        &__mainCnt {
            .advisor-mySubscriptions_Sec{
                display: none;
            }
            .advisor-mySubscriptions_Mobile{
                background-color: #fff;
                border-bottom: 1px solid #e3e3e3;
                height: 50px;
                display: flex;
                justify-content: center;
                width: 100%;
                align-items: center;

                .mySubscriptionsTitle{
                    font-family: @font--poppins-500;
                    font-size: 26px;
                    margin: 0;
                }
            }
            &__section{
                margin-top: 70px !important;
                margin-left: 20px !important;
                padding-top: 0 !important;

                &--mobileTabSec {
                    display: block;
                    width: 90%;
                    margin-inline: auto;
                }   
                &--desktopTabSec{
                    display: none;
                }
            }
        }
    }
}
@media screen and (max-width: 425px) {
    .Advisor-mySubscriptions{
        &__mainCnt {
            .advisor-mySubscriptions_Mobile{
                .mySubscriptionsTitle{
                    font-size: 24px;
                }
            }
            &__section{
                margin-left: 3px !important;

                &__subscriptionSec{
                    display: flex !important;
                    justify-content: center !important;
                    margin-left: 1px !important;

                }
                &--tabPannelSec{
                    &__pannel{
                        &__subscriptionSec{
                            justify-content: center !important;
                        }
                    }
                }
            }
        }
    }
}