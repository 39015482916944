@import "../../../../../assets/css/variables.less";

.milestone_Page{
    &__topSec{       
        &__mobileTitle, &__mobileSubTitle{
            display: none;
        }
        &__desktop{
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 40px;

            &__desktopTitle{
                p{
                    font-family: @font--poppins-500;
                    font-size: 32px;
                    margin-bottom: 0;
                }
            }
        }
    }
    &__midSec{
        .milesTab{
            text-transform: inherit;
        }
    }
}

@media screen and (max-width: 768px) {
    .milestone_Page{
        &__topSec{
            &__desktop{
                display: none;
            }
            &__mobileTitle{
                background-color: #fff;
                border-bottom: 1px solid #e3e3e3;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 50px;

                p{
                    font-family: @font--poppins-500;
                    font-size: 26px;
                    margin: 0;
                }
            }
            &__mobileSubTitle{
                display: flex;
                margin-block-start: 15px;
                margin-inline-start: 1.5em;
                align-items: center;
                gap: 10px;

                &__goBackIcon{
                    width: 25px;
                    height: 25px;
                }
                p{
                    font-family: @font--poppins-500;
                    font-size: 20px;
                    margin-bottom: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: break-word;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                }
            }
        }
        &__midSec{
            padding: 24px;
        }
    }
}
@media screen and (max-width: 425px) {
    .milestone_Page{
        &__topSec{
            &__mobileSubTitle{
                margin-inline-start: .5em;
            }
        }
        &__midSec{
            padding-inline: 5px;
        }
    }
}