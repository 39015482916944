.cardContentMain {
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 10px !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
  box-shadow: none !important;
  justify-content: space-between;
  max-height: 75px;
  height: 75px;

  .card-money {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
    width: 100%;
    padding-right: 30px;
    padding-left: 20px;
    p {
      font-family: "poppins-regular", sans-serif;
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 400 !important;
    text-align: start;
    flex: 2 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    }
    h2 {
      margin-bottom: 0px;
    //   white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 19px;
    font-family: 'poppins-500', sans-serif;
      line-height: 1.6;
      letter-spacing: 0.0075em;
      text-align: start;

    }
  }
}
