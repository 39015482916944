@import "../../../assets/css/variables.less";

.dashCountCard {
    text-align: center;
    // margin-right: 1rem;
    margin-top: 15px;
    border-radius: 10px !important;
    width: 145px;
    height: 138px;
    margin-bottom: 20px;
}

.totalcount {
    font-family: @font--poppins-500 !important
}

.countFrom {
    color: #5A5A5A;
    font-size: 15px !important;
    font-family: @font--poppins-regular !important;
    text-wrap: pretty;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    word-break: break-word;
}