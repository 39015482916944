@import "../../../assets/css/variables.less";

.planCourseAccess{
    &__title{
        color: #1B1C1E;
        font-family: @font--poppins-500;
        font-size: 30px;
    }

    &__newPlanSec, 
    &__existingPlanSec, 
    &__courseActivitySec {
        padding: 10px 10px 10px 40px;
        background-color: #fff;
        border-radius: 10px;
        border: 2px solid #E3E3E3;
    }

    &__courseActivitySec{
        &__title{
            color: #1B1C1E;
            font-size: 20px;
            font-family: @font--poppins-500;
        }

        &__courseList{
            margin-bottom: 30px;
            max-height: 550px;
            overflow-y: scroll;
            padding-right: 10px;
        }
    }

    &__commentSection{
        &__title{
            color: #1B1C1E;
            font-size: 20px;
            font-family: @font--poppins-500;
        }

        &__inputArea{
            max-width: 1000px;

            .input{
                resize: none;
            }
        }
    }

    &__footerSection{
        display: flex;
        justify-content: flex-end;

        &__btnPrimary{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            border-radius: 10px;
            padding: 12px 30px;
            color: #fff;
            background: #1C84EE;
            border: #1C84EE;
            font-family: @font--poppins-regular;
        }
    }
}



@media only screen and (max-width: 600px) {
    .planAccessModal{
        padding: 0px 13px !important;
    }
    .PlanTile{
        padding: 30px !important;
    }
}