@import "../../../assets/css/variables.less";

.studentPublicProfile{
    display: flex;
    flex-direction: column;
    gap: 40px;
    overflow: auto;
    padding: 100px 70px;
    flex-grow: 1;

    &__studentProfileInfo{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;
        padding: 30px 50px;
        background-color: #fff;
        border: 2px solid #E3E3E3;
        border-radius: 15px;

        &__userDetail{
            display: flex;
            gap: 30px;

            &__imgContainer{
                width: 140px !important;
                height: 140px !important;
                border-radius: 50% !important;
                overflow: hidden;

                &__profileImg{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &__placeholderImg{
                border: 1px solid grey; 
                width: 140px !important;
                height: 140px !important;
                border-radius: 50% !important
            }

            &__info{
                &__userName{
                    font-size: 34px;
                    margin-bottom: 5px;
                    font-family: @font--poppins-500;
                }
                &__country{
                    font-size: 18px;
                    color: #515050;
                    font-family: @font--poppins-regular;
                }
            }
        }
        &__ctaSection{
            display: flex;
            flex-direction: column;
            gap: 20px;

            &__btn{
                color: #fff;
                border: none;
                width: 180px;
                height: 42px;
                border-radius: 10px;
                font-family: @font--poppins-500;
            }

            .disabled-btn{
                background-color: #98C6FA;
            }
            .primary-btn{
                background-color: #1C84EE;
            }
        }
    }

    &__studentAboutSection, 
    &__studentInterestSection, 
    &__studentCoursesSection,
    &__studentPlanSection{
        &__subTitle{
            color: #1B1C1E;
            font-size: 22px;
            font-family: @font--poppins-500;
        }

        &__studentAboutDescp, &__studentInterestDescp{
            padding: 30px 50px;
            background-color: #fff;
            border: 2px solid #E3E3E3;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            gap: 15px;

            span{
                display: flex;
                align-items: flex-start;
                gap: 10px;

                p{
                    font-size: 17px;
                    color: #000;
                    margin-bottom: 0;
                    font-family: @font--poppins-regular;
                }

                .descpIcon{
                    color: #515050 !important;
                }
            }
        }
        &__courses{
            display: flex;
            flex-direction: column;
            gap: 30px;
            max-height: 600px;
            overflow-y: scroll;
            scroll-behavior: smooth;
            padding-inline-end: 5px;
        }
        &__plans{
            display: flex;
            gap: 30px;
            flex-wrap: wrap;
        }
    }
}
@media only screen and (max-width: 768px) {
    .studentPublicProfile{
        padding: 80px 30px;
        &__studentProfileInfo{
            justify-content: center;
            gap: 40px;
            padding: 30px 20px;

            &__userDetail{
                align-items: center;

                &__placeholderImg{
                    width: 120px !important;
                    height: 120px !important;
                }
                &__info{
                    &__userName{
                        font-size: 28px;
                    }
                    &__country{
                        font-size: 14px;
                    }
                }
            }
        }

        &__studentAboutSection, 
        &__studentInterestSection, 
        &__studentCoursesSection,
        &__studentPlanSection{
            &__studentAboutDescp, &__studentInterestDescp{
                padding: 30px 20px;

                span{
                    .descpIcon{
                        width: 20px !important;
                        height: 20px !important;
                    }
                    p{
                        font-size: 15px;
                    }
                }
            }
            &__courses{
                gap: 20px;
            }
        }
    }
}
@media only screen and (max-width: 425px) {
    .studentPublicProfile{
        padding: 100px 10px;
        
        &__studentProfileInfo{
            padding: 20px 10px;

            &__userDetail{
                gap: 15px;

                &__placeholderImg{
                    width: 90px !important;
                    height: 90px !important;
                }
                &__info{
                    &__userName{
                        font-size: 24px;
                    }
                }
            }
        }
        &__studentAboutSection, 
        &__studentInterestSection, 
        &__studentCoursesSection,
        &__studentPlanSection{
            &__subTitle{
                font-size: 18px;
            }
            &__studentAboutDescp, &__studentInterestDescp{
                padding: 20px 10px;

                span{
                    .descpIcon{
                        width: 15px;
                        height: 15px;
                    }
                    p{
                    }
                }
            }
        }
    }
}
.studentProfile__studentPlanSection__plans{
    display: flex !important;
    flex-wrap: wrap !important;
    gap: 30px !important;
}