@import "common.less";

body {
    margin: 0px;
}

.role {
    // background-color: @backcolor--white;

    .dark& {
        background-color: @backcolor--dark;
    }

    &_title {
        color: @color--cta--underline;
        margin-bottom: 24px;
        font-size: 48px;
        font-family: @font--poppins-500;

        .dark & {
            color: @color--white;
        }
    }

    &_desc {
        font-size: 21px;
        font-family: @font--poppins-regular;
        color: rgba(27, 28, 30, 0.75);
        opacity: 0.8;
    }

    &_card {
        padding: 20px;

        &.card {
            background-color: #FFFFFF;
            margin: 0 auto;
            border-color: transparent;

            .dark& {
                background-color: @backcolor--black;
            }
        }

        &_form {
            .dark& {
                color: #fff;
            }

            .form-group {
                margin-top: 20px;
            }
        }
    }

    &_text {
        font-size: 16px;
        font-family: @font--poppins-regular;
        color: @color--secondary;
        letter-spacing: 0.15px;
    }

    @media (hover: none),
    (any-hover: none) {

        .btn-check:not(:checked)+.cta--role--btn,
        .btn-check:not(:checked)+.cta--role--btn:active,
        .btn-check:not(:checked)+.cta--role--btn:focus {
            background-color: @btn--primary;
            color: @color--black;
            .Studenticon{
                .iconstroke{
                    stroke: #4B4B4B;
                }
                .iconfill{
                    fill: #4B4B4B;
                }
            }
            .Advisoricon{
                .iconstroke{
                    stroke: #4B4B4B;
                }
                .iconfill{
                    fill: #4B4B4B;
                }
            }
        }
    }

    .btn-check:not(:checked)+.cta--role--btn,
    .btn-check:not(:checked)+.cta--role--btn:active,
    .btn-check:not(:checked)+.cta--role--btn:focus {
        background-color: @btn--white;
        color: @color--black;
        .Studenticon{
            .iconstroke{
                stroke: #4B4B4B;
            }
            .iconfill{
                fill: #4B4B4B;
            }
        }
        .Advisoricon{
            .iconstroke{
                stroke: #4B4B4B;
            }
            .iconfill{
                fill: #4B4B4B;
            }
        }
    }

    .btn-check:checked+.cta--role--btn,
    .btn-check:checked+.cta--role--btn:active,
    .btn-check:checked+.cta--role--btn:focus {
        background-color: @btn--primary;
        color: @color--white;
        border: 2px solid @btn--primary;
        .Studenticon{
            .iconstroke{
                stroke: @color--white;
            }
            .iconfill{
                fill: @color--white;
            }
        }
        .Advisoricon{
            .iconstroke{
                stroke: @color--white;
            }
            .iconfill{
                fill: @color--white;
                fill-opacity: 1 !important;
            }
        }
    }

    .roles_page,
    .goals_page {
        margin: 0 auto;
        width: 100%;
        display: flex;
    }

    .goals_page {
        display: flex;
        justify-content: center;
    }

    .goalsDesc {
        color: #1C84EE;
        font-family: @font--poppins-regular;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    &__btns {
        padding: 0px 10px;
        .Studenticon{
            .iconstroke{
                stroke: #4B4B4B;
            }
            .iconfill{
                fill: #4B4B4B;
            }
        }
        .Advisoricon{
            .iconstroke{
                stroke: #4B4B4B;
            }
            .iconfill{
                fill: #4B4B4B;
            }
        }
    }

    .cta--role--btn {
        border-radius: 300px;
        padding: 6px 20px;
        border: 2px solid #4B4B4B;
        font-size: 15px;

        &.checked {
            background: @btn--primary;
            color: @color--white;
        }
    }

    .goals {
        &__form {
            width: 414px;
            margin: 40px auto 2px auto;

            &_group {
                margin-bottom: 26px;
            }

            &__inputs {
                width: 100%;
                border-radius: 10px;
                background: #FFFFFF;
                border: 1px solid rgba(94, 112, 211, 0.5);
                height: 48px;

                text-align: left;
                padding: 0px 10px;
            }

            &__select {
                text-align: left;

                +div:first-of-type {
                    height: 48px;
                    border-color: rgba(94, 112, 211, 0.5);
                    border-radius: 10px;
                    line-height: 48px;
                }
            }
        }

    }
}



@media screen and (max-width: 768px) {
    .role {
        &_title {
            font-size: 24px;
            margin-bottom: 20px;
        }

        .list-inlibeblock {
            margin-bottom: 0px;

            li {
                svg {
                    width: 35px;
                    height: 35px;
                }
            }
        }

        &_card-container {
            padding: 10px 20px;
        }

        &_form {
            .form-group:first-child {
                margin-top: 0px;
            }
        }

        .roles_page,
        .goals_page {
            margin: 0 auto;
            width: 100%;
            display: block;
            text-align: center;
        }

        &__btns {
            margin-bottom: 10px;
        }

        .goals__form {
            width: 100%;
        }
    }
}



@media (min-width:600px) and (max-width:768px) {
    .fade.alert.show {
        position: absolute;
        bottom: 25px;
        left: 0px;
        right: 0px;
        z-index: 999;
    }
}

// @media screen and (max-width: 768px) {
//     .sendInvite{
//         .sendInvite__form{
//             .sendInvite__form_inner{
//                 .fade.alert.show {
//                     position: absolute;
//                     bottom: 46px;
//                     left: 0px;
//                     right: 0px;
//                     z-index: 999;
//                 }
//             }
//         }
//     }
// }

@media (min-width:500px) and (max-width:767px) {
    .fade.alert.show {
        position: absolute;
        bottom: 80px;
        left: 0px;
        right: 15px;
        z-index: 999;
    }
}




@media screen and (max-width: 500px) {
    .fade.alert.show {
        position: absolute;
        bottom: 80px;
        left: 0px;
        right: 0px;
        z-index: 999;
    }
    .sendInviteParent {
        .fade.alert.show {
            position: absolute;
            bottom: 65px;
            left: 95px !important;
            right: 0px;
            z-index: 999;
        }
    }
}




@media screen and (max-width: 419px) {
    .copyInviteAlert {
        .fade.alert.show {
            position: absolute;
            bottom: 80px;
            left: 80px !important;
            right: 0px;
            z-index: 999;
        }
    }

}

@media screen and (max-width: 360px) {
    .copyInviteAlert {
        .fade.alert.show {
            position: absolute;
            bottom: 25px;
            left: 50px !important;
            right: 0px;
            z-index: 999;
        }
    }
    .sendInviteParent {
        .fade.alert.show {
            position: absolute;
            bottom: 65px;
            left: 50px !important;
            right: 0px;
            z-index: 999;
        }
    }

}


@media screen and (max-width: 300px) {
    .footer__right {
        display: flex;
        padding-left: 0px !important;
    }

    .role .goals__form {
        margin-bottom: 20px;
    }

    .copyInviteAlert {
        .fade.alert.show {
            position: absolute;
            bottom: 65px;
            left: 20px !important;
            right: 0px;
            z-index: 999;
        }
    }
    .sendInviteParent {
        .fade.alert.show {
            position: absolute;
            bottom: 65px;
            left: 30px !important;
            right: 0px;
            z-index: 999;
        }
    }

}

@media screen and (max-width: 280px) {
    .sendInviteParent {
        .fade.alert.show {
            position: absolute;
            bottom: 65px;
            left: 20px !important;
            right: 0px;
            z-index: 999;
        }
    }
}