@import "../../../assets/css/variables.less";


//notes update popup style 
.notes-popup {
    padding: 2rem !important;
    // max-width: 414px;
    width: 35%;
    overflow-y: scroll;

    // height: 53%;
    .milestoneTitle {
        font-size: 20px;
        font-family: 'poppins-500', sans-serif;
        text-align: center;
    }
}
.milestonetopsections{
    margin-bottom: 30px;
}

@media screen and (max-width: 1000px) {
    .notes-popup {
        padding: 1em !important;
        width: 80%;
    }
}