@import "../../../assets/css/variables.less";

.workingHoursCard{
    padding: 30px 25px;
    border: 2px solid #E3E3E3;
    border-radius: 15px;
    padding-bottom: 4rem;

    &__title{
        font-family: @font--poppins-500;
        color: #3C3C3C;
        font-size: 22px;
        margin-bottom: 30px;
    }

    &__hoursSec{
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
    }

    &__dayContent{
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 20px;

        &__label{
            font-family: @font--poppins-500;
            font-size: 18px;
            line-height: 26px;
        }
        &__tagsec{
            display: flex;
            flex-direction: column;
            row-gap: 30px;

            .tag{
                &__tags-chip{
                    margin: 0;
                }
            }
        }
    }
}


@media screen and (max-width: 425px) {
    .workingHoursCard{
        margin: 0 0 30px 0;
        padding: 20px 15px;

        &__dayContent{
            &__label{
                font-family: @font--poppins-500;
                font-size: 16px;
                line-height: 24px;
                min-width: 5em;
            }
            
        }
    }
}

@media screen and (max-width: 366px) {
    .workingHoursCard{
        &__hoursSec{
            justify-content: center;
        }
    }
}