@import "../../../../assets/css/variables.less";

.rejectReasonModal{
    &__header{
        align-items: flex-start;
    }
    &__confirmationModal{
        padding: 30px 30px 20px;
        
        &__danger-btn{
            background-color: #E64A19;
            border: 1.5px solid transparent;
            color: #fff;
            padding: .5rem 2rem;
            font-family: @font--poppins-regular;
            border-radius: 10px;
            
            &:hover{
                background-color: #fff;
                border: 1.5px solid #E64A19;
                color: #E64A19;
            }
        }

    }

    @media screen and (max-Width: 320px) {
        &__header{
            align-items: flex-start;
            margin: 0;
        }
        &__confirmationModal{
            padding: 30px 10px 20px;
        }
    }
}
