@import "common.less";
@import "variables.less";
@import "fonts.less";

.advisor{
    .profile {
        &__form {
            width: 560px;
            margin: 0 auto;
            padding-top: 30px;
            padding-bottom: 90px;

            &__title {
                font-size: 42px;
                line-height: 56px;
                color: @color--cta--underline;
                margin-bottom: 24px;
                font-family: @font--poppins-500;
            }

            &__sub-title {
                font-size: 15px;
                line-height: 24px;
                letter-spacing: 0.15px;
                font-family: @font--poppins-500;
                color: @color--black;
            }

            &__smalltext {
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.15px;
                font-family: @font--poppins-500;
                color: @color--gray;
            }

            &__checkboxcnt{
                .styled-radio{
                    .radio-label{
                        font-size: 15px;
                        font-family: @font--poppins-regular;
                    }
                }
            }

            &__topIcon{
                font-size: 30px;
            }

            .dragdropcnt {
                display: flex;
                justify-content: center;

                .dropzone1 {
                    display: flex;
                    justify-content: center;
                    margin-right: 45px;
                    margin-left: 45px;

                    .dropzoneinside {
                        border: 1px dashed rgba(0, 0, 0, 0.1);
                        margin-top: 20px;
                        border-radius: 15px;
                        padding: 10px;
                        background-color: #f9f9f9;
            
                        .dropzoneinsidetop {
                            display: flex;
                            justify-content: center;
            
                            & span{
                                width: 100%;
                                height: 100%;
                            }
                            .deleteIcon{
                                :hover{
                                    color: #E64A19;
                                }
                            }
                        }
            
                        .dropzoneinsidemid {
                            display: flex;
                            justify-content: center;
                            margin-top: 10px;
                            color: #495057;
                        }
            
                        .dropzoneinsidebtm {
                            display: flex;
                            justify-content: center;
            
                            p {
                                margin-left: 5px;
                                font-weight: 700;
                                font-size: 16px;
                                color: #495057;
                            }
                        }
                    }
                }
                .dropzonebtm {
                    font-weight: 400;
                    font-size: 14px;
                    color: #919293;
                }
            }

            .generalcnt{
                &__emailResend{
                    display: flex;
                    gap: 10px;
                    &__text{
                        font-size: 12px;
                        color: #E64A19;
                        font-family: @font--poppins-regular;
                    }

                    &__advisoryBtn{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 40px;
                        user-select: none;
                        cursor: pointer;
                        background: #1C84EE;
                        border-radius: 10px;
                        color: #fff;
                        width: 200px;
                        line-height: 15px;
                        margin: 0 8px 0 auto;
                        text-align: center;
                        border: 2px solid #1C84EE;
                        font-size: 18px;

                        &:hover{
                            color: #000;
                            cursor: pointer;
                            background: #fff;
                            border: 2px solid #1C84EE;
                        }
                    }
                }
            }
            .highschoolcnt{
                .universityChip{
                    margin: 2px;
                    background-color: #e6e6e6;
                    font-size: 13.5px;
                    font-family: @font--poppins-500;
                    color: #333333;
                }
            }
            @media only screen and (max-width: 628px) {
                .generalcnt{
                    &__emailResend{
                        flex-wrap: wrap;
                    }
                }
            }
        }

        .textboxarea {
            height: 80px;
        }

        .dopZone-FileList {
            padding: 0px;

            span {
                cursor: pointer;
            }
        }

        .thumb {
            display: inline-block;
            border-radius: 50%;
            border: 1px solid #eaeaea;
            margin-bottom: 8px;
            // margin-right: 8px;
            width: 100%;
            height: 100%;
            padding: 4px;
            box-sizing: border-box;
        }

        .thumbInner {
            display: flex;
            min-width: 0px;
            min-height: 0px;
            overflow: hidden;
            width: 100%;
            height: 100%;
        }

        .thumbImg {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }
}

@media screen and (max-width: 768px) {
    .advisor{
        .profile {
            &__form {
                width: 100%;
                padding-left: 15px;
                padding-right: 15px;

                &__title {
                    font-size: 24px;
                    line-height: 35px;
                }

                &__topIcon{
                    font-size: 24px;
                }
                .highschoolcnt{
                    .universityChip{
                        max-width: 400px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 320px) {
    .advisor{
        .profile {
            &__form {
                padding-left: 5px;
                padding-right: 5px;
                
                .highschoolcnt{
                    .universityChip{
                        max-width: 240px;
                    }
                }
                .helpsWith-Sec{
                    gap: 10px;
                    .helpsWith {
                        display: flex;
                        align-items: flex-start;
                    }
                }
                .onboarding-course-section{
                    .OngoingCourse-title{
                        display: flex;
                        flex-wrap: wrap;

                        .advisoryBtn{
                            width: 120px;
                        }
                    }
                }
                &__checkboxcnt{
                    .styled-radio{
                        margin-inline: 0px;
                        display: flex;
                        flex-direction: row;
                    }
                }
            }
        }
    }
}

.modal-footer {

    .modalBtn,
    .modalBtn:focus {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        user-select: none;
        cursor: pointer;
        background: #1C84EE;
        border-radius: 10px;
        color: #fff;
        width: 160px;
        line-height: 36px;
        margin: 0 8px 0 auto;
        text-align: center;
        border: 2px solid #1C84EE;
        font-size: 18px;
    }

    .modalBtn:hover {
        color: #000;
        cursor: pointer;
        background: #fff;
        border: 2px solid #1C84EE;
    }
}

.packagebtncnt,
.packagebtncnt:focus{
 
    display: flex;
    justify-content: center;
    margin-bottom: 200px;
    user-select: none;
    font-family: @font--poppins-500;
    cursor: pointer;
    background: #1C84EE;
    border-radius: 10px;
    color: #fff;
    width: 100%;
    line-height: 36px;
    text-align: center;
    border: 1px solid #1C84EE;
    .packagebtn:hover {
        color: #000;
        width: 100%;
        font-family: @font--poppins-500;
        border-radius: 10px;
            cursor: pointer;
            background: #fff;
            border: 1px solid #1C84EE;
    }
}

@media screen and (max-width: 768px) {
    .packagebtncnt {
        display: flex;
        justify-content: center;
        margin-bottom: 200px;
        background: #1C84EE;
        border-radius: 10px;
        color: #fff;
        width: 100%;
        line-height: 36px;
        text-align: center;
        border: 1px solid #1C84EE;
     
        .packagebtn:hover {
            color: #000;
            font-family: @font--poppins-500;
            border-radius: 10px;
            cursor: pointer;
            background: #fff;
            border: 1px solid #1C84EE;
        }
    }
}

.none {
    display: none;
}

.OngoingCourse-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    .advisoryBtn,
    .advisoryBtn:focus {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        user-select: none;
        cursor: pointer;
        background: #1C84EE;
        border-radius: 10px;
        color: #fff;
        width: 140px;
        line-height: 36px;
        margin: 0 8px 0 auto;
        text-align: center;
        border: 2px solid #1C84EE;
    }

    .advisoryBtn:hover {
        color: #000;
        cursor: pointer;
        background: #fff;
        border: 1px solid #1C84EE;
    }
    
}

@media screen and (max-width: 285px) {
    .OngoingCourse-title {
        .advisoryBtn {
            width: 100%;
            margin: 0;
        }
    }
}

#black-icon {
    fill: #fff;
}

.OngoingCourse {
    display: none;

    &__wrapper {
        display: flex;
        justify-content: space-evenly;

        &__input {
            width: 45%;
        }
    }
}

.ql-toolbar.ql-snow {
    border: none !important;
    border-bottom: 1px solid #eaeaea !important;
    margin: 5px;
}

.quill.textarea--primary.textbox--rounded.input {
    padding: 0 !important;
    min-height: 150px !important;
    word-break: break-all;
}

.ql-container.ql-snow {
    border: none !important;
}

.subtext {
    font-size: 12px;
    color: rgba(27, 28, 30, 0.5) !important;
}

.circle {
    width: 25px;
    height: 25px;
    line-height: 25px;
    border-radius: 50%;
    font-size: 15px;
    color: #fff;
    text-align: center;
    background: #1c84ee;
    cursor: pointer;
}

.ql-link {
    display: none !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 13px;
    border: 0.2px solid rgba(0, 0, 0, 0.1) !important;
}

.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 13px;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.1);
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 11px;
    height: 51px;
}

.css-1bn53lx {
    border-radius: 11px !important;
    height: 51px !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 13px !important;
    font-family: @font--poppins-500 !important;
}

.go3450369076 {
    width: 100% !important;
}

.padding10 {
    padding-left: 10px;
}

.accordion .accordion-button {
    background-color: #fff;
    color: #5c5c5c;
}

.accordion .accordion-button:not(.collapsed) {
    color: #040506 !important;
    background-color: #ededed;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

@media only screen and (max-width: 768px) {
    .childtitle {
        font-size: 20px;
        line-height: 60px;
        color: @color--cta--underline;
        margin-bottom: 14px;
        font-family: @font--poppins-500;
    }

    .listofOngoingCourses {
        margin-bottom: 25px;
    }

    .tooltip {
        width: 550px !important;
    }
}

.input-floating-label.disabled {
    background-color: hsl(0, 0%, 95%);
}

.priceSettingEdit {
    border: 1px solid #1c84ee;
    color: white;
    background-color: #1c84ee;
    border-radius: 5px;
    width: 30%;
    text-align: center;
    margin: 5px;
    height: 35px;
    padding: 5px;
    cursor: pointer;
}

.priceSettingClose {
    border: 1px solid #ff5151;
    color: white;
    background-color: #ff5151;
    border-radius: 5px;
    width: 30%;
    margin: 5px;
    padding: 5px;
    text-align: center;
    height: 35px;
    cursor: pointer;
}

.priceSettingdelete {
    border: 1px solid #d92f2f;
    color: white;
    background-color: #d92f2f;
    border-radius: 10px;
    width: 80px;
    text-align: center;
    padding: 4px;
    margin: 10px;
    cursor: pointer;
}

.priceSettingEditdeleted {
    border: 1px solid #d92f2f;
    color: white;
    background-color: #d92f2f;
    border-radius: 10px;
    width: 80px;
    text-align: center;
    padding: 4px;
    margin: 10px;
    cursor: pointer;
}

.profilepricing {
    .profilepricingcnt {
        padding: 20px;
    }

    .pricelistingcard {
        border: 1px solid rgb(172, 172, 172);
        border-radius: 10px;
        height: 70px;

        &:hover {
            transition: all 0.2s ease-out;
            box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
            top: -4px;
            border: 1px solid #cccccc;
        }

        .pricetitle {
            line-height: 60px;
            padding-left: 30px;
            height: 69px;

            p {
                font-size: 20px;
                font-family: @font--poppins-500;
            }
        }

        .nodatapricetitle {
            line-height: 60px;
            padding-left: 30px;
            height: 69px;

            p {
                font-size: 20px;
                font-family: @font--poppins-500;
            }
        }

        .settingdetails {
            line-height: 60px;
            height: 69px;

            .dotbox {
                line-height: 60px;
                height: 69px;

                .dot {
                    height: 10px;
                    width: 10px;
                    border-radius: 10px;
                    background-color: #1b1c1e;
                }
            }

            .list {
                height: 69px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                p {
                    height: 70px;
                    font-size: 18px;
                    font-family: @font--poppins-500;

                    span {
                        font-size: 18px;
                        font-family: @font--poppins-300;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .profilepricing {
        .profilepricingcnt {
            padding: 0px;
        }

        .pricelistingcard {
            border: 1px solid rgb(70, 70, 70);
            border-radius: 10px;
            height: 70px;

            .pricetitle {
                line-height: 60px;
                padding-left: 30px;
                height: 69px;

                p {
                    font-size: 20px;
                    font-family: @font--poppins-500;
                }
            }

            .nodatapricetitle {
                line-height: 60px;
                padding-left: 30px;
                height: 69px;

                p {
                    font-size: 14px;
                    font-family: @font--poppins-500;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .settingdetails {
                line-height: 60px;
                height: 69px;

                .dotbox {
                    line-height: 60px;
                    height: 69px;

                    .dot {
                        height: 10px;
                        width: 10px;
                        border-radius: 10px;
                        background-color: #1b1c1e;
                    }
                }

                .list {
                    height: 69px;
                    display: none;

                    p {
                        height: 70px;
                        font-size: 18px;
                        font-family: @font--poppins-500;

                        span {
                            font-size: 18px;
                            font-family: @font--poppins-300;
                        }
                    }
                }
            }
        }
    }
}

.profilepackagebtncnt {
    display: flex;
    justify-content: center;
    margin-bottom: 200px;

    .profilepackagebtn {
        height: 50px;
        border: 1px solid #3197ee;
        color: #1b1c1e;
        border-radius: 10px;
        padding: 10px;
        padding-left: 50px;
        padding-right: 50px;
        font-size: 18px;
        font-family: @font--poppins-500;
    }
}

@media only screen and (max-width: 768px) {
    .profilepackagebtncnt {
        display: flex;
        justify-content: center; 
        margin-bottom: 200px;

        .profilepackagebtn {
            height: 50px;
            border: 1px solid #3197ee;
            color: #1b1c1e;
            border-radius: 10px;
            padding: 10px;
            padding-left: 20px;
            padding-right: 20px;
            font-size: 18px;
            font-family: @font--poppins-500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.collapse {
    border: 1px solid #cccccc;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

// Create Profile Courses and activities section
.onboarding-course-section {
    margin-top: 20px;
    
    .addadvisorymaincnt {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 20px;

        .new_add_course_Modal_card {
            width: 270px;
            border: 1px solid #e6e6e6;
            margin: 15px 5px;
            border-radius: 10px;
            padding: 15px 5px;
            &:hover {
                transition: all 0.2s ease-out;
                box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
                top: -4px;
                border: 1px solid #cccccc;
                background-color: white;
            }
            .toplayer{
                display: flex;
                margin-bottom: 5px;

                .coursetitlecnt{
                    padding-left: 10px;
                    padding-top: 10px;
                    width: 220px;
                    text-overflow: ellipsis;
                    
                    .coursetitle {
                        text-overflow: ellipsis;
                        font-size: 18px;
                        font-family: @font--poppins-500;
                        overflow: hidden;
                        -webkit-box-orient: vertical;
                        width: 190px;
                        height: 30px;
                        margin: 0;
                        -webkit-line-clamp: 1;
                        display: -webkit-box;
                    }
                }
            }
            .cardgrid__dot {
                .btn-primary {
                    background-color: #ffffff00 !important;
                    border-color: #ffffff00 !important;
                }
                .btn-primary:hover {
                    background-color: #ffffff00 !important;
                    border-color: #ffffff00 !important;
                }
                .primary.dropdown-toggle {
                    background-color: #dfdfdf00 !important;
                    border-color: #dfdfdf00 !important;
                }
                .btn-primary.dropdown-toggle:focus {
                    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0) !important;
                }
            }
            .dropdown-item:hover {
                background-color: #dfdfdf;
            }
            .dropdown-item {
                color: @color--primary !important;
                font-size: 12px;
                font-family: @font--poppins-regular;
                padding-top: 4px !important;
            }
            .dropdown-menu {
                border: 0.5px solid #DFDFDF;
                border-radius: 8px;
            }
            
            .cardmid {
                padding: 5px;

                .coursetitlecnt {
                    margin-bottom: 20px;

                    .coursetitle {
                        font-size: 18px;
                        font-family: @font--poppins-500;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        min-height: 60px;
                    }
                }

                .coursesubtitlecnt {
                    margin-bottom: 5px;

                    .label {
                        min-width: 75px;

                        .coursesubtitleLabel {
                            font-size: 15px;
                            font-family: @font--poppins-600;
                            color: @color--black;
                        }
                    }
                    .value{
                        .coursesubtitle {
                            font-size: 14px;
                            font-family: @font--poppins-600;
                            color: @color--secondary;
                            white-space: normal;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            word-break: break-word;
                            -webkit-box-orient: vertical;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                        }
                    }
                }
                .coursedescriptioncnt {
                    margin-bottom: 5px;

                    .coursedesc {
                        font-size: 14px;
                        font-family: @font--poppins-500;
                        color: @color--secondary;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        word-break: break-word;
                    }
                }
            }
    
        }
    }

    .addcoursecards {
        height: 100px;
        padding: 25px 15px !important;
        border-radius: 15px;
        border: 1px solid #E4E4E4;
        background-color: #fff;

        .addcoursecardscnt {
            .title {
                font-size: 15px;
                font-weight: 500;
                line-height: 24px;
            }

            .descp {
                font-family: @font--poppins-regular;
                font-size: 13px;
                font-weight: 400;
                color: #495057;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .onboarding-course-section {
        .addadvisorymaincnt {
            .new_add_course_Modal_card {
                width: 260px;

                .coursetitlecnt{
                    width: 190px;

                    .coursetitle{
                        min-width: 190px;
                    }
                }
            }
        }
    }
}

.modelFlex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (min-device-width: 320px) {
        justify-content: center;
    }
}

.modelFlexs {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;

    @media screen and (min-device-width: 320px) {
        justify-content: start;
    }
}

.cardHeaderIcon {
    display: flex;

    &__group {
        display: flex;
        justify-content: flex-end;
    }

    &__editIcon {
        margin: 2px;
    }
}

.cardHeaderIcon>div:last-child:nth-child(2) {
    margin: 0 auto 0 2%;
}

.carditemLeft {
    font-family: @font--poppins-500;
    font-weight: 400;
}

.carditemRight {
    font-family: @font--poppins-500;
    color: #5c5c5c;
}

.carditemHearder {
    font-family: @font--poppins-500;
}

.mySelect__placeholder {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.generalcntsub {
    width: 400px;

    @media screen and (max-device-width: 470px) {
        width: 200px;
    }
}

.generalcnt1 {
    width: 500px;
}

.generalcnt1 {
    .advisoryBtn,
    .advisoryBtn:focus {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        user-select: none;
        cursor: pointer;
        background: #1C84EE;
        border-radius: 10px;
        color: #fff;
        width: 140px;
        line-height: 36px;
        margin: 0 8px 0 auto;
        text-align: center;
        border: 2px solid #1C84EE;
        & svg {
                fill: black !important;
            }
    }

    .advisoryBtn:hover {
        color: #000;
        cursor: pointer;
        background: #fff;
        border: 1px solid #1C84EE;
       
    }
          .advisoryBtn:hover .adv-svg path {
              fill: black !important;
          }
         
}

.profile {
    &__form {
        width: 414px;
        margin: 0 auto;
        padding-top: 30px;
        padding-bottom: 90px;

        &__title {
            font-size: 48px;
            line-height: 64px;
            color: @color--cta--underline;
            margin-bottom: 24px;
            font-family: @font--poppins-500;
        }

        &__sub-title {
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.15px;
            font-family: @font--poppins-500;
            color: @color--black;
        }

        &__smalltext {
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.15px;
            font-family: @font--poppins-500;
            color: @color--gray;
        }
    }

    .textboxarea {
        height: 80px;
    }

    .dragdropcnt {
        display: flex;
        justify-content: center;
    }

    .dropzone1 {
        display: flex;
        justify-content: center;
        margin-right: 45px;
        margin-left: 45px;
    }

    .dropzoneinside {
        border: 1px dashed rgba(0, 0, 0, 0.1);
        margin-top: 20px;
        border-radius: 15px;
        padding: 10px;
        background-color: #f9f9f9;

        .dropzoneinsidetop {
            display: flex;
            justify-content: center;
            
        }

        .dropzoneinsidemid {
            display: flex;
            justify-content: center;
            margin-top: 10px;
            color: #495057;
        }

        .dropzoneinsidebtm {
            display: flex;
            justify-content: center;

            p {
                margin-left: 5px;
                font-weight: 700;
                font-size: 16px;
                color: #495057;
            }
        }
    }

    .dropzonebtm {
        font-weight: 400;
        font-size: 14px;
        color: #919293;
    }

    .dopZone-FileList {
        padding: 0px;

        span {
            cursor: pointer;
        }
    }

    .thumb {
        display: inline-block;
        border-radius: 50%;
        border: 1px solid #eaeaea;
        margin-bottom: 8px;
        width: 100%;
        height: 100%;
        padding: 4px;
        box-sizing: border-box;
    }

    .thumbInner {
        display: flex;
        min-width: 0px;
        min-height: 0px;
        overflow: hidden;
        width: 100%;
        height: 100%;
    }

    .thumbImg {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover !important;
    }
}

@media screen and (max-width: 768px) {
    .profile {
        &__form {
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;

            &__title {
                font-size: 24px;
            }
        }
    }

    .childprofile {
        &__form {
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;

            &__title {
                font-size: 24px;
            }
        }
    }
}

.modal-footer {
    .modalBtn,
    .modalBtn:focus {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        user-select: none;
        cursor: pointer;
        background: #1C84EE;
        border-radius: 10px;
        color: #fff;
        width: 160px;
        line-height: 36px;
        margin: 0 8px 0 auto;
        text-align: center;
        border: 2px solid #1C84EE;
    }

    .modalBtn:hover {
        color: #000;
        cursor: pointer;
        background: #fff;
        border: 2px solid #1C84EE;
    }
}


.packagebtncnt,
.packagebtncnt:focus{
    display: flex;
    justify-content: center;
    margin-bottom: 200px;
    user-select: none;
    font-family: @font--poppins-500;
    cursor: pointer;
    background: #1C84EE;
    border-radius: 10px;
    color: #fff;
    width: 100%;
    line-height: 36px;
    text-align: center;
    border: 1px solid #1C84EE;
    .packagebtn:hover {
        color: #000;
        width: 100%;
        font-family: @font--poppins-500;
        border-radius: 10px;
            cursor: pointer;
            background: #fff;
            border: 1px solid #1C84EE;
    }
}

@media screen and (max-width: 768px) {
    .packagebtncnt {
        display: flex;
        justify-content: center;
        margin-bottom: 200px;
        background: #1C84EE;
        border-radius: 10px;
        color: #fff;
        width: 100%;
        line-height: 36px;
        text-align: center;
        border: 1px solid #1C84EE;

        .packagebtn:hover {
            color: #000;
            font-family: @font--poppins-500;
            border-radius: 10px;
            cursor: pointer;
            background: #fff;
            border: 1px solid #1C84EE;
        }
    }
}

.childprofile {
    &__form {
        width: 414px;
        margin: 0 auto;
        padding-top: 0px;
        padding-bottom: 100px;

        &__title {
            font-size: 48px;
            line-height: 64px;
            color: @color--cta--underline;
            margin-bottom: 24px;
            font-family: @font--poppins-500;
        }

        &__sub-title {
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.15px;
            font-family: @font--poppins-500;
            color: @color--black;
        }

        &__smalltext {
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.15px;
            font-family: @font--poppins-500;
            color: @color--gray;
        }
    }

    .textboxarea {
        height: 80px;
    }

    .dropzoneinside {
        border: 1px dashed rgba(0, 0, 0, 0.1);
        margin-top: 20px;
        border-radius: 15px;
        padding: 10px;
        background-color: #f9f9f9;

        .dropzoneinsidetop {
            display: flex;
            justify-content: center;
        }

        .dropzoneinsidemid {
            display: flex;
            justify-content: center;
            margin-top: 10px;
            color: #495057;
        }

        .dropzoneinsidebtm {
            display: flex;
            justify-content: center;

            p {
                margin-left: 5px;
                font-weight: 700;
                font-size: 16px;
                color: #495057;
            }
        }
    }

    .dropzonebtm {
        font-weight: 400;
        font-size: 14px;
        color: #919293;
    }
}

.none {
    display: none;
}

.advisorycourse {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    display: none;
}

.OngoingCourse-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    .advisoryBtn,
    .advisoryBtn:focus {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        user-select: none;
        cursor: pointer;
        background: #1C84EE;
        border-radius: 10px;
        color: #fff;
        width: 140px;
        line-height: 36px;
        margin: 0 8px 0 auto;
        text-align: center;
        border: 2px solid #1C84EE;
    }

    .advisoryBtn:hover {
        color: #000;
        cursor: pointer;
        background: #fff;
        border: 1px solid #1C84EE;
    }
    
}

@media screen and (max-width: 285px) {
    .OngoingCourse-title {
        .advisoryBtn {
            width: 100%;
            margin: 0;
        }
    }
}

#black-icon {
    fill: #fff;
}

.OngoingCourse {
    display: none;

    &__wrapper {
        display: flex;
        justify-content: space-evenly;

        &__input {
            width: 45%;
        }
    }
}

.ql-toolbar.ql-snow {
    border: none !important;
    border-bottom: 1px solid #eaeaea !important;
    margin: 5px;
}

.quill.textarea--primary.textbox--rounded.input {
    padding: 0 !important;
    min-height: 150px !important;
    word-break: break-all;
}

.ql-container.ql-snow {
    border: none !important;
}

.subtext {
    font-size: 12px;
    color: rgba(27, 28, 30, 0.5) !important;
}

.circle {
    width: 25px;
    height: 25px;
    line-height: 25px;
    border-radius: 50%;
    font-size: 15px;
    color: #fff;
    text-align: center;
    background: #1c84ee;
    cursor: pointer;
}

.ql-link {
    display: none !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 13px;
    border: 0.2px solid rgba(0, 0, 0, 0.1) !important;
}

.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 13px;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.1);
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 11px;
    height: 51px;
}

.css-1bn53lx {
    border-radius: 11px !important;
    height: 51px !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 13px !important;
    font-family: @font--poppins-500 !important;
}

.go3450369076 {
    width: 100% !important;
}

.padding10 {
    padding-left: 10px;
}

.accordion .accordion-button {
    background-color: #fff;
    color: #5c5c5c;
}

.accordion .accordion-button:not(.collapsed) {
    color: #040506 !important;
    background-color: #ededed;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

@media only screen and (max-width: 768px) {
    .childtitle {
        font-size: 20px;
        line-height: 60px;
        color: @color--cta--underline;
        margin-bottom: 14px;
        font-family: @font--poppins-500;
    }

    .listofOngoingCourses {
        margin-bottom: 25px;
    }

    .tooltip {
        width: 550px !important;
    }
}

.input-floating-label.disabled {
    background-color: hsl(0, 0%, 95%);
}

.priceSettingEdit {
    border: 1px solid #1c84ee;
    color: white;
    background-color: #1c84ee;
    border-radius: 5px;
    width: 30%;
    text-align: center;
    margin: 5px;
    height: 35px;
    padding: 5px;
    cursor: pointer;
}

.priceSettingClose {
    border: 1px solid #ff5151;
    color: white;
    background-color: #ff5151;
    border-radius: 5px;
    width: 30%;
    margin: 5px;
    padding: 5px;
    text-align: center;
    height: 35px;
    cursor: pointer;
}

.priceSettingdelete {
    border: 1px solid #d92f2f;
    color: white;
    background-color: #d92f2f;
    border-radius: 10px;
    width: 80px;
    text-align: center;
    padding: 4px;
    margin: 10px;
    cursor: pointer;
}

.priceSettingEditdeleted {
    border: 1px solid #d92f2f;
    color: white;
    background-color: #d92f2f;
    border-radius: 10px;
    width: 80px;
    text-align: center;
    padding: 4px;
    margin: 10px;
    cursor: pointer;
}

.profilepricing {
    .profilepricingcnt {
        padding: 20px;
    }

    .pricelistingcard {
        border: 1px solid rgb(172, 172, 172);
        border-radius: 10px;
        height: 70px;

        &:hover {
            transition: all 0.2s ease-out;
            box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
            top: -4px;
            border: 1px solid #cccccc;
        }

        .pricetitle {
            line-height: 60px;
            padding-left: 30px;
            height: 69px;

            p {
                font-size: 20px;
                font-family: @font--poppins-500;
            }
        }

        .nodatapricetitle {
            line-height: 60px;
            padding-left: 30px;
            height: 69px;

            p {
                font-size: 20px;
                font-family: @font--poppins-500;
            }
        }

        .settingdetails {
            line-height: 60px;
            height: 69px;

            .dotbox {
                line-height: 60px;
                height: 69px;

                .dot {
                    height: 10px;
                    width: 10px;
                    border-radius: 10px;
                    background-color: #1b1c1e;
                }
            }

            .list {
                height: 69px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                p {
                    height: 70px;
                    font-size: 18px;
                    font-family: @font--poppins-500;

                    span {
                        font-size: 18px;
                        font-family: @font--poppins-300;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .profilepricing {
        .profilepricingcnt {
            padding: 0px;
        }

        .pricelistingcard {
            border: 1px solid rgb(70, 70, 70);
            border-radius: 10px;
            height: 70px;

            .pricetitle {
                line-height: 60px;
                padding-left: 30px;
                height: 69px;

                p {
                    font-size: 20px;
                    font-family: @font--poppins-500;
                }
            }

            .nodatapricetitle {
                line-height: 60px;
                padding-left: 30px;
                height: 69px;

                p {
                    font-size: 14px;
                    font-family: @font--poppins-500;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .settingdetails {
                line-height: 60px;
                height: 69px;

                .dotbox {
                    line-height: 60px;
                    height: 69px;

                    .dot {
                        height: 10px;
                        width: 10px;
                        border-radius: 10px;
                        background-color: #1b1c1e;
                    }
                }

                .list {
                    height: 69px;
                    display: none;

                    p {
                        height: 70px;
                        font-size: 18px;
                        font-family: @font--poppins-500;

                        span {
                            font-size: 18px;
                            font-family: @font--poppins-300;
                        }
                    }
                }
            }
        }
    }
}

.profilepackagebtncnt {
    display: flex;
    justify-content: center;
    margin-bottom: 200px;

    .profilepackagebtn {
        height: 50px;
        border: 1px solid #3197ee;
        color: #1b1c1e;
        border-radius: 10px;
        padding: 10px;
        padding-left: 50px;
        padding-right: 50px;
        font-size: 18px;
        font-family: @font--poppins-500;
    }
}

@media only screen and (max-width: 768px) {
    .profilepackagebtncnt {
        display: flex;
        justify-content: center;
        margin-bottom: 200px;

        .profilepackagebtn {
            height: 50px;
            border: 1px solid #3197ee;
            color: #1b1c1e;
            border-radius: 10px;
            padding: 10px;
            padding-left: 20px;
            padding-right: 20px;
            font-size: 18px;
            font-family: @font--poppins-500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.collapse {
    border: 1px solid #cccccc;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

// Create Profile Courses and activities section

.modelFlex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (min-device-width: 320px) {
        justify-content: center;
    }
}

.modelFlexs {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;

    @media screen and (min-device-width: 320px) {
        justify-content: start;
    }
}

.cardHeaderIcon {
    display: flex;

    &__group {
        display: flex;
        justify-content: flex-end;
    }

    &__editIcon {
        margin: 2px;
    }
}

.cardHeaderIcon>div:last-child:nth-child(2) {
    margin: 0 auto 0 2%;
}

.carditemLeft {
    font-family: @font--poppins-500;
    font-weight: 400;
}

.carditemRight {
    font-family: @font--poppins-500;
    color: #5c5c5c;
}

.carditemHearder {
    font-family: @font--poppins-500;
}

.mySelect__placeholder {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.generalcntsub {
    width: 400px;

    @media screen and (max-device-width: 470px) {
        width: 200px;
    }
}

.generalcnt1 {
    width: 500px;
}

.generalcnt1 {
    .advisoryBtn,
    .advisoryBtn:focus {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        user-select: none;
        cursor: pointer;
        background: #1C84EE;
        border-radius: 10px;
        color: #fff;
        width: 140px;
        line-height: 36px;
        margin: 0 8px 0 auto;
        text-align: center;
        border: 2px solid #1C84EE;
        & svg {
                fill: black !important;
            }
    }

    .advisoryBtn:hover {
        color: #000;
        cursor: pointer;
        background: #fff;
        border: 1px solid #1C84EE;
       
    }
          .advisoryBtn:hover .adv-svg path {
              fill: black !important;
          }
         
}