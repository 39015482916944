@import "variables.less";

.center-warp {
    margin-left: 100px;
    margin-right: 380px;
    padding: 50px;
    background-color: #f4f5f8;
    height: 100%;
}

//advisor
.Advisor {
    &__dashboard-top-text {
        h3 {
            font-size: 20px;
            font-family: @font--poppins-500;
            color: @color--primary;
        }

        &__sub-text {
            font-size: 14px;
            font-family: @font--poppins-regular;
            color: #5a5a5a;
        }
    }

    &__sub-sub-text {
        font-size: 18px;
        font-family: @font--poppins-600;
        color: @color--primary;
    }
}

.adv-dash-line {
    .vertic-line {
        border-bottom: 1px solid#ececec;
        margin-top: 17px;
        margin-bottom: 15px;
    }
}

//advisor Transactions list
.Trans-list {
    .sub-heading {
        font-size: 14px;
        font-family: @font--poppins-regular;
        color: #5a5a5a;
        margin-top: 10px;
        line-height: 25px;
    }

    .adv-trans-list {
        background: #fafafa;
        border-radius: 8px;
        padding: 20px;

        &__heading {
            font-size: 18px;
            font-family: @font--poppins-600;
            color: #1a1d1f;
            margin-top: 8px;
        }

        .table-section {
            margin-top: 20px;
            color: #fafafa !important;
            tr:hover {
                background-color: #f4f5f8;
            }
            .avisor-name {
                font-size: 14px;
                font-family: @font--poppins-500;
                color: #1b1c1e;
                margin-top: 16px;
            }
            .avisor-image {
                margin-top: 10px;
            }
            .avisor-rate {
                font-size: 16px;
                font-family: @font--poppins-600;
                color: #1b1c1e;
                margin-top: 15px;
            }

            .avisor-from-date {
                font-size: 13px;
                font-family: @font--poppins-regular;
                color: #8d8d8e;
                margin-top: 16px;
            }

            .avisor-to-date {
                font-size: 13px;
                font-family: @font--poppins-regular;
                color: #8d8d8e;
                margin-top: 16px;
            }
            .avisor-button{
                margin-top: 10px; 
            }
            .tag{
                margin-top: 7px;    
            }
        }
    }
}

//button
.icon-button {
    &__text {
        font-size: 13px;
        border-radius: 8px;
        display: block;
        text-align: center;
        padding: 5px 12px;
        float: right;
        background: #dfdfdf;
        width: 101px;

        span {
            color: #34c38f;
            font-family: @font--poppins-regular;
        }
    }

    &__red-text {
        font-size: 13px;
        border-radius: 8px;
        display: block;
        text-align: center;
        padding: 5px 12px;
        float: right;
        background: #dfdfdf;
        width: 101px;
        font-family: @font--poppins-regular;
        color: #fa5f1c;
    }
}

.bg-color-lgt {
    background-color: "#fafafa";
}
.advisor-dash-cards {
    height: 410px;
    overflow-y: scroll;
}
//media section

@media (max-width: 768px) {
    .main-wraps {
        padding: 10px;
    }
}

@media (max-width: 768px) {
    .Advisor {
        &__dashboard-top-text {
            h3 {
                font-size: 16px;
                margin-bottom: 0px;
                margin-top: 2px;
            }

            &__sub-text {
                span {
                    font-size: 12px;
                }
            }
        }
    }
    .adv-dash-line {
        .vertic-line {
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }
}

@media (min-width: 320px) and (max-width: 374px) {
    .Trans-list {
        .adv-trans-list {
            &__trans-select {
                width: 126px;
            }
            &__heading {
                font-size: 14px;
                margin-top: 11px;
            }
        }
    }
}
@media (min-width: 375px) and (max-width: 384px) {
    .Trans-list {
        .adv-trans-list {
            &__heading {
                font-size: 17px;
                margin-top: 11px;
            }
        }
    }
}
