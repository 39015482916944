@import "../../../../../assets/css/variables.less";

.recommendedAdvisorCard {
    display: flex;
    flex-direction: column;
    border: 2px solid #E3E3E3;
    border-radius: 13px;
    padding: 22px 16px;
    width: 320px;
    min-height: 485px;
    background-color: white;

    &__topSec {
        display: flex;
        gap: 20px;

        &__userIcon {
            border: 1px solid grey !important;
            border-radius: 6px !important;
            font-size: 22px !important;
            width: 50px !important;
            height: 50px !important
        }

        &__avatarImage {
            border: 1px solid grey !important;
            width: 50px !important;
            height: 50px !important;
            border-radius: 6px;
            object-fit: cover;
        }

        &__userInfo {
            &__primaryText {
                color: #1B1C1E;
                font-size: 16px;
                margin-bottom: 4px;
                font-family: @font--poppins-500;
                // word-break: break-all;
                max-width: 120px !important;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            &__secondaryText {
                color: #1B1C1E;
                font-size: 13px;
                margin-bottom: 4px;
                font-family: @font--poppins-regular;
            }
        }

        .bookmark {
            & svg {
                height: unset !important;
            }
        }

    }

    &__midSec {
        display: flex;
        flex-direction: column;

        &__label {
            color: #1B1C1E;
            font-size: 16px;
            margin-bottom: 5px;
            font-family: @font--poppins-500;
        }

        &__educationSec {
            // display: flex;
            // gap: 10px;
            // min-height: 50px;
            // margin-bottom: 20px;

            &__schoolIcon {
                font-size: 12px !important;
                width: 20px !important;
                height: 20px !important;
                color: #404144;
                margin-top: 2px;

            }

            &__schoolName {
                color: #1B1C1E;
                font-size: 15px;
                margin-bottom: 5px;
                font-family: @font--poppins-regular;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .advisor-subText {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }

        &__goalsSec {
            display: flex;
            flex-direction: column;
            background-color: #F4F5F8;
            border-radius: 14px;
            padding: 15px 12px;
            gap: 10px;

            &__targetUniversitySec {
                display: flex;
                align-items: flex-start;
                gap: 10px;

                &__starIcon {
                    color: #1C84EE;
                    width: 18px !important;
                    height: 18px !important;
                    margin-top: 3px;
                }

                &__label {
                    font-family: @font--poppins-regular;
                    font-size: 15px;
                    color: #1B1C1E;
                    margin-bottom: 0;
                }
            }
        }
    }

    &__sample {
        flex-grow: 1;
    }

    &__buttonSec {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: auto;

        &__primary-btn {
            background-color: #1C84EE;
            color: #fff;
            border: none;
            width: 85%;
            height: 42px;
            border-radius: 10px;
            font-family: @font--poppins-500;
        }

        &__danger-btn {
            color: #E64A19;
            border: 2px solid #E64A19;
            background-color: #fff;
            height: 42px;
            width: 85%;
            border-radius: 10px;
            font-family: @font--poppins-500;
        }
    }
}

.recommendedStudentCard-matchParam {
    font-family: @font--poppins-regular;
    font-size: 13px;
    margin-bottom: 8px;
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
    max-width: 320px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: 40px;
}

@media only screen and (max-width: 425px) {
    .recommendedStudentCard {
        max-width: 270px !important;

        &__buttonSec {
            &__primary-btn {
                width: 100% !important;
            }

            &__danger-btn {
                width: 100% !important;
            }
        }
    }

    .recommendedAdvisorCard {
        max-width: 270px !important;
    }
}

@media only screen and (min-width: 325px) and (max-width: 425px) {
    .recommendedAdvisorCard {
        margin-left: 10px;
    }
}

@media only screen and (max-width: 425px) {
    .recommendedAdvisorCard__topSec__userInfo__primaryText {
        max-width: 78px !important;
    }

}