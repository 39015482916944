@import "../../../../assets/css/variables.less";

.curentStatusSec{
    width: 100%;
    padding: 25px 30px;
    border: 1px solid #CDCDCD;
    border-radius: 10px;
    background-color: #fff;

    &__titleSec{
        &__formTitle{
            font-size: 18px;
            letter-spacing: 0.15px;
            font-family: 'poppins-500', sans-serif;
            line-height: 24px;
            color: #000;
        }
        &__formSubTitle{
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.15px;
            font-family: @font--poppins-500;
            color: @color--gray;
        }
    }
}

.curentStatusSec{
    .currentStatusGroup{
        .MuiGrid-item{
            padding-top: 10px !important;
        }
    }
}

@media screen and (max-width: 320px) {
    .curentStatusSec{
        padding: 25px 15px;
    }
}