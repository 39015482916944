@import "../../../assets/css/variables.less";

.extraSessionModal{
    padding: 30px 30px 30px !important;
    .alertComponent{
        top: 0px;
        position: absolute;
        left: 30%;

    }
    .confirmMsg {
        color: #000;
        font-family: @font--poppins-regular !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
    }
    &__bottomSec{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;

        &__extraSessionField{
            flex: 2;
            margin-bottom: 0;
        }
        &__button{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #1C84EE;
            color: #fff;
            border: none;
            height: 42px;
            border-radius: 10px;
            padding-inline: 20px;
            font-family: @font--poppins-500;
        }
        @media only screen and (max-width: 400px) {
            gap: 10px;
        }
    }
    @media only screen and (max-width: 400px) {
        padding: 30px 5px 10px !important;
    }
}