// @import "common.less";
// @import "variables.less";
// @import "fonts.less";
@import "../../../assets/css/variables.less";

.Advisor-MyProfile {
    display: flex;
    &__mainCnt {
        .alertComponent{
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            z-Index: 9999;
            left: -5px;
        }

        .Advisor_Profile_Sec {
            display: flex;
            align-items: center;
            gap: 10px;

            &__profileIcon{
                font-size: 30px;
            }
            .Profile_Title {
                font-family: @font--poppins-500;
                font-size: 32px;
                margin-bottom: 0;
            }
        }
        .Advisor_Profile_Sec_Mobile {
            display: none;
            position: fixed;
            z-index: 99;
        }
        .__Top_Mobile{
            display: none;
        }
        .tab_card{
            display: block;
            p{
                font-family: @font--poppins-regular;
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .Advisor-MyProfile {
        padding: 20px;

        &__mainCnt {
            // .alertComponent{
            //     transform: translateX(0%) !important ;
            //     top: 150px !important;
            //     // left: '50%';
            //     position: fixed;
            //     z-Index: 9999
                
            // }
            .Advisor_Profile_Sec {
                display: none;
            }
            .Advisor_Profile_Sec_Mobile {
                background-color: #fff;
                border-bottom: 1px solid #e3e3e3;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;
                width: 100%;
                margin-left: 25px;
                &__profileIcon{
                    font-size: 20px;
                }
                .Profile_Title {
                    font-family: @font--poppins-500;
                    font-size: 20px;
                    margin: 20px 0 20px 0 ;
                }
            }
            .__Top_Mobile{
                display: block;
                margin-top: 40px;
            }

            .tab_card{
                display: none;
            }
        }
    }
}
