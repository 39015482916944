@import '../../../../../assets/css/variables.less';

.totalRating-sec{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__rate-value{
        font-family: @font--poppins-600;
        font-size: 52px;
        line-height: 78px;
        margin-bottom: 0px;
    }
    .MuiRating-icon{
        color: #E64A19;
    }
    &__review-count{
        font-family: @font--poppins-500;
        font-size: 22px;
        font-weight: 400;
        line-height: 33px;
    }
    &__feedBack-btn{
        height: 50px;
        padding: 10px 25px;
        border: 2px solid #1B1C1E;
        border-radius: 10px;
        background-color: #fff;
        
        &__text {
            font-family: @font--poppins-500;
            color: #000;
            font-size: 16px;
            line-height: 22px;

        }
    }
}