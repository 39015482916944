@import "../../../assets/css/variables.less";

.sessionRemainingCard {
    gap: 10px;
}

.styled-card {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 10px !important;
    border: 1px solid rgba(0, 0, 0, 0.12);
    margin-bottom: 10px;
    box-shadow: none !important;
    justify-content: space-between;

}

.number-circle {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    background-color: #f44336;
    /* Red color */
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    // margin-right: 15px;
    flex-shrink: 0;
    font-family: @font--poppins-500
}

.text-container {
    font-family: @font--poppins-regular ;
    flex: 1;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.session-text {
    margin-left: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.free-text.free {
    font-weight: bold;
    color: #4caf50;
    /* Green color for "free" session */
}


.scheduleTextContainer {
    display: flex;
    flex-wrap: nowrap;
    // gap: 5px;
}




@media (max-width: 450px) {
    .scheduleDashBtn {
        min-width: fit-content !important;
    }

    .button-text-dashboard {
        display: none;
    }

    .scheduleTextContainer {
        flex-wrap: wrap;
    }
    .session-text{
        margin-left: 0px;
    }
  

}