.tag {
  &__tags-chip {
    height: 40px;
    &__tag-text-bold {
      max-width: 350px;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      word-break: break-all;
      @media only screen and (max-width: 320px) {
        max-width: 180px;
       
      }
    }

    &__tag-text-light {
      max-width: 210px;
      @media only screen and (max-width: 320px) {
        max-width: 160px;
      }
    }
  }
}
