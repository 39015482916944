
@import "../../../../assets/css/variables.less";

.advisor-personalInfo{
    gap: 10px;
    display: flex;
    justify-content: space-around;
    
    &__profileSec{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__serviceSec, &__generalSec, &__profileSec, &__passwordSec, &__copyLinkSec, &__deleteProfSec {
        padding: 25px 30px;
        border: 1px solid #CDCDCD;
        border-radius: 10px;
        background-color: #fff;
        height: 100%;
    
        .Profile_Image_Card{
            display: flex;
            justify-content: space-around;
            .User_Image {
                border-radius: 10px;
                width: 90px;
                height: 90px;
                position: relative;

                .profileimage {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                }
                .deletePic{
                    right: -10px;
                    top: -10px;
                    position: absolute;
                    cursor: pointer;
                    
                    :hover{
                        color: #E64A19;
                    }
                }
            }
            .Upload_Btn_Cnt {
                display: flex;
                padding-top: 15px;
                padding-right: 20px;
                .Upload_Btn_Cnt_Top {
                    // display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    .Upload_Btn {
                        background-color: @backcolor--white;
                        border: 2px solid @backcolor--primary;
                        border-radius: 10px;
                        width: 162px;
                        text-transform: capitalize;
                        font-size: 16px;
                        font-family: @font--poppins-regular;
                        svg {
                            fill: @color--primary;
                            width: 28px;
                            height: 28px;
                        }
                    }

                }

                .Upload_Btn_Cnt_Btm{
                    display: flex;
                    justify-content: center;
                    p{
                        font-size: 14px;
                        font-family: @font--poppins-regular;
                        color: @color--secondary;
                        margin-top: 5px;
                    }
                }

                &__deletebtn{
                    height: 40px;
                    border-radius: 6px;
                    padding: 5px 4px;
                    margin-left: 10px;
                    border: 1.6px solid #E64A19;
                    cursor: pointer;
                }
            }
        }
        .Servicecnt {
            &__servicetop {
                font-size: 15px;
                letter-spacing: 0.15px;
                font-family: 'poppins-500', sans-serif;
                line-height: 24px;
                color: #000;
            }
            &__servicemid {
                p {
                    font-family: 'poppins-500', sans-serif;
                    font-size: 14px;
        
                    span {
                        font-family: 'poppins-500', sans-serif;
                        font-size: 14px;
                    }
                }
            }
            &__serviceEnd{
                .servicebtn {
                    cursor: pointer;
                    width: 100%;
                    border: 2px solid #1C84EE;
                    border-radius: 10px;
                    display: flex;
                    justify-content: center;
                    height: 50px;
                    padding: 10px;
        
                    p {
                        font-family: 'poppins-500', sans-serif;
                        font-size: 16px;
                    }
                }
            }
        }

        .sentInviteSec{
            &__sentInviteCnt {
                margin-left: 20px;
                font-weight: 400;
                font-size: 16px;
                line-height: 38px;
                font-family:@font--poppins-500;

                span {
                    margin-right: 0px;
                    margin-left: 5px;
                }

                &__infoSvg {
                    margin-left: 5px;
                    cursor: pointer;
                }
            }
        }
        .Delete_Profile_Card_Top{
            p {
                font-size: 15px;
                line-height: 24px;
                letter-spacing: 0.15px;
                font-family: 'poppins-500', sans-serif;
                color: #000;
            }
            &__Title{
                font-size: 15px;
                line-height: 24px;
                letter-spacing: 0.15px;
                font-family: 'poppins-500', sans-serif;
                color: #000;
            }
        }

    }
}

@media screen and (max-width: 425px) {
    .advisor-personalInfo{
        &__profileSec{

            .Profile_Image_Card {
                display: block;
    
                .User_Image{
                    margin-inline: auto;
                }
                
            }

            .Upload_Btn_Cnt{
                display: flex;
                justify-content: center;
            }
        }
    }

}