@import "../../../assets/css/variables.less";

.resourceCard {
    background-color: @backcolor--white;
    border: 1px solid #e3e3e3;
    border-radius: 10px;

    .resourceDesc {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: #1B1C1E;
        font-size: 13px;
        font-family: @font--poppins-500;
    }
    .resourseUrl{
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: #1C84EE;
        font-size: 13px;
        font-family: @font--poppins-regular;
        word-break: break-all;
    }
}