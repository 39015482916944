@import "../../../assets/css/variables.less";

.parentDashboard{
    .top-sec{
        display: flex;
        justify-content: space-between;
        .star-review{
            font-family: 'poppins-500', sans-serif;
            margin-top: 10px;
            .ratingCont{
                font-family: 'poppins-regular', sans-serif;
                display: flex;
                align-items: flex-end;
                flex-direction: column;
            }
        }
    }
}

.dashboard-top-text {
    margin-top: 10px;

    h2 {
        font-family: @font--poppins-500
    }

}
.studentDashboard_Mobile{
    display: none;
}


.dash-subText {
    color: #5A5A5A;
    font-size: 16px;
    font-family: @font--poppins-regular;
}

.dashTitle {
    font-size: 16px;
    font-family: @font--poppins-500;
    margin: 0;
    flex: 1;
}

.dashSubContainer {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    margin-top: 1rem !important;
}
/* Hide SVG icons on desktop and show text */
.button-svg {
    display: none;
}

/* Show SVG icons only on mobile and tablet */
@media (max-width: 768px) {
    .button-text {
        display: none;
    }
    .button-svg {
        display: inline;
    }
}

.buttonIcon {
    display: none;
  }
  
  .buttonText {
    display: inline;
  }
  
  @media (max-width: 768px) {
    .buttonIcon {
      display: inline;
    }
  
    .buttonText {
      display: none;
    }
  }
  



.CardBody_Section{
    display: flex;
    justify-content: start;
    flex-direction: column;
    text-align: start;
    padding-left: 10px !important;
}
.CardTitle_Section{
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    margin-top: 1rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

@media (max-width: 768px) {
    .parentDashboard {
        padding: 1rem;
    }

    .dashSubContainer {
        display: block !important;
        text-align: center;
    }

    .welcomeUser {
        position: fixed;
        z-index: 99;
        top: 4px;
        left: 64px;
    }

    .dash-subText {
        margin-top: 7rem;
    }

}

@media (max-width: 768px) {
    .star-review{
        margin-top: 43px !important;
    }
        .studentDashboard_Mobile {
            display: flex;

            &_Section {
                display: flex;
                justify-content: center;
                height: 50px;
                position: fixed;
                top: 0;
                width: 100%;
                z-index: 9;
                align-items: center;
                border-bottom: 1px solid #e3e3e3;
                background-color: @backcolor--white;

                &_Title {
                    p {
                        font-size: 18px;
                        font-family: @font--poppins-500;
                        margin-bottom: 0px;
                    }
                }
            }
        }
    
        
}

.tag-container {
    display: flex;
    flex-wrap: wrap; /* Ensures the pills wrap to the next line if they overflow */
    gap: 10px; /* Adds space between the pills */
    margin: 10px 0; /* Adds space around the container */
}

.tag__tags-chip {
    display: flex;
    align-items: center;
    padding: 5px 15px;
    border-radius: 10px;
    background-color: #E3E3E3;
    cursor: pointer;
    white-space: nowrap; /* Ensures the text does not wrap inside the pill */
}

.currentStatusDash{
    margin-bottom: 1rem !important;
}

@media (max-width: 1442px) {
    .advisorActivityTitle {
        white-space: nowrap;
    }
}

@media (max-width: 1400px) {
    .advisorActivityTitle {
        white-space: unset;
    }
}

@media (max-width: 326px) {
    .advisorActivityTitle {
        white-space: nowrap;
    }
}

@media (max-width: 310px) {
    .advisorActivityTitle {
        white-space: unset;
    }
}

.zeroStateFont{
    font-family: @font--poppins-regular;
}

