@import "../../assets/css/variables.less";

.switchTitle {
  padding: 12px;
  font-size: 16px;
  color: #ffffff;
  font-family: @font--poppins-regular;
  width: 37rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

.exitText {
  float: right;
  padding: 12px;
  font-size: 16px;
  color: #ffffff;
  font-family: @font--poppins-regular;
  cursor: pointer;
}


@media only screen and (max-width: 768px) {
  .switchTitle {
    // width: 12rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }

}