@import "common.less";
@import "variables.less";
@import "fonts.less";

body {
    margin: 0px;
}
.login {
    // background-color: @backcolor--white;

    .dark& {
        background-color:@backcolor--dark;
    }
    &_title {
        color:@color--primary;
        margin-bottom: 40px;
        font-size: 48px;
        font-family: @font--poppins-500;
        .dark & {
            color:@color--white;
        }
    }

    &_desc{
        font-size: 21px;
        font-family:@font--poppins-regular;
        color: rgba(27, 28, 30, 0.75);
        opacity: 0.8;
    }
    .cookieContent{
        display: flex;
        flex: 1 0 250px;
        margin-right: 0;

    }
    @media  (max-width: 300px) {
        .cookieContent{
            max-width: 250px;
        }
    }
    &_card {
        width: 343px;
        max-width: 350px;
        padding: 20px;
        margin: 0 auto;
        &.card {
            background-color: #FFFFFF;
            margin: 0 auto;
            border-color: transparent;
            .dark& {
                background-color:@backcolor--black;
            }
        }

        &_form{
            .dark& {
                color:#fff;
            }
    
            .form-group {
                margin-top: 20px;
            }
        }
    }

    &_social-icon{
        li{
            padding: 0px 6px;
        }
    }

    &_text{
        font-size: 16px;
        font-family: @font--poppins-regular;
        color:@color--secondary;
        letter-spacing: 0.15px;
    }

    .rememberlogin{
        span{
            font-size: 14px;
            color: @color--black;
        }
    }

    .Verification_card{
        width: 100%;
    }
}


@media screen and (max-width: 768px) {
    .login {
        &_title {
            font-size: 24px;
            margin-bottom: 20px;
        }
        .list-inlibeblock{
            margin-bottom: 0px;
            li{
                svg {
                    width:35px;
                    height:35px;
                }
            }
        }
        &_card-container {
            padding: 10px 20px;
        }
        &_form{
            .form-group:first-child {
                margin-top: 0px;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .login {
        &_card{
            width: 100% !important;
        }
    }
}
.Verification_card{
    .verifybox{
        h3{
            font-family: @font--poppins-500;
            font-size: 30px;
        }
    }
    p{
        margin-top: 20px;
        font-family: @font--poppins-regular;
        font-size: 14px;
        color: @color--secondary;
    }
}

@media screen and (max-width: 320px) {
    .login {
        .grandParentContaniner {
            width: 100%;
        }
    }
    .mob-d-block {
        display: block;
    }
}