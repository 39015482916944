@import "../../../assets/css/variables.less";

.BookSessionModal {
    padding: 20px;

    &_Top {
        .Modaltitle {
            font-size: 16px;
            font-family: @font--poppins-600;
            color: @color--primary;
            margin-bottom: 0px;
        }

        .closebtn {
            cursor: pointer;
        }
    }

    &_Mid {
        margin-top: 20px;
        .BookSessionModal_Mid_Cnt {
            .BookSessionModal_Mid_Title {
                font-size: 16px;
                font-family: @font--poppins-500;
                color: @color--primary;
                margin-bottom: 30px;
                span{
                    font-size: 16px;
                font-family: @font--poppins-600;
                color: @color--primary;
                }
            }
        }
    }


    &_footer {
        display: flex;
        justify-content: flex-end;

        .BookBtn {
            border-radius: 10px;
            background-color: @backcolor--primary;
            border: none;
            text-transform: capitalize;
            box-shadow: none;
            &:hover{
                background-color: @backcolor--primary;
                box-shadow: none;
            }
        }
        .Bookclose{
            background-color: @color--gray;
            border-radius: 10px;
            border: none;
            text-transform: capitalize;
            margin-right: 20px;
            box-shadow: none;
            &:hover{
                background-color: @color--gray;
                box-shadow: none;
            }
        }
    }
}