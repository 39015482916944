@import '../../../../../assets/css/variables.less';

.review-sec{
    padding: 0 5px !important;
    max-height: 500px;
    overflow-y: scroll;
    
    .user-Review{
        display: flex; 
        padding: 20px 0;
        border-bottom: 2px solid #E9F0F5;

        &__avatar{
            width: 100px;
            height: 100px;
            border-radius: 50%;
        }

        &__reviewDetails{
            margin-left: 20px;

            .MuiRating-icon{
                color: #E64A19;
            }

            &__userName{
                font-family: @font--poppins-500;
                font-size: 22px;
                margin-bottom: 0px;
            }
            
            &__date{
                font-family: @font--poppins-500;
                margin-bottom: 5px;
                font-weight: 400;
                font-size: 16px;
            }
            
            &__review-msg{
                font-family:@font--poppins-regular;
                margin-bottom: 5px;
                font-size: 16px;
                color: #1B1C1E;
            }
        }
    }

    &__morebtn-sec{
        display: flex;
        justify-content: center;

        &__moreBtn{
            border-radius: 10px;
            padding: 10px 50px;
            border: none;
            color: #ffffff;
            background-color: #1C84EE;
            font-family: @font--poppins-500;
            font-size: 16px;
        }
    }
}