@import '../../../../../assets/css/variables.less';

.generalForm--sec{
    &__form--sec{
        &__emailResend{
            display: flex;
            gap: 10px;
            margin-bottom: 20px;

            &__text{
                font-size: 12px;
                color: #E64A19;
                font-family: @font--poppins-regular;
                margin-bottom: 0;
            }

            &__advisoryBtn{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 40px;
                user-select: none;
                cursor: pointer;
                background: #1C84EE;
                border-radius: 10px;
                color: #fff;
                width: 200px;
                line-height: 15px;
                margin: 0 8px 0 auto;
                text-align: center;
                border: 2px solid #1C84EE;
                font-size: 13px;

                &:hover{
                    color: #000;
                    cursor: pointer;
                    background: #fff;
                    border: 2px solid #1C84EE;
                }
            }
        }
        @media only screen and (max-width: 628px) {
            &__emailResend{
                flex-wrap: wrap;

                &__advisoryBtn{
                    width: 140px;
                }
            }
        }

        &__dltprofilefcnt{
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;

            &__deleprfbtn {
                border: 1px solid #1b1c1e;
                border-radius: 10px;
                cursor: pointer;
                display: flex;
                height: 50px;
                justify-content: center;
                padding: 10px 7px;
                width: 190px;
                margin-right: 20px;
                margin-top: 20px;
                & p{
                    display: flex;
                    align-items: center;
                    & span{
                        font-family: @font--poppins-500;
                    }
                }
            }
            &__profilesavebtn{
                background: @btn--primary;
                border: 2px solid #1c84ee;
                border-radius: 10px;
                color: @btn--white;
                cursor: pointer;
                font-size: 16px;
                height: 50px;
                -webkit-user-select: none;
                -ms-user-select: none;
                user-select: none;
                width: 190px;
                margin-top: 20px;
                & span{
                    font-family: @font--poppins-500;
                }
            }
            &__profilesavebtn:hover{
                background: @backcolor--white;
                border: 2px solid #1c84ee;
                color: rgb(0, 0, 0);
                cursor: pointer;
            }
            &__profilesavebtn:focus{
                background: @btn--primary;
                border: 2px solid #1c84ee;
                color: @btn--white;
                cursor: pointer;
            }
        }
    }
}