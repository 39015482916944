@import "../../../../../assets/css/variables.less";

.myWork-activityCard-unApproved {
    border: 1px solid #E64A19;
}
.myWork-activityCard-suggested {
    border: 1.5px solid #29a016
}
.myWork-activityCard{
    border: 1px solid #8A8A8A;
}
.myWork-activityCard, 
.myWork-activityCard-unApproved,
.myWork-activityCard-suggested {
    background-color: #fff;
    min-height: 270px;
    width: 290px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    &__topSec{
        padding: 7px 8px;
        background-color: #1C84EE;
        border-top-right-radius: 9px;
        border-top-left-radius: 9px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        &__titleSec{
            display: flex;
            align-items: center;
            gap: 5px;
            color: #fff;
            &__iconLeft{
                width: 16px !important;
                height: 18px !important;
            }
            &__iconRight{
                font-size: 15px !important;
                margin-bottom: 10px;
                cursor: pointer !important;
            }
            &__title{
                margin: 0;
                color: #fff;
                font-size: 14px;
                max-width: 150px;
                font-family: @font--poppins-500;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                cursor: default;
            }
        }
        &__menuSec{
            position: absolute;
            right: 15px;
        }
    }
    .cardgrid__dot{
        background-color: transparent;
        border: none;
        .dropdown-toggle{
            background-color: transparent;
            border: none;
        }
        .dropdown-toggle::after{
            display: none;
        }
    }
    &__statusSec{
        display: flex;
        justify-content: flex-end;
        margin-top: 5px;
        margin-right: 10px;
        &__status{
            border: 1.5px solid #29a016;
            padding: .1em .8em;
            border-radius: 40px;
            background-color: #E5FFE1;

            &__successText{
                margin: 0;
                font-size: 12px;
                font-family: @font--poppins-regular;
                color: #29a016;
            }
        }
    }
    &__midSec{
        padding: 15px 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        &__content{
            display: flex;
            gap: 10px;
            &__label{
                margin: 0;
                min-width: 100px;
                color: #1B1C1E;
                font-size: 14px;
                font-family: @font--poppins-500;
            }
            &__value{
                margin: 0;
                font-size: 14px;
                font-family: @font--poppins-regular;
                color: #6C6C6C;
                white-space: normal;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                cursor: pointer;
            }
        }
        &__value{
            margin: 0;
            font-size: 14px;
            font-family: @font--poppins-regular;
            color: #6C6C6C;
            white-space: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            cursor: pointer;
        }
    }
    &__ctaSec{
        margin-top: auto;
        margin-bottom: 15px;

        &__primaryBtnSec{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &__helperText{
                font-size: 10px;
                margin-bottom: 5px;
                max-width: 180px;
                font-family: @font--poppins-500;
                color: #6C6C6C;
                white-space: normal;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                cursor: pointer;
            }
            &__primaryBtn{
                display: flex;
                justify-content: center;
                align-items: center;
                padding-inline: 3em;
                background-color: #1C84EE;
                color: #fff;
                border: none;
                height: 35px;
                border-radius: 7px;
                &:hover{
                    cursor: pointer;
                }
            }
        }
    }
}