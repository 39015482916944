@import "../../../assets/css/variables.less";

.myNetwork {
    .ExploreButton {
        background-color: @backcolor--primary;
        border: 1px solid @backcolor--primary;
        text-transform: none;
        color: @color--white;
        font-size: 16px;
        font-family: @font--poppins-regular;
        border-radius: 10px;
        padding: 10px;
        height: 40px;

        &:hover {
            border: 2px solid @backcolor--primary;
            color: @backcolor--primary;
        }
    }

    .RejectButton {
        background-color: @backcolor--white;
        border: 1px solid @backcolor--red;
        text-transform: none;
        color: @backcolor--red;
        font-size: 14px;
        font-family: @font--poppins-regular;
        border-radius: 10px;
        padding: 10px;
        height: 40px;
    }

    &__Top {
        &__Mobile {
            display: none;
        }

        &__Desktop {
            display: flex;

            &__Title {
                p {
                    font-size: 40px;
                    font-family: @font--poppins-500;
                }
            }
        }

        @media only screen and (max-width: 768px) {
            &__Desktop {
                display: none;
            }

            &__Mobile {
                display: flex;
                justify-content: center;
                height: 50px;
                position: fixed;
                top: 0;
                width: 100%;
                z-index: 9;
                align-items: center;
                border-bottom: 1px solid #e3e3e3;
                background-color: @backcolor--white;

                &__Title {
                    p {
                        font-size: 20px;
                        font-family: @font--poppins-500;
                        margin-bottom: 0px !important;
                    }
                }
            }
        }
    }

    &__Mid {
        margin-top: 20px;

        &__Tabs {
            display: block;
            &__Mobile {
                display: none;
            }

            &__Desktop {
                &__Tabs {
                    // background-color: #fff;
                    // border: 1px solid #e3e3e3;
                    border-radius: 10px;
                    padding-left: 15px;
                    padding-right: 15px;
                    width: fit-content;

                    // .MuiTabs-indicator {
                    //     display: none;
                    // }

                    .Tab {
                        font-family: @font--poppins-regular;
                        font-size: 18px;
                        text-transform: none;
                    }
                }

                &__Container {
                    .MuiBox-root {
                        padding: 0px !important;
                    }

                    .Network__Content {
                        margin-top: 30px;
                        display: flex;
                        flex-wrap: wrap;
                        gap: 20px;

                    }

                    @media (max-width: 600px) {
                        .Network__Content {
                            display: flex;
                            flex-wrap: wrap;
                            gap: 20px;
                            margin-left: 10px;
                        }
                    }

                    .Empty__Container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 100px;

                        &__Card {
                            background-color: #fff;
                            border: 1px solid #e3e3e3;
                            border-radius: 10px;
                            padding: 20px;
                            cursor: pointer;
                            width: 600px;
                            text-align: center;

                            &:hover {
                                transition: all 0.2s ease-out;
                                box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
                                top: -4px;
                                border: 1px solid #cccccc;
                                background-color: @backcolor--white;
                            }

                            p {
                                font-family: @font--poppins-regular;
                                font-size: 18px;
                                margin-bottom: 0px;
                            }
                        }
                    }
                    @media only screen and (max-width: 400px) {
                        .Empty__Container {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 0px;
                        }
                    }
                }
            }

            @media only screen and (max-width: 768px) {
                &__Desktop {
                    display: none;
                }

                &__Mobile {
                    display: flex;
                    flex-wrap: wrap;
                    padding: 5px 10px;
                    margin-top: 50px;
                    gap: 20px;
                    .selectbox {
                        width: 280px;
                        max-width: 100%;
                    }
                }
            }
        }
        @media screen and (max-width: 768px) {
            &__Tabs {
                padding: 10px;
            }
        }
    }

    .RecievedRequest {
        margin-top: 30px;

        &__Title {
            p {
                font-size: 20px;
                font-family: @font--poppins-500;
            }
        }

        .Studentcard {
            .connectedStudentCard {
                &__buttonSec {
                    display: block;
                }
            }

            &__footer {
                &__bottom {
                    margin-top: 10px;
                    display: flex;
                    justify-content: space-evenly;
                    gap: 20px;
                }
            }
        }
    }

    .PillStatus {
        &__Pending {
            border-radius: 100px;
            border: 2px solid #1C84EE;
            background-color: #DEEEFF;
            width: fit-content;
            padding-left: 10px;
            padding-right: 10px;

            p {
                margin-bottom: 0px;
                font-size: 10px;
                font-family: @font--poppins-500;
                color: #1C84EE;
            }
        }

        &__Accepted {
            border-radius: 100px;
            border: 2px solid #29A016;
            background-color: #E5FFE1;
            width: fit-content;
            padding-left: 10px;
            padding-right: 10px;

            p {
                margin-bottom: 0px;
                font-size: 10px;
                font-family: @font--poppins-500;
                color: #29A016;
            }
        }

        &__Rejected {
            border-radius: 100px;
            border: 2px solid #E64A19;
            background-color: #FCE5DD;
            width: fit-content;
            padding-left: 10px;
            padding-right: 10px;

            p {
                margin-bottom: 0px;
                font-size: 10px;
                font-family: @font--poppins-500;
                color: #E64A19;
            }
        }
    }

    .advisor-card-content {
        border-radius: 10px;
        background: #F4F5F8;
        padding: 10px;
        margin-top: 10px;

        .advisor-subText {
            color: #1B1C1E;
            font-family: @font--poppins-regular;
            font-size: 14px;
            font-style: normal;
        }
    }

    .tag__tags-chip {
        // max-width: 300px;

        // &__tag-text-bold {
        //     white-space: nowrap;
        //     overflow: hidden;
        //     text-overflow: ellipsis;
        // }
    }
}