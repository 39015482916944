@import "../../../../assets/css/variables.less";

.AdvisorCard{
    background-color: @backcolor--white;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    padding: 10px;
    width: 300px;
    .profile-img{
        width: 60px;
        height: 60px;
        border-radius: 6px;
        object-fit: cover;
    }
    .advisor-country{
        color: #1B1C1E;
        font-size: 12px;
        margin-bottom: 1px;
        font-family: @font--poppins-regular;
    }
    .advisor-card-content{
        background-color: #f4f5f8;
        padding: 10px;
        border-radius: 10px;
        font-family: @font--poppins-regular;
        height: 200px;
    }
    .messagecnt{
        height: 100px;
    }
    .advisor-card-description{
        background-color: #f4f5f8;
        padding: 10px;
        border-radius: 10px;
        height: 100px;
        font-family: @font--poppins-regular;
        p{
            margin-bottom: 0px;
            text-overflow: ellipsis;
            font-family: @font--poppins-500;
            overflow: hidden;
            -webkit-box-orient: vertical;
            height: 74px;
            margin: 0;
            -webkit-line-clamp: 3;
            word-break: break-all;
            display: -webkit-box;
        }
    }
    .advisord-card-footer{
        margin-top: 15px;
        padding: 10px 30px;
        &__View{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &__accept{
            display: flex;
            justify-content: space-evenly;
            gap: 10px;
            align-items: center;
        }
    }
}