@import "../../../assets/css/variables.less";

.yourStudentSection{
    &__topSection{ 
        display: flex;
        justify-content: space-between;
        &__titleDesktop{
            display: flex;
            align-items: center;
            gap: 10px;

            &__title{
                font-family: @font--poppins-500;
                font-size: 32px;
                margin-bottom: 0;
            }
        }
        &__titleMobile{
            display: none;
            position: fixed;
            z-index: 99;
        }

        &__subTitleMobile{
            display: none;
        }

        &__ctaButton, &__ctaButton:focus{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            user-select: none;
            cursor: pointer;
            background: @btn--primary;
            border-radius: 10px;
            color: @btn--white;
            line-height: 36px;
            text-align: center;
            border: 2px solid @btn--primary;
            font-family: @font--poppins-regular;
            padding-inline: 1.5em;
            &:hover{
                cursor: pointer;    
                color: @color--black;
                background: @btn--white;
                border: 2px solid @btn--primary;
            }
        }
    }
    &__midSection{
        &__mobileTabView{
            display: none;
        }
        &__desktopTabView{
            // background-color: #FFFFFF;
            // border: 1px solid #e3e3e3;
            border-radius: 10px;
            width: 100%;

            .MuiTab-root{
                font-family: @font--poppins-regular;
                font-size: 16px;
                color: #000;
                text-transform: capitalize;
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .yourStudentSection{
        &__topSection{
            &__titleDesktop{
                display: none;
            }
            &__titleMobile{
                background-color: #fff;
                border-bottom: 1px solid #e3e3e3;
                height: 50px;
                display: flex;
                justify-content: center;
                width: 100%;
                align-items: center;

                &__title{
                    font-family: @font--poppins-500;
                    font-size: 26px;
                    margin: 0;
                }
            }
            &__subTitleMobile{
                display: block;
                margin-block-start: 60px;
                margin-inline-start: 1.5em;

                &__title{
                    font-family: @font--poppins-500;
                    font-size: 20px;
                    margin-bottom: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: break-word;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                }
            }
            &__ctaButton{
                display: none;
            }
        }
        &__midSection{
            &__mobileTabView{
                display: flex;
                flex-direction: column;
                margin-inline: 5px;
                gap: 20px;

                &__primaryBtn{
                    margin-left: auto;
                    background-color: #1C84EE;
                    border: none;
                    height: 50px;
                    border-radius: 10px;
                    padding: 10px 20px;
                    color: #fff;
                    font-family: @font--poppins-regular;
                    font-size: 16px;
                }
            }
            &__desktopTabView{
                display: none;
            }
        }
    }
}
@media screen and (max-width: 425px) {
    .yourStudentSection{
        &__topSection{
            &__titleMobile{
                &__title{
                    font-size: 20px;
                }
            }
            &__subTitleMobile{
                margin-block-start: 60px;
                margin-inline: .5em;
            }
        }
        &__midSection{
            .myWorkContentSec{
                padding-inline: 5px;
                .myWork-studentPlanSec{
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}