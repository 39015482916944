@import "../../../assets/css/variables.less";

.footers{
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    padding-bottom: 20px;
    padding-top: 20px;
    background-color: #fff;
    display: flex !important;
    justify-content: space-evenly;
    align-items: center;
    z-index: 2;

    &__leftSec, &__midSec, &__rightSec{
        display: flex;
        flex: 1;
        justify-content: center;
        vertical-align: middle;
        align-items: center;
    }
    &__leftSec{
        a, a:hover{
            color: #000;
        }

        &__cta{
            display: flex;
            align-items: center;
            gap: 6px;
            margin-bottom: 0;
        }
    }
    &__midSec{
        &__cta{
            margin-bottom: 0;
            text-align: center;
            overflow-wrap: break-word;

        }
    }

    &__rightSec{
        display: flex;
        gap: 8px;
        &__SkipButton{

        }

        &__cta{
            display: flex !important;
            justify-content: center;
            align-items: center;
            background: #1c84ee;
            border: 2px solid #1c84ee;
            border-radius: 10px;
            padding: 10px 25px;
 		    -webkit-user-select: none;
            user-select: none;
            color: #fff;
            cursor: pointer;
            gap: 8px;
		    // position: fixed;
            // right: 20px;

	        z-index: 999;
            svg{
                .rolestrokes{
                    stroke: #ffffff;
                }
                .rolefills{
                    fill: #fff;
                }
            }

            &:hover {
                color: #000;
                cursor: pointer;
                background: #fff;
                border: 2px solid @btn--primary;

                svg{
                    .rolestrokes{
                        stroke: #000;
                    }
                    .rolefills{
                        fill: #000;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 947px) {
    .footers{
        // padding: 10px 5px;
        border-top: 1px solid #ddd;
        z-index: 999;

        &__rightSec{
            gap: 2px;
            margin-top: 10px;
            &__cta{
                // padding: 5px 10px;
                // font-size: 12px;
                gap: unset;
                position: fixed;
                right: 20px;
                svg{
                    width: 16px;
                    height: 16px;    
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .footers{
        // padding: 10px 5px;
        border-top: 1px solid #ddd;
        z-index: 999;

        &__rightSec{
            gap: 2px;
            margin-top: 10px;
            &__cta{
                // padding: 5px 10px;
                // font-size: 12px;
                gap: unset;
                position: fixed;
                right: 20px;
                svg{
                    width: 16px;
                    height: 16px;    
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .footers{
        &__rightSec{
            font-size: 12px;
            &__cta{
                padding: 10px;
                position: fixed;
                right: 20px;
                svg{
                    width: 20px;
                    height: 20px;    
                }
            }
        }
    }
}