@import "../../../../assets/css/variables.less";

.milestone_Page {
    &_Top {
        &_Desktop {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &_Title {
                p {
                    font-size: 22px;
                    font-family: @font--poppins-500;
                    margin-bottom: 0px;

                    span {
                        margin-right: 20px;
                    }
                }
            }

            &_Btncnt {
                &_PlanBtn {
                    background-color: @backcolor--primary;
                    font-family: @font--poppins-regular;
                    border-radius: 10px;
                }
            }
        }

        &_Mobile {
            display: none;
        }

        @media screen and (max-width: 768px) {
            &_Desktop {
                display: none;
            }

            &_Mobile {
                display: flex;

                &__Section {
                    display: flex;
                    justify-content: center;
                    height: 50px;
                    position: fixed;
                    top: 0;
                    width: 100%;
                    z-index: 9;
                    align-items: center;
                    border-bottom: 1px solid #e3e3e3;
                    background-color: @backcolor--white;

                    &_Title {
                        p {
                            font-size: 18px;
                            font-family: @font--poppins-500;
                            margin-bottom: 0px;
                        }
                    }
                }

                &_Btncnt {
                    display: flex;
                    justify-content: end;
                    align-items: center;
                    margin-top: 60px;
                    width: 100%;
                    margin-right: 10px;

                    &_PlanBtn {
                        background-color: @backcolor--primary;
                        font-family: @font--poppins-regular;
                        border-radius: 10px;
                    }
                }
            }
        }
    }

    &_Mid {
        margin-top: 20px;

        &_Mobile {
            display: none;
        }

        &_Desktop {
            display: flex;

            &_TabCnt {
                display: flex;
                gap: 20px;
                align-items: center;
                background-color: #FFFFFF;
                border: 1px solid #e3e3e3;
                border-radius: 10px;
                width: 100%;
                padding-left: 20px;
                padding-right: 20px;
                height: 50px;

                .Tab {
                    padding: 0px;
                    padding-left: 30px;
                    padding-right: 30px;
                    height: 0px;
                    min-height: 49px;
                    font-family: @font--poppins-regular;
                    font-size: 18px;
                    text-transform: none;

                    &:hover {
                        color: @backcolor--primary;
                    }
                }
            }
        }

        @media screen and (max-width: 768px) {
            &_Desktop {
                display: none;
            }

            &_Mobile {
                display: flex;
                flex-direction: column;
                gap: 20px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        &_Mid {
            margin-top: 20px;
            padding: 0px 10px;
        }
    }

    .MileStoneEmptyPage {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 60vh;

        &__Card {
            background-color: #ffffff;
            border: 1px solid #e3e3e3;
            border-radius: 10px;
            padding: 20px;

            &__Titlecnt {
                font-size: 16px;
                font-family: @font--poppins-500;
                margin-bottom: 0px;
                text-align: center;
                margin-bottom: 10px;
            }

            &__btncnt {
                font-size: 16px;
                margin-bottom: 0px;
                font-family: @font--poppins-regular;
                text-align: center;
                color: #1C84EE;
                cursor: pointer;
            }
        }
    }

    .detail__section {
        &__leftcnt {
            background-color: #ffffff;
            border: 1px solid #e3e3e3;
            border-radius: 10px;
            padding: 15px;
            height: 700px;
            min-height: 700px;
            max-height: 700px;
            margin-bottom: 20px;
            overflow: hidden;
            &__sectioncnt {
                &__top {
                    margin-top: 10px;

                    &__txt {
                        font-size: 18px;
                        font-family: @font--poppins-500;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        word-break: break-all;
                    }

                    &__subtxt {
                        font-size: 16px;
                        font-family: @font--poppins-regular;
                        color: #5C5C5C;
                    }
                }

                &__mid {
                    margin-top: 10px;

                    &__title {
                        font-size: 18px;
                        font-family: @font--poppins-500;
                    }

                    &__decriptiontxt {
                        font-size: 16px;
                        font-family: @font--poppins-regular;
                        color: #5C5C5C;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 6;
                        -webkit-box-orient: vertical;
                        word-break: break-all;
                    }
                }

                &__btm {
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                    gap: 30px;

                    &__title {
                        font-size: 18px;
                        font-family: @font--poppins-500;
                        margin-bottom: 0px;
                    }
                }

                &__btmresource {
                    margin-top: 20px;
                    height: 455px;
                    overflow-y: scroll;

                    &__list {
                        display: flex;
                        flex-direction: column;
                        gap: 20px;

                        &__Emptycnt {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 20px;

                            &__card {
                                background-color: #ffffff;
                                border: 1px solid #e3e3e3;
                                border-radius: 10px;
                                padding: 10px;
                                height: 100px;
                                width: 400px;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                p {
                                    font-size: 16px;
                                    font-family: @font--poppins-regular;
                                    color: #5C5C5C;
                                    margin-bottom: 0px;
                                }
                            }
                        }
                    }

                }
            }
        }

        &__rightcnt {
            background-color: #ffffff;
            border: 1px solid #e3e3e3;
            border-radius: 10px;
            padding: 15px;
            height: 700px;
            min-height: 700px;
            max-height: 700px;
            margin-bottom: 20px;
            &__emptyState {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;

                &__content {
                    background-color: #ffffff;
                    border-radius: 10px;
                    border: 1px solid #e3e3e3;
                    padding: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;

                    &__empty-text {
                        font-size: 16px;
                        font-family: @font--poppins-regular;
                        margin-bottom: 20px;
                    }

                    &__empty-cta {
                        font-size: 16px;
                        font-family: @font--poppins-regular;
                        color: #1C84EE;
                        cursor: pointer;
                        margin-bottom: 0px;
                    }
                }
            }

            &__topSec {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 20px;
                gap: 30px;
                &__title {
                    font-size: 16px;
                    font-family: @font--poppins-regular;
                    color: #5C5C5C;
                    margin-bottom: 0px;
                }
            }
            &__btmSec{
                height: 94%;
                overflow-y: scroll;
            }
        }
    }
}