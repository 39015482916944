@import "../../../../../assets/css/variables.less";


.advisorCard {
    max-width: 310px;
    margin: 0 20px 20px 0;
    padding: 20px 15px;
    border: 1px solid #ECECEC;
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    flex-direction: column;

    &__Top {
        display: none;
    }
    .profile-img{
        width: 60px;
        height: 60px;
        border-radius: 6px;
        object-fit: cover;
    }
    .advisor-country {
        color: #1B1C1E;
        font-family: @font--poppins-500;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
    }

    .pill-status {
        border-radius: 100px;
        background: #1C84EE;
        display: flex;
        width: fit-content;
        height: 17px;
        padding: 7px 7px;
        align-items: center;
        flex-shrink: 0;
        color: #fff;
        font-size: 10px;
        font-family: @font--poppins-500;
    }

    .advisor-subText {
        color: #1B1C1E;
        font-family: @font--poppins-regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400 !important;
        margin-bottom: 0px;
    }

    .advisor-content {
        height: 110px;
    }

    .advisor-card-content {
        border-radius: 10px;
        background: #F4F5F8;
        padding: 10px;
    }

    .packageBlock {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        align-items: center;

        .diamondicon {
            width: 14px !important;
            height: 23px !important;
            color: #404144e3 !important;
        }

        .packageSessionTitle {
            color: #1B1C1E;
            font-family: @font--poppins-500;
            font-size: 12px;

        }

        .purchasedContent {
            color: #9D9D9D;
            font-family: @font--poppins-regular;
            font-size: 10.5px;
            font-style: italic;
        }

        .sessionDetails {
            color: #9D9D9D;
            font-family: @font--poppins-regular;
            font-size: 8px;
            font-style: normal;
        }

        .packageCount {
            color: #1B1C1E;
            font-weight: bold;
        }
    }

    .meetTimeZone {
        color: #353535;
        font-family: @font--poppins-regular;
        font-size: 9px;
        text-align: center;
    }
}

@media (max-width: 600px) {
    .advisorCard {
        max-width: 280px;
        height: fit-content;
    }

    .packageBlock {
        display: flex;
        flex-wrap: nowrap;

    }

}

@media (max-width: 480px) {
    .advisorCard {
        max-width: 320px !important;
    }
}

@media (max-width: 320px) {
    .advisorCard {
        max-width: 240px !important;
        padding: 20px 10px !important;

        .advisorCardTop {
            display: none;
        }

        &__Top {
            display: flex;
            flex-wrap: wrap;
            justify-content: start;

            &__namecnt {
                margin-left: 10px;

                .profile-heading {
                    font-family: @font--poppins-500;
                    font-size: 12px;
                }
            }

            &__ratingcnt {
                margin-top: 5px;
            }
        }

        .advisor-card-content {
            width: 210px;
        }

        .advisor-subText-coll {
            margin-top: 10px !important;
        }

        .advisor-subText {
            width: 205px !important;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

&__userInfo {
    &__nameSec {
        display: flex;

        &__primaryText {
            color: #1B1C1E;
            font-size: 16px;
            margin-bottom: 1px;
            font-family: @font--poppins-500;

        }

        &__iconBg {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            position: relative;
            bottom: 7px;
            left: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f4f5f8d3;

            &:hover {
                cursor: pointer;
                background-color: #f1f2f3d8;
            }

            &__chatIcon {
                width: 11px !important;
                height: 11px !important;
                color: #515050 !important;
                border-radius: 50%;
            }
        }
    }

    &__secondaryText {
        color: #1B1C1E;
        font-size: 12px;
        margin-bottom: 1px;
        font-family: @font--poppins-regular;
    }

    &__helperText {
        color: #515050;
        font-size: 10px;
        margin-bottom: 2px;
        font-family: @font--poppins-regular;
    }
}

.connectedAdvisor {

    &__topSec {
        &__userIcon {
            border: 1px solid grey !important;
            border-radius: 6px !important;
            font-size: 22px !important;
            width: 65px !important;
            height: 70px !important
        }

        &__userInfo {
            &__nameSec {
                display: flex;

                &__primaryText {
                    color: #1B1C1E;
                    font-size: 16px;
                    margin-bottom: 1px;
                    font-family: @font--poppins-500;

                }

                &__iconBg {
                    width: 25px;
                    height: 20px;
                    border-radius: 50%;
                    position: relative;
                    bottom: 7px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #f4f5f8d3;

                    &:hover {
                        cursor: pointer;
                        background-color: #f1f2f3d8;
                    }

                    &__chatIcon {
                        width: 11px !important;
                        height: 11px !important;
                        color: #515050 !important;
                        border-radius: 50%;
                    }
                }
            }
        }
    }

    &__midSec {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px !important;

        &__educationSec {
            display: flex;
            gap: 10px;

            &__schoolIcon {
                font-size: 12px !important;
                width: 20px !important;
                height: 20px !important;
                color: #404144;

            }

            &__schoolName {
                color: #1B1C1E;
                font-size: 15px;
                margin-bottom: 5px;
                font-family: @font--poppins-regular;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &__goalsSec {
            display: flex;
            flex-direction: column;
            background-color: #F4F5F8;
            border-radius: 14px;
            padding: 15px 10px;
            gap: 10px;

            &__targetUniversitySec {
                display: flex;
                // align-items: center;
                gap: 10px;

                &__starIcon {
                    color: #1C84EE;
                    width: 16px !important;
                    height: 16px !important;
                }

                &__label {
                    font-family: @font--poppins-regular;
                    font-size: 15px;
                    color: #1B1C1E;
                    margin-bottom: 0;
                }
            }
        }
    }

    &__sample {
        flex-grow: 1;
    }

    &__buttonSec {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: auto;

        &__primary-btn {
            background-color: #1C84EE;
            color: #fff;
            border: none;
            width: 85%;
            height: 42px;
            border-radius: 10px;
            font-family: @font--poppins-500;
        }
    }
}


.advisorEyebrow {
    color: #000;
    font-family: @font--poppins-300;
    font-size: 13px;
    margin-bottom: 0px;
    width: 18rem;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    // overflow: hidden;
    // margin-bottom: 8px;
    text-align: center;

}

.netWork-card__button-container {
    display: flex;
    flex-direction: column;
    margin-top: auto;

    .netWork-card__button-sec {
        .netWork-card__button-sec__primes-btn {}

        @media screen and (max-width: 320px) {
            .netWork-card__button-sec__primes-btn {
                font-size: 14px;
            }
        }
    }
}

.myNetwork__Mid__Tabs__Desktop__Container {
    .advisorCard {
        max-width: 310px;
        margin: 0 20px 20px 0;
        padding: 20px 15px;
        border: 1px solid #ECECEC;
        background-color: #fff;
        border-radius: 15px;
    }
}