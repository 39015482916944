@import "../../../../../assets/css/variables.less";

.courseVerticalCard{
    padding: 25px 30px;
    border-radius: 15px;
    border: 2px solid #e3e3e3;
    background: #fff;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__topSec{
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__title{
            font-size: 20px;
            color: #000;
            margin-bottom: 0;
            font-family: @font--poppins-500;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 40px;
        }
    }
    &__bottomSec{
        display: flex;
        flex-direction: column;
        gap: 10px;
        &__label{
            font-size: 17px;
            color: #000;
            margin-bottom: 0;
            font-family: @font--poppins-500;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            span {
                font-size: 16px;
                color: #515050;
                margin-bottom: 0;
                font-family: @font--poppins-regular;
                margin-left: 15px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        @media screen and (max-width: 420px) {
            &__label{
                max-width: 200px;
            }
        }
        &__detailedDescription {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            word-break: break-word;
            font-size: 16px;
            color: #515050;
            margin-bottom: 0;
            font-family: @font--poppins-regular;
            cursor: pointer;

            &:hover {
                background-color: rgba(227, 227, 227, 0.133);
                border-radius: 5px;
            }
        }

        &__description {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            word-break: break-word;
            font-size: 16px;
            color: #515050;
            margin-bottom: 0;
            font-family: @font--poppins-regular;
            cursor: pointer;
        }
    }
    
}
@media screen and (max-width: 540px) {
    .courseVerticalCard{
        padding: 25px 20px;
    }
}