@import "../../../assets/css/variables.less";

.pillsection{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    .MuiChip-root{
        border-radius: 5px !important;
        background-color: #e3e3e3;
        font-family: @font--poppins-regular;
    }
}
