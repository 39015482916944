@import "../../../../assets/css/variables.less";

.semesterDetailSec{
    &__topSection{ 
        display: flex;
        justify-content: space-between;
        &__titleDesktop{
            display: flex;
            align-items: center;
            gap: 10px;
            &__goBackIcon{
                cursor: pointer;
            }
            &__title{
                font-family: @font--poppins-500;
                font-size: 32px;
                margin-bottom: 0;
            }
        }
        &__titleMobile{
            display: none;
            position: fixed;
            z-index: 99;
        }
        &__subTitleMobile{
            display: none;
        }
    }
    &__midSection{
        &__desktopTabView{
            // background-color: #FFFFFF;
            // border: 1px solid #e3e3e3;
            border-radius: 10px;
            width: 100%;

            .semTab{
                font-family: @font--poppins-regular;
                font-size: 16px;
                color: #000;
                text-transform: capitalize;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .semesterDetailSec{
        &__topSection{
            &__titleDesktop{
                display: none;
            }
            &__titleMobile{
                background-color: #fff;
                border-bottom: 1px solid #e3e3e3;
                height: 50px;
                display: flex;
                justify-content: center;
                width: 100%;
                align-items: center;

                &__title{
                    font-family: @font--poppins-500;
                    font-size: 26px;
                    margin: 0;
                }
            }
            &__subTitleMobile{
                display: block;
                margin-block-start: 60px;
                margin-inline-start: 1.5em;

                &__title{
                    font-family: @font--poppins-500;
                    font-size: 20px;
                    margin-bottom: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: break-word;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                }
            }

        }
    }
}
@media screen and (max-width: 425px) {
    .semesterDetailSec{
        &__topSection{
            &__titleMobile{
                &__title{
                    font-size: 20px;
                    max-width: 220px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }
            &__subTitleMobile{
                margin-block-start: 60px;
                margin-inline: .5em;
            }
        }
        &__midSection{
            .semContentSec{
                padding-inline: 5px;
            }
        }
    }
}