@import "../../../../assets/css/variables.less";

.BookSessionModal {
    padding: 20px;

    &_Top {
        .Modaltitle {
            font-size: 16px;
            font-family: @font--poppins-600;
            color: @color--primary;
            margin-bottom: 0px;
        }

        .closebtn {
            cursor: pointer;
        }
    }

    &_Mid {
        margin-top: 20px;
        .BookSessionModal_Mid_Cnt {
            .BookSessionModal_Mid_Title {
                font-size: 16px;
                font-family: @font--poppins-500;
                color: @color--primary;
                margin-bottom: 30px;
                span{
                    font-size: 16px;
                    font-family: @font--poppins-600;
                    color: @color--primary;
                }
            }
        }
    }


    &__footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 80px;

        .bookBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            user-select: none;
            cursor: pointer;
            background: @btn--primary;
            border-radius: 10px;
            color: @btn--white;
            width: 140px;
            line-height: 36px;
            margin: 0 8px 0 auto;
            text-align: center;
            border: 2px solid @btn--primary;
            &:hover{
                cursor: pointer;
                color: @color--primary !important;
                background: @btn--white;
                border: 2px solid @btn--primary;
            }
        }
        .Bookclose{
            background-color: @color--gray;
            border-radius: 10px;
            border: none;
            text-transform: capitalize;
            margin-right: 20px;
            box-shadow: none;
            &:hover{
                background-color: @color--gray;
                box-shadow: none;
            }
        }
    }
}