@import "variables.less";
@import "fonts.less";

.Student_Profile {
    .Student_Profile_Sec_Mobile {
        display: none;
    }

    .input[disabled] {
        background: #f4f5f8;
        color: #6c6c6c;
    }
    .profile_pill_btns {
        padding: 5px 10px;
        background-color: @backcolor--primary;
        color: @backcolor--white;
        font-size: 12px;
        font-family: @font--poppins-regular;
        border-radius: 30px;
        cursor: pointer;
        margin-left: 0px !important;
    }

    @media only screen and (max-width: 530px) {
        .profile_pill_btns {
            width: fit-content !important;
        }
    }

    .ResendEmail-text {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    .Student_Profile_Sec {
        display: flex;
        align-items: center;

        .Profile_Title {
            font-family: @font--poppins-500;
            font-size: 28px;
            margin-bottom: 0px;
        }
    }

    .Student_Profile_Mid_Section {
        padding-top: 40px;

        .Student_Profile_Mid_Section_Top {
            display: block;

            .tab_card {
                width: 100%;
                border: 1px solid #e3e3e3;
                background-color: #ffff;
                border-radius: 10px;

                .MuiTabs-root {
                    align-items: center;
                }

                // .MuiTabs-indicator {
                //     display: none;
                // }

                .MuiTouchRipple-root span {
                    background-color: transparent;
                }

                .Tab {
                    padding: 0px;
                    padding-left: 30px;
                    padding-right: 30px;
                    height: 0px;
                    min-height: 49px;
                    font-family: @font--poppins-500;
                    font-size: 18px;
                    text-transform: none;

                    .tabicon {
                        stroke: #666666;
                    }

                    .tabicon2 {
                        g {
                            fill: #666666;
                        }

                        .clipPath {
                            fill: #fff;
                        }
                    }

                    &:hover {
                        color: @backcolor--primary;

                        .tabicon {
                            stroke: @backcolor--primary;
                        }

                        .tabicon2 {
                            g {
                                fill: @backcolor--primary;
                            }

                            .clipPath {
                                fill: @backcolor--primary;
                            }
                        }
                    }
                }

                .Mui-selected {
                    .tabicon {
                        stroke: @backcolor--primary !important;
                    }

                    .tabicon2 {
                        g {
                            fill: @backcolor--primary !important;
                        }

                        .clipPath {
                            fill: @backcolor--primary !important;
                        }
                    }
                }
            }

            .MuiBox-root {
                padding: 0px !important;
            }

            .Tabspanel {
                padding-top: 20px;
            }

            .GeneralForm {
                padding-top: 20px;
            }
        }

        .Student_Profile_Mid_Section_Top_Mobile {
            display: none;
        }
    }
}

.Student_Profile_Form_Card {
    border: 1px solid #e3e3e3;
    background-color: #ffff;
    border-radius: 10px;
    height: 300px;
    padding: 20px;

    .Form_Title {
        font-family: @font--poppins-500;
        font-size: 20px;
    }

    .Student_Profile_Form {}
}

// User Image Section
.UserImageSection {
    padding-top: 20px;
    height: 100%;
}

// Connect Parent Section
.ConnectParentSection {
    padding-top: 20px;

    .Connect_Parent_Card {
        padding: 10px;
        background-color: @backcolor--white;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        cursor: default;

        .Connect_Parent_Card_Top_Mobile {
            display: none;
        }

        .Connect_Parent_Card_Top {
            display: flex;
            justify-content: center;

            .connectparenttitle {
                margin-top: 20px;
                font-size: 18px;
                color: @color--secondary;
                font-family: @font--poppins-regular;
            }

            .connectparenttext {
                margin-top: 20px;
                margin-left: 10px;
                font-size: 18px;
                color: @backcolor--dark;
                font-family: @font--poppins-regular;
                overflow: hidden;
                display: inline-block;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 380px;
            }

            .notconnectparenttext {
                font-size: 18px;
                color: @color--secondary;
                font-family: @font--poppins-regular;
                margin-top: 20px;
            }
        }

        .Connect_Parent_Card_Btm {
            display: flex;
            justify-content: center;

            .ConnectBtn {
                background-color: @backcolor--primary;
                color: @color--white;
                text-transform: none;
                font-size: 18px;
                font-family: @font--poppins-regular;
                border-radius: 10px;
                width: 230px;
                margin-bottom: 15px;
                padding: 5px;
            }
        }
    }

}

//  Sent Invite Container
.Sent_Invite_Form_Container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    .Sent_Invite_Form {
        width: 600px;
        height: 100%;

        .Sent_Invite_Form_Title {
            font-size: 44px;
            font-family: @font--poppins-500;
            margin-bottom: 50px;
            display: flex;
            justify-content: center;
        }

        .Sent_Invite_Forms {
            margin-top: 100px;
            margin-bottom: 50px;
        }
    }

    .Savesection {
        display: flex;
        justify-content: center;
        margin-top: 100px;

        .savebtn {
            background-color: @backcolor--primary;
            color: @color--white;
            text-transform: none;
            font-size: 22px;
            font-family: @font--poppins-regular;
            width: 200px;
            border-radius: 10px;
        }
    }
}

// General Form Section
.GeneralFormSection {
    padding-top: 20px;

    .General_Form_Card {
        background-color: @backcolor--white;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        padding: 10px;

        .General_Form_Title {
            font-size: 18px;
            font-family: @font--poppins-500;
            padding-left: 10px;
            padding-top: 10px;
        }

        .General_Forms {
            padding-top: 10px;
            padding-left: 10px;
            padding-right: 20px;

            .Savesection {
                display: flex;
                justify-content: flex-end;

                .savebtn {
                    background-color: @backcolor--primary;
                    color: @color--white;
                    text-transform: none;
                    font-size: 18px;
                    font-family: @font--poppins-regular;
                    width: 200px;
                    border-radius: 10px;
                    height: 50px;
                    border: 2px solid @backcolor--primary;

                    // &:hover {
                    //     border: 2px solid @backcolor--primary;
                    //     color: @backcolor--primary;
                    //     background-color: @color--white;
                    // }
                }
            }
        }
    }
}

// Form Interest Section

.FormInterestSection {
    padding-top: 20px;

    .Profile_Interest_Card {
        background-color: @backcolor--white;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        padding: 10px 0px 20px 20px;
        cursor: default;

        .InterestTitle {
            font-family: @font--poppins-500;
            font-size: 18px;
            padding-left: 10px;
        }

        .InterestForm {
            padding-left: 10px;

            .MuiTypography-root {
                font-family: @font--poppins-500;
                font-size: 16px;
            }
        }
    }
}

// Change Password Section
.ChangePasswordSection {
    padding-top: 20px;

    .Change_Password_Card {
        background-color: @backcolor--white;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        padding: 10px;

        .Change_Password_Card_Title {
            font-family: @font--poppins-500;
            font-size: 18px;
            padding-left: 10px;
            padding-top: 10px;
        }

        .Change_Password_Card_Form {
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 20px;

            .Savesection {
                display: flex;
                justify-content: center;
                margin-bottom: 20px;

                .savebtn {
                    background-color: @backcolor--primary;
                    color: @btn--white;
                    text-transform: none;
                    font-size: 18px;
                    font-family: @font--poppins-regular;
                    width: 200px;
                    border-radius: 10px;
                    text-transform: none;
                    height: 50px;
                    border: 2px solid @backcolor--primary;

                    // &:hover {
                    //     border: 2px solid @backcolor--primary;
                    //     color: @backcolor--primary;
                    //     background-color: @color--white;
                    // }
                }
            }
        }
    }

    .SocialLoginCard {
        cursor: default;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        padding: 10px;
        background-color: @backcolor--white;

        .socialloginpassword {
            .logpassword {
                p {
                    font-family: @font--poppins-regular;
                    font-size: 14px;
                    color: @color--gray;
                    line-height: 33px !important;
                    margin-bottom: 0px !important;

                    span {
                        font-family: @font--poppins-500;
                        font-size: 13px;
                        color: @backcolor--black;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }
        }
    }

}

// Sent Invite Section
.SentInviteSection {
    padding-top: 20px;

    .Sent_Invite_Card {
        background-color: @backcolor--white;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        padding: 10px;

        .Sent_Invite_Card_Title {
            font-family: @font--poppins-500;
            font-size: 16px;
            padding-left: 10px;
        }

        .Sent_Invite_Card_Content {
            display: flex;
            justify-content: center;

            .Sent_Invite_Text {
                font-family: @font--poppins-500;
                font-size: 16px;
                cursor: pointer;
                padding-top: 8px;
                text-align: center;

                .Sent_Invite_First_Icon {
                    margin-right: 10px;
                }

                .Sent_Invite_Second_Icon {
                    margin-left: 10px;
                }
            }
        }
    }
}

.Sent_Invite_Text {
    font-family: @font--poppins-500;
    font-size: 16px;
    cursor: pointer;
    padding-top: 8px;
    text-align: center;

    .Sent_Invite_First_Icon {
        margin-right: 10px;
    }

    .Sent_Invite_Second_Icon {
        margin-left: 10px;
    }
}

// Delete Section
.DeleteSection {
    padding-top: 20px;

    .Delete_Profile_Card {
        background-color: @backcolor--white;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        padding: 10px;

        .Delete_Profile_Card_Top {
            display: flex;
            justify-content: center;
            padding-top: 6px;

            .Delete_Profile_Card_Title {
                font-size: 15px;
                font-family: @font--poppins-600;
            }
        }

        .Delete_Profile_Btm {
            display: flex;
            justify-content: center;

            .DeleteBtn {
                border: 2px solid @backcolor--red;
                color: @backcolor--red;
                text-transform: none;
                font-size: 18px;
                font-family: @font--poppins-regular;
                border-radius: 10px;
                width: 230px;
            }
        }
    }
}

// Goals Page
.GoalsSection {
    padding-top: 20px;

    .Goals_Card {
        border: 1px solid #e3e3e3;
        background-color: #ffff;
        border-radius: 10px;
        padding: 20px;

        .Goals_Card_Title {
            font-size: 20px;
            font-family: @font--poppins-500;
        }

        .Goals_Mid {
            display: flex;
            justify-content: center;
            padding-left: 20%;
            padding-right: 20%;

            .Goals_Mid_Title {
                font-size: 20px;
                font-family: @font--poppins-500;
                color: @backcolor--primary;
                text-align: center;
            }

        }

        .Goals_Container {
            display: flex;
            margin-top: 20px;

            .Goals_Container_Button {
                margin: 0 auto;
                width: 100%;
                display: flex;
                width: 678px;
                display: flex;
                justify-content: center;

                .cta--role--btn {
                    border-radius: 300px;
                    padding: 6px 20px;
                    border: 2px solid #4B4B4B;
                    font-size: 15px;

                    &.checked {
                        background: @btn--primary;
                        color: @color--white;
                    }
                }
            }
        }

        .Goals_Form {
            display: flex;
            justify-content: center;
            margin-top: 20px;

            .Form_Group {
                width: 520px;
                padding: 20px;
            }
        }

        .Goals_Save_Btn {
            display: flex;
            justify-content: flex-end;

            .Goals_Save {
                width: 200px;
                background-color: @backcolor--primary;
                color: @color--white;
                font-size: 22px;
                font-family: @font--poppins-500;
                border-radius: 10px;
                text-transform: none;
            }
        }

        .Goals_Bottom {
            display: flex;
            justify-content: flex-end;
            margin-right: 40px;

            .Goals_Save_Btn {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 200px;
                height: 50px;
                background-color: @backcolor--primary;
                color: @color--white;
                border-radius: 10px;
                font-size: 18px;
                font-family: @font--poppins-500;
            }
        }
    }
}

// Billing Section
.BillingSection {
    padding-top: 20px;

    .Billing_Card {
        border: 1px solid #e3e3e3;
        background-color: #ffff;
        border-radius: 10px;
        padding: 20px;

        .Billing_Card_Title {
            font-size: 20px;
            font-family: @font--poppins-500;
        }

        .Debit_Card_Container {
            display: flex;
            flex-wrap: wrap;
            padding-top: 20px;

            .Debit_Card {
                border-radius: 10px;
                width: 350px;
                // min-height: 200px;
                background: #5A94ED;
                background: -webkit-linear-gradient(top left, #5A94ED, #2EC2E8);
                background: -moz-linear-gradient(top left, #5A94ED, #2EC2E8);
                background: linear-gradient(to bottom right, #5A94ED, #2EC2E8);
                border: 1px solid #5A94ED;
                margin-right: 30px;
                margin-bottom: 40px;

                &:hover {
                    transition: all 0.1s ease-out;
                    box-shadow: 0px 4px 8px #5A94ED;
                    top: -4px;
                    border: 1px solid #2EC2E8;
                    background-color: #2EC2E8;
                }

                .Debit_Card_Number_Cnt {
                    padding-left: 20px;
                    padding-top: 40px;

                    .cardnumber {
                        font-size: 36px;
                        font-family: @font--poppins-500;
                        color: @color--white;
                    }
                }

                .Debit_Card_Expiry_Cnt {
                    padding-left: 20px;
                    padding-top: 15px;

                    .Expire {
                        font-size: 22px;
                        font-family: @font--poppins-regular;
                        color: @color--white;
                        line-height: 0px;
                    }

                    .carddate {
                        font-size: 22px;
                        font-family: @font--poppins-regular;
                        color: @color--white;
                    }

                    .edit_row {
                        display: flex;
                        justify-content: space-evenly;

                        .Edit_btn {
                            font-size: 22px;
                            font-family: @font--poppins-regular;
                            color: @color--white;
                            cursor: pointer;

                            &:hover {
                                color: @backcolor--black;
                            }
                        }

                        .Delete_btn {
                            font-size: 22px;
                            font-family: @font--poppins-regular;
                            color: @color--white;
                            cursor: pointer;

                            &:hover {
                                color: @backcolor--black;
                            }
                        }
                    }
                }
            }

        }
    }

    .Billing_Card_Form_Card {
        border: 1px solid #e3e3e3;
        background-color: #ffff;
        border-radius: 10px;
        padding: 20px;
        margin-top: 20px;

        .Billing_Card_Form_Title {
            font-size: 20px;
            font-family: @font--poppins-500;
        }

        .Debit_Card_Container {
            .Debit_Card_Container_Form {
                .card_number {
                    font-size: 18px;
                    font-family: @font--poppins-500;
                    color: @color--secondary;
                }

                .Billing_Save_Btn {
                    display: flex;
                    justify-content: flex-end;
                    padding-top: 10px;

                    .Billing_Save {
                        width: 200px;
                        background-color: @backcolor--primary;
                        color: @color--white;
                        font-size: 22px;
                        font-family: @font--poppins-500;
                        border-radius: 10px;
                        text-transform: none;
                        height: 50px;
                    }
                }
            }
        }
    }
}

// Delete Profile
.Delete_Profile_cnt {
    .Delete_Profile_Top {
        .Delete_Profile_Title_cnt {
            .Delete_Profile_Title {
                font-size: 32px;
                font-family: @font--poppins-500;
                color: @color--primary;
            }

            .Delete_Profile_Sub_Title {
                margin-top: 20px;
                font-size: 18px;
                font-family: @font--poppins-500;
                color: @color--secondary;
                text-align: center;
            }
        }
    }

    .Delete_Profile_btm {
        margin-top: 50px;

        .Delete_Profile_Form_cnt {
            display: flex;
            justify-content: center;
        }
    }
}

@media only screen and (max-width: 768px) {
    .Student_Profile {
        background-color: #f4f5f8;
        margin-top: 50px;

        .Student_Profile_Sec_Mobile {
            display: block;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: @backcolor--white;
            border-bottom: 1px solid #e3e3e3;
            height: 50px;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 9;

            .Profile_Title {
                font-family: @font--poppins-500;
                font-size: 16px;
                margin-bottom: 0px;
                margin-left: 12px;
            }
        }

        .Student_Profile_Mid_Section {
            padding: 20px;

            .Student_Profile_Mid_Section_Top {
                .tab_card {
                    display: none;
                }

                .Student_Profile_Mid_Section_Top_Mobile {
                    display: block;
                }
            }
        }



        .Student_Profile_Sec {
            display: none;
        }
    }
}


@media only screen and (max-width: 504px) {

    // Connect Parent Section
    .ConnectParentSection {
        .Connect_Parent_Card {
            padding: 10px;
            background-color: @backcolor--white;
            border: 1px solid #e3e3e3;
            border-radius: 10px;

            .Connect_Parent_Card_Top_Mobile {
                display: block;

                .connectparenttitle {
                    margin-top: 20px;
                    font-size: 16px;
                    color: @color--secondary;
                    font-family: @font--poppins-regular;
                }

                .connectparenttext {
                    font-size: 16px;
                    color: @backcolor--dark;
                    font-family: @font--poppins-regular;
                    overflow: hidden;
                    display: inline-block;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    max-width: 380px;
                }
            }

            .Connect_Parent_Card_Top {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .Goals_Card {
        .Goals_Mid {
            padding-left: 0% !important;
            padding-right: 0% !important;
        }
    }
}

@media only screen and (max-width: 478px) {
    .Goals_Card {
        .Goals_Mid {
            padding-left: 0px;
            padding-right: 0px;

            .Goals_Mid_Title {
                font-size: 15px !important;
            }
        }

        .role {
            .role_card {
                .login_card_form {
                    .goals__form {
                        .multinew {
                            .MuiAutocomplete-tag {
                                min-width: 141px;
                            }
                        }

                        .asyncmultiselect {
                            .mySelect__value-container {
                                min-width: 141px !important;
                                padding-top: 10px !important;
                            }
                        }
                    }
                }
            }
        }

        .Goals_Bottom {
            margin-right: 0px !important;
        }
    }
}

@media only screen and (max-width: 310px) {
    .BillingSection {
        .Billing_Card {
            max-width: 428px;
            min-width: 238px;

            .Debit_Card_Container {
                padding-top: 0px;

                .Debit_Card {
                    margin-right: 0px;
                    margin-bottom: 0px;

                    .Debit_Card_Number_Cnt {
                        padding-left: 10px;
                        padding-top: 20px;

                        .cardnumber {
                            font-size: 18px;
                        }
                    }

                    .Debit_Card_Expiry_Cnt {
                        padding-left: 5px;
                        padding-top: 5px;

                        .Expire {
                            font-size: 14px;
                        }

                        .carddate {
                            font-size: 14px;
                        }

                        .edit_row {
                            .Edit_btn {
                                font-size: 14px;
                            }

                            .Delete_btn {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }

        }
    }
}

@media only screen and(min-width: 311px) and (max-width: 470px) {
    .BillingSection {
        .Billing_Card {
            max-width: 428px;
            min-width: 238px;

            .Debit_Card_Container {
                padding-top: 0px;

                .Debit_Card {
                    margin-right: 0px;
                    margin-bottom: 0px;

                    .Debit_Card_Number_Cnt {
                        padding-left: 10px;
                        padding-top: 20px;

                        .cardnumber {
                            font-size: 24px;
                        }
                    }

                    .Debit_Card_Expiry_Cnt {
                        padding-left: 5px;
                        padding-top: 5px;

                        .Expire {
                            font-size: 18px;
                        }

                        .carddate {
                            font-size: 18px;
                        }

                        .edit_row {
                            .Edit_btn {
                                font-size: 18px;
                            }

                            .Delete_btn {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }

        }
    }
}

.billingMonth {
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-readOnly {
        border-radius: 11px !important;
        height: 51px !important;
    }
}

// Parent Billing page
.commanCardCnt {
    background-color: white;
    border: 1px solid #e3e3e3;
    padding: 1rem;
    border-radius: 10px;
    margin-bottom: 1rem;
    margin-top: 20px;

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }



    .Billing_Card {

        .Billing_Card_Title {
            font-size: 20px;
            font-family: @font--poppins-500;
        }

        .Debit_Card_Container {
            display: flex;
            flex-wrap: wrap;
            padding-top: 20px;

            .Debit_Card {
                border-radius: 10px;
                width: 350px;
                // height: 200px;
                background: #5A94ED;
                background: -webkit-linear-gradient(top left, #5A94ED, #2EC2E8);
                background: -moz-linear-gradient(top left, #5A94ED, #2EC2E8);
                background: linear-gradient(to bottom right, #5A94ED, #2EC2E8);
                border: 1px solid #5A94ED;
                margin-right: 30px;
                margin-bottom: 40px;



                .Debit_Card_Number_Cnt {
                    padding-left: 20px;
                    padding-top: 40px;

                    .cardnumber {
                        font-size: 36px;
                        font-family: @font--poppins-500;
                        color: @color--white;
                    }
                }

                .Debit_Card_Expiry_Cnt {
                    padding-left: 20px;
                    padding-top: 15px;

                    .Expire {
                        font-size: 22px;
                        font-family: @font--poppins-regular;
                        color: @color--white;
                        line-height: 0px;
                    }

                    .carddate {
                        font-size: 22px;
                        font-family: @font--poppins-regular;
                        color: @color--white;
                    }

                    .edit_row {
                        display: flex;
                        //justify-content: space-evenly;

                        .Edit_btn {
                            font-size: 22px;
                            font-family: @font--poppins-regular;
                            color: @color--white;
                            cursor: pointer;
                            margin-right: 1rem;

                        }

                        .Delete_btn {
                            font-size: 22px;
                            font-family: @font--poppins-regular;
                            color: @color--white;
                            cursor: pointer;


                        }
                    }
                }
            }

        }
    }

    .input-non-floating-label {

        margin-bottom: 20px;
        width: 100%;

        .input {
            width: 100%;
            display: block;
            outline: 0;
            background: transparent;
            transition: border-color 0.2s;

            &:invalid,
            &:required {
                box-shadow: none;
            }

            &:focus {
                border-color: rgba(0, 0, 0, 0.1);
            }


            &:not(:placeholder-shown)~label {
                color: #000 !important;
            }
        }

    }
}

@media only screen and(min-width: 409px) and (max-width: 440px) {
    .GoalsSection {
        .Goals_Card {
            padding: 10px;

            .role {
                .role_card {
                    padding: 9px;

                    .login_card_form {
                        .goals__form {
                            .asyncmultiselect {
                                padding: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 408px) {
    .GoalsSection {
        .Goals_Card {
            padding: 8px;

            .role {
                .role_card {
                    padding: 9px;

                    .login_card_form {
                        .goals__form {
                            .asyncmultiselect {
                                padding: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 408px) {
    .GoalsSection {
        .Goals_Card {
            padding: 8px;

            .role {
                .role_card {
                    padding: 9px;

                    .login_card_form {
                        .goals__form {
                            .asyncmultiselect {
                                padding: 10px;

                                .goals__form__select {
                                    min-width: 200px !important;
                                    max-width: 100%;
                                }
                            }

                        }
                    }
                }
            }

            .Goals_Bottom {
                display: flex;
                justify-content: center;
            }
        }
    }
}

@media only screen and (max-width: 319px) {
    .MuiCalendarPicker-root {
        width: 270px;

        .MuiMonthPicker-root {
            width: 260px !important;
        }
    }

    .MuiCalendarOrClockPicker-root {
        width: 270px;

        .MuiCalendarPicker-root {
            width: 260px !important;
        }
    }

    .MuiYearPicker-root {
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 318px) {
    .MuiCalendarPicker-root {
        width: 210px;

        .MuiMonthPicker-root {
            width: 210px !important;
        }
    }
}

@media only screen and (max-width: 2560px) {
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-readOnly {
        border-radius: 11px !important;
        height: 51px !important;
    }
}

// Connected Account Page
.Connected_Account_Page {
    margin-top: 10px;

    .Connected_Account_Card {
        background-color: @backcolor--white;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        padding: 20px;
        min-height: 400px;

        .Connected_Account_Card_Top {
            .Connected_Account_Card_Title {
                font-size: 20px;
                font-family: @font--poppins-500;
                margin-bottom: 0px;
            }
        }

        .Connected_Account_Card_Mid {
            margin-top: 30px;

            .Empty_Connected_Account_Card {
                background-color: @backcolor--white;
                border: 1px solid #e3e3e3;
                border-radius: 10px;
                padding: 15px;
                cursor: pointer;

                // &:hover {
                //     transition: all 0.2s ease-out;
                //     box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
                //     top: -4px;
                //     border: 1px solid #cccccc;
                //     background-color: @backcolor--white;
                // }

                .Empty_Connected_Account_Card_Top {
                    display: flex;
                    justify-content: center;

                    .Empty_Connected_Account_Card_Title {
                        font-size: 22px;
                        font-family: @font--poppins-500;
                        color: @color--primary;
                    }
                }

                .Empty_Connected_Account_Card_Mid {
                    display: flex;
                    justify-content: center;

                    .Empty_Connected_Account_Card_SubTitle {
                        font-size: 18px;
                        font-family: @font--poppins-500;
                        color: @color--secondary;
                    }
                }
            }
        }

        .sendInvite__form_inner {
            margin-top: 26px;
            width: 400px;

            .Already_Connect_Account {
                border: 1px solid #e3e3e3;
                background-color: #ffff;
                border-radius: 10px;
                padding: 20px;
                display: flex;
                justify-content: center;

                .Already_Connect_Account_Title {
                    font-size: 18px;
                    font-family: @font--poppins-500;
                }
            }
        }
    }
}

@media only screen and (min-width:1921px) {
    .Connected_Parent_Card {
        background-color: @backcolor--white;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        padding: 5px;
        cursor: pointer;
        margin: 20px;
        display: flex;
        align-items: center;
        // &:hover {
        //     transition: all 0.2s ease-out;
        //     box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        //     top: -4px;
        //     border: 1px solid #cccccc;
        //     background-color: @backcolor--white;
        // }

        .Connected_Parent_Profile {
            display: flex;
            align-items: center;
        }

        .Connected_Parent_Name_Cnt {
            margin-left: 10px;
            width: 520px;
            margin-right: 10px;
            display: flex;
            align-items: center;

            .Connected_Parent_Name {
                font-size: 18px;
                font-family: @font--poppins-500;
                width: 520px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: @color--primary;
                margin-bottom: 0px;
            }

            .Connected_Parent_Title {
                font-size: 18px;
                font-family: @font--poppins-500;
                color: @color--secondary;
                margin-bottom: 0px;
            }
        }

        .Connected_Parent_Disconnect_Cnt {
            padding: 10px;

            .Disconnect_Btn {
                border: 3px solid @backcolor--red;
                background-color: @backcolor--white;
                font-size: 16px;
                font-family: @font--poppins-500;
                color: @color--red;
                border-radius: 10px;
            }
        }
    }
}

@media only screen and (min-width: 1888px) and (max-width: 1920px) {
    .Connected_Parent_Card {
        background-color: @backcolor--white;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        padding: 5px;
        cursor: pointer;
        margin: 20px;
        display: flex;
        align-items: center;
        // &:hover {
        //     transition: all 0.2s ease-out;
        //     box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        //     top: -4px;
        //     border: 1px solid #cccccc;
        //     background-color: @backcolor--white;
        // }

        .Connected_Parent_Profile {
            display: flex;
            align-items: center;
        }

        .Connected_Parent_Name_Cnt {
            margin-left: 10px;
            width: 520px;
            margin-right: 10px;
            display: flex;
            align-items: center;

            .Connected_Parent_Name {
                font-size: 18px;
                font-family: @font--poppins-500;
                width: 520px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: @color--primary;
                margin-bottom: 0px;
            }

            .Connected_Parent_Title {
                font-size: 18px;
                font-family: @font--poppins-500;
                color: @color--secondary;
                margin-bottom: 0px;
            }
        }

        .Connected_Parent_Disconnect_Cnt {
            padding: 10px;

            .Disconnect_Btn {
                border: 3px solid @backcolor--red;
                background-color: @backcolor--white;
                font-size: 16px;
                font-family: @font--poppins-500;
                color: @color--red;
                border-radius: 10px;
            }
        }
    }
}

@media only screen and (min-width: 1689px) and (max-width: 1887px) {
    .Connected_Parent_Card {
        background-color: @backcolor--white;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        padding: 5px;
        cursor: pointer;
        margin: 20px;
        display: flex;
        align-items: center;
        // &:hover {
        //     transition: all 0.2s ease-out;
        //     box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        //     top: -4px;
        //     border: 1px solid #cccccc;
        //     background-color: @backcolor--white;
        // }

        .Connected_Parent_Profile {
            display: flex;
            align-items: center;
        }

        .Connected_Parent_Name_Cnt {
            margin-left: 10px;
            width: 420px;
            margin-right: 10px;
            display: flex;
            align-items: center;

            .Connected_Parent_Name {
                font-size: 18px;
                font-family: @font--poppins-500;
                width: 420px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: @color--primary;
                margin-bottom: 0px;
            }

            .Connected_Parent_Title {
                font-size: 18px;
                font-family: @font--poppins-500;
                color: @color--secondary;
                margin-bottom: 0px;
            }
        }

        .Connected_Parent_Disconnect_Cnt {
            padding: 10px;

            .Disconnect_Btn {
                border: 3px solid @backcolor--red;
                background-color: @backcolor--white;
                font-size: 16px;
                font-family: @font--poppins-500;
                color: @color--red;
                border-radius: 10px;
            }
        }
    }
}

@media only screen and (min-width: 1490px) and (max-width: 1688px) {
    .Connected_Parent_Card {
        background-color: @backcolor--white;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        padding: 5px;
        cursor: pointer;
        margin: 20px;
        display: flex;
        align-items: center;
        // &:hover {
        //     transition: all 0.2s ease-out;
        //     box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        //     top: -4px;
        //     border: 1px solid #cccccc;
        //     background-color: @backcolor--white;
        // }

        .Connected_Parent_Profile {
            display: flex;
            align-items: center;
        }

        .Connected_Parent_Name_Cnt {
            margin-left: 10px;
            width: 320px;
            margin-right: 10px;
            display: flex;
            align-items: center;

            .Connected_Parent_Name {
                font-size: 18px;
                font-family: @font--poppins-500;
                width: 320px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: @color--primary;
                margin-bottom: 0px;
            }

            .Connected_Parent_Title {
                font-size: 17px;
                font-family: @font--poppins-500;
                color: @color--secondary;
                margin-bottom: 0px;
            }
        }

        .Connected_Parent_Disconnect_Cnt {
            padding: 10px;

            .Disconnect_Btn {
                border: 3px solid @backcolor--red;
                background-color: @backcolor--white;
                font-size: 16px;
                font-family: @font--poppins-500;
                color: @color--red;
                border-radius: 10px;
            }
        }
    }
}

@media only screen and (min-width: 1262px) and (max-width: 1489px) {
    .Connected_Parent_Card {
        background-color: @backcolor--white;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        padding: 5px;
        cursor: pointer;
        margin: 20px;
        display: flex;
        align-items: center;
        // &:hover {
        //     transition: all 0.2s ease-out;
        //     box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        //     top: -4px;
        //     border: 1px solid #cccccc;
        //     background-color: @backcolor--white;
        // }

        .Connected_Parent_Profile {
            display: flex;
            align-items: center;
        }

        .Connected_Parent_Name_Cnt {
            margin-left: 10px;
            width: 220px;
            margin-right: 10px;
            display: flex;
            align-items: center;

            .Connected_Parent_Name {
                font-size: 18px;
                font-family: @font--poppins-500;
                width: 220px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: @color--primary;
                margin-bottom: 0px;
            }

            .Connected_Parent_Title {
                font-size: 16px;
                font-family: @font--poppins-500;
                color: @color--secondary;
                margin-bottom: 0px;
            }
        }

        .Connected_Parent_Disconnect_Cnt {
            padding: 10px;

            .Disconnect_Btn {
                border: 3px solid @backcolor--red;
                background-color: @backcolor--white;
                font-size: 14px;
                font-family: @font--poppins-500;
                color: @color--red;
                border-radius: 10px;
            }
        }
    }
}

@media only screen and (min-width: 1202px) and (max-width: 1261px) {
    .Connected_Parent_Card {
        background-color: @backcolor--white;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        padding: 5px;
        cursor: pointer;
        margin: 20px;
        display: flex;
        align-items: center;
        // &:hover {
        //     transition: all 0.2s ease-out;
        //     box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        //     top: -4px;
        //     border: 1px solid #cccccc;
        //     background-color: @backcolor--white;
        // }

        .Connected_Parent_Profile {
            display: flex;
            align-items: center;
        }

        .Connected_Parent_Name_Cnt {
            margin-left: 10px;
            width: 150px;
            margin-right: 10px;
            display: flex;
            align-items: center;

            .Connected_Parent_Name {
                font-size: 18px;
                font-family: @font--poppins-500;
                width: 150px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: @color--primary;
                margin-bottom: 0px;
            }

            .Connected_Parent_Title {
                font-size: 16px;
                font-family: @font--poppins-500;
                color: @color--secondary;
                margin-bottom: 0px;
            }
        }

        .Connected_Parent_Disconnect_Cnt {
            padding: 10px;

            .Disconnect_Btn {
                border: 3px solid @backcolor--red;
                background-color: @backcolor--white;
                font-size: 14px;
                font-family: @font--poppins-500;
                color: @color--red;
                border-radius: 10px;
            }
        }
    }
}

@media only screen and (min-width: 1050px) and (max-width: 1201px) {
    .Connected_Parent_Card {
        background-color: @backcolor--white;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        padding: 5px;
        cursor: pointer;
        margin: 20px;
        display: flex;
        align-items: center;
        // &:hover {
        //     transition: all 0.2s ease-out;
        //     box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        //     top: -4px;
        //     border: 1px solid #cccccc;
        //     background-color: @backcolor--white;
        // }

        .Connected_Parent_Profile {
            display: flex;
            align-items: center;
        }

        .Connected_Parent_Name_Cnt {
            margin-left: 10px;
            width: 533px;
            margin-right: 10px;
            display: flex;
            align-items: center;

            .Connected_Parent_Name {
                font-size: 18px;
                font-family: @font--poppins-500;
                width: 533px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: @color--primary;
                margin-bottom: 0px;
            }

            .Connected_Parent_Title {
                font-size: 18px;
                font-family: @font--poppins-500;
                color: @color--secondary;
                margin-bottom: 0px;
            }
        }

        .Connected_Parent_Disconnect_Cnt {
            padding: 10px;

            .Disconnect_Btn {
                border: 3px solid @backcolor--red;
                background-color: @backcolor--white;
                font-size: 16px;
                font-family: @font--poppins-500;
                color: @color--red;
                border-radius: 10px;
            }
        }
    }
}

@media only screen and (min-width: 900px) and (max-width: 1049px) {
    .Connected_Parent_Card {
        background-color: @backcolor--white;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        padding: 5px;
        cursor: pointer;
        margin: 20px;
        display: flex;
        align-items: center;
        // &:hover {
        //     transition: all 0.2s ease-out;
        //     box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        //     top: -4px;
        //     border: 1px solid #cccccc;
        //     background-color: @backcolor--white;
        // }

        .Connected_Parent_Profile {
            display: flex;
            align-items: center;
        }

        .Connected_Parent_Name_Cnt {
            margin-left: 10px;
            width: 375px;
            margin-right: 10px;
            display: flex;
            align-items: center;

            .Connected_Parent_Name {
                font-size: 18px;
                font-family: @font--poppins-500;
                width: 375px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: @color--primary;
                margin-bottom: 0px;
            }

            .Connected_Parent_Title {
                font-size: 16px;
                font-family: @font--poppins-500;
                color: @color--secondary;
                margin-bottom: 0px;
            }
        }

        .Connected_Parent_Disconnect_Cnt {
            padding: 10px;

            .Disconnect_Btn {
                border: 2px solid @backcolor--red;
                background-color: @backcolor--white;
                font-size: 15px;
                font-family: @font--poppins-500;
                color: @color--red;
                border-radius: 10px;
            }
        }
    }
}

@media only screen and (min-width: 769px) and (max-width: 899px) {
    .Connected_Parent_Card {
        background-color: @backcolor--white;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        padding: 5px;
        cursor: pointer;
        margin: 20px;
        display: flex;
        align-items: center;
        // &:hover {
        //     transition: all 0.2s ease-out;
        //     box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        //     top: -4px;
        //     border: 1px solid #cccccc;
        //     background-color: @backcolor--white;
        // }

        .Connected_Parent_Profile {
            display: flex;
            align-items: center;
        }

        .Connected_Parent_Name_Cnt {
            margin-left: 10px;
            width: 270px;
            margin-right: 10px;
            display: flex;
            align-items: center;

            .Connected_Parent_Name {
                font-size: 16px;
                font-family: @font--poppins-500;
                width: 270px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: @color--primary;
                margin-bottom: 0px;
            }

            .Connected_Parent_Title {
                font-size: 16px;
                font-family: @font--poppins-500;
                color: @color--secondary;
                margin-bottom: 0px;
            }
        }

        .Connected_Parent_Disconnect_Cnt {
            padding: 10px;

            .Disconnect_Btn {
                border: 2px solid @backcolor--red;
                background-color: @backcolor--white;
                font-size: 14px;
                font-family: @font--poppins-500;
                color: @color--red;
                border-radius: 10px;
            }
        }
    }
}

@media only screen and (min-width: 500px) and (max-width: 768px) {
    .Connected_Parent_Card {
        background-color: @backcolor--white;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        padding: 5px;
        cursor: pointer;
        margin: 20px;
        display: flex;
        align-items: center;
        // &:hover {
        //     transition: all 0.2s ease-out;
        //     box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        //     top: -4px;
        //     border: 1px solid #cccccc;
        //     background-color: @backcolor--white;
        // }

        .Connected_Parent_Profile {
            display: flex;
            align-items: center;
        }

        .Connected_Parent_Name_Cnt {
            margin-left: 10px;
            width: 150px;
            margin-right: 10px;
            display: flex;
            align-items: center;

            .Connected_Parent_Name {
                font-size: 16px;
                font-family: @font--poppins-500;
                width: 150px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: @color--primary;
                margin-bottom: 0px;
            }

            .Connected_Parent_Title {
                font-size: 16px;
                font-family: @font--poppins-500;
                color: @color--secondary;
                margin-bottom: 0px;
            }
        }

        .Connected_Parent_Disconnect_Cnt {
            padding: 10px;

            .Disconnect_Btn {
                border: 2px solid @backcolor--red;
                background-color: @backcolor--white;
                font-size: 14px;
                font-family: @font--poppins-500;
                color: @color--red;
                border-radius: 10px;
            }
        }
    }
}

@media only screen and (min-width: 416px) and (max-width: 499px) {
    .Connected_Parent_Card {
        background-color: @backcolor--white;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        padding: 5px;
        cursor: pointer;
        margin: 5px;
        display: flex;
        align-items: center;
        // &:hover {
        //     transition: all 0.2s ease-out;
        //     box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        //     top: -4px;
        //     border: 1px solid #cccccc;
        //     background-color: @backcolor--white;
        // }

        .Connected_Parent_Profile {
            display: flex;
            align-items: center;
        }

        .Connected_Parent_Name_Cnt {
            margin-left: 10px;
            width: 100px;
            margin-right: 10px;
            display: flex;
            align-items: center;

            .Connected_Parent_Name {
                font-size: 16px;
                font-family: @font--poppins-500;
                width: 100px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: @color--primary;
                margin-bottom: 0px;
            }

            .Connected_Parent_Title {
                font-size: 16px;
                font-family: @font--poppins-500;
                color: @color--secondary;
                margin-bottom: 0px;
            }
        }

        .Connected_Parent_Disconnect_Cnt {
            padding: 10px;

            .Disconnect_Btn {
                border: 2px solid @backcolor--red;
                background-color: @backcolor--white;
                font-size: 14px;
                font-family: @font--poppins-500;
                color: @color--red;
                border-radius: 10px;
            }
        }
    }
}

@media only screen and (min-width: 376px) and (max-width: 415px) {
    .Connected_Parent_Card {
        background-color: @backcolor--white;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        padding: 5px;
        cursor: pointer;
        display: flex;
        margin-bottom: 5px;
        align-items: center;
        // &:hover {
        //     transition: all 0.2s ease-out;
        //     box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        //     top: -4px;
        //     border: 1px solid #cccccc;
        //     background-color: @backcolor--white;
        // }

        .Connected_Parent_Profile {
            display: flex;
            align-items: center;
        }

        .Connected_Parent_Name_Cnt {
            margin-left: 5px;
            width: 100px;
            margin-right: 10px;
            display: flex;
            align-items: center;

            .Connected_Parent_Name {
                font-size: 14px;
                font-family: @font--poppins-500;
                width: 90px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: @color--primary;
                margin-bottom: 0px;
            }

            .Connected_Parent_Title {
                font-size: 14px;
                font-family: @font--poppins-500;
                color: @color--secondary;
                margin-bottom: 0px;
            }
        }

        .Connected_Parent_Disconnect_Cnt {
            padding: 10px;

            .Disconnect_Btn {
                border: 2px solid @backcolor--red;
                background-color: @backcolor--white;
                font-size: 12px;
                font-family: @font--poppins-500;
                color: @color--red;
                border-radius: 10px;
            }
        }
    }
}

@media only screen and (min-width: 358px) and (max-width: 375px) {
    .Connected_Parent_Card {
        background-color: @backcolor--white;
        border: 1px solid #e3e3e3;
        border-radius: 10px;
        padding: 5px;
        cursor: pointer;
        display: flex;
        margin-bottom: 5px;
        align-items: center;
        // &:hover {
        //     transition: all 0.2s ease-out;
        //     box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        //     top: -4px;
        //     border: 1px solid #cccccc;
        //     background-color: @backcolor--white;
        // }

        .Connected_Parent_Profile {
            display: flex;
            align-items: center;
        }

        .Connected_Parent_Name_Cnt {
            margin-left: 5px;
            width: 80px;
            margin-right: 10px;
            display: flex;
            align-items: center;

            .Connected_Parent_Name {
                font-size: 14px;
                font-family: @font--poppins-500;
                width: 80px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: @color--primary;
                margin-bottom: 0px;
            }

            .Connected_Parent_Title {
                font-size: 12px;
                font-family: @font--poppins-500;
                color: @color--secondary;
                margin-bottom: 0px;
            }
        }

        .Connected_Parent_Disconnect_Cnt {
            padding: 10px;

            .Disconnect_Btn {
                border: 2px solid @backcolor--red;
                background-color: @backcolor--white;
                font-size: 10px;
                font-family: @font--poppins-500;
                color: @color--red;
                border-radius: 10px;
            }
        }
    }
}

@media only screen and (min-width: 320px) and (max-width: 357px) {
    .Connected_Account_Page {
        .Connected_Account_Card {
            min-height: 550px;

            .Connected_Parent_Card {
                background-color: @backcolor--white;
                border: 1px solid #e3e3e3;
                border-radius: 10px;
                padding: 5px;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-bottom: 5px;

                // &:hover {
                //     transition: all 0.2s ease-out;
                //     box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
                //     top: -4px;
                //     border: 1px solid #cccccc;
                //     background-color: @backcolor--white;
                // }

                .Connected_Parent_Profile {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .Connected_Parent_Name_Cnt {
                    margin-left: 5px;
                    width: 220px;
                    margin-right: 10px;
                    margin-top: 10px;
                    display: flex;
                    align-items: center;

                    .Connected_Parent_Name {
                        font-size: 14px;
                        font-family: @font--poppins-500;
                        width: 220px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: @color--primary;
                        margin-bottom: 0px;
                    }

                    .Connected_Parent_Title {
                        font-size: 16px;
                        font-family: @font--poppins-500;
                        color: @color--secondary;
                        margin-bottom: 0px;
                    }
                }

                .Connected_Parent_Disconnect_Cnt {
                    padding: 10px;
                    display: flex;
                    justify-content: center;

                    .Disconnect_Btn {
                        border: 2px solid @backcolor--red;
                        background-color: @backcolor--white;
                        font-size: 14px;
                        font-family: @font--poppins-500;
                        color: @color--red;
                        border-radius: 10px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 280px) and (max-width: 319px) {
    .Connected_Account_Page {
        .Connected_Account_Card {
            min-height: 550px;

            .Connected_Parent_Card {
                background-color: @backcolor--white;
                border: 1px solid #e3e3e3;
                border-radius: 10px;
                padding: 5px;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-bottom: 5px;

                // &:hover {
                //     transition: all 0.2s ease-out;
                //     box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
                //     top: -4px;
                //     border: 1px solid #cccccc;
                //     background-color: @backcolor--white;
                // }

                .Connected_Parent_Profile {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .Connected_Parent_Name_Cnt {
                    margin-left: 5px;
                    width: 162px;
                    margin-right: 10px;
                    margin-top: 10px;

                    .Connected_Parent_Name {
                        font-size: 14px;
                        font-family: @font--poppins-500;
                        width: 162px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: @color--primary;
                    }

                    .Connected_Parent_Title {
                        font-size: 16px;
                        font-family: @font--poppins-500;
                        color: @color--secondary;
                        margin-bottom: 0px;
                    }
                }

                .Connected_Parent_Disconnect_Cnt {
                    padding: 10px;
                    display: flex;
                    justify-content: center;

                    .Disconnect_Btn {
                        border: 2px solid @backcolor--red;
                        background-color: @backcolor--white;
                        font-size: 14px;
                        font-family: @font--poppins-500;
                        color: @color--red;
                        border-radius: 10px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 279px) {
    .Connected_Account_Page {
        .Connected_Account_Card {
            min-height: 550px;

            .Connected_Parent_Card {
                background-color: @backcolor--white;
                border: 1px solid #e3e3e3;
                border-radius: 10px;
                padding: 5px;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-bottom: 5px;

                // &:hover {
                //     transition: all 0.2s ease-out;
                //     box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
                //     top: -4px;
                //     border: 1px solid #cccccc;
                //     background-color: @backcolor--white;
                // }

                .Connected_Parent_Profile {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .Connected_Parent_Name_Cnt {
                    margin-left: 5px;
                    width: 162px;
                    margin-right: 10px;
                    margin-top: 10px;

                    .Connected_Parent_Name {
                        font-size: 14px;
                        font-family: @font--poppins-500;
                        width: 162px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: @color--primary;
                    }

                    .Connected_Parent_Title {
                        font-size: 16px;
                        font-family: @font--poppins-500;
                        color: @color--secondary;
                        margin-bottom: 0px;
                    }
                }

                .Connected_Parent_Disconnect_Cnt {
                    padding: 10px;
                    display: flex;
                    justify-content: center;

                    .Disconnect_Btn {
                        border: 2px solid @backcolor--red;
                        background-color: @backcolor--white;
                        font-size: 14px;
                        font-family: @font--poppins-500;
                        color: @color--red;
                        border-radius: 10px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 375px) and (max-width: 472px) {
    .Connected_Account_Page {
        .Connected_Account_Card {
            .sendInvite__form_inner {
                margin-top: 26px;
                width: 300px;
            }
        }
    }
}

@media only screen and (min-width: 280px) and (max-width: 374px) {
    .Connected_Account_Page {
        .Connected_Account_Card {
            padding: 7px;

            .sendInvite__form_inner {
                margin-top: 26px;
                width: 210px;
            }
        }
    }
}

@media only screen and (max-width: 279px) {
    .Connected_Account_Page {
        .Connected_Account_Card {
            padding: 4px;

            .sendInvite__form_inner {
                margin-top: 26px;
                width: 204px;
            }
        }
    }
}

.Connected_Account_Page {
    .Connected_Account_Card {
        .Connected_Account_Card_Mid {
            .Invite_Connected_Account_Card_Top {
                .Invite_Connected_Account_Card_Title {
                    font-size: 20px;
                    font-family: @font--poppins-500;
                }
            }
        }
    }
}

.planviewcnt {
    .planviewcnttopsec {
        .My_Work_MidSection_Desktop_Tabs {
            display: block;
        }

        .My_Work_MidSection_Mobile_Tabs {
            display: none;
        }
    }
}

@media only screen and (max-width: 768px) {
    .planviewcnt {
        .planviewcnttopsec {
            .My_Work_MidSection_Desktop_Tabs {
                display: none;
            }

            .My_Work_MidSection_Mobile_Tabs {
                display: block;

                .CreateMilestonebtn {
                    background-color: @backcolor--primary;
                    color: #ffff;
                    border-radius: 10px;
                    height: 40px;
                    text-transform: capitalize;
                    font-size: 18px;
                    font-weight: @font--poppins-500;
                }
            }
        }
    }
}