@import "../../../../../assets/css/variables.less";

.semester-section {

    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .semesterCard {
        border-radius: 10px;
        border: 2px solid #E3E3E3;
        background: #FFF;
        display: flex;
        flex-direction: column;
        max-width: 340px;

        .MuiButtonBase-root-MuiIconButton-root {
            padding-left: 0px !important;
        }


        .semseterfield__section {
            display: flex;
            gap: 10px;
            max-width: 340px;
            padding: 10px 15px;
            margin-top: 15px;

            .MuiOutlinedInput-root:hover {
                border: 1px solid rgba(0, 0, 0, 0.87) !important;
            }

            .MuiOutlinedInput-input {
                font-size: 12x !important;
                font-family: @font--poppins-regular;
            }
        }
    }

    .semesterCard__active {
        transition: all 0.2s ease-out;
        box-shadow: 0px 4px 8px rgba(46, 111, 252, 0.466);
        top: -4px;
        background-color: @backcolor--white;
        border-radius: 10px;
        border: 2px solid #1C84EE;
        background: #FFF;
        display: flex;
        flex-direction: column;
        max-width: 340px;

        .MuiButtonBase-root-MuiIconButton-root {
            padding-left: 0px !important;
        }

        .semseterfield__section {
            display: flex;
            gap: 10px;
            max-width: 300px;
            padding: 10px 15px;
            margin-top: 15px;
            max-width: 340px;

            .MuiOutlinedInput-root:hover {
                border: 1px solid rgba(0, 0, 0, 0.87) !important;
            }

            .MuiOutlinedInput-input {
                font-size: 12x !important;
                font-family: @font--poppins-regular;
            }
        }
    }

    .semTopSection {
        height: 33px;
        border-radius: 8.5px 8.5px 0px 0px;
        background: #1C84EE;
        color: #FFF;
        font-family: @font--poppins-500;
        font-size: 14px;
        text-align: center;
    }

    .SemesterDuration {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }

    .SemesterDataSection {
        .MuiOutlinedInput-root {
            height: 40px !important;
        }

        .error-text {
            font-size: 11px;
            color: @color--red;
            font-family: @font--poppins-regular;
            padding-left: 17px;
            background-color: transparent;
        }

        .tag__tags-chip {
            display: flex;
            align-items: center;
        }
    }

    .subSemHeading {
        color: #000;
        font-family: @font--poppins-500;
        font-size: 16px;
        text-align: center;
    }

    .phaseNewBtn {
        height: fit-content;
        border-radius: 20px;
        width: fit-content;
        padding-left: 10px;
        padding-right: 10px;
        padding-block: 2px;
        &--disabled{
            height: fit-content;
            border-radius: 20px;
            width: fit-content;
            padding-left: 10px;
            padding-right: 10px;
            padding-block: 2px;
            background-color: #b7b8b98f !important;
        }
    }
}





@media screen and (max-width: 320px) {
    .semesterSwitch {
        justify-content: center !important;
    }

    .semesterCard {
        max-width: 260px !important;
        justify-content: center !important;
    }

    .semesterCard__active {
        max-width: 260px !important;
        justify-content: center !important;
    }
}

@media screen and (max-width: 298px) {
    .semesterCard {
        max-width: 237px !important;
        justify-content: center !important;
    }

    .semesterCard__active {
        max-width: 237px !important;
        justify-content: center !important;
    }
}

@media screen and (max-width: 319px) {
    .MuiCalendarOrClockPicker-root {
        width: 260px !important;

        .css-epd502 {
            width: 260px !important;
            margin: 0px !important;
        }

        .MuiCalendarPicker-root {
            margin: 5px !important;

            .MuiPickersCalendarHeader-root {
                margin-left: 0px !important;
                padding-left: 10px !important;

                .MuiPickersCalendarHeader-labelContainer {
                    font-size: 0.8rem !important;
                }
            }
        }
    }
}

@media screen and (max-width: 320px) {
.MuiModal-root-MuiDialog-root, .MuiDialog-paper{
    max-width: 270px !important;
    min-width: 270px !important;
}
}