@import "common.less";
@import "variables.less";

body {
    margin: 0px;
}

.sidebar {
    .active {
        color: #fcfcfc;
    }

    width: 100px;

    &__light {
        background-color: #fff;
        height: 100vh;
        position: fixed;
        top: 70px;
        z-index: 9;
    }

    &__nav {
        width: 100%;
        display: block;

        ul {
            padding: 0px;

            li {
                display: block;

                a {
                    padding: 10px 0px;
                    display: block;
                    font-size: 14px;
                }
            }

            &.main-navbar {
                height: calc(100vh - 250px);
                overflow: auto;

            }
        }
    }
}

.profileimage {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    object-fit: cover;
}

.navbar {
    &__mobile__view {
        width: 50px;
        height: 50px;
        background-color: #1C84EE;
        text-align: center;
        position: fixed;
        top: 0;
        z-index: 999;

        a {
            line-height: 50px;
        }
    }

    &__brand {
        width: 100px;
        height: 70px;
        background-color: #1C84EE;
        position: fixed;

        a {
            line-height: 70px;
        }

        &_icon {
            line-height: 70px;
        }
    }
}

.navbar__mobile__view {
    display: none;
}

.profile-menu {
    position: relative;
}

.profile-menu-list {
    display: none;
    position: absolute;
    z-index: 1000;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 0.25rem;
    bottom: 0px;
    left: 100px;

    li {
        height: auto !important;

        &:hover {
            background-color: rgba(63, 81, 181, 0.1);
        }
    }

    .dropdown-item {
        cursor: pointer;
        display: block;
        width: 100%;
        padding: 10px 15px 15px 15px; 
        clear: both;
        font-weight: 400;
        color: #212529;
        text-align: inherit;
        text-decoration: none;
        border: 0;
        background-color: #fff;
        &:hover {
            background-color: rgba(63, 81, 181, 0.1);
        }
    }
}

@media only screen and (max-width: 768px) {
    .sidebar {
        &__light {
            top: 50px;

        }
    }

    .sidebarcnt {
        .navbar__view {
            .sidebar__light {
                display: none;
                left: 0;
                width: 100px;
                height: 100%;
                // overflow: hidden;
                z-index: 999;
                transform: translateX(100%);
                -webkit-transform: translateX(100%);

                &.active {
                    display: block;
                    z-index: 999;
                    animation: slide-in 0.5s forwards;
                    -webkit-animation: slide-in 0.5s forwards;
                }

                &.remove {
                    animation: slide-out 0.5s forwards;
                    -webkit-animation: slide-out 0.5s forwards;
                }

                @keyframes slide-in {
                    0% {
                        -webkit-transform: translateX(-100%);
                    }

                    100% {
                        -webkit-transform: translateX(0);
                    }
                }

                @-webkit-keyframes slide-in {
                    0% {
                        transform: translateX(-100%);
                    }

                    100% {
                        transform: translateX(0);
                    }
                }

                @keyframes slide-out {
                    0% {
                        transform: translateX(0%);
                    }

                    100% {
                        transform: translateX(-100%);
                    }
                }

                @-webkit-keyframes slide-out {
                    0% {
                        -webkit-transform: translateX(0%);
                    }

                    100% {
                        -webkit-transform: translateX(-100%);
                    }
                }
            }

            .navbar__brand {
                display: none;
            }
        }

        .navbar__mobile__view {
            display: block;
        }
    }

    .dashtopsec {
        margin-left: 50px;
        background: #fff;
        padding: 5px 10px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 99;
        border-bottom: 1px solid #ddd;

        &-text {
            h3 {
                font-size: 16px;
                margin-bottom: 4px;
            }

            p {
                font-size: 12px;
            }
        }
    }

    .profile-menu-list.show {
        display: block;
    }
}

@media only screen and (min-width: 768px) {
    .profile-menu {
        .profile-menu-item {
            &:hover {
                .profile-menu-list {
                    display: block;
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .childAccount {
        width: 97%;
    }
}


/* Basic styles for nav links */
nav a {
    background-color: transparent;
    color: #000 !important;

    &:hover {
        background-color: rgba(63, 81, 181, 0.1);
    }
}

/* Specific styles for non-active links */
.link {
    color: white;
}

/* Specific styles for active links */
.link-active {
    background: rgba(63, 81, 181, 0.1);
    display: block;
    padding: 10px 0px;
}

.childviewcnt {
    display: flex;
    justify-content: center;

    .childbox {
        height: 28px;
        background-color: #21252940;
        border-radius: 6px;
        margin-top: 10px;
        cursor: pointer;
        padding: 2px;

        p {
            width: 50px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

    }
}

.popupchildcardcnt {
    font-family: @font--poppins-500;

    .childcard {
        border: 1px solid #9F9F9F;
        border-radius: 10px;
        padding: 10px;
        // margin: 0px 50px 25px 50px;

        @media only screen and (max-width: 990px) {
            margin: 0;
            margin-bottom: 20px;
        }

        &__wrapper {
            display: flex;
            width: 100%;

            @media only screen and (max-width: 990px) {
                display: block;
            }

            &__right {
                display: flex;
                width: 60%;

                @media only screen and (max-width: 990px) {
                    width: 100%;
                }

                &__container {
                    display: flex;

                    &__profile-pic {
                        & img {
                            width: 100px;
                            height: 100px
                        }
                    }

                    &__content {
                        margin-left: 10px;
                        text-transform: capitalize;

                        & span {
                            color: #807c7c;
                            font-weight: 500;
                        }
                    }
                }
            }

            &__left {
                width: 40%;

                @media only screen and (max-width: 990px) {
                    width: 100%;
                    margin-top: 10px;
                }

                & p {
                    font-size: small;
                    color: #5C5C5C;
                }

                &__button {
                    margin-top: 30px;
                    font-size: x-large;
                    text-align: center;
                    color: #1C4BF1;
                    font-weight: 500;
                    cursor: pointer;
                }
            }
        }

    }
}

.modal-header {
    font-family: @font--poppins-500;
    border: none !important;
    margin-left: 20px;
    font-size: larger;
    margin-bottom: 0 !important;
}