@import "../../../../../assets/css/variables.less";

.courseFullViewCard {
    padding: 30px 50px;
    border-radius: 15px;
    border: 2px solid #e3e3e3;
    background: #fff;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__topSection {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__title {
            font-size: 19px;
            color: #000;
            margin-bottom: 0;
            font-family: @font--poppins-500;
            word-break: break-word;
            margin-right: 15px;
        }

        &__successStatus {
            padding: 5px 15px;
            border-radius: 20px;
            color: #fff;
            background-color: #29a016;
            font-family: @font--poppins-regular;
            font-size: 14px;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }

        &__upCommingStatus {
            padding: 5px 15px;
            border-radius: 20px;
            color: #fff;
            background-color: #E64A19;
            font-family: @font--poppins-regular;
            font-size: 14px;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }
        &__inProgressStatus {
            padding: 5px 15px;
            border-radius: 20px;
            color: #fff;
            background-color: #1C84EE;
            font-family: @font--poppins-regular;
            font-size: 14px;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }
    }

    &__midSections {
        .label {
            font-size: 17px;
            color: #000;
            margin-bottom: 0;
            font-family: @font--poppins-500;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
            span {
                font-size: 16px;
                color: #515050;
                margin-bottom: 0;
                font-family: @font--poppins-regular;
                margin-left: 15px;
            }
        }
    }

    &__midSection {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 10px;

        span {
            display: flex;
            justify-content: space-between;
            gap: 10px;

            .label {
                font-size: 17px;
                color: #000;
                margin-bottom: 0;
                font-family: @font--poppins-500;
            }

            .value {
                font-size: 16px;
                color: #515050;
                margin-bottom: 0;
                font-family: @font--poppins-regular;
            }
        }

        .label {
            font-size: 16px;
            color: #000;
            margin-bottom: 0;
            font-family: @font--poppins-500;
        }
    }

    &__bottomSec {
        &__detailedDescription {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            word-break: break-word;
            font-size: 16px;
            color: #515050;
            margin-bottom: 0;
            font-family: @font--poppins-regular;
            cursor: pointer;

            &:hover {
                background-color: rgba(227, 227, 227, 0.133);
                border-radius: 5px;
            }
        }

        &__description {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            word-break: break-word;
            font-size: 16px;
            color: #515050;
            margin-bottom: 0;
            font-family: @font--poppins-regular;
            cursor: pointer;
        }

        &__viewCta {
            display: inline;
            margin-bottom: 0;
            margin-top: 10px;
            color: #1C84EE;
            cursor: pointer;
        }
    }
}

@media only screen and (max-width: 768px) {
    .courseFullViewCard {
        padding: 30px 20px;

        &__topSection {
            &__title {
                font-size: 17px;
            }

            &__status {
                padding: 3px 15px;
                font-size: 14px;
            }
        }

        &__midSection {
            flex-direction: column;

            span {
                justify-content: flex-start;

                .label {
                    font-size: 16px;
                }

                .value {
                    font-size: 14px;
                }
            }
        }
    }
}

@media only screen and (max-width: 425px) {
    .courseFullViewCard {
        padding: 30px 10px;

        &__topSection {
            &__title {
                font-size: 16px;
            }

            &__status {
                padding: 3px 12px;
                font-size: 13px;
            }
        }

        &__midSection {
            span {
                .label {
                    font-size: 16px;
                }

                .value {
                    font-size: 14px;
                }
            }
        }
    }
}