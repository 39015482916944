@import "../../../../../assets/css/variables.less";

.SentRequest {
    .AdvisorCard {
        background-color: @backcolor--white;
        border: 3px solid #e3e3e3;
        border-radius: 10px;
        padding: 10px;
        width: 300px;

        .profile-img {
            width: 60px !important;
            height: 60px !important;
            border-radius: 6px !important;
            object-fit: cover !important;
        }

        &__Pending {
            border: 3px solid #1c85ee81;

            &:hover {
                border: 3px solid #1C84EE;
            }
        }

        &__Accepted {
            border: 3px solid rgba(41, 160, 22, 0.50);

            &:hover {
                border: 3px solid rgba(41, 160, 22, 1);
            }
        }

        &__Rejected {
            border: 3px solid #e6491975;

            &:hover {
                border: 3px solid #E64A19;
            }
        }

        &__Pending {
            .PillStatus__Pending {
                p {
                    font-size: 9px;
                }
            }
        }

        &__Accepted {
            .PillStatus__Accepted {
                width: 110px;

                p {
                    font-size: 9px;
                }
            }
        }

        &__Rejected {
            .PillStatus__Rejected {
                width: 110px;

                p {
                    font-size: 9px;
                }
            }
        }

        .advisor-card-content {
            background-color: #f4f5f8;
            padding: 10px;
            border-radius: 10px;
            font-family: @font--poppins-regular;
        }

        .advisor-subText {
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }

        .service-heading {
            font-family: @font--poppins-500;
        }

        .advisor-card-description {
            background-color: #f4f5f8;
            padding: 10px;
            border-radius: 10px;
            font-family: @font--poppins-regular;

            p {
                margin-bottom: 0px;
                text-overflow: ellipsis;
                font-family: @font--poppins-regular;
                overflow: hidden;
                -webkit-box-orient: vertical;
                max-height: 72px;
                margin: 0;
                -webkit-line-clamp: 3;
                // word-break: break-word;
                display: -webkit-box;
            }
        }
    }

    &__AdvisorFooter {
        display: flex;
        width: 100%;
        flex-direction: column;
        height: 110px;
        justify-content: end;
    }

    @media screen and (max-width: 320px) {
        .AdvisorCard {
            &__Pending {
                width: 260px;

                .PillStatus__Pending {
                    p {
                        font-size: 8px;
                    }
                }
            }

            &__Accepted {
                width: 260px;

                .PillStatus__Accepted {
                    p {
                        font-size: 8px;
                    }
                }
            }

            &__Rejected {
                width: 260px;

                .PillStatus__Rejected {
                    p {
                        font-size: 8px;
                    }
                }
            }

            .personicon {
                width: 50px;
                height: 50px !important;
            }
        }
    }
}