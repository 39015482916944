@import "variables.less";
@import "fonts.less";

/*network tab */
.networktab {
    &__top-text h3 {
        font-family: @font--poppins-500;
        font-size: 30px;
    }
}

/*tab */
.tab-container {
    .nav-pills {
        border-bottom: 1px solid #ececec;
    }

    .nav-link {
        color: @color--primary !important;
        font-size: 15px !important;
        font-family: @font--poppins-500;
        padding-left: 8px;
    }

    .nav-pills .nav-link.active,
    .nav-pills .show > .nav-link {
        color: #1c84ee !important;
        background-color: transparent !important;
        border-bottom: 2px solid #1c84ee;
        border-radius: unset;
        padding-left: 8px;
    }
}

.feebackbtn {
    display: flex;
    justify-content: center;
    width: 180px;
    height: 40px;
    border: 1px solid @backcolor--primary;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
}

/* advisor */
.advisor-friends {
    .advisor-top {
        font-size: 20px;
        font-family: @font--poppins-600;
    }

    .tab-cta {
        height: 40px;
        -webkit-user-select: none;
        user-select: none;
        cursor: default;
        background: #1c84ee;
        border-radius: 10px;
        color: #fff;
        width: 140px;
        display: block;
        line-height: 36px;
        margin: 0 auto;
        text-align: center;
        border: 2px solid #1c84ee;
    }

    .tab-content {
        .sub-title {
            font-size: 15px;
            font-family: @font--poppins-500;
            color: @color--text-gray;
            text-align: center;
            margin-top: 25px;
        }

        span {
            text-decoration: underline;
            color: @backcolor--primary;
            padding-left: 6px;
        }

        .box-network {
            display: flex;
            justify-content: center;
            margin-top: 80px;

            .boxnetwork {
                width: 450px;
                height: 250px;
                background: #ffffff;
                border: 4px solid #ececec;
                border-radius: 20px;
            }
        }
    }
}
.advior-table-section {
    &__title {
        font-size: 14px;
        font-family: @font--poppins-600;
        color: @color--primary;
    }
    &__img {
        width: 40px;
        height: 40px;
    }
    &__location {
        font-size: 12px;
        font-family: @font--poppins-regular;
        color: #525252;
    }
    &__university {
        font-size: 12px;
        font-family: @font--poppins-regular;
        color: #525252;
    }
    &__content span {
        background: #dfdfdf;
        border-radius: 8px;
        width: 209px;
        height: 64px;
        font-family: @font--poppins-regular;
        font-size: 13px;
        line-height: 27px;
        color: #1b1c1e;
        padding: 4px 15px;
        display: inline-block;
    }
    &__green-button {
        height: 40px;
        margin-left: 8px;
        cursor: pointer;
        background-color: #f5f6f9;
        border-radius: 10px;
        color: #34c38f;
        width: 135px;
        display: block;
        line-height: 34px;
        text-align: center;
        border: 1px solid #34c38f;
    }
    &__red-button {
        height: 40px;
        margin-left: 8px;
        cursor: pointer;
        background-color: #f5f6f9;
        border-radius: 10px;
        color: #fa5f1c;
        width: 135px;
        display: block;
        line-height: 34px;
        text-align: center;
        border: 1px solid #fa5f1c;
    }
    .advisor-card {
        width: 360px;
    }
    .top {
        height: 16px;
        margin-top: 16px;
    }
}

//advisor wrap
@media (max-width: 768px) {
    .advisor-wrap {
        padding: 10px;
    }
}

//common  start
.text-rights {
    text-align: right;
}

.float-rights {
    float: right;
}

.cursorshow {
    cursor: pointer;
}

//commom end

/* advisor card */
.advisor-card {
    .card {
        &__item {
            &__lists {
                background: #ffffff;
                border: 1px solid #dad5d530;
                border-radius: 24px;
                padding: 2px 24px;
                margin-top: 8px;
                min-height: 327px;

                &:hover {
                    transition: all 0.2s ease-out;
                    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
                    background: #ffffff;
                    border: 1px solid #dad5d530;
                    border-radius: 24px;
                    padding: 2px 24px;
                    margin-top: 8px;
                    min-height: 327px;
                }

                &__card-height {
                    background: #ffffff;
                    border: 1px solid #ececec;
                    box-shadow: 0 0 5px 2px #ececec;
                    border-radius: 24px;
                    padding: 2px 24px;
                    margin-top: 8px;
                    min-height: 100%;
                }

                &__icon {
                    width: 70px;
                    height: 72px;
                }

                &__session_left {
                    font-family: @font--poppins-600;
                    font-size: 15px;
                    line-height: 24px;
                    text-align: left;
                    color: #1b1c1e;
                    margin-bottom: 8px;
                    padding-top: 13px;

                    p {
                        margin-bottom: 0px;
                        padding-left: 9px;
                    }
                }

                &__rating_left {
                    font-family: @font--poppins-regular;
                    font-size: 12px;
                    line-height: 16px;
                    text-align: left;
                    color: #000000;

                    p {
                        margin-bottom: 0px;
                        display: inline-flex;
                        padding-left: 9px;
                    }
                }

                &__titel {
                    font-family: @font--poppins-600;
                    font-size: 18px;
                    line-height: 24px;
                    color: #1b1c1e;
                    margin-top: 17px;
                }

                &__location {
                    margin-top: 8px;

                    p {
                        margin-bottom: 0px;

                        span {
                            font-family: @font--poppins-regular;
                            font-size: 12px;
                            line-height: 16px;
                            color: #525252;
                        }
                    }
                }

                &__tags {
                    margin-top: 5px;

                    span {
                        background: #dfdfdf;

                        border-radius: 8px;
                        height: 26px;
                        font-family: @font--poppins-regular;
                        font-size: 13px;
                        line-height: 26px;
                        color: @color--primary;
                        padding: 0px 15px;
                        display: inline-block;
                        margin: 6px;
                    }

                    .tg-clr {
                        background: #f8f8f8;
                    }
                }

                &__cta {
                    color: #3e3e3e;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 26px;
                    text-align: center;
                    display: none;
                    margin-top: 26px;
                }
            }

            &:hover {
                .owl__item__lists {
                    border: 2px solid #3c3f51;
                }

                .owl__item__lists__cta {
                    display: block;
                }
            }
        }
    }

    .cardgrid {
        position: relative;
        overflow: hidden;
        display: inline-block;
        padding-right: 20px;
        width: 25%;

        &__dot {
            text-align: right;
            cursor: pointer;
            margin-left: 15px;
        }
    }

    .dropdown-menu.show {
        position: absolute !important;
        background-color: #dfdfdf;
        inset: 29px 0px auto !important;
        transform: translate(-36px, 0px) !important;
    }
    .dropdown-toggle::after {
        color: transparent !important;
    }
    .request-card {
        .dropdown-menu.show {
            position: absolute !important;
            background-color: #dfdfdf;
            inset: 29px 0px auto !important;
            transform: translate(-76px, 0px) !important;
        }
    }

    .cardgrid__dot {
        .btn-primary {
            background-color: transparent !important;
            border-color: transparent !important;
        }

        .btn-primary:hover {
            background-color: transparent !important;
            border-color: transparent !important;
        }

        .primary.dropdown-toggle {
            background-color: transparent !important;
            border-color: transparent !important;
        }

        .btn-primary.dropdown-toggle:focus {
            box-shadow: 0 0 0 0.25rem transparent !important;
        }
    }

    .dropdown-item:hover {
        background-color: #dfdfdf;
    }

    .dropdown-item {
        color: @color--primary !important;
        font-size: 12px;
        font-family: @font--poppins-regular;
        padding-top: 4px !important;
    }

    .dropdown-menu {
        border: 1px solid #dfdfdf;
        border-radius: 8px;
    }
}

@media (min-width: 320px) and (max-width: 385px) {
    //button
    .advisor-button {
        .cta-primary {
            font-size: 12px !important;
        }
    }

    .advisor-friends .advisor-top {
        font-size: 15px;
    }
}

/*explore card */
.explore-card {
    .card {
        &__item {
            &__lists {
                background: #ffffff;
                border: 1px solid #dad5d530;
                border-radius: 24px;
                padding: 2px 24px;
                margin-top: 8px;
                min-height: 327px;

                &:hover {
                    transition: all 0.2s ease-out;
                    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
                    background: #ffffff;
                    border: 1px solid #dad5d530;
                    border-radius: 24px;
                    padding: 2px 24px;
                    margin-top: 8px;
                    min-height: 327px;
                }

                &__card-height {
                    background: #ffffff;
                    border: 1px solid #ececec;
                    box-shadow: 0 0 5px 2px #ececec;
                    border-radius: 24px;
                    padding: 2px 24px;
                    margin-top: 8px;
                    min-height: 100%;
                }

                &__icon {
                    width: 70px;
                    height: 72px;
                }

                &__session_left {
                    font-family: @font--poppins-600;
                    font-size: 15px;
                    line-height: 24px;
                    text-align: left;
                    color: #1b1c1e;
                    margin-bottom: 8px;

                    p {
                        margin-bottom: 0px;
                        padding-left: 9px;
                    }
                }

                &__rating_left {
                    font-family: @font--poppins-regular;
                    font-size: 12px;
                    line-height: 16px;
                    text-align: left;
                    color: #000000;

                    p {
                        margin-bottom: 0px;
                        display: inline-flex;
                        padding-left: 9px;
                    }
                }

                &__titel {
                    font-family: @font--poppins-600;
                    font-size: 18px;
                    line-height: 24px;
                    color: #1b1c1e;
                    margin-top: 17px;
                }

                &__location {
                    margin-top: 8px;

                    p {
                        margin-bottom: 0px;

                        span {
                            font-family: @font--poppins-regular;
                            font-size: 12px;
                            line-height: 16px;
                            color: #525252;
                        }
                    }
                }

                &__tags {
                    margin-top: 5px;

                    span {
                        background: #dfdfdf;

                        border-radius: 8px;
                        height: 26px;
                        font-family: @font--poppins-regular;
                        font-size: 13px;
                        line-height: 26px;
                        color: @color--primary;
                        padding: 0px 15px;
                        display: inline-block;
                        margin: 6px;
                    }

                    .tg-clr {
                        background: #f8f8f8;
                    }
                }

                &__cta {
                    color: #3e3e3e;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 26px;
                    text-align: center;
                    display: none;
                    margin-top: 26px;
                }
            }

            &:hover {
                .owl__item__lists {
                    border: 2px solid #3c3f51;
                }

                .owl__item__lists__cta {
                    display: block;
                }
            }
        }
    }
}

//library
.library {
    &__search {
        margin-top: 36px;
    }
}

.librarytable {
    .library-top-text {
        font-family: @font--poppins-500;
        font-size: 25px;
    }

    .cta-document {
        height: 40px;
        -webkit-user-select: none;
        user-select: none;
        cursor: default;
        background: #1c84ee;
        border-radius: 10px;
        color: #fff;
        width: 140px;
        display: block;
        line-height: 36px;
        margin: 0 auto;
        text-align: center;
        border: 2px solid #1c84ee;
        font-size: 14px;
    }

    .fileType {
        width: 28px;
        height: 36px;
        font-weight: 500;
        font-size: 10px;
        line-height: 20px;
        border-radius: 2px;
        text-align: center;
        background-color: #fff;
        padding-top: 7px;
    }

    .cta--libbutton1 {
        font-size: 13px;
        border-radius: 8px;
        display: block;
        text-align: center;
        padding: 5px 12px;
        float: left;
    }

    .cta--libbutton2 {
        font-size: 13px;
        border-radius: 8px;
        display: block;
        text-align: center;
        padding: 5px 12px;
        float: left;
        margin-right: 7px;
    }

    .two-icon {
        margin-right: 19px;
    }

    .hovertbl tr td {
        .cta--libbutton2 {
            background-color: rgba(250, 95, 28, 0.5);
            border-color: rgba(250, 95, 28, 0.5);
            color: white;
        }

        .cta--libbutton1 {
            background-color: rgba(28, 133, 237, 0.5);
            border-color: rgba(28, 133, 237, 0.5);
            color: white;
        }
    }

    .hovertbl tr:hover td {
        background-color: #fff !important;
        cursor: pointer;

        .cta--libbutton2 {
            background-color: #fa5f1c;
            border-color: #fa5f1c;
            color: white;
        }

        .cta--libbutton1 {
            background-color: #1c84ee;
            border-color: #1c84ee;
            color: white;
        }
    }

    .libtable tr th {
        color: @color--text-gray;
        font-size: 14px;
        font-family: @font--poppins-regular;
        font-weight: 400;
    }

    .libtable {
        border-color: #fff !important;
        color: #fff !important;

        .document-title {
            font-family: @font--poppins-500;
            font-size: 14px;
            line-height: 22px;
            color: @color--primary;
            margin-bottom: 0px;
        }

        .document-date {
            font-size: 12px;
            line-height: 16px;
            color: @color--text-gray;
            font-family: @font--poppins-regular;
        }

        .document-update {
            font-family: @font--poppins-500;
            font-size: 12px;
            line-height: 16px;
            color: @color--primary;
            padding-top: 11px;
            margin-right: 60px;
            width: 100%;
        }

        .document-desc {
            font-size: 12px;
            line-height: 16px;
            color: @color--text-gray;
            font-family: @font--poppins-regular;
            padding-top: 11px;
        }
    }

    .libtable tr td {
        color: #000 !important;
    }
}

/*media */
@media screen and (max-width: 768px) {
    .tab-heading {
        padding: 10px 10px 10px 70px;
        background-color: #fff;
        position: fixed;
        left: 0;
        right: 0px;
        z-index: 99;
        border-bottom: 1px solid #ddd;
        min-height: 50px;
        top: 0px;

        .network-top-text {
            margin-bottom: 0px !important;
            display: flex;
            justify-content: center;
        }

        .library-top-text {
            margin-bottom: 0px !important;
            display: flex;
            justify-content: center;
        }

        .topHeader-text h3 {
            font-size: 22px !important;
        }
    }

    .display-view {
        display: none;
    }

    .wrap-space {
        padding: 0px 10px 10px 10px;
    }

    .pg-top {
        padding-top: 50px;
    }

    .none-text {
        font-size: 20px !important;
        padding-top: 14px !important;
    }

    .arrow-view {
        padding-top: 14px !important;
    }

    //profile-about
    .profile-card {
        .empty-div {
            display: none;
        }

        .pleft-zero {
            padding-left: 0px;
        }

        .pright-zero {
            padding-right: 0px;
        }
    }

    .docs-view-popup {
        .imFHXY {
            padding-top: 45px;
        }
    }
}

@media screen and (max-width: 350px) {
    .advisor-card {
        .dropdown-menu.show {
            transform: translate(-77px, 0px) !important;
        }
    }
}

@media (min-width: 482px) and (max-width: 575px) {
    .explore-card {
        .float-rights {
            float: none;
            margin-left: 45px;
        }
    }
}

@media (min-width: 1800px) and (max-width: 1920px) {
    .fnt-20 {
        font-size: 20px !important;
    }

    .fnt-16 {
        font-size: 17px !important;
    }

    .fnt-14 {
        font-size: 14px !important;
    }
}

@media (min-width: 1559px) and (max-width: 1921px) {
    .advisor-grid {
        .col-xxl-3 {
            width: 20% !important;
        }
    }

    .explore-grid {
        .col-xxl-3 {
            width: 20% !important;
        }
    }
}

//carousel
.explore-list {
    .owl {
        &__item {
            &__desc {
                min-height: 1px !important;
            }

            &__lists {
                background: #ffffff;
                border: 2px solid #dad5d55c !important;
                border-radius: 24px;
                padding: 24px;
                margin-top: 8px;
                min-height: 360px;

                &__session {
                    font-family: @font--poppins-600 !important;
                    font-size: 15px !important;
                    line-height: 24px;
                    text-align: left !important;
                    color: #1b1c1e;
                    margin-bottom: 8px !important;
                    padding-top: 13px !important;

                    p {
                        margin-bottom: 0px;
                        padding-left: 9px !important;
                    }
                }

                &__rating {
                    font-family: @font--poppins-regular !important;
                    font-size: 12px !important;
                    line-height: 16px;
                    text-align: left !important;
                    color: #000000;

                    p {
                        margin-bottom: 0px;
                        display: inline-flex;
                        padding-left: 9px !important;
                    }
                }

                &__titel {
                    font-family: @font--poppins-600 !important;
                    font-size: 18px;
                    line-height: 24px;
                    color: #1b1c1e;
                    margin-top: 17px;
                }

                &__location {
                    margin-top: 8px;

                    p {
                        margin-bottom: 0px;

                        span {
                            font-family: @font--poppins-regular !important;
                            font-size: 12px;
                            line-height: 16px;
                            color: #525252;
                        }
                    }
                }

                &__tags {
                    margin-top: 26px;

                    span {
                        background: #dfdfdf !important;
                        border-radius: 8px;
                        height: 26px;
                        font-family: @font--poppins-regular !important;
                        font-size: 13px;
                        line-height: 26px;
                        color: #1b1c1e;
                        padding: 0px 15px;
                        display: inline-block;
                        margin: 6px;
                    }
                }
            }
        }
    }

    .SelectAdvisors__list {
        min-height: 410px !important;
    }
}

@media (min-width: 768px) {
    .none-view {
        display: none;
    }
}

// library
@media (max-width: 1170px) {
    .two-icon {
        .Two-Avatar-icon {
            width: 64px;
        }
    }
}

//profile

@media (max-width: 768px) {
    .profilepic {
        .card-img-top {
            height: 225px;
        }
    }
}

//page-profile
//profile -student say
.profile-student-say {
    .review-font {
        font-size: 40px !important;
        margin-left: 30px;
    }

    .profile-review {
        font-size: 22px;
    }

    .back-none {
        background-color: white;
    }

    .review-side {
        margin-left: 15px;
    }

    .rating-side {
        margin-left: 30px;
    }
}

//profil-reviews
.profil-reviews {
    .review-title {
        font-size: 19px !important;
        font-family: @font--poppins-500 !important;
        color: #3c3c3c;
    }

    .review-rating {
        font-size: 14px;
        font-family: @font--poppins-500 !important;
        color: #3c3c3c;
    }

    .student__sub-title {
        font-size: 15px;
        font-family: @font--poppins-regular;
        color: #5c5c5c;
    }

    .student__List {
        font-size: 14px;
        font-family: @font--poppins-regular;
        color: #1b1c1e;
        width: 90%;
        line-height: 23px;
    }
}

//review popup

.review-pop-sec {
    margin-left: 106px;
}

//package popup
.package {
    &__top {
        font-size: 18px;
        font-family: @font--poppins-500;
    }

    &__modals-body {
        padding-top: 0px !important;
    }

    &__tags {
        background: #dfdfdf !important;
        border-radius: 8px;
        height: 26px;
        font-family: @font--poppins-regular !important;
        font-size: 13px;
        line-height: 26px;
        color: #1b1c1e;
        padding: 0px 10px;
        display: inline-block;
        margin: 6px;
    }

    &__change-color {
        background-color: #1c84ee;
        color: white;
        border-radius: 8px;
        height: 26px;
        font-family: @font--poppins-regular !important;
        font-size: 13px;
        line-height: 26px;

        padding: 0px 10px;
        display: inline-block;
        margin: 6px;
    }

    &__profile-sub {
        font-family: @font--poppins-500 !important;
        font-size: 18px;
    }

    &__sub-heading {
        font-family: @font--poppins-regular !important;
        font-size: 16px;
    }
}

.feedrating {
    display: flex;
    justify-content: center;
}

.payment-method {
    .border-rad {
        border: 1px solid #e3e6e9;
        border-radius: 13px;
        text-align: center;
        width: 100px;
        height: 48px;
        line-height: 44px;
        margin-left: 33px;
    }

    &__popup-text {
        font-size: 12px;
        font-family: @font--poppins-regular !important;
        color: @color--text-gray;
        line-height: 150%;
    }
}

//error
.error-popup {
    &__text {
        font-size: 18px;
        font-family: @font--poppins-500;
    }
}

//profile top

.profilepic {
    .card {
        border: 1px solid rgb(255, 255, 255);
    }

    .card-img-top {
        height: 248px;
    }

    .card-title {
        font-size: 22px;
        font-family: @font--poppins-600;
    }

    .card-rating {
        display: -webkit-inline-box;

        &__rating-title {
            font-size: 16px;
            font-family: @font--poppins-regular;
        }

        &__star-rate {
            margin-top: -2px;
        }
    }

    .card-button {
        display: flex;
        justify-content: center;

        &__msg-button {
            border: 1px solid #1c84ee !important;
            border-radius: 5px;

            .text {
                font-size: 15px !important;
                font-family: @font--poppins-500;
                text-align: center;
            }
        }

        &__book-button {
            .text {
                font-size: 15px !important;
                font-family: @font--poppins-500;
            }
        }

        &__request-button {
            text-align: right;

            .text {
                font-size: 15px !important;
                font-family: @font--poppins-500;
            }

            .footer__cta {
                width: 190px !important;
            }
        }
    }

    .card-no-border .card {
        border-color: #ffffff;
        border-radius: 4px;
        margin-bottom: 30px;
    }

    .card-body {
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 1.25rem;
    }

    .pro-img {
        margin-top: -80px;
    }

    .little-profile .pro-img img {
        width: 128px;
        height: 128px;

        border-radius: 100%;
    }

    .card__item__lists {
        padding: 2px 24px 10px !important;
    }
}

.network-profile {
    .vertic-line {
        border-bottom: 1px solid#E9F0F5;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .horizon-line {
        border-right: 1px solid#E9F0F5;
    }
}

//document viewer
.view-document {
    &__doc-top {
        padding: 10px 10px 10px 50px;
        background-color: #f8f8f8;
        position: fixed;
        left: 0;
        right: 0px;
        z-index: 99;

        min-height: 50px;
    }

    &__heading {
        font-size: 20px;
        font-family: @font--poppins-600;
        color: @color--primary;
    }

    &__subheading {
        font-size: 12px;
        font-family: @font--poppins-regular;
        color: rgba(0, 0, 0, 0.6);
    }

    &__docIcon {
        margin-left: 370px;
        display: flex;
    }
}

.docs-view-popup {
    .ioovfF {
        background-color: rgba(48, 46, 55, 0.7) !important;
    }

    .hCugdT {
        text-align: right;
    }
}

@media (max-width: 320px) {
    .view-document {
        &__top-heading {
            margin-right: 0px !important;
        }
    }
}

@media (max-width: 600px) {
    .view-document {
        &__docIcon {
            margin-left: 0px;
            display: flex;
        }
    }
}

@media (max-width: 1023px) and (min-width: 768px) {
    .view-document {
        &__docIcon {
            margin-left: 50px;
            display: flex;
        }
    }
}

@media (max-width: 1200px) and (min-width: 1024px) {
    .view-document {
        &__docIcon {
            margin-left: 150px;
            display: flex;
        }
    }
}

@media (max-width: 1440px) and (min-width: 1201px) {
    .view-document {
        &__docIcon {
            margin-left: 350px;
            display: flex;
        }
    }
}

@media (max-width: 1920px) and (min-width: 1600px) {
    .view-document {
        &__docIcon {
            margin-left: 580px;
            display: flex;
        }
    }
}

//end

// profile about card
.profile-card {
    .package-pargh {
        text-align: center;
        font-family: @font--poppins-regular;
        font-size: 13px;
        line-height: 18px;
        color: #515050;
        min-height: 19px;
    }

    .btn {
        background-color: @backcolor--primary;
        color: @backcolor--white;
        border-radius: 10px;
    }

    .review-bottom {
        text-align: center;

        .footer__cta {
            width: 250px;
        }
    }

    .card {
        &__item {
            &__lists {
                background: #ffffff;
                border: 1px solid #dad5d55c;
                border-radius: 24px;
                padding: 2px 24px;
                margin-top: 8px;
                min-height: 327px;

                &__card-height {
                    background: #ffffff;
                    border: 1px solid #ececec;
                    box-shadow: 0 0 5px 2px #ececec;
                    border-radius: 24px;
                    padding: 2px 24px;
                    margin-top: 8px;
                    min-height: 100%;
                }

                &__icon {
                    width: 70px;
                    height: 72px;
                }

                &__session_left {
                    font-family: @font--poppins-600;
                    font-size: 15px;
                    line-height: 24px;
                    text-align: left;
                    color: #1b1c1e;
                    margin-bottom: 8px;
                    padding-top: 13px;

                    p {
                        margin-bottom: 0px;
                        padding-left: 9px;
                    }
                }

                &__rating_left {
                    font-family: @font--poppins-regular;
                    font-size: 12px;
                    line-height: 16px;
                    text-align: left;
                    color: #000000;

                    p {
                        margin-bottom: 0px;
                        display: inline-flex;
                        padding-left: 9px;
                    }
                }

                &__profile-sub {
                    font-family: @font--poppins-500;
                    font-size: 14px;
                    color: #3c3c3c;

                    .bar-size {
                        display: flex;
                    }

                    .padding-left {
                        padding-left: 5px;
                    }

                    .sub-heading {
                        font-family: @font--poppins-regular;
                        font-size: 12px;
                        line-height: 24px;
                        color: #515050;

                        span {
                            padding: 0px 5px;
                            display: inline-block;
                            margin: 6px;
                        }
                    }

                    .progress {
                        width: 338px;
                        height: 8px;
                    }

                    .progress-bar {
                        background-color: @backcolor--red;
                    }
                }

                &__titel {
                    font-family: @font--poppins-600;
                    font-size: 17px;
                    line-height: 31px;
                    color: #3c3c3c;
                    margin-top: 24px;
                }

                &__location {
                    margin-top: 8px;

                    p {
                        margin-bottom: 0px;

                        span {
                            font-family: @font--poppins-regular;
                            font-size: 12px;
                            line-height: 16px;
                            color: #525252;
                        }
                    }
                }

                &__tags {
                    margin-top: 5px;

                    span {
                        background: #dfdfdf;

                        border-radius: 8px;
                        height: 26px;
                        font-family: @font--poppins-regular;
                        font-size: 11px;
                        line-height: 26px;
                        color: @color--primary;
                        padding: 0px 15px;
                        display: inline-block;
                        margin-top: 0px !important;
                        margin: 6px;
                    }
                }

                &__cta {
                    color: #3e3e3e;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 26px;
                    text-align: center;
                    display: none;
                    margin-top: 26px;
                }
            }

            &:hover {
                .owl__item__lists {
                    border: 2px solid #3c3f51;
                }

                .owl__item__lists__cta {
                    display: block;
                }
            }
        }
    }
}

//feedback popup
.feeback-sub {
    font-size: 18px;
    font-family: @font--poppins-regular;
    color: rgba(27, 28, 30, 0.75);
}

.cta--zoombtn {
    font-size: 15px;
    border-radius: 10px;
    background: #1c84ee;
    color: #fff;
    display: block;
    text-align: center;
    padding: 7px 10px;
    border-color: #1c84ee;
    float: left;
}

.msg--button {
    font-size: 15px;
    border-radius: 10px;
    color: #000;
    display: block;
    text-align: center;
    padding: 8px 10px;
    border-color: #1c84ee;
}

.feed-select {
    .role {
        .role_card {
            padding-bottom: 0px;
        }
    }

    .input-floating-label {
        margin-bottom: 0px;
    }

    .role__btns {
        padding-bottom: 0px;
    }
}

//advisor-network

.cards-clrbuttons {
    height: 40px;
    margin-left: 8px;
    cursor: pointer;
    background-color: white;
    border-radius: 10px;
    color: #1c84ee;
    width: 222px;
    display: block;
    line-height: 34px;
    text-align: center;
    border: 1px solid #1c84ee;
}
.add__cta-button {
    height: 40px;
    cursor: pointer;
    background: #1c84ee;
    border-radius: 10px;
    color: white;
    width: 165px;
    display: block;
    line-height: 34px;
    margin: 0 auto;
    text-align: center;
    border: 2px solid #1c84ee;
}
.advisor-top__icons {
    margin-top: 17px;
}

//advisor library

@media (min-width: 576px) and (max-width: 899px) {
    .Feedbackboxsec {
        .feedtoplayer {
            display: flex;
            justify-content: center;
        }

        .feedmidlayer {
            display: flex;
            justify-content: center;

            .ratingsub {
                display: flex;
                justify-content: center;
            }

            .rating {
                display: flex;
                justify-content: center;
            }
        }

        .feedbottomlayer {
            display: flex;
            justify-content: center;

            .feebackbtn {
                display: flex;
                justify-content: center;
                width: 150px;
                height: 40px;
                border: 1px solid @backcolor--primary;
                border-radius: 10px;
                padding: 5px;
            }
        }
    }
}

@media (min-width: 320px) and (max-width: 575px) {
    .Feedbackboxsec {
        .feedtoplayer {
            display: flex;
            justify-content: center;
        }

        .feedmidlayer {
            display: flex;
            justify-content: center;

            .ratingsub {
                display: flex;
                justify-content: center;
            }

            .rating {
                display: flex;
                justify-content: center;
            }
        }

        .feedbottomlayer {
            display: flex;
            justify-content: center;

            .feebackbtn {
                display: flex;
                justify-content: center;
                width: 150px;
                height: 40px;
                border: 1px solid @backcolor--primary;
                border-radius: 10px;
                padding: 5px;
            }
        }
    }
}

@media (min-width: 320px) and (max-width: 425px) {
    .review-pop-sec {
        margin-left: 1px;
    }

    .view-document {
        &__doc-top {
            padding: 10px 10px 10px 15px;
        }
    }
    .adv-tab-buttton {
        .add__cta-button {
            width: 115px;
            height: 34px;
            line-height: 30px;
        }
    }
    .add__cta-button {
        width: 140px;
        margin-top: 7px !important;
    }
}

@media (min-width: 426px) {
    .review-pop-sec {
        margin-left: 100px;
    }
}

@media (min-width: 320px) and (max-width: 500px) {
    .payment-method {
        .border-rad {
            margin-left: 0px !important;
        }
    }
}

@media (min-width: 320px) and (max-width: 991px) {
    .package {
        &__margin-zero {
            margin-left: 0px !important;
        }
    }
}

@media (min-width: 320px) and (max-width: 375px) {
    .payment-method {
        .border-rad {
            width: 100% !important;
        }
    }
}

@media only screen and (max-width: 1000px) and (min-width: 100px) {
    .profile-card {
        .card__item__lists__profile-sub .progress {
            width: 100%;
            height: 8px;
        }
    }

    .empty-h3 {
        display: none;
    }

    .little-profile .pro-img img {
        width: 95px !important;
        height: 95px !important;
    }
}

@media (max-width: 1363px) and (min-width: 992px) {
    .profile-card {
        .tag-space {
            margin-left: 33px;
        }
    }
}

@media (min-width: 1750px) and (max-width: 1925px) {
    .ft-range26 {
        font-size: 26px !important;
    }

    .ft-range20 {
        font-size: 20px !important;
    }

    .ft-range18 {
        font-size: 18px !important;
    }

    .profile-card {
        .card {
            &__item {
                &__lists {
                    &__tags {
                        span {
                            height: 38px !important;
                            font-size: 16px !important;
                            line-height: 38px !important;
                            padding: 0px 30px !important;
                        }
                    }

                    &__card-height {
                        padding: 32px 24px !important;
                    }
                }
            }
        }
    }

    .network-profile {
        .vertic-line {
            margin-top: 19px !important;
            margin-bottom: 30px !important;
        }
    }

    .profile-footer {
        .footer__cta {
            height: 56px !important;
            font-size: 23px !important;
            line-height: 54px !important;
        }
    }
}

//advisor card
@media (min-width: 1600px) and (max-width: 1919px) {
    .responsive-card {
        .col-xxl-3 {
            width: 20%;
        }
    }
}

@media (min-width: 1920px) {
    .responsive-card {
        .col-xxl-3 {
            width: 16%;
        }
    }
}
