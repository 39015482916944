@import "common.less";

.sendInvite {
    &__form {
        width: 630px;
        margin: 0 auto;

        &_inner {
            width: 343px;
            margin: 0 auto;
            padding-top: 30px;
            padding-bottom: 90px;
        }

        &__title {
            font-size: 48px;
            line-height: 64px;
            color: @color--cta--underline;
            margin-bottom: 24px;
            font-family: @font--poppins-500;
        }

        &__sub-title {
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.15px;
            font-family: @font--poppins-500;
            color: @color--black;
        }
    }
}

@media screen and (max-width: 768px) {
    .sendInvite {
        &__form {
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;

            &_inner {
                width: 100%;
            }

            &__title {
                font-size: 24px;
                line-height: 30px;
            }
        }
    }
}

.tooltip {
    width: fit-content !important;
}

@media screen and (max-width: 768px) {
    .sendInvite {
        .sendInvite__form {
            .sendInvite__form_inner {
                .alertcmp {
                    position: absolute;
                    bottom: 53px;
                    left: 0px;
                    right: 0px;
                    z-index: 999;
                }
            }
        }
    }
}