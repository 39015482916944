@import "variables.less";
@import "fonts.less";

.set_initial_plan {
  padding-bottom: 100px;

  .initial_plan {
    margin: 0 auto;

    &_label {
      padding: 8px 0px;
      margin: 0px;
    }

    .empty_phase_card_cnt {
      display: flex;
      justify-content: center;
      padding: 5px;

      .empty_phase_card {
        border: 1px solid #e6e6e6;
        min-height: 200px;
        width: 390px;
        border-radius: 10px;
        padding: 10px;
        cursor: pointer;
        max-height: fit-content;

        &:hover {
          transition: all 0.2s ease-out;
          box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
          top: -4px;
          border: 1px solid #cccccc;
          background-color: @backcolor--white;
        }

        .empty_phase_card_titlecnt {
          padding: 5px;
          display: flex;
          justify-content: flex-start;

          p {
            font-family: @font--poppins-600;
            font-size: 16px;
          }
        }

        .empty_phase_card_desccnt {
          p {
            font-family: @font--poppins-500;
            font-size: 13px;
            color: @color--secondary;
          }
        }
      }
    }

    .addnewphasebtn {
      margin-top: 10px;
      width: 180px;
      background-color: @btn--primary;
      border: 2px solid @btn--primary;
      font-size: 18px;
      border-radius: 10px;

      &:hover {
        background-color: @btn--white;
        border: 2px solid @btn--primary;
        color: @color--primary;
      }
    }

    .react-select-noborder {
      width: 100%;
      display: inline-block;
    }

    .add_course_cnt {
      .add_course_top {
        .addcoursesec {
          display: flex;
          justify-content: flex-start;

          .course_title {
            font-family: @font--poppins-500;
            font-size: 20px;
          }
        }

        .addcoursebtncnt {
          display: flex;
          justify-content: flex-end;

          .addcoursebtn {
            background-color: @btn--primary;
            border: 2px solid @btn--primary;
            font-size: 16px;
            border-radius: 10px;
            width: 150px;
            height: 40px;
            margin-right: 20px;
            color: white;
            text-transform: capitalize;

            &:hover {
              background-color: @btn--white;
              border: 2px solid @btn--primary;
              color: @color--primary;
            }
          }

          .addcoursebtn2 {
            background-color: @btn--primary;
            border: 2px solid @btn--primary;
            font-size: 16px;
            border-radius: 10px;
            width: 150px;
            height: 40px;
            margin-right: 10px;
            color: white;
            text-transform: capitalize;

            &:hover {
              background-color: @btn--white;
              border: 2px solid @btn--primary;
              color: @color--primary;
            }
          }
        }
      }

      .add_course_mid {
        margin-top: 20px;
        display: flex;
        justify-content: center;

        .add_course_empty {
          padding: 10px;
          border: 1px solid #e6e6e6;
          border-style: dashed;
          border-radius: 10px;
          width: 300px;

          .addcoursetitle {
            font-family: @font--poppins-500;
            font-size: 18px;
          }

          .addcoursesubtitle {
            font-family: @font--poppins-500;
            font-size: 13px;
            color: @color--secondary;
          }
        }
      }
    }

    .Addcoursecnt {
      display: flex;
      flex-wrap: wrap;

      .AddBtn {
        background-color: #1C84EE;
        border-radius: 10px;
        color: #fff;
        text-transform: none;
        padding: 10px 15px;
      }

      .CourseBox {
        width: 50%;
        border-right: 1px solid rgb(0, 0, 0);
        padding: 20px
      }

      .ActvityBox {
        width: 50%;
        padding: 20px
      }
    }

    .greycard {
      background-color: #DFDFDF;
      border-radius: 15px;
      padding: 10px;
      min-height: 130px;
      max-height: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      p {
        font-size: 14px;
        font-family: @font--poppins-regular;
        text-align: center;
      }
    }
  }
}

.planTitleParent {
  word-break: break-all;
}


@media screen and (max-width: 768px) {
  .set_initial_plan {
    .initial_plan {
      width: 100%;
    }
  }
}

.newintial {
  .role_card {
    padding: 0px !important;
  }
}

.createplan_plancards_cnt {
  .cardgrid__dot {
    .btn-primary {
      background-color: #ffffff00 !important;
      border-color: #ffffff00 !important;
    }

    .btn-primary:hover {
      background-color: #ffffff00 !important;
      border-color: #ffffff00 !important;
    }

    .primary.dropdown-toggle {
      background-color: #dfdfdf00 !important;
      border-color: #dfdfdf00 !important;
    }

    .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0) !important;
    }
  }

  .dropdown-item:hover {
    background-color: #dfdfdf;
  }

  .dropdown-item {
    color: @color--primary !important;
    font-size: 12px;
    font-family: @font--poppins-regular;
    padding-top: 4px !important;
  }

  .dropdown-menu {
    border: 0.5px solid #dfdfdf;
    border-radius: 8px;
  }

  .cardcnts {

    .New_Phase_Card {
      border: 1px solid #ececec;
      width: 300px;
      background-color: @backcolor--white;
      border-radius: 15px;
      padding: 15px;
      margin: 10px;

      &:hover {
        transition: all 0.2s ease-out;
        box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        top: -4px;
        border: 1px solid #cccccc;
        background-color: @backcolor--white;
        cursor: pointer;
      }

      .Top_Tag {
        min-width: fit-content;
        max-width: fit-content;
        background-color: @backcolor--light--black;
        min-height: 40px;
        max-height: 70px;
        border-radius: 10px;
        padding-left: 10px;
        padding-right: 10px;

        .Tag_Name {
          font-size: 18px;
          font-family: @font--poppins-600;
          color: @color--primary;
          overflow: hidden;
          padding: 5px;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-word;
        }
      }

      .cardgrid__dot {
        // margin-left: 20px;

        .btn-primary {
          background-color: #ffffff00 !important;
          border-color: #ffffff00 !important;
        }

        .btn-primary:hover {
          background-color: #ffffff00 !important;
          border-color: #ffffff00 !important;
        }

        .primary.dropdown-toggle {
          background-color: #dfdfdf00 !important;
          border-color: #dfdfdf00 !important;
        }

        .btn-primary.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0) !important;
        }
      }

      .dropdown-item:hover {
        background-color: #dfdfdf;
      }

      .dropdown-item {
        color: @color--primary !important;
        font-size: 12px;
        font-family: @font--poppins-regular;
        padding-top: 4px !important;
      }

      .dropdown-menu {
        border: 0.5px solid #dfdfdf;
        border-radius: 8px;
      }

      .plancardtopic {
        // margin-top: 20px;
        padding-top: 20px;

        .plansubtopic {
          font-family: @font--poppins-500;
          font-size: 16px;
          color: @color--primary;

          span {
            margin-left: 10px;
            font-family: @font--poppins-500;
            font-size: 14px;
            color: @color--secondary;
          }
        }

        .nextplansubtopic {
          font-family: @font--poppins-500;
          font-size: 16px;
          color: @color--primary;

          span {
            margin-left: 40px;
            font-family: @font--poppins-500;
            font-size: 14px;
            color: @color--secondary;
          }
        }
      }

      .midcard {
        // margin-top: 20px;
        padding-top: 20px;

        .course_title {
          font-size: 16px;
          font-family: @font--poppins-500;
          line-height: 10px;
        }

        .Card_Tags_Cnt {
          display: flex;
          flex-wrap: wrap;
          overflow: hidden;
          // max-height: 90px;

          .No_Course_box {
            display: flex;
            align-items: center;
            // border: 1px solid red;

            .No_course_Text {
              font-size: 15px;
              font-family: @font--poppins-500;
              color: @color--secondary;
            }
          }

          .Tags {
            width: fit-content;
            min-height: 40px;
            // max-height: 40px;
            background-color: @backcolor--light--black;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: end;
            margin-right: 5px;
            margin-bottom: 5px;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 5px;
            padding-bottom: 5px;

            p {
              font-size: 13px;
              font-family: @font--poppins-600;
              // line-height: 0px;
              word-break: break-all;
              margin-bottom: 6px;
            }
          }
        }
      }

      .activemidcard {
        // margin-top: 15px;
        padding-top: 15px;

        .course_title {
          font-size: 16px;
          font-family: @font--poppins-500;
          line-height: 10px;
        }

        .Card_Tags_Cnt {
          display: flex;
          flex-wrap: wrap;
          overflow: hidden;
          // max-height: 90px;

          .No_Course_box {
            display: flex;
            align-items: center;
            // border: 1px solid red;
            width: 100%;
            height: 100%;
            padding: 10px 20px;

            .No_course_Text {
              font-size: 15px;
              font-family: @font--poppins-500;
              color: @color--secondary;
            }
          }

          .Tags {
            width: fit-content;
            min-height: 40px;
            // max-height: 40px;
            background-color: @backcolor--light--black;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: end;
            margin-right: 5px;
            margin-bottom: 5px;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 5px;
            padding-bottom: 5px;

            p {
              font-size: 13px;
              font-family: @font--poppins-600;
              // line-height: 0px;
              word-break: break-all;
              margin-bottom: 6px;

            }
          }
        }
      }
    }




    .newphasecard {
      border: 1px solid #ececec;
      width: 290px;
      min-height: 300px;
      background-color: @backcolor--white;
      border-radius: 15px;
      margin: 10px;
      padding-top: 10px;
      padding-left: 20px;

      &:hover {
        transition: all 0.2s ease-out;
        box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        top: -4px;
        border: 1px solid #cccccc;
        background-color: @backcolor--white;
      }

      .cardtag {
        min-width: fit-content;
        max-width: fit-content;
        background-color: @backcolor--light--black;
        margin-top: 15px;
        min-height: 40px;
        max-height: 70px;
        border-radius: 10px;

        p {
          font-family: @font--poppins-600;
          color: @color--primary;
          // padding-right: 5px;
          // padding-left: 5px;
          overflow: hidden;
          padding: 5px;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-word;
        }
      }

      .midcard {
        .Goaltitle {
          padding-top: 17px;
          font-family: @font--poppins-600;
          font-size: 16px;
          color: @color--primary;
          line-height: 20px;
        }

        .Goalsubtitle {
          font-size: 16px;
          font-family: @font--poppins-500;
          color: @color--secondary;
        }
      }

      .plancardtopic {
        margin-top: 20px;

        .plansubtopic {
          font-family: @font--poppins-600;
          font-size: 16px;
          color: @color--primary;

          span {
            margin-left: 10px;
            font-family: @font--poppins-500;
            font-size: 14px;
            color: @color--secondary;
          }
        }

        .nextplansubtopic {
          font-family: @font--poppins-600;
          font-size: 16px;
          color: @color--primary;

          span {
            margin-left: 25px;
            font-family: @font--poppins-500;
            font-size: 14px;
            color: @color--secondary;
          }
        }
      }
    }

    .carddes {
      border: 1px solid #ececec;
      width: 275px;
      height: 300px;
      background-color: @backcolor--white;
      border-radius: 15px;
      margin: 10px;
      padding: 20px;

      &:hover {
        transition: all 0.2s ease-out;
        box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        top: -4px;
        border: 1px solid #cccccc;
        background-color: @backcolor--white;
      }

      .endcols {
        display: flex;
        justify-content: end;
      }

      .cardbtn {
        width: 140px;
        border-radius: 20px;
      }

      .midcard {
        .cardtag {
          min-width: fit-content;
          max-width: fit-content;
          background-color: @backcolor--light--black;
          margin-top: 15px;
          height: 40px;
          padding: 10px;
          border-radius: 10px;

          p {
            font-family: @font--poppins-500;
            font-size: 16px;
            color: @color--primary;
            padding-right: 5px;
            padding-left: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .cardtitle {
          margin-top: 10px;

          p {
            font-size: 16px;
            font-family: @font--poppins-500;
            color: @color--primary;
            text-transform: capitalize;
          }
        }

        .plancardtopic,
        .plansubtopic {
          font-size: 14px;
          font-family: @font--poppins-500;
          color: @color--secondary;
          line-height: 20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .nextmilestonetext {
        color: @color--primary;
        font-size: 18px;
        font-family: @font--poppins-500;
        margin-top: 10px;
        text-decoration: underline;

        span {
          margin-left: 10px;
        }
      }
    }
  }
}

.createplan_plancards_cnt {
  .cardcnts {
    .phase_card {
      border: 1px solid #e4e4e4;
      width: 292px;
      height: 370px;
      background-color: @backcolor--white;
      border-radius: 15px;
      margin: 10px;
      padding: 20px;

      &:hover {
        transition: all 0.2s ease-out;
        box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        top: -4px;
        border: 1px solid #cccccc;
        background-color: @backcolor--white;
      }

      .cardbtn {
        width: 140px;
        border-radius: 20px;
      }

      .midcard {
        .cardtag {
          min-width: fit-content;
          max-width: fit-content;
          background-color: @backcolor--light--black;
          margin-top: 15px;
          height: 40px;
          padding: 10px;
          border-radius: 10px;

          p {
            font-family: @font--poppins-500;
            font-size: 16px;
            color: @color--primary;
            padding-right: 5px;
            padding-left: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .phase-time {
          .start {
            margin-top: 20px;
            font-family: @font--poppins-500;
            font-size: 13px;
            color: @color--text-gray;
            line-height: 25px;

            span {
              font-family: @font--poppins-500;
              font-size: 14px;
              color: @color--primary;
            }
          }

          .end {
            font-family: @font--poppins-500;
            font-size: 13px;
            color: @color--text-gray;

            span {
              font-family: @font--poppins-500;
              font-size: 14px;
              color: @color--primary;
            }
          }
        }
      }

      .bottomcard {
        .addcourse {
          font-family: @font--poppins-500;
          font-size: 13px;
          color: @color--text-gray;
          line-height: 5px;
        }

        .addcourselist {
          font-family: @font--poppins-500;
          font-size: 13px;
          color: @color--primary;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}

.add_Modal_course_card_cnt {
  height: fit-content;
  padding: 5px;
  width: 600px;
  display: flex;
  flex-wrap: wrap;

  .add_Modal_course_card {
    border: 1px solid #e6e6e6;
    width: 186px;
    height: 186px;
    margin: 5px;
    border-radius: 10px;

    .add_Modal_course_card_top {
      .coursetitle {
        padding: 10px;
        font-size: 18px;
        font-family: @font--poppins-500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .cardgrid__dot {
        .btn-primary {
          background-color: #ffffff00 !important;
          border-color: #ffffff00 !important;
        }

        .btn-primary:hover {
          background-color: #ffffff00 !important;
          border-color: #ffffff00 !important;
        }

        .primary.dropdown-toggle {
          background-color: #dfdfdf00 !important;
          border-color: #dfdfdf00 !important;
        }

        .btn-primary.dropdown-toggle:focus {
          box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0) !important;
        }
      }

      .dropdown-item:hover {
        background-color: #dfdfdf;
      }

      .dropdown-item {
        color: @color--primary !important;
        font-size: 12px;
        font-family: @font--poppins-regular;
        padding-top: 4px !important;
      }

      .dropdown-menu {
        border: 0.5px solid #dfdfdf;
        border-radius: 8px;
      }
    }

    .add_Modal_course_card_mid {
      p {
        font-size: 14px;
        font-family: @font--poppins-500;
        color: @color--secondary;
        padding-left: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          font-size: 16px;
          font-family: @font--poppins-500;
          color: @color--primary;
        }
      }
    }

    .add_Modal_course_card_btm {
      p {
        font-size: 14px;
        font-family: @font--poppins-500;
        color: @color--secondary;
        padding-left: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .new_add_Modal_course_card {
    width: 285px;
    height: 160px;
    border: 1px solid #e6e6e6;
    margin: 5px;
    border-radius: 10px;
    padding: 5px;

    &:hover {
      transition: all 0.2s ease-out;
      box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
      top: -4px;
      border: 1px solid #cccccc;
      background-color: white;
    }

    .toplayer {
      display: flex;
    }

    .cardgrid__dot {
      .btn-primary {
        background-color: #ffffff00 !important;
        border-color: #ffffff00 !important;
      }

      .btn-primary:hover {
        background-color: #ffffff00 !important;
        border-color: #ffffff00 !important;
      }

      .primary.dropdown-toggle {
        background-color: #dfdfdf00 !important;
        border-color: #dfdfdf00 !important;
      }

      .btn-primary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0) !important;
      }
    }

    .dropdown-item:hover {
      background-color: #dfdfdf;
    }

    .dropdown-item {
      color: @color--primary !important;
      font-size: 12px;
      font-family: @font--poppins-regular;
      padding-top: 4px !important;
    }

    .dropdown-menu {
      border: 0.5px solid #dfdfdf;
      border-radius: 8px;
    }

    .coursetitlecnt {
      padding-left: 4px;
      padding-top: 5px;

      .coursetitle {
        font-size: 18px;
        font-family: @font--poppins-500;
        overflow: hidden;
        // text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        min-height: 51px;
        max-height: 51px;
        min-width: 180px;
        width: 190px;
        max-width: 200px;
        word-break: break-word;
        margin-right: 12px;
      }
    }

    .cardmid {
      padding: 5px;

      .coursetitlecnt {
        margin-bottom: 10px;

        .coursetitle {
          font-size: 18px;
          font-family: @font--poppins-500;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          min-height: 60px;
          word-break: normal;
        }
      }

      .coursesubtitlecnt {
        .coursesubtitlehead {
          font-size: 14px;
          font-family: @font--poppins-500;
          color: @color--secondary;
          width: 265px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          word-break: break-word;

          span {
            font-size: 15px;
            font-family: @font--poppins-600;
            margin-right: 10px;
            color: @color--primary;
          }
        }

        .coursesubtitle {
          font-size: 15px;
          font-family: @font--poppins-500;
          color: @color--secondary;
        }
      }

      .coursedescriptioncnt {
        margin-bottom: 5px;

        .coursedesc {
          font-size: 14px;
          font-family: @font--poppins-500;
          color: @color--secondary;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          word-break: break-word;
          margin-top: -5px;
        }
      }
    }
  }
}


.empty_plan_cnt {
  display: flex;
  justify-content: center;
  padding: 20px;

  .empty_plan_card {
    border: 1px solid #ececec;
    width: 300px;
    height: 300px;
    background-color: @backcolor--white;
    border-radius: 15px;
    margin: 10px;
    padding: 20px;
    cursor: pointer;

    &:hover {
      transition: all 0.2s ease-out;
      box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
      top: -4px;
      border: 1px solid #cccccc;
      background-color: @backcolor--white;
    }

    .empty_plan_card_mid {
      margin-top: 20px;

      .midtitle {
        font-size: 16px;
        font-family: @font--poppins-600;
      }

      .midsubtitle {
        font-size: 14px;
        font-family: @font--poppins-500;
        color: @color--secondary;
      }

      .midhead {
        font-size: 14px;
        font-family: @font--poppins-500;
        cursor: pointer;
        display: flex;
        justify-content: center;
        margin-top: 30px;
      }
    }
  }
}

@media screen and (max-width: 370px) {
  .new_add_Modal_course_card {
    width: 223px !important;

    .cardgrid__dot {
      margin-left: -27px;
    }
  }



  .coursesubtitlehead {
    width: 200px !important;
  }



}

@media screen and (max-width: 768px) {
  .empty_plan_cnt {
    display: flex;
    justify-content: start;
    padding: 20px;

    .empty_plan_card {
      border: 1px solid #ececec;
      width: 300px;
      height: 300px;
      background-color: @backcolor--white;
      border-radius: 15px;
      margin: 10px;
      padding: 20px;
      cursor: pointer;

      &:hover {
        transition: all 0.2s ease-out;
        box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        top: -4px;
        border: 1px solid #cccccc;
        background-color: @backcolor--white;
      }

      .empty_plan_card_mid {
        margin-top: 20px;

        .midtitle {
          font-size: 16px;
          font-family: @font--poppins-600;
        }

        .midsubtitle {
          font-size: 14px;
          font-family: @font--poppins-500;
          color: @color--secondary;
        }

        .midhead {
          font-size: 14px;
          font-family: @font--poppins-500;
          cursor: pointer;
          display: flex;
          justify-content: center;
          margin-top: 20px;
        }
      }
    }
  }
}

.goals__form {
  .profile__form__sub-title_new {
    font-size: 21px;
    font-family: @font--poppins-500;
    color: @color--primary;
    line-height: 0px;
  }

  .profile__form__sub-title_text {
    font-size: 14px;
    font-family: @font--poppins-regular;
    color: @color--primary;
    line-height: 0px;
  }
}

.planSubpara {
  font-family: @font--poppins-regular;
  color: @color--primary;
}

.text-wrapper {
  color: #000000;
  font-family: @font--poppins-500;
  font-size: 21px;

}


@media screen and (max-width: 768px) {
  .goals__form {
    .profile__form__sub-title_new {
      font-size: 14px;
      font-family: @font--poppins-500;
      color: @color--primary;
      line-height: 20px;
      text-align: center;
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 380px) {
  .role_card {
    .initial_plan {
      .initial_plan_clone {
        .createplan_plancards_cnt {
          .cardcnts {
            .New_Phase_Card {
              height: fit-content;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 379px) and (min-width: 280px) {
  .New_Phase_Card {
    width: 227px !important;
    height: fit-content;
  }

  .role_card {
    .initial_plan {

      .initial_plan_clone {
        .createplan_plancards_cnt {
          .cardcnts {
            .New_Phase_Card {
              height: fit-content;
            }
          }
        }
      }

      .add_course_cnt {
        .add_course_top {
          .addcoursesec {
            .course_title {
              font-size: 16px;
            }

          }

          .addcoursebtncnt {
            .addcoursebtn {
              margin-right: 0px;
              font-size: 13px;
            }

            .addcoursebtn2 {
              margin-right: 0px;
              font-size: 12px;
            }
          }
        }
      }
    }

  }

  .profile {
    .mySelect__input-container {
      max-width: 160px !important;
    }

    .mySelect__value-container {
      max-width: 220px !important;
    }
  }

  .addcoursecnt {
    .mySelect__input-container {
      max-width: 160px !important;
    }

    .mySelect__value-container {
      max-width: 160px !important;
    }
  }
}

.plansFooter {
  display: flex !important;
}

.footer__cta_plan {
  height: 40px;
  user-select: none;
  cursor: pointer;
  background: #1C84EE;
  border-radius: 10px;
  color: #fff;
  width: 180px;
  text-align: center;
  border: 2px solid #1C84EE;
  display: flex !important;
  justify-content: center;
  align-items: center;
  line-height: unset;

  svg {
    margin-left: 5px;

    .rolestrokes {
      stroke: #ffffff;
    }

    .rolefills {
      fill: #fff;
    }
  }
}

a.footer__cta_plan {
  color: #fff !important;
}

.skipButton {
  display: flex !important;
  justify-content: center;
  align-items: center;
  line-height: unset;
}

@media screen and (max-width: 850px) {
  .set_initial_plan {
    .initial_plan {
      .greycard {
        background-color: #DFDFDF;
        border-radius: 15px;
        padding: 10px;
       
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 13rem !important;
  
        p {
          font-size: 14px;
          font-family: @font--poppins-regular;
          text-align: center;
        }
      }
    }
  }
  }

@media screen and (max-width: 425px) {

  .plansFooter {
    display: block !important;
  }

  .footer__cta_plan {
    width: 83px;
  }
}


@media screen and (max-width: 768px) {
  .Addcoursecnt {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;

    .AddBtn {
      background-color: #1C84EE;
      border-radius: 10px;
      color: #fff;
      text-transform: none;
    }

    .CourseBox {
      width: 100% !important;
      border-right: none !important;
      padding: 20px
    }

    .ActvityBox {
      width: 100% !important;
      padding: 20px
    }
  }
}

.phasesection {
  width: 1100px;
}

@media screen and (max-width: 1117px) and (min-width: 1000px) {
  .phasesection {
    width: 1000px;
  }
}

@media screen and (max-width: 999px) and (min-width: 720px) {
  .phasesection {
    width: 700px;
  }
}

@media screen and (max-width: 719px) {
  .phasesection {
    width: 100%;
  }
}

.phase_helpertext {
  font-size: 13px;
  text-align: center;
  font-family: @font--poppins-regular;
}

.gradeLabel {
  font-size: 11px;
  font-family: @font--poppins-regular;
  color: #000000af;
}

.ModalName {
  font-family: @font--poppins-500 !important;
  border: none !important;
  font-size: 1.5rem !important;
  margin-bottom: 0 !important;

  @media screen and (max-width: 320px) {
    font-family: @font--poppins-500 !important;
    border: none !important;
    font-size: 1.2rem !important;
    margin-bottom: 0 !important;
  }
}