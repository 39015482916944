@import "../../../../../assets/css/variables.less";

.connectedStudentCard {
    display: flex;
    flex-direction: column;
    border: 2px solid #E3E3E3;
    border-radius: 13px;
    padding: 22px 16px;
    width: 330px;
    background-color: #fff;
    height: 100%;

    &__topSec {
        display: flex;
        margin-bottom: 10px;
        gap: 20px;

        &__userIcon {
            border: 1px solid grey !important;
            border-radius: 6px !important;
            font-size: 22px !important;
            width: 65px !important;
            height: 70px !important
        }

        &__avatarImage {
            border: 1px solid grey !important;
            border-radius: 6px !important;
            width: 65px !important;
            height: 70px !important;
            padding: 2px;

            &--img {
                width: 100%;
                height: 100%;
                border-radius: 6px !important;
                object-fit: cover;
            }
        }

        &__userInfo {
            min-height: 50px;
            &__nameSec {
                display: flex;

                &__primaryText {
                    color: #1B1C1E;
                    font-size: 16px;
                    margin-bottom: 1px;
                    font-family: @font--poppins-500;

                }

                &__iconBg {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    position: relative;
                    bottom: 7px;
                    left: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #f4f5f8d3;

                    &:hover {
                        cursor: pointer;
                        background-color: #f1f2f3d8;
                    }

                    &__chatIcon {
                        width: 11px !important;
                        height: 11px !important;
                        color: #515050 !important;
                        border-radius: 50%;
                    }
                }
            }

            &__secondaryText {
                color: #1B1C1E;
                font-size: 12px;
                margin-bottom: 1px;
                font-family: @font--poppins-regular;
            }

            &__helperText {
                color: #515050;
                font-size: 10px;
                margin-bottom: 2px;
                max-width: 180px;
                font-family: @font--poppins-regular;
            }
        }
    }

    &__midSec {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &__educationSec {
            display: flex;
            gap: 10px;
            align-items: center;
            &__schoolIcon {
                font-size: 12px !important;
                width: 16px !important;
                height: 16px !important;
                color: #404144e3 !important;

            }

            &__schoolName {
                color: #1B1C1E;
                font-size: 14px !important;
                margin-bottom: 0;
                font-family: @font--poppins-regular;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &__goalsSec {
            display: flex;
            flex-direction: column;
            background-color: #F4F5F8;
            border-radius: 14px;
            padding: 15px 10px;
            gap: 10px;

            .studentCourse {
                .pkgCardTag-Sec {
                    flex-wrap: wrap !important;
                }
            }

            &__targetUniversitySec {
                display: flex;
                // align-items: center;
                gap: 10px;

                &__starIcon {
                    color: #1C84EE;
                    width: 16px !important;
                    height: 16px !important;
                    margin-top: 2px;
                }

                &__label {
                    font-family: @font--poppins-regular;
                    font-size: 15px;
                    color: #1B1C1E;
                    margin-bottom: 0;
                }
            }
        }

        &__otherServices {
            padding: 15px 10px;
            border-radius: 10px;
            background-color: #F4F5F8;
            gap: 10px;

            &__description {
                font-family: @font--poppins-regular;
                font-size: 15px;
                color: #1B1C1E;
                margin-bottom: 0;
                word-wrap: break-word;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &__packageSec {
            display: flex;
            flex-direction: column;
            background-color: #F4F5F8;
            border-radius: 14px;
            padding: 12px 10px;
            gap: 5px;

            &__section {
                display: flex;
                justify-content: space-between;

                &__packName {
                    display: flex;

                    &__icon {
                        width: 14px !important;
                        height: 14px !important;
                        margin-top: 3px;
                        margin-right: 4px;
                        color: #404144e3 !important;
                    }

                    &__primaryText {
                        color: #1B1C1E;
                        font-size: 14px;
                        margin-bottom: 1px;
                        font-family: @font--poppins-500;
                        max-width: 104px;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        display: -webkit-box;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                &__secondaryText {
                    color: #1B1C1E;
                    font-size: 13px;
                    margin-bottom: 1px;
                    font-family: @font--poppins-regular;

                    &::first-letter {
                        text-transform: capitalize;
                    }
                }

                &__helperText {
                    color: #1B1C1E;
                    font-size: 12px;
                    margin-bottom: 1px;
                    font-family: @font--poppins-regular;
                    max-width: 130px;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    .strongText {
                        color: #1B1C1E;
                        font-size: 13px;
                        font-family: @font--poppins-500;
                    }
                }

                &__sessionOfferBtn {
                    margin-inline: auto;
                    border: 2px solid #1C84EE;
                    background-color: #fff;
                    padding: 8px 20px;
                    margin-top: 5px;
                    border-radius: 10px;
                    color: #1C84EE;
                    font-family: @font--poppins-500;
                }

            }

            &__scheduleDetailSec {
                display: flex;
                align-items: flex-start;
                margin-inline: auto;
                gap: 5px;

                &__calendarIcon {
                    margin-top: 2px;
                    width: 12px !important;
                    height: 12px !important;
                    color: #8a8989 !important;
                }

                &__meetTime {
                    color: #8a8989;
                    font-size: 12px;
                    margin-bottom: 2px;
                    font-family: @font--poppins-regular;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-wrap: wrap;
                }
            }

            &__validitySection {
                display: flex;
                justify-content: space-between;

                &__tertiaryText {
                    color: #8a8989;
                    font-size: 13px;
                    margin-bottom: 1px;
                    font-family: @font--poppins-regular;
                    max-width: 130px;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

    }

    &__buttonSec {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: auto;
        gap: 10px;

        &__primary-btn {
            background-color: #1C84EE;
            color: #fff;
            border: none;
            width: 85%;
            height: 42px;
            border-radius: 10px;
            font-family: @font--poppins-500;
        }

        &__danger-btn {
            color: #E64A19;
            border: 2px solid #E64A19;
            background-color: #fff;
            height: 42px;
            width: 85%;
            border-radius: 10px;
            font-family: @font--poppins-500;
        }

        &__inline {
            display: flex;
            align-items: center;
            gap: 20px;

            &__danger-btn {
                color: #E64A19;
                border: 1px solid #E64A19;
                background-color: #fff;
                height: 42px;
                padding-inline: 25px;
                border-radius: 10px;
                font-family: @font--poppins-500;
            }

            &__primary-btn {
                background-color: #1C84EE;
                color: #fff;
                border: none;
                height: 40px;
                padding-inline: 25px;
                border-radius: 10px;
                font-family: @font--poppins-500;
            }
        }
    }
}

.clickable {
    &:hover {
        cursor: pointer !important;
        transition: all 0.3s ease-in-out;
        scale: 1.008;
        box-shadow: 0px 2px 4px rgba(165, 163, 163, 0.6);
    }
}



@media only screen and (max-width: 425px) {
    .connectedStudentCard {
        width: 230px !important;

        &__buttonSec {
            &__primary-btn {
                width: 100% !important;
            }

            &__danger-btn {
                width: 100% !important;
            }
        }
    }
}