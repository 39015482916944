@import "../../../../../assets/css/variables.less";

.myWork-milestoneCard{
    background-color: #fff;
    // min-height: 270px;
    width: 290px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid #8A8A8A;
    &__topSec {
        padding: 7px 8px;
        background-color: #1C84EE;
        border-top-right-radius: 9px;
        border-top-left-radius: 9px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        &__titleSec {
            display: flex;
            align-items: center;
            gap: 10px;
            color: #fff;

            &__iconLeft {
                width: 16px !important;
                height: 18px !important;
            }

            &__iconRight {
                font-size: 15px !important;
                margin-bottom: 10px;
                cursor: pointer !important;
            }

            &__title {
                margin: 0;
                color: #fff;
                font-size: 14px;
                max-width: 180px !important;
                font-family: @font--poppins-500;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                cursor: default;
            }
        }

        &__menuSec {
            position: absolute;
            right: 5px;
        }
    }
    &__statusSec{
        display: flex;
        justify-content: flex-end;
        width: 100%;
        &__inprogress{
            margin-top: 10px;
            border: 3px solid #1C84EE;
            margin-right: 20px;
            border-radius: 50px;
            padding: 5px 10px 5px 10px;
            background-color: #DEEEFF;
        }
        &__inprogressText{
            margin-bottom: 0px;
            color: #1C84EE;
            font-family: @font--poppins-600;
            font-size: 10px;
        }
        &__completed{
            margin-top: 10px;
            border: 3px solid #29A016;
            margin-right: 20px;
            border-radius: 50px;
            padding: 5px 10px 5px 10px;
            background-color: #E5FFE1;
        }
        &__completedText{
            margin-bottom: 0px;
            color: #29A016;
            font-family: @font--poppins-600;
            font-size: 10px;
        }
        &__notstarted{
            margin-top: 10px;
            border: 3px solid #E64A19;
            margin-right: 20px;
            border-radius: 50px;
            padding: 5px 10px 5px 10px;
            background-color: #FCE5DD;
        }
        &__notstartedText{
            margin-bottom: 0px;
            color: #E64A19;
            font-family: @font--poppins-600;
            font-size: 10px;
        }
    }
    &__midSec {
        padding: 15px 10px 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        &__content {
            display: flex;
            gap: 10px;

            &__label {
                margin: 0;
                min-width: 100px;
                color: #1B1C1E;
                font-size: 14px;
                font-family: @font--poppins-500;
            }

            &__value {
                margin: 0;
                font-size: 14px;
                font-family: @font--poppins-regular;
                color: #6C6C6C;
                white-space: normal;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                cursor: pointer;
            }
        }

        &__value {
            margin: 0;
            font-size: 14px;
            font-family: @font--poppins-regular;
            color: #6C6C6C;
            white-space: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            cursor: pointer;
        }
        &__timecontent{
            &__label{
                margin: 0;
                font-size: 14px;
                font-family: @font--poppins-500;
                color: #6C6C6C;
                white-space: normal;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                cursor: pointer;
            }
        }

        
    }
    .AvatarSection{
        display: flex;
        justify-content: center;
    }
    .MilestoneButtonSection{
        display: flex;
        flex-direction: column;
        padding: 15px 10px 10px;
        gap: 10px;
        align-items: center;
        margin-top: 10px;
        .milestoneupdates{
            p{
                margin-bottom: 0px;
                font-size: 12px;
                color: #6C6C6C;
                font-family: @font--poppins-500;
            }
        }
        .filledbtn{
            background-color: #1C84EE;
            color: #ffffff;
            border: none;
            width: 90%;
            border-radius: 8px;
            height: 35px;
            position: relative;
            .badge {
                position: absolute;
                top: -5px;
                right: -5px;
              }
        }
        .outlinedbtn{
            background-color: #fff;
            color: #1c84ee;
            border: 1px solid #1c84ee;
            width: 90%;
            border-radius: 8px;
            height: 35px;
        }
    }
    &__footersec{
        padding-block: 10px;
        margin-block-start: auto;
    }
}
