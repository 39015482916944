
@import "../../../assets/css/variables.less";

.addMilestone{
    &__form{
        max-width: 414px;
        margin: 0 auto;
        padding-bottom: 90px;
        .time {
            position: relative;
            margin-bottom: 32px;
            width: 100%;

            .input {
                width: 100%;
                outline: 0;
                background: transparent;
                transition: border-color 0.2s;

                &:invalid,
                &:required {
                    box-shadow: none;
                }

                &::placeholder {
                    color: transparent;
                }

                &:placeholder-shown {
                    border-color: rgba(0, 0, 0, 0.1);
                }

                &:placeholder-shown~label {
                    pointer-events: none;
                    cursor: text;
                    transform: translatey(14px);
                    padding: 0px 0px;
                    font-size: 13px;
                    font-family: @font--poppins-500;
                    color: rgba(27, 28, 30, 0.5);
                    margin-left: 5px;
                }

                &:focus {
                    border-color: rgba(0, 0, 0, 0.1);
                }

                &:focus~label {
                    position: absolute;
                    transform: translatey(-10px);
                    font-family: @font--poppins-500;
                    color: #000 !important;
                    padding: 0px 8px;
                    font-size: 13px;
                    background-color: #fff;
                    margin-left: 0px;
                }

                &:not(:placeholder-shown)~label {
                    color: #000 !important;
                }
            }

            label {
                position: absolute;
                left: 12px;
                display: block;
                transform: translatey(-10px);
                transition: 0.3s;
                padding: 0px 8px;
                font-size: 13px;
                font-family: @font--poppins-500;
                // color: rgba(27, 28, 30, 0.5) !important;
                top: 0;
                background-color: #fff;
            }

            &.w-right-icon {
                position: relative;

                .textbox-icon {
                    position: absolute;
                    right: 10px;
                    top: 10px;
                }

                .input {
                    padding-right: 30px;
                }
            }

            &.w-left-icon {
                position: relative;

                .textbox-icon {
                    position: absolute;
                    left: 10px;
                    top: 10px;
                }

                .input {
                    padding-left: 30px;

                    &:placeholder-shown~label {
                        padding-left: 20px;
                    }
                }
            }
        }


        &__title {
            font-size: 48px;
            line-height: 64px;
            color: @color--cta--underline;
            margin-bottom: 24px;
            font-family: @font--poppins-500;
        }

        &__resource-sec {
            width: 100%;

            &__title-sec{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 30px;

                &__title{
                    font-family: @font--poppins-500;
                    color: #1B1C1E;
                    font-size: 18px;
                    margin: 0;
                }

                &__addRsource-btn,
                    &__addRsource-btn:focus {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    user-select: none;
                    cursor: pointer;
                    background: @btn--primary;
                    font-size: 16px;
                    border-radius: 10px;
                    color: @btn--white;
                    line-height: 34px;
                    text-align: center;
                    font-family: @font--poppins-500;
                    border: 2px solid @btn--primary;
                    padding: 2px 16px;
                }
       
                &__addRsource-btn:focus,
                &__addRsource-btn:hover {
                    cursor: pointer;
                    color: @color--black;
                    background: @btn--white;
                    border: 2px solid @btn--primary; 
                }
            }

            &__resourses-cont{
                display: flex;
                flex-direction: column;
                row-gap: 20px;
                margin-bottom: 40px;
                max-height: 360px;
                overflow-y: auto;
                min-height: 120px;
                &__emptyState{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    padding: 20px;
                    min-height: 150px;
                    border: 1.5px solid #E3E3E3;
                    border-radius: 10px;

                    .text{
                        font-family: @font--poppins-500;
                        font-size: 16px;
                        color: #919293;
                        text-align: center;
                    }
                }
            }
        }
    }
}
// foter save btn - Add milestone
.footer__cta{
    display: flex !important;
    justify-content: center;
    align-items: center;
    line-height: unset;
    background-color: @btn--primary;
    font-size: 16px;
    border-radius: 8px;
    color: @btn--white;
    font-family: @font--poppins-500;
    border: 2px solid @btn--primary;
    padding: 2px 16px;
}
@media only screen and (max-width: 768px) {
    .addMilestone{
        &__form{    
            &__title {
                font-size: 48px;
            }
            &__resource-sec {
                &__title-sec{
                    margin-bottom: 10px;
                }
            }
        }
    }
}

@media only screen and (max-width: 425px) {
    .addMilestone{
        &__form{    
            &__title {
                font-size: 30px;
            }
        }
    }
}

.input-floating-label label {
    font-size: 12px !important;
    color: #888;
    font-family: @font--poppins-500;
  }

  .input-floating-label .input:placeholder-shown{
    border-color: rgba(0, 0, 0, 0.1);
  }

  .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    width: 100%;
    display: block;
    outline: 0;
    background: white;
    transition: border-color 0.2s;

  }

  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root{
    border: 0.5px solid #E3E3E3;
    border-radius: 10px;
  }

