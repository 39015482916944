@import '../../../../assets/css/variables.less';

.reviewCard {
    background-color: #fff;
    padding: 30px 25px;
    width: 100%;
    border-radius: 15px;
    border: 2px solid #e3e3e3;

    &__title {
        font-family: @font--poppins-500;
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 30px;
        color: #3c3c3c;
    }
}

@media only screen and (max-width: 900px) {
    .reviewCard {
        .reviewsRatings{
            .totalRating-sec{
                order: 1 !important;
               
            }
            .ratingSec{
                order: 2 !important;
            }
           
        }
    }
}