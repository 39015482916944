@import "../../../assets/css/variables.less";


.timeLineCard {
    margin-inline: auto;
    max-width: 280px;
    border-radius: 10px;
    padding: 10px 5px;
    cursor: pointer;
    // height: 6rem;

    &__topSec {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        &__updateDetails {
            margin-left: 10px;

            &__userName {
                text-align: left;
                margin-bottom: 0;
                font-weight: 400;
                font-size: 14px;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            &__updated-time {
                text-align: left;
                font-weight: 400;
                font-size: 13px;
                margin-bottom: 0;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }

        &__iconSec {
            display: flex;
        }
    }

    &__descp-sec {
        .descp {
            font-weight: 400;
            font-family: @font--poppins-regular;
            font-size: 13px;
            color: #5C5C5C;
            margin-bottom: 0;
            text-align: left;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-break: break-all;
        }
        .resourcename{
            font-weight: 400;
            font-family: @font--poppins-regular;
            font-size: 13px;
            margin-bottom: 0;
            text-align: left;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-break: break-all;
        }
        .resourcelink{
            font-weight: 400;
            font-family: @font--poppins-regular;
            font-size: 13px;
            margin-bottom: 0;
            text-align: left;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-break: break-all;
            color: @backcolor--primary;
        }
    }

}

.reply-container {
    // border: 1px solid lightgray;
    padding: 7px;
    border-radius: 5px;

}

.content-to-reply {
    background-color: #ececec;
    // border-radius: 2px;
    border-left: 3px solid #0d6efd;
    padding-left: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .postName {
        font-size: 14px;
        font-weight: 400;
        font-family: @font--poppins-regular;
        color: #0d6efd;
    }

    .postContent {
        font-size: 13px;
        font-weight: 400;
        font-family: @font--poppins-regular;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;

    }
}