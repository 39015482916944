@import "../../../assets/css/variables.less";

.Advisor-MyNetwork {
    display: flex;

    &__mainCnt {
        .Advisor_Network_Sec {
            .Network_Title {
                font-family: @font--poppins-500;
                font-size: 32px;
            }
        }

        .Advisor_Network_Sec_Mobile {
            display: none;
            position: fixed;
            z-index: 99;
        }

        &__mobileTab{
            display: none;
        }

        .card-sec {
            display: flex;
            flex-wrap: wrap;
            min-height: 200px;
            gap: 20px;

            .netWork-card {
                max-width: 260px;
                margin: 0 !important; 
                padding: 20px 15px;
                border: 1px solid #ECECEC;
                background-color: #fff;
                border-radius: 15px;

                &__top-sec {
                    display: flex;

                    &__profile-img {
                        width: 70px;
                        height: 70px;
                    }

                    &__profile-heading {
                        font-family: @font--poppins-600;
                        font-size: 16px;
                        color: #1B1C1E;
                        align-self: center;
                        margin-left: 20px;
                    }
                }

                &__address-sec {
                    margin-top: 20px;

                    &__addr-line {
                        display: flex;

                        .location {
                            font-family: @font--poppins-300;
                            font-size: 12px;
                            font-weight: 400;
                            color: #525252;
                            margin-left: 10px;
                            margin-bottom: 10px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                        }
                    }
                }

                &__tags-sec {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 10px;

                    &__noMsg-tag {
                        display: flex;
                        justify-content: flex-start !important;
                    }
                }

                &__button-section {
                    margin-top: 10px;
                    display: flex;
                    flex-direction: column;
                    row-gap: 10px;

                    &__primary-btn {
                        background-color: #1C84EE;
                        color: #fff;
                        border: none;
                        width: 100%;
                        height: 35px;
                        border-radius: 10px;
                    }
                    &__primaryCta-btn {
                        background-color: #fff !important;
                        color: #1C84EE !important;
                        border: 1px solid #1C84EE !important;
                        width: 100%;
                        height: 35px;
                        border-radius: 10px !important;
                    }

                    &__secondary-btn {
                        border: 2px solid #E64A19 !important;
                        background: none;
                        color: #E64A19;
                        font-size: 16px;
                        width: 100%;
                        height: 35px;
                        border-radius: 10px;
                    }
                    &__cancel-btn{
                        margin-top: 10px;
                        background-color: #E64A19;
                        color: #fff;
                        border: none;
                        width: 100%;
                        height: 35px;
                        border-radius: 10px;
                    }

                }

                &:hover {
                    transition: all 0.2s ease-out;
                    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
                    top: -4px;
                    border: 1px solid #cccccc;
                }
            }
            .Plan_Card_Empty_Cnt{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .Empty_Card{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 10px;
                    padding: 20px 40px;
                    min-height: 100px;
                    text-align: center;
                    transition: all 0.2s ease-out;
                    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
                    top: -4px;
                    border: 1px solid #cccccc;
                    background-color: #fff;

                    p{
                        font-family:  @font--poppins-600;
                        margin-bottom: 0;
                    }
                }
            }
            .networkNoDataSec{
                width: 100%;
                min-height: 500px;
                display: flex;
                justify-content: center;
                align-items: center;

                &__nodataCard{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    gap: 20px;
                    border-radius: 10px;
                    padding: 60px 40px;
                    min-height: 100px;
                    min-width: 260px;
                    max-width: 500px;
                    transition: all 0.2s ease-out;
                    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.1);
                    border: 1px solid #cccccc;
                    background-color: #fff;

                    &__textBlack{
                        text-align: center;
                        font-family:  @font--poppins-regular;
                        margin-bottom: 0;
                        color: #000;
                    }
                    &__cta{
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        cursor: pointer;

                        &__searchIcon{
                            width: 20px !important;
                            height: 20px !important;
                            color: #222222 !important;
                        }
                        &__blueText{
                            font-family:  @font--poppins-regular;
                            font-size: 16px;
                            margin-bottom: 0;
                            color: #1C84EE;
                            user-select: none;
                        }
                    }
                }
            }
        }

        .tab-Section {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            &__explore-btn {
                margin-left: auto;
                background-color: #1C84EE;
                border: none;
                height: 50px;
                border-radius: 10px;
                padding: 10px 20px;
                color: #fff;
                font-family: @font--poppins-500;
                font-size: 16px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .Advisor-MyNetwork {
        padding: 20px;

        &__mainCnt {
            .card-sec {
                margin-left: 20px;

                .netWork-card {
                    border: 1px solid #c9c7c7;
                }
            }

            .tab_card{
                display: none;
            }

            &__mobileTab{
                display: block;
                width: 95%;
                margin-inline: auto;
            }

            .Advisor_Network_Sec {
                display: none;
            }

            .Advisor_Network_Sec_Mobile {
                background-color: #fff;
                border-bottom: 1px solid #e3e3e3;
                height: 50px;
                display: flex;
                justify-content: center;
                width: 100%;
                align-items: center;

                .Network_Title {
                    font-family: @font--poppins-500;
                    font-size: 28px;
                    margin: 0;
                }
            }

            .tab-Section {
                display: block;
                margin: 30px 10px 0;

                // &__explore-btn {
                //     margin-top: 20px;
                // }
            }
        }
    }
}
@media screen and (max-width:1226px){
    .Advisor-MyNetwork {
        // padding: 20px;

        &__mainCnt {
            .tab-Section {
                display: block;
                margin: 30px 10px 0;

                // &__explore-btn {
                //     margin-top: 20px;
                // }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .Advisor-MyNetwork {
        &__mainCnt {
            .card-sec {
                display: flex;
                justify-content: center;
                margin: 0;

                .networkNoDataSec{
                    padding: 40px 10px;
                    &__nodataCard{
                        padding: 40px 10px;
                    }
                }
            }
        }
    }
}