.myWorkPhase{
    .phasesection{
        width: 100%;
        padding-left: 7rem;
        padding-right: 7rem;
    }
    .semester-section{
        justify-content: center;
    }   
}

@media screen and (max-width: 800px) {
    .myWorkPhase{
        .phasesection{
            padding-left: 0rem;
            padding-right: 0rem;
        }
    }
}