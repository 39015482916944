@import '../../../../assets/css/variables.less';

.priceSettingSec {
    width: 100%;
    padding: 25px 30px;
    border: 1px solid #CDCDCD;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__topSec{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        &__titleSec{
            p {
                margin: 0;
                font-size: 18px;
                letter-spacing: 0.15px;
                font-family: 'poppins-500', sans-serif;
                line-height: 24px;
                color: #000;
            }
        }

        &__profilesavebtn,&__profilesavebtn:active {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-family: @font--poppins-500;
            padding: 15px 25px;
            // width: 150px;
            height: 42px;
            -webkit-user-select: none;
            user-select: none;
            cursor: pointer;
            background: #1C84EE;
            border-radius: 10px;
            color: @color--white;
            border: 2px solid #1C84EE;

            &:hover {
                color: #000;
                cursor: pointer;
                background: @backcolor--white;
                border: 2px solid @btn--primary;
            }

            &:focus {
                background: #1C84EE;
                color: @color--white;
                border: 1px solid transparent;
            }
        }
    }

    &__contentSec {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        min-height: 350px;
        gap: 20px;

        &__noPkgSec {
            margin-inline: auto;
            min-width: 220px;
            padding: 20px 15px;
            min-height: 150px;
            border: 1px solid #CDCDCD;
            border-radius: 15px;
        }

        .new_add_course_Modal_card{
            margin: 0;
            padding: 15px 5px;

            .toplayer{
                display: flex;
                justify-content: space-between;

                .coursetitle{
                    text-overflow: ellipsis;
                    font-size: 18px;
                    font-family: @font--poppins-500;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                    width: 190px;
                    height: 30px;
                    margin: 0;
                    padding-left: 5px;
                    -webkit-line-clamp: 1;
                    // word-break: break-word;
                    display: -webkit-box;
                }

                .cardgrid__dot{
                    margin: 0 10px;
                }
            }

            .cardmid{
                .courseValue{
                    font-size: 14px;
                    font-family: @font--poppins-500;
                    color: @color--secondary;
                    white-space: normal;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    height: 40px;
                }

                .activeStatus{
                    color: #2E8A37;
                    text-transform: capitalize;
                }

                .inActiveStatus{
                    color: #E45D40;
                    text-transform: capitalize;
                }
            }

            &:hover {
                box-shadow: 0px 4px 8px rgba(92, 91, 91, 0.1);
            }
        }

    }

    .addadvisorycards {
        border: 1px solid rgba(48, 46, 55, 0.279);
        padding: 6px;
        height: 210px;
        max-width: 200px;
        min-width: 200px;
        border-radius: 15px;
        margin-bottom: 15px;

        .addadvisorycardstop {
            .dropdown-menu.show {
                position: absolute !important;
                background-color: #dfdfdf;
                inset: 29px 0px auto !important;
                transform: translate(-82px, 0px) !important;
            }

            .cardgrid__dot {
                .btn-primary {
                    background-color: #d7111100 !important;
                    border-color: #ffffff00 !important;
                    color: #0d0d0dc1 !important;
                    padding: 0;
                }

                .dropdown-toggle::after {
                    display: none;
                }

                .btn-primary:hover {
                    background-color: #ffffff00 !important;
                    border-color: #ffffff00 !important;
                }

                .primary.dropdown-toggle {
                    background-color: #dfdfdf00 !important;
                    border-color: #dfdfdf00 !important;
                }

                .btn-primary.dropdown-toggle:focus {
                    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0) !important;
                }
            }

            .dropdown-item {
                display: flex;
                align-items: center;
                color: @color--primary !important;
                font-size: 13px;
                font-weight: 400;
                font-family: @font--poppins-regular;
                padding: 6px 10px !important;

                span {
                    margin: 0 12px;
                }
            }

            .dropdown-item:hover {
                background-color: #d1cdcdc1 !important;
            }

            .dropdown-menu {
                border: 1px solid #dfdfdf;
                border-radius: 8px;
                width: 80px !important;
            }

            p {
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: 1px;
                font-family: @font--poppins-500;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #333333;
            }
        }

        .label {
            padding-right: 0px;
            font-weight: 500;
            font-size: 12px;
            font-family: @font--poppins-600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 16px;
        }

        .value {
            padding-left: 0;
            font-weight: 400;
            font-size: 12px;
            font-family: @font--poppins-500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 16px;
        }

        .advdescp {
            font-weight: 400;
            min-height: 50px;
            font-size: 14px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .addadvisorycards:hover {
        border: 1px solid rgba(48, 46, 55, 0.406);
    }
}
.packageModal {
    .serviceType {
        span{
            word-wrap: anywhere;
        }
    }
}

@media screen and (max-width: 425px) {
    .priceSettingSec {
        padding: 25px 15px;
        &__topSec{
            &__profilesavebtn,&__profilesavebtn:active {
                padding-inline: 10px;
            }
        }
        &__contentSec{
            justify-content: center;

            .new_add_course_Modal_card{
                margin: 10px 0px;
                width: 260px;

                .toplayer{
                    .coursetitle{
                        min-width: 160px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 510px) {
    .priceSettingSec {
        padding: 25px 15px;

        &__topSec{
            gap: 20px;
        }
    }
    .packageModal {
        padding: 32px 10px !important;
    }
}