// .owlForDashboard {
//     .owl-carousel {
//         .owl-nav {
//             position: absolute;
//             top: 50%;
//             transform: translateY(-50%);
//             width: 100%;
//             display: flex;
//             justify-content: space-between;
//             padding: 0px 7px !important;
//             margin: 0px !important;

//             .owl-prev,
//             .owl-next {
//                 position: relative;
//                 background: transparent;
//                 color: black;
//                 font-size: 40px;
//             }

//             .owl-prev {
//                 left: -33px;
//             }

//             .owl-next {
//                 right: -25px;
//             }
//         }
//     }
// }

// .owlForDashboard {
//     .owl-theme .owl-nav [class*='owl-']:hover {
//         background-color: unset !important;
//         color: black;
//     }
// }

// /* Media queries for responsive adjustments */

// /* For small phones */
// @media screen and (max-width: 425px) {
//     .owlForDashboard {
//         .owl-carousel {
//             .owl-nav {
//                 padding: 0px 7px !important;
//                 margin: 0px !important;

//                 .owl-next {
//                     right: -7px !important;
//                     top: unset;
//                     margin: 0;
//                 }

//                 .owl-prev {
//                     left: -20px !important;
//                     top: unset;
//                     margin: 0;
//                 }
//             }
//         }
//     }
// }

// /* For very small screens (like 320px wide) */
// @media screen and (max-width: 279px) {
//     .owlForDashboard {
//         .owl-carousel {

//             .owl-nav {
//                 display: block;
//                 top: unset !important;
//                 bottom: 10px;
//                 /* Adjust bottom position for small devices */
//             }

//             .owl-prev,
//             .owl-next {
//                 font-size: 30px;
//                 /* Smaller icons for small screens */
//             }
//         }
//     }
// }

// /* For medium devices (tablets) */
// @media screen and (min-width: 768px) and (max-width: 1024px) {
//     .owlForDashboard {
//         .owl-carousel {
//             .owl-nav {
//                 .owl-next {
//                     right: -15px;
//                 }

//                 .owl-prev {
//                     left: -15px;
//                 }
//             }
//         }
//     }
// }

// /* For larger screens (like 1600px and above) */
// @media screen and (min-width: 1600px) {
//     .owlForDashboard {
//         .owl-carousel {
//             .owl-nav {
//                 .owl-next {
//                     right: -30px;
//                 }

//                 .owl-prev {
//                     left: -30px;
//                 }
//             }
//         }
//     }
// }

/* Ensure the carousel is relative to position elements inside it */
.owl-stage-outer {
    position: relative;
    /* Relative positioning for absolute child elements */
    // padding: 0 40px; /* Ensures space for buttons */
}

.owlForDashboard{
    position: relative;
    
    .owl-nav{
        display: flex;
        justify-content: space-between;
        position: absolute;
        width: 100%;
        transform: translateY(-120px);
    }
    .owl-prev{
        transform: translateX(-10px);
    }
    .owl-next{
        transform: translateX(10px);
    }
}

@media screen and (max-width: 425px) {
    .owlForDashboard{
        .owl-prev{
            transform: translateX(20px);
        }
        .owl-next{
            transform: translateX(-20px);
        }
    }
}