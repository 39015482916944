@import "../../../assets/css/variables.less";

.confirmationModal{
    padding: 10px 30px 20px !important;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .confirmMsg {
        color: #000;
        font-family: @font--poppins-regular !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
    }

    &__primary-btn{
        background-color: #1C84EE;
        border: none;
        height: 42px;
        padding-inline: 25px;
        border-radius: 10px;
        
        p {
            font-family: @font--poppins-500;
            color: #fff;
            margin-bottom: 0;
            text-transform: capitalize;
        }
    }
}
