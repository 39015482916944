@import "../../../assets/css/variables.less";

.addResource-popup{
    &__header{
        .addResource{
            &__title{
                font-family: @font--poppins-500;
                font-size: 22px;
                color: #1B1C1E;
                margin-bottom: 0;
            }
        }
    }

    &__body{
        padding: 10px 30px;

       
    }
}