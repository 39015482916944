@import "common.less";
@import "variables.less";
@import "fonts.less";

.profile {
    &__form {
        width: 414px;
        margin: 0 auto;
        padding-top: 30px;
        padding-bottom: 90px;

        &__title {
            font-size: 48px;
            line-height: 64px;
            color: @color--cta--underline;
            margin-bottom: 24px;
            font-family: @font--poppins-500;
        }

        &__sub-title {
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.15px;
            font-family: @font--poppins-500;
            color: @color--black;
        }

        &__smalltext {
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.15px;
            font-family: @font--poppins-500;
            color: @color--gray;
        }
    }

    .textboxarea {
        height: 80px;
    }

    .dragdropcnt {
        display: flex;
        justify-content: center;
    }

    .dropzone1 {
        display: flex;
        justify-content: center;
        margin-right: 45px;
        margin-left: 45px;
    }

    .dropzoneinside {
        border: 1px dashed rgba(0, 0, 0, 0.1);
        margin-top: 20px;
        border-radius: 15px;
        padding: 10px;
        background-color: #f9f9f9;

        .dropzoneinsidetop {
            display: flex;
            justify-content: center;
        }

        .dropzoneinsidemid {
            display: flex;
            justify-content: center;
            margin-top: 10px;
            color: #495057;
        }

        .dropzoneinsidebtm {
            display: flex;
            justify-content: center;

            p {
                margin-left: 5px;
                font-weight: 700;
                font-size: 16px;
                color: #495057;
            }
        }
    }

    .dropzonebtm {
        font-weight: 400;
        font-size: 14px;
        color: #919293;
    }

    .dopZone-FileList {
        padding: 0px;

        span {
            cursor: pointer;
        }
    }

    .thumb {
        display: inline-block;
        border-radius: 50%;
        border: 1px solid #eaeaea;
        margin-bottom: 8px;
        // margin-right: 8px;
        width: 100%;
        height: 100%;
        padding: 4px;
        box-sizing: border-box;
    }

    .thumbInner {
        display: flex;
        min-width: 0px;
        min-height: 0px;
        overflow: hidden;
        width: 100%;
        height: 100%;
    }

    .thumbImg {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
}

@media screen and (max-width: 768px) {
    .profile {
        
    .studentIcon{
        margin-left: 2rem;  
    }
        &__form {
            width: 100% !important;
            padding-left: 15px;
            padding-right: 15px;

            &__title {
                font-size: 24px;
                line-height: 10px !important;
            }
        }
    }
            width: 414px;
            margin: 0 auto;
            padding-top: 30px;
            padding-bottom: 90px;
    
            &__title {
                font-size: 48px;
                line-height: 64px;
                color: @color--cta--underline;
                margin-bottom: 24px;
                font-family: @font--poppins-500;
            }

    .childprofile {
        &__form {
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;

            &__title {
                font-size: 24px;
            }
        }
    }
}

.modal-footer {
    .modalBtnAccept,
    .modalBtnAccept:focus {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        user-select: none;
        cursor: pointer;
        background: #1c84ee;
        border-radius: 10px;
        color: #fff;
        width: 160px;
        line-height: 36px;
        margin: 0 8px 0 auto;
        text-align: center;
        border: 2px solid #1c84ee;
    }

    .modalBtnAccept:hover {
        color: #000;
        cursor: pointer;
        background: #fff;
        border: 2px solid #1c84ee;
    }
}

@media screen and (max-width: 315px) {
    .modal-footer {
        display: flex;
        justify-content: center;
        .modalBtnAccept,
        .modalBtnAccept:focus{
            margin-right: 0;
            width: 130px;
        }
    }
}

.packagebtncnt1,
.packagebtncnt1:focus {
    display: flex;
    justify-content: center;
    margin-bottom: 10px !important;
    user-select: none;
    font-family: @font--poppins-500;
    cursor: pointer;
    background: #1c84ee;
    border-radius: 10px;
    color: #fff;
    width: 100%;
    line-height: 36px;
    // margin: 0 8px 0 auto;
    text-align: center;
    border: 1px solid #1c84ee;
    & span {
        padding: 20px;
    }
    .packagebtn:hover {
        color: #000;
        width: 100%;
        font-family: @font--poppins-500;
        border-radius: 10px;
        cursor: pointer;
        margin: 0px;
        padding: 0px;
        background: #fff;
        border: 1px solid #1c84ee;
        line-height: 36px;
        & span {
            padding: 20px;
        }
    }
}

@media screen and (max-width: 768px) {
    .packagebtncnt {
        display: flex;
        justify-content: center;
        background: #1c84ee;
        border-radius: 10px;
        margin-bottom: 10px !important;
        color: #fff;
        width: 100%;
        line-height: 36px;
        // margin: 0 8px 0 auto;
        text-align: center;
        border: 1px solid #1c84ee;

        .packagebtn:hover {
            color: #000;
            font-family: @font--poppins-500;
            border-radius: 10px;
            cursor: pointer;
            background: #fff;
            border: 1px solid #1c84ee;
        }
    }
}

.childprofile {
    &__form {
        width: 414px;
        margin: 0 auto;
        padding-top: 0px;
        padding-bottom: 100px;

        &__title {
            font-size: 48px;
            line-height: 64px;
            color: @color--cta--underline;
            margin-bottom: 24px;
            font-family: @font--poppins-500;
        }

        &__sub-title {
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.15px;
            font-family: @font--poppins-500;
            color: @color--black;
        }

        &__smalltext {
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.15px;
            font-family: @font--poppins-500;
            color: @color--gray;
        }
    }

    .textboxarea {
        height: 80px;
    }

    .dropzoneinside {
        border: 1px dashed rgba(0, 0, 0, 0.1);
        margin-top: 20px;
        border-radius: 15px;
        padding: 10px;
        background-color: #f9f9f9;

        .dropzoneinsidetop {
            display: flex;
            justify-content: center;
        }

        .dropzoneinsidemid {
            display: flex;
            justify-content: center;
            margin-top: 10px;
            color: #495057;
        }

        .dropzoneinsidebtm {
            display: flex;
            justify-content: center;

            p {
                margin-left: 5px;
                font-weight: 700;
                font-size: 16px;
                color: #495057;
            }
        }
    }

    .dropzonebtm {
        font-weight: 400;
        font-size: 14px;
        color: #919293;
    }
}

.none {
    display: none;
}

.advisorycourse {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    display: none;
}

.OngoingCourse-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    //   display: none;

    .advisoryBtn,
    .advisoryBtn:focus {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        user-select: none;
        cursor: pointer;
        background: #1c84ee;
        border-radius: 10px;
        color: #fff;
        width: 140px;
        line-height: 36px;
        margin: 0 8px 0 auto;
        text-align: center;
        border: 2px solid #1c84ee;
    }

    .advisoryBtn:hover {
        color: #000;
        cursor: pointer;
        background: #fff;
        border: 1px solid #1c84ee;
    }
}

@media screen and (max-width: 285px) {
    .OngoingCourse-title {
        .advisoryBtn {
            width: 100%;
            margin: 0;
        }
    }
}

#black-icon {
    fill: #fff;
}

.OngoingCourse {
    display: none;

    &__wrapper {
        display: flex;
        justify-content: space-evenly;

        &__input {
            width: 45%;
        }
    }
}

.ql-toolbar.ql-snow {
    border: none !important;
    border-bottom: 1px solid #eaeaea !important;
    margin: 5px;
}

.quill.textarea--primary.textbox--rounded.input {
    padding: 0 !important;
    min-height: 150px !important;
    word-break: break-all;
}

.ql-container.ql-snow {
    border: none !important;
}

.subtext {
    font-size: 12px;
    color: rgba(27, 28, 30, 0.5) !important;
}

.circle {
    width: 25px;
    height: 25px;
    line-height: 25px;
    border-radius: 50%;
    font-size: 15px;
    color: #fff;
    text-align: center;
    background: #1c84ee;
    cursor: pointer;
}

.ql-link {
    display: none !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 13px;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 13px;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.1);
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 11px;
    height: 51px;
}

.css-1bn53lx {
    border-radius: 11px !important;
    height: 51px !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 13px !important;
    font-family: @font--poppins-500 !important;
}

// .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
//   width: 100%;
// }
.go3450369076 {
    width: 100% !important;
}

.padding10 {
    padding-left: 10px;
}

.accordion .accordion-button {
    background-color: #fff;
    color: #5c5c5c;
}

.accordion .accordion-button:not(.collapsed) {
    color: #040506 !important;
    background-color: #ededed;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

@media only screen and (max-width: 768px) {
    .childtitle {
        font-size: 20px;
        line-height: 60px;
        color: @color--cta--underline;
        margin-bottom: 14px;
        font-family: @font--poppins-500;
    }

    .listofOngoingCourses {
        margin-bottom: 25px;
    }

    .tooltip {
        width: 550px !important;
    }
}

.input-floating-label.disabled {
    background-color: hsl(0, 0%, 95%);
}

.priceSettingEdit {
    border: 1px solid #1c84ee;
    color: white;
    background-color: #1c84ee;
    border-radius: 5px;
    width: 30%;
    text-align: center;
    margin: 5px;
    height: 35px;
    padding: 5px;
    cursor: pointer;
}

.priceSettingClose {
    border: 1px solid #ff5151;
    color: white;
    background-color: #ff5151;
    border-radius: 5px;
    width: 30%;
    margin: 5px;
    padding: 5px;
    text-align: center;
    height: 35px;
    cursor: pointer;
}

.priceSettingdelete {
    border: 1px solid #d92f2f;
    color: white;
    background-color: #d92f2f;
    border-radius: 10px;
    width: 80px;
    text-align: center;
    padding: 4px;
    margin: 10px;
    cursor: pointer;
}

.priceSettingEditdeleted {
    border: 1px solid #d92f2f;
    color: white;
    background-color: #d92f2f;
    border-radius: 10px;
    width: 80px;
    text-align: center;
    padding: 4px;
    margin: 10px;
    cursor: pointer;
}

.profilepricing {
    .profilepricingcnt {
        padding: 20px;
    }

    .pricelistingcard {
        border: 1px solid rgb(172, 172, 172);
        border-radius: 10px;
        height: 70px;

        &:hover {
            transition: all 0.2s ease-out;
            box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
            top: -4px;
            border: 1px solid #cccccc;
        }

        .pricetitle {
            line-height: 60px;
            padding-left: 30px;
            height: 69px;

            p {
                font-size: 20px;
                font-family: @font--poppins-500;
            }
        }

        .nodatapricetitle {
            line-height: 60px;
            padding-left: 30px;
            height: 69px;

            p {
                font-size: 20px;
                font-family: @font--poppins-500;
            }
        }

        .settingdetails {
            line-height: 60px;
            height: 69px;

            .dotbox {
                line-height: 60px;
                height: 69px;

                .dot {
                    height: 10px;
                    width: 10px;
                    border-radius: 10px;
                    background-color: #1b1c1e;
                }
            }

            .list {
                height: 69px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                p {
                    height: 70px;
                    font-size: 18px;
                    font-family: @font--poppins-500;

                    span {
                        font-size: 18px;
                        font-family: @font--poppins-300;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .profilepricing {
        .profilepricingcnt {
            padding: 0px;
        }

        .pricelistingcard {
            border: 1px solid rgb(70, 70, 70);
            border-radius: 10px;
            height: 70px;

            .pricetitle {
                line-height: 60px;
                padding-left: 30px;
                height: 69px;

                p {
                    font-size: 20px;
                    font-family: @font--poppins-500;
                }
            }

            .nodatapricetitle {
                line-height: 60px;
                padding-left: 30px;
                height: 69px;

                p {
                    font-size: 14px;
                    font-family: @font--poppins-500;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .settingdetails {
                line-height: 60px;
                height: 69px;

                .dotbox {
                    line-height: 60px;
                    height: 69px;

                    .dot {
                        height: 10px;
                        width: 10px;
                        border-radius: 10px;
                        background-color: #1b1c1e;
                    }
                }

                .list {
                    height: 69px;
                    display: none;

                    p {
                        height: 70px;
                        font-size: 18px;
                        font-family: @font--poppins-500;

                        span {
                            font-size: 18px;
                            font-family: @font--poppins-300;
                        }
                    }
                }
            }
        }
    }
}

.profilepackagebtncnt {
    display: flex;
    justify-content: center;
    margin-bottom: 200px;

    .profilepackagebtn {
        height: 50px;
        border: 1px solid #3197ee;
        color: #1b1c1e;
        border-radius: 10px;
        padding: 10px;
        padding-left: 50px;
        padding-right: 50px;
        font-size: 18px;
        font-family: @font--poppins-500;
    }
}

@media only screen and (max-width: 768px) {
    .profilepackagebtncnt {
        display: flex;
        justify-content: center;
        margin-bottom: 200px;

        .profilepackagebtn {
            height: 50px;
            border: 1px solid #3197ee;
            color: #1b1c1e;
            border-radius: 10px;
            padding: 10px;
            padding-left: 20px;
            padding-right: 20px;
            font-size: 18px;
            font-family: @font--poppins-500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.collapse {
    border: 1px solid #cccccc;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

// Create Profile Courses and activities section
.onboarding-course-section {
    .addadvisorymaincnt {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        margin-top: 20px;

        .addadvisorycards {
            border: 1px solid rgba(48, 46, 55, 0.279);
            padding: 6px;
            height: 210px;
            max-width: 200px;
            min-width: 200px;
            border-radius: 15px;
            margin-bottom: 15px;

            .addadvisorycardstop {
                .dropdown-menu.show {
                    position: absolute !important;
                    background-color: #dfdfdf;
                    inset: 29px 0px auto !important;
                    transform: translate(-82px, 0px) !important;
                }

                .cardgrid__dot {
                    .btn-primary {
                        background-color: #d7111100 !important;
                        border-color: #ffffff00 !important;
                        color: #0d0d0dc1 !important;
                        padding: 0;
                    }

                    .dropdown-toggle::after {
                        display: none;
                    }

                    .btn-primary:hover {
                        background-color: #ffffff00 !important;
                        border-color: #ffffff00 !important;
                    }

                    .primary.dropdown-toggle {
                        background-color: #dfdfdf00 !important;
                        border-color: #dfdfdf00 !important;
                    }

                    .btn-primary.dropdown-toggle:focus {
                        box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0) !important;
                    }
                }

                .dropdown-item {
                    display: flex;
                    align-items: center;
                    color: @color--primary !important;
                    font-size: 13px;
                    font-weight: 400;
                    font-family: @font--poppins-regular;
                    padding: 6px 10px !important;

                    span {
                        margin: 0 12px;
                    }
                }

                .dropdown-item:hover {
                    background-color: #d1cdcdc1 !important;
                }

                .dropdown-menu {
                    border: 1px solid #dfdfdf;
                    border-radius: 8px;
                    width: 80px !important;
                }

                p {
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 20px;
                    letter-spacing: 1px;
                    font-family: @font--poppins-500;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: #333333;
                }
            }

            .label {
                padding-right: 0px;
                font-weight: 500;
                font-size: 12px;
                font-family: @font--poppins-600;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 16px;
            }

            .value {
                padding-left: 0;
                font-weight: 400;
                font-size: 12px;
                font-family: @font--poppins-500;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 16px;
            }

            .advdescp {
                font-weight: 400;
                min-height: 50px;
                font-size: 14px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .addadvisorycards:hover {
            border: 1px solid rgba(48, 46, 55, 0.406);
        }
    }

    .addcoursecards {
        height: 100px;
        padding: 10px, 15px;
        border-radius: 15px;
        border: 1px solid #e4e4e4;

        .addcoursecardscnt {
            .title {
                font-size: 15px;
                font-weight: 500;
                line-height: 24px;
            }

            .descp {
                font-family: @font--poppins-regular;
                font-size: 13px;
                font-weight: 400;
                color: #495057;
            }
        }
    }
}

.addcoursecards {
    padding: 10px, 15px;
    border-radius: 15px;
    border: 1px solid #e4e4e4;
    font-family: @font--poppins-regular;

    .addcoursecardscnt {
        .title {
            font-size: 15px;
            font-weight: 500;
            line-height: 24px;
        }

        .descp {
            font-size: 13px;
            font-weight: 400;
            color: #495057;
        }
    }
}

.modelFlex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (min-device-width: 320px) {
        justify-content: center;
    }
}

.modelFlexs {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;

    @media screen and (min-device-width: 320px) {
        justify-content: start;
    }
}

.cardHeaderIcon {
    display: flex;

    &__group {
        display: flex;
        justify-content: flex-end;
    }

    &__editIcon {
        margin: 2px;
    }
}

.cardHeaderIcon > div:last-child:nth-child(2) {
    margin: 0 auto 0 2%;
}

.carditemLeft {
    font-family: @font--poppins-500;
    font-weight: 400;
}

.carditemRight {
    font-family: @font--poppins-500;
    color: #5c5c5c;
}

.carditemHearder {
    font-family: @font--poppins-500;
}

.mySelect__placeholder {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.generalcntsub {
    width: 400px;

    @media screen and (max-device-width: 470px) {
        width: 200px;
    }
}

.generalcnt1 {
    width: 500px;
}
@media screen and (max-width: 510px) {
    .packageModal {
        padding: 32px 10px !important;
    }
}

.addadvisorymaincnt {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 20px;

    .addadvisorycards {
        border: 1px solid rgba(48, 46, 55, 0.279);
        padding: 6px;
        height: 210px;
        max-width: 200px;
        min-width: 200px;
        border-radius: 15px;
        margin-bottom: 15px;

        .addadvisorycardstop {
            .dropdown-menu.show {
                position: absolute !important;
                background-color: #dfdfdf;
                inset: 29px 0px auto !important;
                transform: translate(-82px, 0px) !important;
            }

            .cardgrid__dot {
                .btn-primary {
                    background-color: #d7111100 !important;
                    border-color: #ffffff00 !important;
                    color: #0d0d0dc1 !important;
                    padding: 0;
                }

                .dropdown-toggle::after {
                    display: none;
                }

                .btn-primary:hover {
                    background-color: #ffffff00 !important;
                    border-color: #ffffff00 !important;
                }

                .primary.dropdown-toggle {
                    background-color: #dfdfdf00 !important;
                    border-color: #dfdfdf00 !important;
                }

                .btn-primary.dropdown-toggle:focus {
                    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0) !important;
                }
            }

            .dropdown-item {
                display: flex;
                align-items: center;
                color: @color--primary !important;
                font-size: 13px;
                font-weight: 400;
                font-family: @font--poppins-regular;
                padding: 6px 10px !important;

                span {
                    margin: 0 12px;
                }
            }

            .dropdown-item:hover {
                background-color: #d1cdcdc1 !important;
            }

            .dropdown-menu {
                border: 1px solid #dfdfdf;
                border-radius: 8px;
                width: 80px !important;
            }

            p {
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: 1px;
                font-family: @font--poppins-500;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #333333;
            }
        }

        .label {
            padding-right: 0px;
            font-weight: 500;
            font-size: 12px;
            font-family: @font--poppins-600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 16px;
        }

        .value {
            padding-left: 0;
            font-weight: 400;
            font-size: 12px;
            font-family: @font--poppins-500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 16px;
        }

        .advdescp {
            font-weight: 400;
            min-height: 50px;
            font-size: 14px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .addadvisorycards:hover {
        border: 1px solid rgba(48, 46, 55, 0.406);
    }
}
.generalcnt1 {
    .advisoryBtn,
    .advisoryBtn:focus {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        user-select: none;
        cursor: pointer;
        background: #1c84ee;
        border-radius: 10px;
        color: #fff;
        width: 140px;
        line-height: 36px;
        margin: 0 8px 0 auto;
        text-align: center;
        border: 2px solid #1c84ee;
        & svg {
            fill: black !important;
        }
    }

    .advisoryBtn:hover {
        color: #000;
        cursor: pointer;
        background: #fff;
        border: 1px solid #1c84ee;
    }
    .advisoryBtn:hover .adv-svg path {
        fill: black !important;
    }

    .statuscnt{
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }

        /* Firefox */
        input[type=number] {
        -moz-appearance: textfield;
        }

        .serviceType {
            span{
                word-wrap: anywhere;
                
            }
        }
    }
}
.CustomCheckbox {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: 400;
    font-family: @font--poppins-regular;

    input[type="checkbox"] {
        display: none;
    }

    .advisorycard {
        height: 88px;
        max-width: 180px;
        min-width: 180px;
        border: 1px solid #e6e6e6;
        margin: 15px 5px;
        border-radius: 10px;
        padding: 5px;
        &:hover {
            transition: all 0.2s ease-out;
            box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
            top: -4px;
            border: 1px solid #cccccc;
            background-color: white;
        }

        .addadvisorycardstop {
            .advName {
                user-select: none;
                overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
                word-break: break-word;
                font-family: @font--poppins-500;
            }
            input + label {
                cursor: pointer;
                border: 1px solid rgba(255, 255, 255, 0);
            }
        }
    }
    .selected {
        border: 1px solid #1c84ee;
    }
}
.innerCheckbox {
    display: "flex";
    flex-direction: "column";
    & p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.new_add_course_Modal_card {
    width: 270px;
    border: 1px solid #e6e6e6;
    margin: 15px 5px;
    border-radius: 10px;
    padding: 15px 5px;
    &:hover {
        box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
        border: 1px solid #cccccc;
        background-color: white;
    }
    .toplayer {
        display: flex;
        justify-content: space-between;
		.coursetitlecnt {
			padding-left: 5px;
			padding-top: 5px;
			.coursetitle {
				font-size: 18px;
				font-family: @font--poppins-500;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
                height: 30px;
				min-width: 210px;
                // word-break: break-word;
			}
		}
        .cardgrid__dot {
            margin: 0 10px;
            
            .btn-primary {
                background-color: #ffffff00 !important;
                border-color: #ffffff00 !important;
            }
            .btn-primary:hover {
                background-color: #ffffff00 !important;
                border-color: #ffffff00 !important;
            }
            .primary.dropdown-toggle {
                background-color: #dfdfdf00 !important;
                border-color: #dfdfdf00 !important;
            }
            .btn-primary.dropdown-toggle:focus {
                box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0) !important;
            }
        }
    }
    
    .dropdown-item:hover {
        background-color: #dfdfdf;
    }
    .dropdown-item {
        color: @color--primary !important;
        font-size: 12px;
        font-family: @font--poppins-regular;
        padding-top: 4px !important;
    }
    .dropdown-menu {
        border: 0.5px solid #dfdfdf;
        border-radius: 8px;
    }
    .cardmid {
		padding: 5px;

		.label {
			font-size: 15px;
			font-family: @font--poppins-600;
			color: @color--black;
		}

		.value{
			font-size: 14px;
			font-family: @font--poppins-500;
			color: @color--secondary;
			white-space: normal;
			overflow: hidden;
			text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
		}
        .courseValue{
            height: 40px;
        }
        .activeStatus{
            color: #2E8A37;
            text-transform: capitalize;
        }
        .inActiveStatus{
            color: #E45D40;
            text-transform: capitalize;
        }
	}
    .cardBottom{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;

        .label {
			font-size: 15px;
			font-family: @font--poppins-600;
			color: @color--black;
            margin-bottom: 0 !important;
		}

		.value{
			font-size: 14px;
            margin-bottom: 0 !important;
			font-family: @font--poppins-500;
			color: @color--secondary;
			white-space: normal;
			overflow: hidden;
			text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
		}

    }
}
@media screen and (max-width: 425px) {
    .profile{
        &__form{
            .cardSection{
                display: flex;
                justify-content: center;

                .new_add_course_Modal_card{
                    margin: 10px 0px;
                    width: 250px;
                    
                    .toplayer{
                        .coursetitlecnt{
                            .coursetitle{
                                min-width: 160px;
                            }
                        }
                    }
                }
            }
        }
    }

}

