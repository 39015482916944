@import "../../../assets/css/variables.less";

.studentDashboard {
    &_Mobile {
        display: none;
    }

    &_Dashboard {
        &_Title{
            font-size: 16px;
            font-family: @font--poppins-500;
            margin-bottom: 10px;
        }
        &_TopText {
            h2 {
                font-family: @font--poppins-500
            }
        }
        &_SubText{
            color: #5A5A5A;
            font-size: 16px;
            font-family: @font--poppins-regular;
            margin-bottom: 20px;
            margin-top: 10px;
        }
        &_NetworkCarousel{
            margin-top: 30px;
        }
        &_GoalsCarousel{
            margin-top: 30px;
        }
        .CardTitle_Section{
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
            margin-top: 1rem !important;
            padding-left: 1rem !important;
            padding-right: 1rem !important;
        }
        &_ExploreCarousel{
            margin-top: 30px;
        }
        .ExploreCarousel_btnContainer{
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
        }
        .Explore_CardTitle_Section{
            display: flex !important;
            align-items: center !important;
            margin-top: 1rem !important;
            padding-left: 1rem !important;
            padding-right: 1rem !important;
            justify-content: space-between !important;
            flex-wrap: wrap !important;
            gap: 20px;
        }
        .CardBody_Section{
            display: flex;
            justify-content: start;
            flex-direction: column;
            text-align: start;
            padding-left: 10px !important;
        }
    }
}
.studentDashboard_Dashboard .ExploreCarousel_btnContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
@media (max-width: 768px) {
    .studentDashboard {
        &_Mobile {
            display: flex;

            &_Section {
                display: flex;
                justify-content: center;
                height: 50px;
                position: fixed;
                top: 0;
                width: 100%;
                z-index: 9;
                align-items: center;
                border-bottom: 1px solid #e3e3e3;
                background-color: @backcolor--white;

                &_Title {
                    p {
                        font-size: 18px;
                        font-family: @font--poppins-500;
                        margin-bottom: 0px;
                    }
                }
            }
        }
    
        &_Dashboard {
            margin-top: 60px;
            padding: 10px;
            .CardTitle_Section {
                display: flex !important;
                justify-content: space-between !important;
                flex-wrap: wrap;
                gap: 20px;
                align-items: center !important;
                margin-top: 1rem !important;
            }
        }
    }
}
.outline-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    margin-top: auto;
    color: #1C84EE;
    border: 1px solid #1C84EE;
    width: 85%;
    height: 42px;
    border-radius: 10px;
    font-family: 'poppins-500', sans-serif;
    padding-inline: 1em;
    cursor: pointer;
}

.dashSubContainer {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    margin-top: 1rem !important;
}


/* Hide SVG icons on desktop and show text */
.button-svg {
    display: none;
}

/* Show SVG icons only on mobile and tablet */
@media (max-width: 768px) {
    .button-text {
        display: none;
    }
    .button-svg {
        display: inline;
    }
    .updateDashboard{
        margin-top: 1rem !important;
    }
}

.buttonIcon {
    display: none;
  }
  
  .buttonText {
    display: inline;
  }
  
  @media (max-width: 768px) {
    .buttonIcon {
      display: inline;
    }
  
    .buttonText {
      display: none;
    }
  }
  