@import "../../../../assets/css/variables.less";

.Advisor-MyNetwork{
    &__tabs-sec{
        .milestoneSec__tabContent {
            display: flex;
            justify-content: center;
            &--left{
                .leftCard{
                    background-color: @backcolor--white;
                    border: 1px solid #CDCDCD;
                    border-radius: 10px;
                    height: 45rem;
                    padding: 25px 30px;

                    &--title{
                        font-size: 20px;
                        font-family: @font--poppins-600;
                    }
                    &__subDetails{
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;

                        & p{
                            font-family: @font--poppins-regular;
                            color: #5C5C5C;
                            font-size: 14px;
                        }
                    }
                    &__description{
                        margin-block: 20px;

                        &--title{
                            font-family: @font--poppins-500;
                            font-size: 17px;
                        }
                        &--subTitle{
                            font-family: @font--poppins-regular;
                            color: #5C5C5C;
                            font-size: 14px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 6;
                            -webkit-box-orient: vertical;
                            word-break: break-word;
                            cursor: default;
                        }
                    }
                    &__resourceSec{
                        margin-block: 30px;
                        display: flex;
                        flex-direction: column;
                        gap: 30px;

                        &__topSec{
                            display: flex;
                            gap: 10px;

                            &--title{
                                font-family: @font--poppins-500;
                                font-size: 17px;
                                margin-bottom: 0;
                            }
                        }
                        &__bottomSec{
                            display: flex;
                            flex-direction: column;
                            gap: 20px;
                            padding-inline-end: 10px;
                            max-height: 300px;
                            min-height: 200px;
                            overflow: scroll;
                        }
                        &__noDataSec{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            padding: 20px;
                            min-height: 150px;
                            border: 1.5px solid #E3E3E3;
                            border-radius: 10px;

                            & p{
                                font-family: @font--poppins-500;
                                font-size: 16px;
                                color: #919293;
                            }
                        }
                    }
                }
                
            }
            &--right{
                .rightCard{
                    background-color: @backcolor--white;
                    border: 1px solid #CDCDCD;
                    border-radius: 10px;
                    height: 45rem;
                    padding: 25px 0;
                    display: flex;
                    flex-direction: column;
                    gap: 30px;

                    &__topSec{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 10px;
                        
                        &--title{
                            font-size: 16px;
                            font-family: @font--poppins-500;
                            margin-bottom: 0;
                        }

                    }

                    .Timelinecontainer{
                        overflow-y: scroll;

                        
                    }
                }
            }
        }
    }
}