@import "../../../assets/css/variables.less";


.goalsModal {
    &__viewProfile-sec {
        padding: 20px 140px;
    }

    .advisorProfileCard {
        padding: 30px 25px;
        border: 2px solid #E3E3E3;
        border-radius: 15px;
        background-color: #fff;

        .pkgCard-btnSec__primary-btn {
            background-color: #1C84EE;
            color: #fff;
            border: 1px solid #1C84EE;
            width: 100%;
            height: 40px;
            border-radius: 10px;

            &__text {
                margin-bottom: 0px;
                font-size: 16px;
                font-family: @font--poppins-regular;
            }
        }

        &__container {
            display: flex;
            justify-content: space-between;
        }

        &__info-sec {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            &__sub-img {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 140px !important;
                height: 140px !important;
                border-radius: 50% !important;
                overflow: hidden !important;
            }

            &__profileCont {
                margin-left: 30px;

                .profileName {
                    font-family: @font--poppins-600;
                    font-size: 32px;
                    margin-bottom: 10px;
                }

                &__about-sec {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    span {
                        display: flex;
                        align-items: center;
                        gap: 20px;

                        .label {
                            font-size: 14px;
                            font-family: @font--poppins-500;
                            margin: 0;
                            color: #515050;
                        }
                    }
                }
            }
        }

        &__connect-btn {
            &__primary-btn {
                width: 160px;
                height: 40px;
                background-color: #1C84EE;
                color: #fff;
                border-radius: 10px;
                border: none;
                text-transform: none;
                font-family: @font--poppins-regular;

                &:hover {
                    background-color: #1C84EE;
                    color: #fff;
                    border: none;
                }
            }

            &__secondary-btn {
                width: 160px;
                height: 40px;
                border: 2px solid #E64A19;
                color: #E64A19;
                border-radius: 10px;
                background-color: transparent;
                font-family: @font--poppins-regular;

                &:hover {
                    border: 2px solid #E64A19;
                    color: #E64A19;
                    background-color: transparent;
                }
            }
        }

        &__Services {
            padding: 30px 25px;
            border: 2px solid #E3E3E3;
            border-radius: 15px;
            background-color: @backcolor--white;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 30px;

            .servicelist {
                display: flex;
                gap: 20px;
                align-items: center;

                .label {
                    font-size: 14px;
                    font-family: @font--poppins-500;
                    margin: 0;
                    color: #515050;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: wrap;
                }

                .label-pills {
                    font-size: 14px;
                    font-family: @font--poppins-500;
                    margin: 0;
                    color: #515050;
                    overflow: hidden;
                    display: flex;
                    flex-wrap: wrap;
                }
            }
        }
    }

    .advisorCoursesCard,
    .advisorPackageCard {
        &__heading {
            font-family: @font--poppins-500;
            font-size: 22px;
            margin-bottom: 10px;
            color: #1B1C1E;
        }

        &__courseCards-sec {
            position: relative;

            .owl-carousel {
                .owl-stage-outer {
                    .owl-stage {
                        display: flex;

                        .owl-item {
                            margin-inline: auto;

                            .owl__item {
                                height: 100%;
                            }

                            .advProfile--CourseSec {
                                &__CourseSec {
                                    &__courseCard {
                                        border: 2px solid #E3E3E3;
                                        margin: 0;
                                        height: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .owl-prev,
            .owl-next {
                position: absolute;
            }

            .owl-prev {
                left: -35px;
                top: 60px;
            }

            .owl-nav {
                .owl-next {
                    right: -35px !important;
                    top: 60px;
                }
            }

            .owl-theme .owl-nav [class*='owl-'] {
                background: transparent !important;
                color: black !important;
                font-size: 40px;

            }
        }

        &__packCard-sec {
            position: relative;

            .owl-carousel {
                .owl-stage-outer {
                    .owl-stage {
                        display: flex;

                        .owl-item {
                            margin-inline: auto;
                        }
                    }
                }
            }

            .owl-prev,
            .owl-next {
                position: absolute;
            }

            .owl-prev {
                left: -35px;
                top: 160px;
            }

            .owl-nav {
                .owl-next {
                    right: -35px !important;
                    top: 160px;
                }
            }

            .owl-theme .owl-nav [class*='owl-'] {
                background: transparent !important;
                color: black !important;
                font-size: 40px;

            }
        }
    }

}

.networkText {
    color: #515050;
    font-family: @font--poppins-regular;

    font-size: 18px;

}

.packageTitle {
    color: #000;
    font-family: @font--poppins-600;
    font-size: 22px;
    font-style: normal;

}

.packageSession {
    color: #000;
    font-family: @font--poppins-500;
    font-size: 15px;
}

.packageAdvisor {
    display: flex;
    flex-wrap: wrap;
    gap: 15px
}

.subPackagePrice {
    color: #000;
    font-family: @font--poppins-regular;
    font-size: 15px;
    font-style: normal;
}

.courseCardTitle {
    color: #3C3C3C;
    font-family: @font--poppins-600;
    font-size: 17px;

}

.advPackageGrid {
    height: 100%;
    width: 24%;
}


.flex-grow-1 {
    flex-grow: 1;
}

.courseSubCardTitle {
    color: #3C3C3C;
    font-family: @font--poppins-500;
    font-size: 14px;
    margin-right: 10px;

}

.courseSubParah {
    color: #515050;
    font-family: @font--poppins-500;
    font-size: 14px;
}

.coursePara {
    color: #515050;
    font-size: 14px;
    font-family: @font--poppins-300;
    font-weight: 400px;
}

.reviewerName {
    color: #3C3C3C;
    font-family: @font--poppins-500;
    font-size: 18px;
}

.reviewedDate {
    color: #5C5C5C;
    font-family: @font--poppins-300;
    font-size: 14px;
}

.reviewMsg {
    color: #1B1C1E;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media only screen and (max-width: 1204px) {
    .advPackageGrid {
        width: 30%;
    }

    .packageAdvisor {
        display: flex;
        flex-wrap: wrap;
        gap: 20px
    }

    .advisorProfileCard {
        &__connect-btn {
            &__primary-btn {
                width: 180px !important;
                height: 40px;
                background-color: #1C84EE;
                color: #fff;
                border-radius: 10px;
                border: none;
                text-transform: none;
                font-family: @font--poppins-regular;

                &:hover {
                    background-color: #1C84EE;
                    color: #fff;
                    border: none;
                }
            }
            &__secondary-btn {
                width: 180px !important;
                height: 40px;
                border: 2px solid #E64A19;
                color: #E64A19;
                border-radius: 10px;
                background-color: transparent;
                font-family: @font--poppins-regular;
    
                &:hover {
                    border: 2px solid #E64A19;
                    color: #E64A19;
                    background-color: transparent;
                }
            }
        }


    }
}



@media only screen and (max-width: 760px) {
    .advPackageGrid {
        width: 48%;
    }

}

@media only screen and (max-width: 732px) {
    .advPackageGrid {
        width: 48%;
    }

    .goalsModal {
        .advisorProfileCard {
            &__container {
                justify-content: space-around;
            }

            &__info-sec {
                justify-content: space-around;
            }
        }
    }
}

@media only screen and (max-width: 625px) {
    .advPackageGrid {
        width: 100%;
    }
}

@media only screen and (max-width: 520px) {
    .goalsModal {
        .modal-body {
            padding: 20px 10px !important;

            .advisorProfileCard {
                padding: 30px 15px;

                &__info-sec {
                    &__sub-img {
                        margin-bottom: 20px;
                    }

                    &__profileCont {
                        margin: 0 0 20px 0;
                    }
                }
            }

            .advisorCoursesCard {
                &__courseCards-sec {
                    margin: 0 30px;

                    .owl-carousel {

                        .owl-prev,
                        .owl-next {
                            position: absolute;
                        }

                        .owl-nav {
                            .owl-prev {
                                left: -45px !important;
                                top: 60px;
                            }
                        }

                        .owl-nav {
                            .owl-next {
                                right: -35px !important;
                                top: 60px;
                            }
                        }

                        .owl-theme .owl-nav [class*='owl-'] {
                            background: transparent !important;
                            color: black !important;
                            font-size: 40px;

                        }
                    }
                }
            }

            .advisorPackageCard {
                &__packCard-sec {
                    margin: 0 30px;

                    .owl-carousel {
                        .owl-nav {
                            .owl-prev {
                                left: -45px !important;
                                top: 160px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 400px) {
    .goalsModal {
        .modal-body {
            .advisorCoursesCard {
                &__courseCards-sec {
                    margin: 0;

                    .owl-carousel {

                        .owl-prev,
                        .owl-next {
                            position: unset;
                        }
                    }
                }
            }

            .advisorPackageCard {
                &__packCard-sec {
                    margin: 0;

                    .owl-carousel {

                        .owl-prev,
                        .owl-next {
                            position: unset;
                        }
                    }
                }
            }
        }
    }
}



.pkgCard-btnSec {
    &__primary-btn {
        border-radius: 10px;
        padding: 10px 50px;
        border: none;
        color: #ffffff;
        background-color: #1C84EE;
        font-family: 'poppins-500', sans-serif;
        font-size: 16px;
    }
}


.pkgCard-description {
    p {
        text-overflow: ellipsis;
        font-size: 14px;
        font-family: @font--poppins-regular;
        overflow: hidden;
        -webkit-box-orient: vertical;
        height: 66px;
        margin: 0;
        -webkit-line-clamp: 3;
        display: -webkit-box;
    }
}

.Advisor_PackageCard {
    padding: 15px;
    width: 380px;
    height: 620px;
    background-color: @backcolor--white;
    border-radius: 15px;
    border: 1px solid #e3e3e3;

    &_TopSection {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        &_Title {
            &_Name {
                font-size: 20px;
                font-family: @font--poppins-600;
                margin-bottom: 0px;
                text-overflow: ellipsis;
                overflow: hidden;
                -webkit-box-orient: vertical;
                width: 230px;
                height: 35px;
                margin: 0;
                -webkit-line-clamp: 1;
                display: -webkit-box;
                text-transform: capitalize;
            }
        }

        &_subText {
            &_Name {
                font-size: 14px;
                font-family: @font--poppins-regular;
                margin-bottom: 0px;
            }
        }
    }

    &_MidSection {
        height: 350px;

        &_Price {
            &_Text {
                font-size: 20px;
                font-family: @font--poppins-600;
                margin-bottom: 0px;

                span {
                    font-size: 20px;
                    font-family: @font--poppins-500;
                    margin-bottom: 0px;
                    color: @backcolor--primary;
                    margin-left: 10px;
                }
            }
        }

        &_PackageList {
            margin-top: 20px;

            &_Section {
                margin-bottom: 10px;

                &_Text {
                    font-size: 18px;
                    font-family: @font--poppins-500;
                    margin-bottom: 0px;

                    span {
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    &_FooterSection {
        &_Description {
            margin-bottom: 20px;

            &_Text {
                font-size: 14px;
                font-family: @font--poppins-regular;
                margin-bottom: 0px;
                text-overflow: ellipsis;
                overflow: hidden;
                -webkit-box-orient: vertical;
                height: 65px;
                margin: 0;
                -webkit-line-clamp: 3;
                display: -webkit-box;
                text-transform: capitalize;
            }
        }

        &_Container {
            display: flex;
            justify-content: center;
            align-items: center;

            &_PurchaseBtn {
                background-color: @backcolor--primary !important;
                border-radius: 10px !important;
                text-transform: none !important;
                width: 90%;
                height: 50px !important;
                color: @backcolor--white !important;
                font-size: 18px !important;
                font-family: @font--poppins-regular !important;
            }
        }
    }
}

@media only screen and (max-width: 400px) {
    .Advisor_PackageCard {
        padding: 15px;
        width: 100%;
        height: fit-content;
        background-color: @backcolor--white;
        border-radius: 15px;
        border: 1px solid #e3e3e3;

        &_TopSection {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            align-items: center;
            margin-bottom: 20px;
        }

        &_MidSection {
            height: 380px;
            margin-bottom: 20px;
        }

        &_FooterSection {
            &_Container {
                display: flex;
                justify-content: center;
                align-items: center;

                &_PurchaseBtn {
                    background-color: @backcolor--primary !important;
                    border-radius: 10px !important;
                    text-transform: none !important;
                    width: 90%;
                    height: 50px !important;
                    color: @backcolor--white !important;
                    font-size: 14px !important;
                    font-family: @font--poppins-regular !important;
                }
            }
        }
    }
}

.Subscriptiontext {
    font-size: 14px;
    font-family: @font--poppins-regular;
    margin-bottom: 0px;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    margin: 0;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    text-transform: none;
}