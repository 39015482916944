@import "../../../assets/css/variables.less";

.mywork_Page {
    &_Top {
        &_Desktop {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &_Title {
                p {
                    font-size: 20px;
                    font-family: @font--poppins-500;
                    margin-bottom: 0px;
                }
            }

            &_Btncnt {
                &_PlanBtn {
                    background-color: @backcolor--primary;
                    font-family: @font--poppins-regular;
                    border-radius: 10px;
                }
            }
        }

        &_Mobile {
            display: none;
        }

        @media screen and (max-width: 768px) {
            &_Desktop {
                display: none;
            }

            &_Mobile {
                display: flex;

                &__Section {
                    display: flex;
                    justify-content: center;
                    height: 50px;
                    position: fixed;
                    top: 0;
                    width: 100%;
                    z-index: 9;
                    align-items: center;
                    border-bottom: 1px solid #e3e3e3;
                    background-color: @backcolor--white;

                    &_Title {
                        p {
                            font-size: 18px;
                            font-family: @font--poppins-500;
                            margin-bottom: 0px;
                        }
                    }
                }

                &_Btncnt {
                    display: flex;
                    justify-content: end;
                    align-items: center;
                    margin-top: 60px;
                    width: 100%;
                    margin-right: 10px;

                    &_PlanBtn {
                        background-color: @backcolor--primary;
                        font-family: @font--poppins-regular;
                        border-radius: 10px;
                    }
                }
            }
        }
    }

    &_Mid {
        margin-top: 20px;

        &_Mobile {
            display: none;
        }

        &_Desktop {
            display: flex;

            &_TabCnt {
                // background-color: #FFFFFF;
                // border: 1px solid #e3e3e3;
                border-radius: 10px;
                width: 100%;
                padding-left: 20px;
                padding-right: 20px;

                .Tab {
                    padding: 0px;
                    padding-left: 30px;
                    padding-right: 30px;
                    height: 0px;
                    min-height: 49px;
                    font-family: @font--poppins-regular;
                    font-size: 18px;
                    text-transform: none;

                    &:hover {
                        color: @backcolor--primary;
                    }
                }
            }
        }

        @media screen and (max-width: 768px) {
            &_Desktop {
                display: none;
            }

            &_Mobile {
                display: flex;
            }
        }
    }

    &_Mid_id {
        margin-top: 20px;
    }

    @media screen and (max-width: 768px) {
        &_Mid {
            margin-top: 20px;
            padding: 0px 10px;
        }

        &_Mid_id {
            margin-top: 60px;
            padding: 0px 10px;
        }

        .mywork_Page_Top_Desktop_Btncnt {
            display: none;
        }
    }
}

.PlanSaveBtn {
    background-color: @backcolor--primary;
    font-family: @font--poppins-regular;
    font-size: 20px;
    border-radius: 10px;
    width: 150px;
    height: 50px;
}