@import "../../../../assets/css/variables.less";

.studentExploreContent {
    &__desktop {
        display: flex;
        align-items: center;
        column-gap: 10px;

        &--title {
            font-family: @font--poppins-500;
            font-size: 32px;
            margin-bottom: 0;
        }

        &__goBackIcon {
            cursor: pointer;
        }
    }

    &__mobile {
        display: none;
    }

    .sectionDescription {
        margin-bottom: 0;
        font-family: @font--poppins-500;
        font-size: 20px;
    }

    &__topLeftSec {
        display: flex !important;
        flex-direction: column !important;
        row-gap: 20px;

        &__infoCard {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #FFFFFF;
            border: 1px solid #e3e3e3;
            border-radius: 10px;
            min-height: 100px;
            padding: 10px 20px;

            &--primaryText {
                color: #1C84EE;
                font-family: @font--poppins-regular;
                cursor: pointer;

            }

            &--secondarytext {
                font-family: @font--poppins-regular;
                font-size: 16px;
                margin-bottom: 0;
                color: #9A9A9A;
            }
        }

        &__recommendationSec {
            padding-inline: 10px;
            display: flex;
            flex-direction: column;
            gap: 30px;

            .owl-carousel {
                .owl-nav {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    .owl-prev {
                        position: relative;
                        left: -15px;
                    }

                    .owl-next {
                        position: relative;
                        right: -15px;
                    }
                }
            }
        }
    }

    &__topRightSec {
        padding-left: 10px;

        &__searchFilterSec {
            display: flex;
            flex-direction: column;
            gap: 20px;

            &--filterBtn {
                width: 90px;
                padding: 5px 10px;
                background-color: #fff;
                border: 1px solid #e3e3e3;
                display: flex;
                border-radius: 5px;
                gap: 5px;
                margin-left: auto;
            }

            &__filterSection {
                padding: 20px 10px;
                background-color: #fff;
                border-radius: 8px;
                border: 1px solid #e3e3e3;

                &__topSec {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    p {
                        margin-bottom: 0;
                    }

                    &--primaryText {
                        font-family: @font--poppins-500;
                        color: #1B1C1E;
                    }

                    &--secondaryText {
                        font-family: @font--poppins-regular;
                        font-size: 14px;
                        cursor: pointer;

                        &:hover {
                            color: #000;
                        }
                    }
                }
            }

            &__accordianSec {
                margin-block: 40px;
            }

            &__button-sec {
                width: 100%;
                display: flex;
                justify-content: center;
                margin-top: 70px;

                &--primary-btn {
                    background-color: #1C84EE;
                    color: #fff;
                    border: none;
                    width: 100%;
                    height: 42px;
                    border-radius: 10px;
                    font-family: @font--poppins-500;
                }
            }
        }
    }

    &__bottomSec {
        display: flex;
        flex-direction: column !important;
        gap: 30px !important;

        span {
            display: flex;
            flex-direction: column;
            gap: 30px;
        }
    }

    .owl-carousel {
        .owl-nav {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            display: flex;
            justify-content: space-between;

            .owl-prev,
            .owl-next {
                position: relative;
                background: transparent;
                color: black;
                font-size: 40px;
            }

            .owl-prev {
                left: -25px;
            }

            .owl-next {
                right: -25px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        &__desktop {
            display: none !important;
        }

        &__mobile {
            background-color: #fff;
            border-bottom: 1px solid #e3e3e3;
            height: 50px;
            display: flex !important;
            justify-content: center;
            width: 100%;
            align-items: center;
            z-index: 99;

            &--title {
                font-family: @font--poppins-500;
                font-size: 26px;
                margin: 0;
            }
        }

        &__topLeftSec,
        &__topRightSecc,
        &__bottomSec {
            padding: 15px !important;
            padding-left: 50px !important;
        }

    }

    @media screen and (max-width: 899px) {
        .parentExploreSearch {
            margin-top: 8rem;
        }

        &__topLeftSec {
            order: 1;
        }

        &__topRightSecc {
            order: 0;

            &__searchFilterSec {
                max-width: 400px;
                margin-inline: auto;
            }
        }

        &__bottomSec {
            order: 2;
        }
    }

    @media screen and (max-width: 425px) {
        &__mobile {
            &--title {
                font-size: 22px;
            }
        }

        &__topLeftSec {
            &__recommendationSec {
                padding-inline: 0 !important;
            }
        }

        &__bottomSec,
        &__topLeftSec {
            .owl-carousel {
                .owl-nav {
                    .owl-next {
                        right: -7px !important;
                        top: unset;
                        margin: 0;
                    }

                    .owl-prev {
                        left: -20px !important;
                        top: unset;
                        margin: 0;
                    }
                }
            }
        }

    }

    @media screen and (max-width: 320px) {

        &__bottomSec,
        &__topLeftSec {
            .owl-carousel {
                .owl-nav {
                    display: block;
                    top: unset !important;
                }
            }
        }
    }
}