@import "../../../../assets/css/variables.less";

.myWork-studentPlanSec{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    max-height: 90vh;
    overflow-y: scroll;
    
    .cardgrid__dot{
        &__dropDownContainer{
            &__menu{
                &__item{
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    padding-inline: 8px;

                    &__icon{
                        width: 17px;
                    }
                    &__text{
                        font-family: @font--poppins-500;
                        font-size: 13px;
                    }
                }
            }
        }
    }

    &__ctaSection{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &__primary-btn{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #1C84EE;
            color: #fff;
            border: none;
            width: 85%;
            height: 42px;
            border-radius: 10px;
            font-family: @font--poppins-500;

            &:hover{
                cursor: pointer;
            }
        }
    }

    &__noDataSec{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-inline: auto;
        gap: 20px;
        border-radius: 10px;
        padding: 60px 40px;
        min-height: 100px;
        min-width: 260px;
        max-width: 500px;
        margin-top: 200px;
        transition: all 0.2s ease-out;
        box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.1);
        border: 1px solid #cccccc;
        background-color: #fff;
        &__textBlack{
            text-align: center;
            font-family:  @font--poppins-regular;
            margin-bottom: 0;
            color: #000;
        }
    }
}

.fadedSectionLoader{
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    bottom: 0;
    z-index: 999;
    backdrop-filter: blur(5px);
    opacity: .5;
    mask: linear-gradient(transparent, black 75%); 
}