@import "variables.less";
@import "fonts.less";

.planviewcnt {
    padding: 10px;

    .planviewmobile {
        display: none;
    }

    .planviewcnttopsec {
        padding: 15px;
        .plancnttitle {
            p {
                font-size: 14px;
                font-family: @font--poppins-300;
            }
        }

        .planMaintitle {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            justify-content: space-between;
            p {
                font-size: 24px;
                font-family: @font--poppins-500;
                margin-left: 20px;
            }
        }

        &__emptyState{
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 70vh;

            &__content{
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;
                width: 90%;
                margin-inline: auto;
                min-height: 240px;
                border-radius: 13px;

                &__empty-text{
                    font-family: @font--poppins-500;
                    font-size: 16px;
                    color: #919293;
                }
                &__empty-cta{
                    font-family: @font--poppins-500;
                    font-size: 18px;
                    color: #1C84EE;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }

        .topbariconcnt {
            display: flex;
            justify-content: flex-end;

            .topicons {
                margin-right: 10px;
                margin-left: 10px;
                cursor: pointer;
            }
        }

        .topbarsubtitle {
            margin-left: 45px;

            .suptitlelabel {
                font-size: 16px;

                .subtitle {
                    font-size: 16px;
                    font-family: @font--poppins-500;
                }

                .subtitledropicon {
                    margin-left: 10px;
                    cursor: pointer;
                }
            }
        }
    }
    .topbarnoAdvisorbar-mobileview{
        display: none;
    }
    .topbarnoAdvisorbar {
        height: 90px;
        width: 100%;
        padding-left: 50px;
        padding-right: 50px;
        .topbarnoAdvisorcnt {
            border: 1px solid rgb(201, 201, 201);
            height: 70px;
            background-color: #eee;
            border-radius: 10px;

            .emptyadv {
                margin-left: 10px;
                margin-top: 10px;
                height: 70px;
            }

            .parastyle {
                line-height: 60px;
                height: 70px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                p {
                    font-size: 16px;
                    font-family: @font--poppins-500;
                    
                }
            }

            .Findadvisorsbtncnt {
                display: flex;
                justify-content: flex-end;

                .Findadvisorsbtn {
                    height: 50px;
                    width: 150px;
                    margin-top: 10px;
                    border-radius: 10px;
                    background-color: @backcolor--primary;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .planviewcntmidsec {
        padding: 20px;

        // Tabs Course section
        .nav-pills .nav-link.active,
        .nav-pills .show>.nav-link {
            color: @backcolor--primary;
            background-color: transparent !important;
            border-bottom: 3px solid @backcolor--primary;
            border-radius: unset;
            cursor: pointer;
        }

        .nav-pills .nav-link {
            cursor: pointer;
            font-size: 16px;
            font-family: @font--poppins-500;
            color: @color--secondary;
            overflow: hidden;
            width: fit-content !important;
            margin-right: 20px;
            margin-bottom: 30px;
        }

        .addphase {
            color: black;
            font-size: 16px;
            font-family: @font--poppins-500;
        }

        // Course section
        .plancoursecnt {
            padding: 10px;

            .plancoursebox {
                border-bottom: 1px solid rgb(201, 201, 201);

                .plancourseboxcnt {
                    .plancoursetitle {
                        font-size: 24px;
                        font-family: @font--poppins-500;
                    }

                    .plancoursesubtitle {
                        font-size: 16px;
                        color: @color--secondary;
                        line-height: 0px;
                    }

                    .plancoursedatacnt {
                        height: 90px;
                        width: 100%;
                        border: 1px solid rgb(211, 211, 211);
                        border-radius: 10px;
                        background-color: #fff;
                        padding: 10px;

                        .plancoursedatasec {
                            .plancourseheading {
                                font-size: 22px;
                                font-family: @font--poppins-500;
                                margin-left: 20px;
                            }

                            .plancoursesubhead {
                                font-size: 14px;
                                color: @color--secondary;
                                margin-left: 20px;
                                line-height: 0px;
                            }
                        }

                        .dropdowncnt {
                            display: flex;
                            justify-content: flex-end;
                            margin-top: 10px;

                            .dropdown-menu.show {
                                position: absolute !important;
                                background-color: #DFDFDF;
                                inset: 29px 0px auto !important;
                                transform: translate(-36px, 0px) !important;
                            }

                            .btn-primary {
                                background-color: #ffffff00 !important;
                                border-color: #ffffff00 !important;
                            }

                            .btn-primary:hover {
                                background-color: #ffffff00 !important;
                                border-color: #ffffff00 !important;
                            }

                            .primary.dropdown-toggle {
                                background-color: #dfdfdf00 !important;
                                border-color: #dfdfdf00 !important;
                            }

                            .btn-primary.dropdown-toggle:focus {
                                box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0) !important;
                            }

                            .dropdown-item:hover {
                                background-color: #dfdfdf00;
                            }

                            .dropdown-item {
                                color: @color--primary  !important;
                                font-size: 12px;
                                font-family: @font--poppins-regular;
                                padding-top: 4px !important;

                                &:hover {
                                    background-color: rgb(199, 199, 199);
                                }
                            }

                            .dropdown-menu {
                                border: 1px solid #DFDFDF;
                                border-radius: 8px;
                            }
                        }

                        &:hover {
                            transition: all 0.2s ease-out;
                            box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
                            top: -4px;
                            border: 1px solid #cccccc;
                            background-color: @backcolor--white;
                        }
                    }
                }

                .Addcoursebtn {
                    margin-top: 20px;
                    margin-bottom: 20px;
                    border-radius: 10px;
                    background-color: @btn--primary;
                    font-size: 18px;
                    width: 200px;
                }
            }

            .planActivitybox {
                margin-top: 10px;

                .planActivityboxcnt {
                    .planActivitytitle {
                        font-size: 24px;
                        font-family: @font--poppins-500;
                    }

                    .planActivitysubtitle {
                        font-size: 16px;
                        color: @color--secondary;
                        line-height: 0px;
                    }
                    .plancoursedatacnt {
                        height: 90px;
                        width: 100%;
                        border: 1px solid rgb(211, 211, 211);
                        border-radius: 10px;
                        background-color: #fff;
                        padding: 10px;

                        .plancoursedatasec {
                            .plancourseheading {
                                font-size: 22px;
                                font-family: @font--poppins-500;
                                margin-left: 20px;
                            }

                            .plancoursesubhead {
                                font-size: 14px;
                                color: @color--secondary;
                                margin-left: 20px;
                                line-height: 0px;
                            }
                        }

                        .dropdowncnt {
                            display: flex;
                            justify-content: flex-end;
                            margin-top: 10px;

                            .dropdown-menu.show {
                                position: absolute !important;
                                background-color: #DFDFDF;
                                inset: 29px 0px auto !important;
                                transform: translate(-36px, 0px) !important;
                            }

                            .btn-primary {
                                background-color: #ffffff00 !important;
                                border-color: #ffffff00 !important;
                            }

                            .btn-primary:hover {
                                background-color: #ffffff00 !important;
                                border-color: #ffffff00 !important;
                            }

                            .primary.dropdown-toggle {
                                background-color: #dfdfdf00 !important;
                                border-color: #dfdfdf00 !important;
                            }

                            .btn-primary.dropdown-toggle:focus {
                                box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0) !important;
                            }

                            .dropdown-item:hover {
                                background-color: #dfdfdf00;
                            }

                            .dropdown-item {
                                color: @color--primary  !important;
                                font-size: 12px;
                                font-family: @font--poppins-regular;
                                padding-top: 4px !important;

                                &:hover {
                                    background-color: rgb(199, 199, 199);
                                }
                            }

                            .dropdown-menu {
                                border: 1px solid #DFDFDF;
                                border-radius: 8px;
                            }
                        }

                        &:hover {
                            transition: all 0.2s ease-out;
                            box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
                            top: -4px;
                            border: 1px solid #cccccc;
                            background-color: @backcolor--white;
                        }
                    }
                }

                .AddActivitybtn {
                    margin-top: 20px;
                    margin-bottom: 20px;
                    border-radius: 10px;
                    background-color: @btn--primary;
                    font-size: 18px;
                    width: 200px;
                    cursor: pointer;
                }
            }
        }
    }

}


// Course Dropzone
.coursedropzonebox {
    border: 1px solid rgb(199, 199, 199);
    border-style: dashed;
    height: 150px;
    border-radius: 5px;
    padding: 10px;
    background-color: #f8f8f8
}

.tab_card{
    &__addMileStonebtn, 
    &__addMileStonebtn:focus{
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        cursor: pointer;
        background: @btn--primary;
        border-radius: 7px;
        color: @btn--white;
        line-height: 36px;
        text-align: center;
        border: 2px solid @btn--primary;
        padding: 3px;
    }
    &__addMileStonebtn:hover {
        cursor: pointer;
        color: @color--black;
        background: @btn--white;
        border: 2px solid @btn--primary;
    }
}

.savecoursebtn {
    margin: 10px 0 20px 0;
    font-size: 16px;
    width: 180px;
    height: 40px;
    user-select: none;
    cursor: pointer;
    background: #1C84EE;
    border-radius: 10px;
    color: #fff;
    border: 2px solid #1C84EE;
    font-family: @font--poppins-500;

    &:hover {
        background-color: #fff;
        color: #000;
        border: 2px solid @backcolor--primary;
    }
}

.saveactivitybtn {
    margin-top: 20px;
    background-color: @backcolor--primary;
    border: 2px solid @backcolor--primary;
    height: 60px;
    border-radius: 10px;
    width: 100%;
    font-size: 20px;
    font-family: @font--poppins-500;

    &:hover {
        background-color: #fff;
        color: @backcolor--primary;
        border: 2px solid @backcolor--primary;
    }
}



// Media query Course Container
@media only screen and (max-width: 768px) {
    .planviewcnt {
        padding: 0px;
        .planviewmobile {
            display: block;
           

            .plantopmobile {
                border-bottom: 1px solid #eee;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;

                .mobileviewtitle {
                    font-size: 20px;
                    font-family: @font--poppins-500;
                    margin-top: 15px;
                }
            }
        }

        .planviewcnttopsec {
            margin-top: 56px;
            .plancnttitle {
                display: none;
            }

            .planMaintitle {
                display: flex;
                justify-content: start;
                margin-top: 10px;

                p {
                    font-size: 20px;
                    font-family: @font--poppins-500;

                    .planbackbtn {
                        margin-right: 5px;
                        cursor: pointer;

                        svg {
                            width: 25px;
                            height: 25px;
                        }
                    }

                    .planendbtn {
                        margin-left: 0px;
                        cursor: pointer;

                        svg {
                            width: 25px;
                            height: 25px;
                        }
                    }
                }
            }

            .topbariconcnt {
                display: flex;
                justify-content: space-evenly;

                .topicons {
                    margin-right: 10px;
                    margin-left: 10px;
                    cursor: pointer;
                }
            }

            .topbarsubtitle {
                margin-top: 20px;
                margin-left: 45px;

                .suptitlelabel {
                    font-size: 16px;

                    .subtitle {
                        font-size: 16px;
                        font-family: @font--poppins-500;
                    }

                    .subtitledropicon {
                        margin-left: 10px;
                        cursor: pointer;
                    }
                }
            }
        }
        .topbarnoAdvisorbar{
            display: none;
        }
    }
    .coursedropzonebox{
        .dropzonetype{
            font-size: 14px;
            line-height: 0px;
        }
    }
}