@import "variables.less";
@import "fonts.less";

.Notification_Page {
    .Notification_Page_Top {
        .Notification_Page_Title_Cnt {
            .Notification_Page_Title {
                font-size: 30px;
                font-family: @font--poppins-500;
            }
        }

        .Notification_Mobile_Page_Title_Cnt {
            display: none;
        }
    }

    @media only screen and (max-width: 768px) {
        .Notification_Page_Top {
            .Notification_Page_Title_Cnt {
                display: none;
            }

            .Notification_Mobile_Page_Title_Cnt {
                display: flex;

                .My_Work_Top_Mobile {
                    display: flex;
                    justify-content: center;
                    height: 50px;
                    position: fixed;
                    top: 0;
                    width: 100%;
                    z-index: 9;
                    align-items: center;
                    border-bottom: 1px solid #e3e3e3;
                    background-color: @backcolor--white;

                    .My_Work_Top_Mobile_Title {
                        margin-top: 10px;

                        p {
                            font-size: 18px;
                            font-family: @font--poppins-500;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }

    .Notification_Page_Mid {
        margin-top: 30px;

        .Notification_Page_Mid_Desktop_Tabs {
            background-color: @backcolor--white;
            border: 1px solid #e3e3e3;
            border-radius: 10px;
            width: 100%;

            .Tab {
                padding: 0px;
                padding-left: 30px;
                padding-right: 30px;
                height: 0px;
                min-height: 49px;
                font-family: @font--poppins-regular;
                font-size: 18px;
                text-transform: none;

                &:hover {
                    color: @backcolor--primary;
                }
            }
        }
        @media only screen and (max-width: 768px) {
            .Notification_Page_Mid_Desktop_Tabs {
                margin-top: 70px;
            }
        }
        .GenerlTabs {
            margin-top: 30px;
        }
        .Unread_Request_Card{
            border: 1px solid @backcolor--primary;
            padding: 10px;
            background-color: @backcolor--white;
            border-radius: 10px;
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            min-width: 270px;
            margin-bottom: 20px;
            cursor: pointer;

            .ProfileImage {
                flex: 1 1 50px;
                max-width: 60px;
                display: flex;
                .prfimg{
                    width: 60px;
                    height: 60px;
                    border-radius: 100px;
                }
            }

            .RequestContent {
                flex: 2 1 60px;

                p {
                    margin-bottom: 0px;
                }

                &_Name {
                    font-size: 14px;
                    font-family: @font--poppins-regular;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;

                }

                &_Time {
                    font-size: 12px;
                    font-family: @font--poppins-regular;
                }

                &_btn_cnt {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 20px;
                    margin-top: 10px;

                    .acptbtn {
                        background-color: @backcolor--primary;
                        border: none;
                        font-family: @font--poppins-regular;
                        text-transform: capitalize;
                        border-radius: 10px;
                        height: 40px;
                        width: 100px;
                    }

                    .denybtn {
                        border-color: @backcolor--red;
                        font-family: @font--poppins-regular;
                        text-transform: capitalize;
                        border-radius: 10px;
                        color: @backcolor--red;
                        height: 40px;
                        width: 100px;
                    }
                    .viewbtn{
                        background-color: @backcolor--primary;
                        border: none;
                        font-family: @font--poppins-regular;
                        text-transform: capitalize;
                        border-radius: 10px;
                        height: 40px;
                    }
                }
            }
        }
        .Request_Card {
            padding: 10px;
            background-color: @backcolor--white;
            border: 1px solid #e3e3e3;
            border-radius: 10px;
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            min-width: 270px;
            margin-bottom: 20px;
            cursor: pointer;

            .ProfileImage {
                flex: 1 1 50px;
                max-width: 60px;
                display: flex;
                .prfimg{
                    width: 60px;
                    height: 60px;
                    border-radius: 100px;
                }
            }

            .RequestContent {
                flex: 2 1 60px;

                p {
                    margin-bottom: 0px;
                }

                &_Name {
                    font-size: 14px;
                    font-family: @font--poppins-regular;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;

                }

                &_Time {
                    font-size: 12px;
                    font-family: @font--poppins-regular;
                }

                &_btn_cnt {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 20px;
                    margin-top: 10px;

                    .acptbtn {
                        background-color: @backcolor--primary;
                        border: none;
                        font-family: @font--poppins-regular;
                        text-transform: capitalize;
                        border-radius: 10px;
                        height: 40px;
                        width: 100px;
                    }

                    .denybtn {
                        border-color: @backcolor--red;
                        font-family: @font--poppins-regular;
                        text-transform: capitalize;
                        border-radius: 10px;
                        color: @backcolor--red;
                        height: 40px;
                        width: 100px;
                    }
                    .viewbtn{
                        background-color: @backcolor--primary;
                        border: none;
                        font-family: @font--poppins-regular;
                        text-transform: capitalize;
                        border-radius: 10px;
                        height: 40px;
                    }
                }
            }
        }

    }
}