@import "../../../../assets/css/variables.less";

.Modal-header{
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    &-title{
        margin-bottom: 0px;
        font-size: 22px;
        font-family: @font--poppins-500;
    }
}

@media screen and (max-width: 400px) {
    .book-view-modal{
        min-width: 83% !important;
    }
    
}