@import "../../../assets/css/variables.less";

.pricingPackage{
    &__midSec{
        display: flex;
        // flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        gap: 30px;
        width: 600px;
        padding-top: 30px;
        padding-bottom: 90px;

        &__title {
            font-size: 44px;
            line-height: 64px;
            color: @color--cta--underline;
            font-family: @font--poppins-500;
            margin-inline: auto;

            svg{
                margin-right: 20px;
            }
        }

        &__noDataSec{
            max-width: 380px;
            padding: 40px;
            border-radius: 20px;
            border: 1px solid #C7C7C7;
            background: #FFF;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &__mainText{
                color: #8d8d8d;
                font-size: 18px;
                font-family: @font--poppins-500;
            }

            &__subText{
                color: #8d8d8d;
                font-size: 13px;
                margin-inline: auto;
                font-family: @font--poppins-regular;
            }
        }

        &__btnSection{
            display: flex;
            width: 80%;
            margin-inline: auto;
            
            &__primaryBtn{
                margin-inline: auto;
                min-width: 380px;
                height: 52px;
                font-size: 17px;
                color: #FFF;
                border: none;
                border-radius: 10px;
                background-color: #1c84ee;
                font-family: @font--poppins-500;
            }
        }
    }
}

.pricingPackageModal{
    padding: 20px 40px !important;

    &__title{
        font-size: 34px !important;
        font-family: @font--poppins-500 !important;
    }

    &__form{
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-inline: auto;
        width: 480px;
        margin-top: 50px;

        &__serviceFrequency, 
        &__packPrice, 
        &__paidSession, 
        &__sessionLength,
        &__sessionExpiry,
        &__serviceType,
        &__courses,
        &__maxChatMessages{
            &__title{
                font-size: 18px;
                font-family: @font--poppins-500 !important;
            }
        }
        &__serviceFrequency{
            &__radioGroup{
                display: flex;
                gap: 80px;
            }
            .MuiFormControlLabel-label{
                font-size: 16px;
                font-family: @font--poppins-500;
            }
        }
        
        &__serviceType{
            .serviceType-Sec{
                display: flex;
                gap: 10px;
            }
            &__label{
                display: flex;
                align-items: flex-start !important;
                &__checkBox{
                    padding: 5px !important;
                }
                .MuiFormControlLabel-label{
                    margin-top: 6px;
                    font-family: @font--poppins-500;
                }
            }
        }

        &__courses{
            &__coursesSec{
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                &__courseLabel{
                    margin: 0 !important;
                }
                .courseDetailCard{
                    max-width: 220px;
                }
            }
        }

        &__buttonSec{
            display: flex;
            justify-content: flex-end;
            
            &__primaryBtn{
                font-size: 17px;
                color: #FFF;
                padding: 10px 50px;
                border: none;
                border-radius: 10px;
                background-color: #1c84ee;
                font-family: @font--poppins-500;
            }
        }
    }
}

@media only screen and (max-width: 728px) {
    .pricingPackage{
        &__midSec{
            width: unset;

            &__title {
                font-size: 36px;

                svg{
                    margin-right: 16px;
                }
            }

            &__noDataSec{
                max-width: 320px;
                padding: 40px 20px;

                &__mainText{
                    font-size: 16px;
                }
    
                &__subText{
                    font-size: 12px;
                }
            }
            &__btnSection{
                width: 100%;

                &__primaryBtn{
                    min-width: 270px;  
                    font-size: 17px;
                }
            }
        }
    }
    .pricingPackageModal{
        padding: 20px 20px !important;

        &__form{
            width: unset;
        }
    }
}

@media only screen and (max-width: 425px) {
    .pricingPackage{
        &__midSec{
            padding-top: 60px;

            &__title {
                font-size: 28px;
                margin-inline: auto;
                text-align: center;
                line-height: 48px;

                svg{
                    margin-right: 15px;
                }
            }
            &__noDataSec{
                max-width: 260px;
                padding: 30px 20px;

                &__mainText{
                    font-size: 14px;
                }
    
                &__subText{
                    font-size: 12px;
                }
            }
        }
        &__btnSection{
            &__primaryBtn{
                min-width: 260px;  
                font-size: 15px;
            }
        }
    }
    .pricingPackageModal{
        padding: 20px 10px !important;

        &__title{
            margin-top: 20px;
            font-size: 28px !important;
            text-align: center;
        }

        &__form{
            &__serviceFrequency{
                &__radioGroup{
                    gap: 20px;
                }
            }
            &__courses{
                &__coursesSec{
                    .courseFormGroup{
                        justify-content: center;
                    }
                }
            }
        }
    }
}